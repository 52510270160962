import { injectIntl, intlShape } from 'react-intl'

// import { Button } from 'packages/components/buttons'

import Card from '../Card'
import { Expire, Status, UpgradeButton } from '../../nodes'

const TrialEndedCard = ({
  onSelectPlan,
  planName,
  // onDowngradeToFree,
  intl: { formatMessage },
}) => {
  return (
    <Card
      planName={planName}
      Status={
        <Status status='error'>
          {formatMessage({ id: 'accountBilling.billing.status.expired' })}
        </Status>
      }
      Expire={<Expire>{formatMessage({ id: 'accountBilling.billing.expired' })}</Expire>}
      RightBlock={
        <>
          <UpgradeButton onClick={onSelectPlan}>
            {formatMessage({ id: 'accountBilling.billing.upgrade' })}
          </UpgradeButton>

          {/* <Button view='primaryText' onClick={onDowngradeToFree}>
            {formatMessage({ id: 'accountBilling.billing.downgrade' })}
          </Button> */}
        </>
      }
    />
  )
}

TrialEndedCard.propTypes = {
  intl: intlShape.isRequired,
}

TrialEndedCard.defaultProps = {}

export default injectIntl(TrialEndedCard)
