import React from 'react'
import { injectIntl, intlShape } from 'react-intl'

import { HeaderStyled } from 'packages/components/layout/nodes'
import {
  Container,
  Header,
  Teaser,
  TeaserText,
  Logo,
  FormWrapper,
  Grid,
  Notes,
  NoteHeader,
  Step,
  StepContent,
  StepNumber,
  StepWrapper,
  Paragraph,
  // VideoLink,
  CodeSample,
} from '../nodes'
import Image from 'packages/components/gatsby-image'
import calendly from '../../assets/calendly.png'

import { Button } from 'packages/components/buttons'
import { wrapper } from '../../../../containers/integrations/IntegrationsDetails'
import calendly_instructions from '@configurator/components/integrations/assets/calendly_instructions.png'

const Calendly = ({ intl: { formatMessage } }) => {
  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header>
          {formatMessage({
            id: 'integration.calendly.header',
          })}
        </Header>
        <Teaser>
          <Logo>
            <Image
              fluid={{
                src: calendly,
                aspectRatio: 1,
              }}
              alt=''
              fadeIn
              imgStyle={{ objectFit: 'contain' }}
              loading='lazy'
              backgroundColor='white'
            />
          </Logo>
          <TeaserText small>
            {formatMessage({
              id: 'integration.calendly.teaser',
            })}
          </TeaserText>
          <div>
            <Button
              as='a'
              href={'https://calendly.com/signup'}
              target='_blank'
              content={formatMessage({
                id: 'integration.start.free',
              })}
            />
          </div>
        </Teaser>
        <Grid>
          <div>
            <FormWrapper>
              <Image
                fluid={{
                  src: calendly_instructions,
                  aspectRatio: 5 / 4,
                }}
                alt=''
                fadeIn
                loading='lazy'
                backgroundColor='white'
                style={{ top: -35 }}
              />
            </FormWrapper>
          </div>
          <div>
            <Notes>
              <NoteHeader>
                {formatMessage({
                  id: 'integration.calendly.note.header',
                })}
              </NoteHeader>
              <StepWrapper>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integration.calendly.step.1',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={25}>
                      <b>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: formatMessage({
                              id: 'integration.calendly.step.1.text',
                            }),
                          }}
                        />
                      </b>
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integration.calendly.step.2',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      <b>
                        {formatMessage({
                          id: 'integration.calendly.step.2.text',
                        })}
                      </b>
                    </Paragraph>
                    <CodeSample normal mb={20}>
                      {`<iframe src="`}
                      <b>
                        {formatMessage({
                          id: 'integrations.calendly.sample.header',
                        })}
                      </b>
                      {`" width="100%" height="950" scrolling="no" frameborder="0">`}
                      {`</iframe>`}
                    </CodeSample>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integration.calendly.step.3',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      <b>
                        {formatMessage({
                          id: 'integration.calendly.step.3.text',
                        })}
                      </b>
                    </Paragraph>

                    {/*<VideoLink*/}
                    {/*    href={"https://vimeo.com/583278750"}*/}
                    {/*    target={"_blank"}*/}
                    {/*>*/}
                    {/*    {formatMessage({*/}
                    {/*        id: "integration.watch.tutorial",*/}
                    {/*    })}*/}
                    {/*</VideoLink>*/}
                  </StepContent>
                </Step>
              </StepWrapper>
            </Notes>
          </div>
        </Grid>
      </Container>
    </>
  )
}

Calendly.propTypes = {
  intl: intlShape.isRequired,
}

Calendly.defaultProps = {}

export default wrapper(injectIntl(Calendly), 'calendly')
