import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { setActiveTab } from './actions'

const defaultState = fromJS({
  activeTab: 0,
})

export default handleActions(
  {
    [setActiveTab]: {
      next(state, { payload }) {
        return state.set('activeTab', payload)
      },
    },
  },
  defaultState
)
