import styled from 'styled-components'

export const LayerContainer = styled.div`
  min-width: 100px;
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  opacity: 1;
  width: max-content !important;
`
