import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'

import { Button } from 'packages/components/buttons'

import { Expire, Status, TextBold, TextInterval } from '../../nodes'
import Card from '../Card'

const UnpaidCard = ({ planName, onManageSubscription, interval, intl: { formatMessage } }) => {
  return (
    <Card
      planName={planName}
      Status={
        <Status status='error'>
          {formatMessage({ id: 'accountBilling.billing.status.unpaid' })}
        </Status>
      }
      alertBlock={formatMessage({ id: 'accountBilling.billing.alert.disabled' })}
      RightBlock={
        <Button view='secondaryBlack' onClick={onManageSubscription}>
          {formatMessage({ id: 'accountBilling.billing.manage' })}
        </Button>
      }
      Expire={
        <Expire>
          <TextInterval>
            {formatMessage({ id: 'accountBilling.billing.paymentInterval' })}:
          </TextInterval>

          <TextBold>{interval}.</TextBold>

          <TextInterval>{formatMessage({ id: 'accountBilling.billing.renewsOn' })}:</TextInterval>

          <TextBold>{formatMessage({ id: 'accountBilling.billing.status.cancelled' })}</TextBold>
        </Expire>
      }
    />
  )
}

UnpaidCard.propTypes = {
  intl: intlShape.isRequired,
  onUpgrade: PropTypes.func,
}

UnpaidCard.defaultProps = {}

export default injectIntl(UnpaidCard)
