export const countryOptions = [
  {
    value: 47,
    text: 'USA',
    flag: 'us',
  },
  {
    value: 13,
    text: 'Great Britain',
    flag: 'gb',
  },
  {
    value: 10,
    text: 'Germany',
    flag: 'de',
  },
  {
    value: 12,
    text: 'France',
    flag: 'fr',
  },
  {
    value: 1,
    text: 'Bulgaria',
    flag: 'bg',
  },
  {
    value: 2,
    text: 'Egypt',
    flag: 'eg',
  },
  {
    value: 3,
    text: 'Argentina',
    flag: 'ar',
  },
  {
    value: 4,
    text: 'Australia',
    flag: 'au',
  },
  {
    value: 5,
    text: 'Belgium',
    flag: 'be',
  },
  {
    value: 6,
    text: 'Brasil',
    flag: 'br',
  },
  {
    value: 7,
    text: 'Cyprus',
    flag: 'cy',
  },
  {
    value: 8,
    text: 'China',
    flag: 'cn',
  },
  {
    value: 9,
    text: 'Denmark',
    flag: 'dk',
  },
  {
    value: 58,
    text: 'Iceland',
    flag: 'ax',
  },
  {
    value: 11,
    text: 'Finland',
    flag: 'fi',
  },
  {
    value: 14,
    text: 'Hongkong',
    flag: 'hk',
  },
  {
    value: 15,
    text: 'India',
    flag: 'in',
  },
  {
    value: 16,
    text: 'Indonesia',
    flag: 'id',
  },
  {
    value: 17,
    text: 'Ireland',
    flag: 'ie',
  },
  {
    value: 18,
    text: 'Italy',
    flag: 'it',
  },
  {
    value: 19,
    text: 'Jamaica',
    flag: 'jm',
  },
  {
    value: 20,
    text: 'Japan',
    flag: 'jp',
  },
  {
    value: 21,
    text: 'Canada',
    flag: 'ca',
  },
  {
    value: 22,
    text: 'Croatia',
    flag: 'hr',
  },
  {
    value: 23,
    text: 'Luxembourg',
    flag: 'lu',
  },
  {
    value: 24,
    text: 'Macau',
    flag: 'mo',
  },
  {
    value: 25,
    text: 'Malaysia',
    flag: 'my',
  },
  {
    value: 26,
    text: 'Morocco',
    flag: 'ma',
  },
  {
    value: 27,
    text: 'Mexico',
    flag: 'mx',
  },
  {
    value: 28,
    text: 'Monaco',
    flag: 'mc',
  },
  {
    value: 29,
    text: 'New Zealand',
    flag: 'nz',
  },
  {
    value: 30,
    text: 'Netherlands',
    flag: 'nl',
  },
  {
    value: 31,
    text: 'Norway',
    flag: 'no',
  },
  {
    value: 32,
    text: 'Austria',
    flag: 'at',
  },
  {
    value: 33,
    text: 'Poland',
    flag: 'pl',
  },
  {
    value: 34,
    text: 'Portugal',
    flag: 'pt',
  },
  {
    value: 35,
    text: 'Russia',
    flag: 'ru',
  },
  {
    value: 57,
    text: 'Ukraine',
    flag: 'ua',
  },
  {
    value: 36,
    text: 'Saudi-Arabia',
    flag: 'sa',
  },
  {
    value: 37,
    text: 'Sweden',
    flag: 'se',
  },
  {
    value: 38,
    text: 'Switzerland',
    flag: 'ch',
  },
  {
    value: 39,
    text: 'Singapore',
    flag: 'sg',
  },
  {
    value: 40,
    text: 'Slovakia',
    flag: 'sk',
  },
  {
    value: 41,
    text: 'Slovenia',
    flag: 'si',
  },
  {
    value: 42,
    text: 'Spain',
    flag: 'es',
  },
  {
    value: 43,
    text: 'Thailand',
    flag: 'th',
  },
  {
    value: 44,
    text: 'Czech Republic',
    flag: 'cz',
  },
  {
    value: 45,
    text: 'Tunesia',
    flag: 'tn',
  },
  {
    value: 46,
    text: 'Turkey',
    flag: 'tr',
  },
  {
    value: 49,
    text: 'Estonia',
    flag: 'ee',
  },
  {
    value: 50,
    text: 'Greece',
    flag: 'gr',
  },
  {
    value: 51,
    text: 'Hungary',
    flag: 'hu',
  },
  {
    value: 52,
    text: 'Latvia',
    flag: 'lv',
  },
  {
    value: 53,
    text: 'Lithuania',
    flag: 'lt',
  },
  {
    value: 54,
    text: 'Malta',
    flag: 'mt',
  },
  {
    value: 55,
    text: 'Romania',
    flag: 'ro',
  },
  {
    value: 56,
    text: 'South Korea',
    flag: 'kr',
  },
]
