import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Semantic } from 'packages/components'
import { fieldProptypes } from 'packages/utils/formikPropTypes'
import { CONTACT_FORM_ALIGN } from 'packages/enum'
import { AlignCheckboxContainer } from './nodes'

const AlignCheckbox = ({
  field, // { name, value, onChange, onBlur }
  active = true,
  alignment,
  ...props // {id, form}
}) => {
  return (
    <AlignCheckboxContainer
      active={active}
      id={props.id}
      onClick={() => props.form.setFieldValue('align', alignment)}
    >
      {alignment === CONTACT_FORM_ALIGN.LEFT && (
        <Semantic.Icon name='align left' color={active ? 'blue' : 'grey'} />
      )}
      {alignment === CONTACT_FORM_ALIGN.CENTER && (
        <Semantic.Icon name='align center' color={active ? 'blue' : 'grey'} />
      )}
      {alignment === CONTACT_FORM_ALIGN.RIGHT && (
        <Semantic.Icon name='align right' color={active ? 'blue' : 'grey'} />
      )}
    </AlignCheckboxContainer>
  )
}

AlignCheckbox.propTypes = {
  ...fieldProptypes,
}

AlignCheckbox.defaultProps = {}

const FormAlignCheckbox = ({ name, ...props }) => (
  <Field name={name} {...props} component={AlignCheckbox} />
)

FormAlignCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
}

export default FormAlignCheckbox
