import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Container, Label } from './nodes'
import { FormColorPicker } from 'packages/components/inputs'

import PropTypes from 'prop-types'

const ColorPickerComponent = ({ intl: { formatMessage }, customText, ...props }) => {
  return (
    <Container>
      <Label>{formatMessage({ id: customText ?? 'Color' })}</Label>
      <FormColorPicker {...props} />
    </Container>
  )
}

ColorPickerComponent.propTypes = {
  intl: intlShape.isRequired,
  customText: PropTypes.string,
}

export const StylesColorPicker = injectIntl(ColorPickerComponent)
