import PropTypes from 'prop-types'
import React from 'react'
import { useDrop } from 'react-dnd'
import { ALBUM_TYPES } from 'packages/enum'
import { Container } from './nodes'

const AlbumPlaceholder = ({ itemType, onDrop, pageId }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [ALBUM_TYPES.MAIN, ALBUM_TYPES.FOOTER],
    drop: (item) =>
      onDrop({
        itemId: item.id,
        droppedId: null,
        type: itemType,
        newPageId: pageId,
        sourcePageId: item.pageId,
        sourceType: item.type,
      }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return <Container ref={drop} isOver={isOver} />
}

AlbumPlaceholder.propTypes = {
  pageId: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  itemType: PropTypes.string,
}
AlbumPlaceholder.defaultProps = {
  itemType: ALBUM_TYPES.MAIN,
}
export default AlbumPlaceholder
