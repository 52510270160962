import React from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { FOOTER_ICON_TYPE, FOOTER_SOCIAL_ICON_COLOR } from 'packages/enum'
import { Position, SOCIAL_LINK_ITEM } from '../consts'
import { socialIcons } from '../socialIcons'
import { DeleteIcon, DragIndicatorIcon } from './icons'
import {
  Bottom,
  DeleteIconButton,
  DragIndicatorIconButton,
  DropSeparator,
  Inner,
  Name,
  Root,
  SocialIcon,
  StyledFormInput,
  Top,
  Wrapper,
} from './nodes'

export function LinkItem({ id, index, onDelete, fieldName, onDrop, iconType, iconColor }) {
  const [insertPosition, setInsertPosition] = React.useState(null)
  const wrapperRef = React.useRef(null)
  const isBefore = insertPosition === Position.Before
  const isAfter = insertPosition === Position.After

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: { type: SOCIAL_LINK_ITEM },
    begin: () => ({ index }),
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [{ isOver }, dropRef] = useDrop({
    accept: SOCIAL_LINK_ITEM,
    drop: (item) => {
      if (insertPosition === null) {
        return
      }

      onDrop(
        item.index,
        isAfter && item.index > index
          ? index + 1
          : isBefore && item.index < index
          ? index - 1
          : index
      )

      setInsertPosition(null)
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      }
    },
    hover: (item, monitor) => {
      if (!wrapperRef.current) {
        return
      }

      if (index === item.index) {
        if (insertPosition !== null) {
          setInsertPosition(null)
        }

        return
      }

      if (index === item.index + 1) {
        return setInsertPosition(Position.After)
      }

      if (index === item.index - 1) {
        return setInsertPosition(Position.Before)
      }

      const rect = wrapperRef.current?.getBoundingClientRect()

      if (!rect) {
        return
      }

      const middleY = (rect.bottom - rect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const clientY = clientOffset.y - rect.top

      setInsertPosition(middleY > clientY ? Position.Before : Position.After)
    },
  })

  const socialIcon = socialIcons[id]

  if (socialIcon === undefined) {
    return null
  }

  const { name, icon, placeholder } = socialIcon
  const Icon = icon[iconType][iconColor]
  const isShadow =
    iconType === FOOTER_ICON_TYPE.ICON && iconColor === FOOTER_SOCIAL_ICON_COLOR.WHITE

  const setWrapperRef = (element) => {
    wrapperRef.current = element
    preview(element)
  }

  return (
    <Root ref={dropRef}>
      {isOver && isBefore && <DropSeparator $position={Position.Before} />}
      <Wrapper ref={setWrapperRef} $isDragging={isDragging}>
        <Inner $isDragging={isDragging}>
          <Top>
            <DragIndicatorIconButton ref={dragRef}>
              <DragIndicatorIcon />
            </DragIndicatorIconButton>
            <SocialIcon $isShadow={isShadow}>
              <Icon />
            </SocialIcon>
            <Name>{name}</Name>
          </Top>
          <Bottom>
            <StyledFormInput name={fieldName} placeholder={placeholder} />
            <DeleteIconButton onClick={onDelete}>
              <DeleteIcon />
            </DeleteIconButton>
          </Bottom>
        </Inner>
      </Wrapper>
      {isOver && insertPosition === Position.After && <DropSeparator $position={Position.After} />}
    </Root>
  )
}

LinkItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  iconType: PropTypes.oneOf(Object.values(FOOTER_ICON_TYPE)).isRequired,
  iconColor: PropTypes.oneOf(Object.values(FOOTER_SOCIAL_ICON_COLOR)).isRequired,
}
