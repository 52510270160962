import { compose } from 'redux'
import { connect } from 'react-redux'
import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { lockModal } from 'packages/redux/modules/modalProgress/actions.js'

import UploadPhotoModal from '@configurator/components/upload/uploadPhoto/UploadPhoto'

export const uploadPhotoWrapper = (Component) =>
  compose(
    connect(
      // mapStateToProps
      () => ({}),
      // mapDispatchToProps
      (dispatch) => ({
        lockModalProgress: () => dispatch(lockModal(true)),
        unLockModalProgress: () => dispatch(lockModal(false)),
        openErrorModal: ({
          headerMessageId,
          yesMessageId,
          subMessageId,
          subMessageValues,
          onClickYes,
        }) =>
          dispatch(
            openErrorModal({
              headerMessageId,
              yesMessageId,
              subMessageId,
              subMessageValues,
              hideCancelButton: true,
              onClickYes,
            })
          ),
      })
    )
  )(Component)

export default uploadPhotoWrapper(UploadPhotoModal)
