import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'

import statisticLiveGet from '@graphql/gql/website/statisticLiveGet.gql'

import TotalVisitorsNow from '@configurator/components/statistics/totalInfo/TotalVisitorsNow'

export const totalVisitorsNowWrapper = (Component) =>
  compose(
    connect((state) => ({ matomoOptions: state.get('periodPicker').toJS() })),
    graphql(statisticLiveGet, {
      skip: ({ matomoOptions }) => !matomoOptions.idSite,
      options: ({ matomoOptions }) => ({
        variables: matomoOptions,
        ssr: false,
      }),
      props({ data: { loading, statisticLiveGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(statisticLiveGet)) {
          return { loading }
        }
        const { data } = statisticLiveGet
        const { visitors: count } = data[0]

        return {
          count: +count,
        }
      },
    })
  )(Component)

export default totalVisitorsNowWrapper(TotalVisitorsNow)
