import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ width, height, color }) => css`
    &,
    & div {
      box-sizing: border-box;
    }
    & {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 80px;
      height: 80px;
      transform: translateZ(1px) scaleX(${width / 80}) scaleY(${height / 80});
      transform-origin: left top;
      color: ${color};
    }
    & > div {
      display: inline-block;
      width: 64px;
      height: 64px;
      background: currentColor;
      border-radius: 50%;
      animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    @keyframes lds-circle {
      0%,
      100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
      }
      0% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
      }
      100% {
        transform: rotateY(3600deg);
      }
    }
  `}
`
