import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'packages/components/echart'
import { intlShape } from 'react-intl'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import moment from 'moment'

import { PERIOD_OPTIONS } from '@configurator/constants/statistics'

import { ChartComponent, Container } from './nodes'
import { useMediaQuery } from 'packages/helpers/useMediaQuery'
import { sizes } from 'packages/components/media'

const StatisticLineChart = (
  { matomoOptions, data, loading },
  { intl: { formatDate, formatMessage } }
) => {
  const isMobile = useMediaQuery(`(max-width: ${sizes.phone}px)`)

  let dateStart
  let dateEnd
  let pageViews
  let visitors

  if (data && !isEmpty(data)) {
    if (matomoOptions.period === PERIOD_OPTIONS.week) {
      map(data, (value, key) => {
        if (!dateStart || moment(key).valueOf() < moment(dateStart).valueOf()) {
          dateStart = key
        }
        if (!dateEnd || moment(key).valueOf() > moment(dateEnd).valueOf()) {
          dateEnd = key
        }
        return key.slice(0, key.indexOf(','))
      })
      pageViews = map(data, (value, key) => [key.slice(0, key.indexOf(',')), value.nb_visits || 0])
      visitors = map(data, (value, key) => [
        key.slice(0, key.indexOf(',')),
        value.nb_uniq_visitors || 0,
      ])
    } else {
      map(data, (value, key) => {
        if (!dateStart || moment(key).valueOf() < moment(dateStart).valueOf()) {
          dateStart = key
        }
        if (!dateEnd || moment(key).valueOf() > moment(dateEnd).valueOf()) {
          dateEnd = key
        }
        return key
      })
      pageViews = map(data, (value, key) => [key, value.nb_visits || 0])
      visitors = map(data, (value, key) => [key, value.nb_uniq_visitors || 0])
    }
  }

  const formatLabel = (dateValue) => {
    if (matomoOptions.period === PERIOD_OPTIONS.month) {
      return `${formatDate(dateValue, {
        year: 'numeric',
        month: 'numeric',
      })}`
    } else if (matomoOptions.period === PERIOD_OPTIONS.year) {
      return `${formatDate(dateValue, {
        year: 'numeric',
      })}`
    } else {
      return `${formatDate(dateValue, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`
    }
  }
  const formatAxisLabel = (dateValue) => {
    if (matomoOptions.period === PERIOD_OPTIONS.month) {
      return `${formatDate(dateValue, {
        year: 'numeric',
        month: 'short',
      })}`
    } else if (matomoOptions.period === PERIOD_OPTIONS.year) {
      return `${formatDate(dateValue, {
        year: 'numeric',
      })}`
    } else {
      return `${formatDate(dateValue, {
        // year: 'numeric',
        month: 'short',
        weekday: 'short',
        day: 'numeric',
      })}`
    }
  }

  const option = {
    tooltip: { trigger: 'axis' },
    title: {
      text: formatMessage({ id: 'statistics.line.header' }),
      left: 0,
      textStyle: {
        color: '#343840',
        fontSize: 17,
        fontWeight: 'bold',
      },
    },
    legend: {
      data: [
        {
          name: formatMessage({ id: 'lineChart.label.pageViews' }),
          textStyle: {
            color: '#00C2FF',
          },
        },
        {
          name: formatMessage({ id: 'lineChart.label.visitors' }),
          textStyle: {
            color: '#6727AB',
          },
        },
      ],
      right: 20,
      top: 0,
      icon: 'path://M 0 0 V 0 L 15 0 L 15 0 V 1 L 0 1 V 0',
      ...(isMobile && {
        orient: 'vertical',
        itemGap: 5,
      }),
    },
    grid: {
      left: '0%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      min: dateStart,
      max: dateEnd,
      boundaryGap: false,
      axisPointer: {
        snap: true,
        lineStyle: {
          show: false,
          color: '#666A73',
          width: 1,
          opacity: 0.8,
        },
        label: {
          show: false,
          formatter: (params) => formatLabel(params.value),
          backgroundColor: '#004E52',
        },
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#EDEDED',
        },
      },
      axisLabel: {
        formatter: (v) => formatAxisLabel(v),
        color: '#666A73',
      },
      splitLine: {
        show: true,
        interval: 1,
        lineStyle: {
          color: '#EDEDED',
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          opacity: 0,
        },
      },
      axisLabel: {
        color: '#666A73',
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#EDEDED',
        },
      },
    },
    series: [
      {
        name: formatMessage({ id: 'lineChart.label.pageViews' }),
        type: 'line',
        color: '#00C2FF',
        symbolSize: 8,
        symbol: 'path://M 100, 100m -75, 0a 75,75 0 1,0 150,0a 75,75 0 1,0 -150,0',
        data: pageViews,
      },
      {
        name: formatMessage({ id: 'lineChart.label.visitors' }),
        type: 'line',
        color: '#6727AB',
        symbolSize: 8,
        symbol: 'path://M 100, 100m -75, 0a 75,75 0 1,0 150,0a 75,75 0 1,0 -150,0',
        data: visitors,
      },
    ],
  }

  return (
    <Container>
      <ChartComponent>
        <Chart showLoading={loading} option={option} notMerge />
      </ChartComponent>
    </Container>
  )
}

StatisticLineChart.propTypes = {
  loading: PropTypes.bool,
  matomoOptions: PropTypes.object.isRequired,
  data: PropTypes.object,
}

StatisticLineChart.defaultProps = {
  loading: false,
  data: {},
}

StatisticLineChart.contextTypes = { intl: intlShape }

export default StatisticLineChart
