import { injectIntl, intlShape } from 'react-intl'

import { Tabs } from 'packages/components/shared'
import { HeaderStyled } from 'packages/components/layout/nodes'

import {
  Container as ContainerSettings,
  ContentWrapper,
  Footer,
  Header,
  Settings,
  WrapperSettings,
  WrapperTabs,
} from '../../settings/nodes'

const tabs = [
  { id: 1, title: 'seo.google.website.settings', slug: 'settings' },
  { id: 2, title: 'seo.google.site.verification', slug: 'verification' },
]

const SeoDesktop = ({
  intl: { formatMessage },
  activeSlug,
  setActiveSlug,
  Content,
  VerificationForm,
}) => {
  const currentYear = new Date().getFullYear()

  const handleRenderContent = () => {
    switch (activeSlug) {
      case 'settings':
        return Content

      case 'verification':
        return VerificationForm

      default:
        return null
    }
  }

  return (
    <>
      <HeaderStyled backArrow />

      <Header>{formatMessage({ id: 'seo.google' })}</Header>

      <WrapperSettings>
        <ContainerSettings>
          <Settings>
            <WrapperTabs>
              <Tabs tabs={tabs} active={activeSlug} setActive={setActiveSlug} />
            </WrapperTabs>

            <ContentWrapper>{handleRenderContent()}</ContentWrapper>
          </Settings>
        </ContainerSettings>
      </WrapperSettings>

      <Footer>
        {formatMessage({ id: 'website.footer' })} @ {currentYear}
      </Footer>
    </>
  )
}

SeoDesktop.propTypes = {
  intl: intlShape.isRequired,
}

SeoDesktop.defaultProps = {}

export default injectIntl(SeoDesktop)
