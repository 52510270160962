import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const ImageIcon = ({ color }) => (
  <svg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <clipPath id='clip554_2639'>
        <rect
          id='image-03'
          width='24.000000'
          height='24.000000'
          fill={iconColors[color]}
          fill-opacity='0'
        />
      </clipPath>
    </defs>
    <rect
      id='image-03'
      width='24.000000'
      height='24.000000'
      fill={iconColors[color]}
      fill-opacity='0'
    />
    <g clip-path='url(#clip554_2639)'>
      <path
        id='Icon'
        d='M4.27 20.72L10.86 14.13C11.26 13.73 11.46 13.53 11.69 13.46C11.89 13.39 12.1 13.39 12.3 13.46C12.53 13.53 12.73 13.73 13.13 14.13L19.68 20.68M8 11C6.89 11 6 10.1 6 9C6 7.89 6.89 7 8 7C9.1 7 10 7.89 10 9C10 10.1 9.1 11 8 11ZM22 15L19.13 12.13C18.73 11.73 18.53 11.53 18.3 11.46C18.1 11.39 17.89 11.39 17.69 11.46C17.46 11.53 17.26 11.73 16.86 12.13L14 15M17.19 3C18.88 3 19.72 3 20.36 3.32C20.92 3.61 21.38 4.07 21.67 4.63C22 5.27 22 6.11 22 7.79L22 16.2C22 17.88 22 18.72 21.67 19.36C21.38 19.92 20.92 20.38 20.36 20.67C19.72 21 18.88 21 17.19 21L6.8 21C5.11 21 4.27 21 3.63 20.67C3.07 20.38 2.61 19.92 2.32 19.36C2 18.72 2 17.88 2 16.2L2 7.79C2 6.11 2 5.27 2.32 4.63C2.61 4.07 3.07 3.61 3.63 3.32C4.27 3 5.11 3 6.8 3L17.19 3Z'
        stroke={iconColors[color]}
        stroke-opacity='1.000000'
        stroke-width='1.500000'
        stroke-linejoin='round'
      />
    </g>
  </svg>
)

ImageIcon.propTypes = {
  ...iconColorPropTypes,
}

ImageIcon.defaultProps = {
  color: 'white',
}
