import React from 'react'
import PropTypes from 'prop-types'
import { Container, Title, Divider } from './nodes'

const Component = ({ children }) => (
  <Container>
    <Divider />
    <Title>{children}</Title>
    <Divider />
  </Container>
)

Component.propTypes = {
  children: PropTypes.string.isRequired,
}

export const StylesTitle = Component
