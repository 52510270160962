import React from 'react'

// в макете называется weight
export const WeightIcon = () => (
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.7274 11C17.0436 9.82829 17.0853 8.60023 16.8494 7.41002C16.6136 6.2198 16.1063 5.09903 15.3666 4.13366C14.6269 3.16829 13.6743 2.38396 12.582 1.84081C11.4897 1.29766 10.2867 1.01013 9.06509 1.00026C7.84352 0.990394 6.6359 1.25845 5.53484 1.78388C4.43378 2.3093 3.46852 3.07814 2.71308 4.03143C1.95764 4.98472 1.43209 6.09715 1.17673 7.28339C0.921376 8.46964 0.942993 9.69821 1.23993 10.8749H6.22222L11.2222 5.85464V10.9687L16.7274 11Z'
      stroke='#242426'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

WeightIcon.propTypes = {}

WeightIcon.defaultProps = {}
