import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const FileUploadIcon = ({ color }) => (
  <svg
    width='43'
    height='38'
    viewBox='0 0 43 38'
    fill={iconColors[color]}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.5 27.4845C3.08803 25.8699 1.5 23.1204 1.5 20C1.5 15.3129 5.08302 11.4626 9.65948 11.0387C10.5956 5.34426 15.5405 1 21.5 1C27.4595 1 32.4044 5.34426 33.3405 11.0387C37.917 11.4626 41.5 15.3129 41.5 20C41.5 23.1204 39.912 25.8699 37.5 27.4845M13.5 27L21.5 19M21.5 19L29.5 27M21.5 19V37'
      stroke='#9FA1A6'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={`none`}
    />
  </svg>
)

FileUploadIcon.propTypes = {
  ...iconColorPropTypes,
}

FileUploadIcon.defaultProps = {
  color: 'dark',
}
