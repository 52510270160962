import React from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'
import get from 'lodash/get'

import Loader from '@configurator/components/loader/Loader'
import { Button } from 'packages/components/buttons'

import { Header, NoItemsMsg, Content } from './nodes'

const BillingHistory = (
  { data, loading, billingCreateCustomerPortal },
  { intl: { formatMessage } }
) => {
  const onManageSubscription = async () => {
    const res = await billingCreateCustomerPortal()

    if (get(res, 'data.billingCreateCustomerPortal.data.sessionId')) {
      window.location.href = get(res, 'data.billingCreateCustomerPortal.data.sessionId')
    }
  }

  if (loading) {
    return <Loader />
  }

  const hasData = data && data?.length > 0

  return (
    <Content>
      <Header hasData={hasData}>
        {formatMessage({ id: 'billingHistory.header' })}

        {hasData ? (
          <Button view='secondaryBlack' onClick={onManageSubscription}>
            {formatMessage({ id: 'billingHistory.button.viewInvoices' })}
          </Button>
        ) : null}
      </Header>

      {!hasData ? (
        <NoItemsMsg>{formatMessage({ id: 'billingHistory.noItems.message' })}</NoItemsMsg>
      ) : null}
    </Content>
  )
}

BillingHistory.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
}

BillingHistory.defaultProps = {
  data: [],
  loading: false,
}

BillingHistory.contextTypes = {
  intl: intlShape.isRequired,
}

export default BillingHistory
