import { compose } from 'redux'
import { graphql } from 'react-apollo'
import get from 'lodash/get'
import downgradeToFree from '@graphql/gql/website/downgradeToFree.gql'
import ChangePassword from '@configurator/components/billing/modals/changePassword'

export default compose(
  graphql(downgradeToFree, {
    props({ mutate }) {
      return {
        async downgradeToFree() {
          try {
            const res = await mutate({})
            if (get(res, 'data.downgradeToFree.errors._error')) {
              console.log('error')
            }
            return res
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  })
)(ChangePassword)
