import { createActions } from 'redux-actions'

export const {
  pageLoader: { start, stop },
} = createActions({
  PAGE_LOADER: {
    start: ({ content } = { content: '' }) => ({ open: true, content }),
    stop: () => ({ open: false }),
  },
})
