import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { HeaderStyled } from 'packages/components/layout/nodes'
import {
  Container,
  Header,
  Teaser,
  TeaserText,
  Logo,
  FormWrapper,
  Grid,
  Notes,
  NoteHeader,
  Step,
  StepContent,
  StepNumber,
  StepWrapper,
  Paragraph,
  VideoLink,
  CodeSample,
} from '../nodes'
import Image from 'packages/components/gatsby-image'
import blogger from '../../assets/blogger.png'
import blogger_instructions from '../../assets/blogger_instructions.png'
import { Button } from 'packages/components/buttons'
import { wrapper } from '../../../../containers/integrations/IntegrationsDetails'

const Blogger = ({ intl: { formatMessage } }) => {
  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header>
          {formatMessage({
            id: 'integrations.blogger.header',
          })}
        </Header>
        <Teaser>
          <Logo>
            <Image
              fluid={{
                src: blogger,
                aspectRatio: 38 / 15,
              }}
              alt=''
              fadeIn
              imgStyle={{ objectFit: 'contain' }}
              loading='lazy'
              backgroundColor='white'
            />
          </Logo>
          <TeaserText small>
            {formatMessage({
              id: 'integrations.blogger.teaser',
            })}
          </TeaserText>
          <div>
            <Button
              as='a'
              href={'https://www.blogger.com/about/'}
              target='_blank'
              content={formatMessage({
                id: 'integration.start.free',
              })}
            />
          </div>
        </Teaser>
        <Grid>
          <div>
            <FormWrapper>
              <Image
                fluid={{
                  src: blogger_instructions,
                  aspectRatio: 3 / 2,
                }}
                alt=''
                fadeIn
                imgStyle={{ objectFit: 'contain' }}
                loading='lazy'
                backgroundColor='white'
              />
            </FormWrapper>
          </div>
          <div>
            <Notes>
              <NoteHeader>
                {formatMessage({
                  id: 'integrations.blogger.note.header',
                })}
              </NoteHeader>
              <StepWrapper>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integrations.blogger.step.1',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: 'integrations.blogger.note.1',
                          }),
                        }}
                      />
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integrations.blogger.step.2',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integrations.blogger.note.2',
                      })}
                    </Paragraph>

                    <CodeSample>
                      {`<iframe src="`}
                      <b>
                        {formatMessage({
                          id: 'integrations.blogger.sample.header',
                        })}
                      </b>
                      {`" width="100%" height="800" frameBorder="0">`}
                      <br />
                      <p>
                        {formatMessage({
                          id: 'integrations.blogger.sample.text',
                        })}
                      </p>
                      <br />
                      {`</iframe>`}
                    </CodeSample>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>3</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integrations.blogger.note.3',
                      })}
                    </Paragraph>

                    <VideoLink href='https://vimeo.com/632680725' target='_blank'>
                      {formatMessage({
                        id: 'integration.watch.tutorial',
                      })}
                    </VideoLink>
                  </StepContent>
                </Step>
              </StepWrapper>
            </Notes>
          </div>
        </Grid>
      </Container>
    </>
  )
}

Blogger.propTypes = {
  intl: intlShape.isRequired,
}

Blogger.defaultProps = {}

export default wrapper(injectIntl(Blogger), 'blogger')
