import Navigation from '@configurator/containers/NavigationContainer'
import { Semantic } from 'packages/components'
import { WEBSITE_NAVIGATION_VIEW_MODE } from 'packages/enum'
import { StyledPopup } from './nodes'

const NavigationPopup = ({ onClose, ...props }) => {
  const handleClose = () => {
    const isModalOpen = document.querySelector('.modal.visible.active')
    !isModalOpen && onClose && onClose()
  }

  return <StyledPopup {...props} content={<Navigation {...props} />} onClose={handleClose} />
}

const popupDefaultProps = {
  position: 'right center',
  basic: true,
}

const navigationDefaultProps = {
  viewMode: WEBSITE_NAVIGATION_VIEW_MODE.ALBUM_ITEM_MOVING,
}

NavigationPopup.propTypes = {
  ...Navigation?.propTypes,
  ...Semantic.Popup.propTypes,
}

NavigationPopup.defaultProps = {
  ...popupDefaultProps,
  ...navigationDefaultProps,
}

export default NavigationPopup
