import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { UpgradeBlock } from 'packages/upgradeBlock'
import { Wrapper } from './nodes'

// TODO сделать intl как в дизайне, если надо
export const StylesUpgradeBlock = ({ reference }) => (
  <UpgradeBlock
    reference={reference}
    headerMsg='styles.subscription.subheader'
    descMsg='styles.subscription.description'
    buttonMsg='styles.subscription.upgrade'
  />
)

StylesUpgradeBlock.propTypes = { reference: PropTypes.object }
StylesUpgradeBlock.defaultProps = { reference: null }

export const StylesSubscriptionModal = () => {
  // TODO сделать другую логику, если надо
  const [isModalClosed, setIsModalClosed] = useState(false)
  const divRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsModalClosed(true)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [divRef])

  return (
    <Wrapper isModalClosed={isModalClosed}>
      <StylesUpgradeBlock reference={divRef} />
    </Wrapper>
  )
}
