import styled from 'styled-components'
import { views } from 'packages/components/buttons/button/nodes'
import { media } from 'packages/components/media'

export const Container = styled.div`
  max-width: 1272px;
  margin: 66px auto;
  padding: 0 26px;

  ${media.max.tablet`
        margin: 30px 16px;
    `}
`

export const Item = styled.div`
  position: relative;
  margin-bottom: 66px;
  width: 47.54%;
  display: flex;
  flex-direction: column;

  ${media.max.tablet`
        width: 100%;
        margin-bottom: 30px;
    `}
`

export const ProBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  width: 75px;
  letter-spacing: 0.6px;
  background: #343840;
  color: white;
  font-weight: normal;
  height: 31px;
  line-height: 30px;
  text-align: center;
`

export const FreeBlock = styled(ProBlock)`
  color: #1b1c1d;
  background: white;
  border: 1px solid #1b1c1d;
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  position: relative;
  ${(props) =>
    props.selected
      ? `
        border: 4px solid #58C367;
        border-radius: 6px;
    `
      : ''}

  &:hover > ${ProBlock} {
    ${({ selected }) => (selected ? `opacity: 1` : `opacity: 0`)};
  }
`

export const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;

  opacity: 0;
  transition: opacity 100ms;
  display: ${(props) => (props.selected ? 'none' : 'flex')};
  cursor: ${(props) => (props.selected ? 'initial' : 'pointer')};

  &:hover {
    opacity: ${(props) => (props.selected ? 0 : 1)};
  }

  & > button,
  & > a,
  & > div > button {
    display: block !important;
    width: 300px !important;
    text-align: center !important;

    &:not(:first-child) {
      margin-top: 10px !important;
    }
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${media.max.tablet`
        flex-direction: column;
    `}
`

export const Content = styled.div`
  display: flex;
`

export const ItemHeader = styled.p`
  margin: 20px 0 0 0 !important;
  width: 100%;
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #343840;

  ${media.max.tablet`
        margin-top: 10px !important;
    `}
`

export const Description = styled.p`
  margin-top: 5px;
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #666a73;
`

export const HeaderBlock = styled.div`
  padding-bottom: 70px;

  h1 {
    font-size: 36px;
    font-weight: 700;
    width: 47.54%;
    display: flex;
    flex-direction: column;
  }

  div > span {
    font-weight: 400;
    width: 47.54%;
    display: flex;
    flex-direction: column;
  }

  ${media.max.tablet`
        padding-bottom: 30px;

        h1 {
            width: 100%;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 20px;
        }

        div > span {
            width: 100%;
        }
    `}
`

export const SelectedBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 100px;
  width: 160px;
  height: 30px;
  line-height: 31px;
  z-index: 10;
  background-color: ${views.primary.background};
  color: white;
  text-align: center;

  span {
    vertical-align: center;
  }
`

export const SlideshowNotification = styled.div`
  display: flex;
  margin-top: 20px;

  & > div > svg {
    width: 35px;
    height: 35px;
  }

  & > span {
    font-size: 12px;
    margin-left: 20px;
    width: 150px;
    line-height: 15px;
    align-self: center;
  }

  ${media.max.tablet`
        margin-top: 10px;
    `}
`
