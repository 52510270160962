import React from 'react'

export const Verified = () => {
  return (
    <svg
      width='54.013275'
      height='54.066406'
      viewBox='0 0 54.0133 54.0664'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id='Icon'
        d='M52.0051 24.7446L52.0051 27.0444C52.002 32.4355 50.2563 37.6812 47.0284 41.999C43.8005 46.3169 39.2633 49.4756 34.0935 51.0044C28.9237 52.5327 23.3983 52.3491 18.3413 50.481C13.2843 48.6128 8.96674 45.1597 6.0325 40.6372C3.09827 36.1147 1.70456 30.7646 2.0593 25.3853C2.414 20.0059 4.49811 14.8853 8.00079 10.7871C11.5035 6.68896 16.2371 3.83301 21.4956 2.64502C26.7541 1.45703 32.2558 2.00049 37.1801 4.19482M52.0051 7.03027L27.0051 32.0552L19.5051 24.5552'
        stroke='#58C367'
        stroke-opacity='1.000000'
        stroke-width='4.000000'
        stroke-linejoin='round'
      />
    </svg>
  )
}
