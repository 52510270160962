import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Layout from 'packages/components/layout/Layout'

export default compose(
  withRouter,
  connect(
    // mapStateToProps
    (state) => ({
      name: state.getIn(['user', 'name']),
      email: state.getIn(['user', 'email']),
      expandedView: state.getIn(['albumView', 'expandedView']),
    }),
    () => ({})
  )
)(Layout)
