import { Button } from 'packages/components/buttons'
import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { Footer } from './nodes'

function ModalFooterButtons({ intl: { formatMessage }, disabled, onClose = () => {} }) {
  return (
    <Footer>
      <Button
        type='button'
        content={formatMessage({
          id: 'newDesign.modal.buttons.cancel',
        })}
        fluid
        view='primaryGray'
        disabled={disabled}
        onClick={onClose}
      />
      <Button
        type='submit'
        content={formatMessage({
          id: 'newDesign.modal.buttons.save',
        })}
        fluid
        disabled={disabled}
      />
    </Footer>
  )
}

export default injectIntl(ModalFooterButtons)
ModalFooterButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
