import { intlShape } from 'react-intl'
import * as Semantic from 'semantic-ui-react'
import { getTitle, intlDuck } from '../../utils'
import {
  StyledInputContainer,
  StyledInputError,
  StyledInputWithIcon,
  StyledLabel,
  StyledTextArea,
} from './nodes'

export const Textarea = (
  { placeholder, label, className, inlineNode, errorText, ...props },
  { intl: { formatMessage } = intlDuck }
) => {
  return (
    <StyledInputContainer className={className}>
      {label ? <StyledLabel>{getTitle(label, formatMessage)}</StyledLabel> : null}
      <StyledInputWithIcon>
        <StyledTextArea
          maxLength={255}
          {...props}
          placeholder={getTitle(placeholder, formatMessage)}
        />
        {inlineNode || null}
      </StyledInputWithIcon>
      {errorText ? <StyledInputError>{errorText}</StyledInputError> : null}
    </StyledInputContainer>
  )
}

Textarea.propTypes = {
  ...Semantic.Input.propTypes,
}

Textarea.defaultProps = {
  ...Semantic.Input.defaultProps,
}

Textarea.contextTypes = {
  intl: intlShape,
}
