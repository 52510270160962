import Message from 'packages/components/message/Message'
import {
  DeleteButton,
  Header,
  RadioField,
  RadioGroup,
  UploadWrapper,
  VideoInput,
  Flex,
  FlexNode,
  HeaderFlex,
} from '@configurator/components/upload/uploadVideo/nodes'
import { FormInput, FormRadio } from 'packages/components/inputs'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { FileUploadIcon, TrashIcon } from 'packages/components/icons'
import Dropzone from 'react-dropzone'
import { MAX_PHOTO_SIZE } from '@configurator/constants/upload'
import AutomaticVideoPreview from '@configurator/components/upload/uploadVideo/AutomaticVideoPreview'
import { Button } from 'packages/components/buttons'
import React, { useCallback, useEffect, useState } from 'react'
import { useSubscription } from '@configurator/providers/subscription'
import queryString from 'query-string'
import { HOSTING_URLS, VIDEO_HOSTING } from 'packages/enum'
import debounce from 'lodash/debounce'
import { Semantic } from 'packages/components'
import PropTypes from 'prop-types'

const updateVideoDebounce = debounce((func = () => null) => {
  func()
}, 1000)

export function useVideo(
  values,
  setValues,
  setStatus,
  setFieldValue,
  openErrorModal,
  srcSetWebpMap = undefined
) {
  const [preview, setPreview] = useState({
    preview: srcSetWebpMap,
  })
  const handleDeletePreview = () => {
    setFieldValue('file', undefined)
    setPreview()
  }

  const onDropRejected = (files, accept) => {
    if (files.find((el) => el.size > MAX_PHOTO_SIZE)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: MAX_PHOTO_SIZE / 1024 / 1024 },
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
      })
    }
  }

  const changeValues = useCallback(
    (values) => {
      if (values.videoUrl) {
        const { url, query } = queryString.parseUrl(values.videoUrl || '')

        if (url.indexOf('youtube.com') !== -1) {
          const videoId = query.v
          if (query.v) {
            setValues({
              ...values,
              hosting: VIDEO_HOSTING.YOUTUBE,
              videoId,
              videoUrl: HOSTING_URLS.YOUTUBE + videoId,
            })
            setStatus({})
          } else if (url.indexOf('/shorts/') !== -1) {
            console.log(url)
            console.log(url.split('?')[0])
            const videoId = url.split('?')[0].split('/shorts/')[1]
            setValues({
              ...values,
              hosting: VIDEO_HOSTING.YOUTUBE,
              videoId,
              videoUrl: HOSTING_URLS.YOUTUBE + videoId,
            })
            setStatus({})
          } else {
            setStatus({ error: 'upload.modal.error.invalid' })
          }
        } else if (url.indexOf('youtu.be') !== -1) {
          const videoId = url.split('/').pop()
          if (videoId) {
            setValues({
              ...values,
              hosting: VIDEO_HOSTING.YOUTUBE,
              videoId,
              videoUrl: HOSTING_URLS.YOUTUBE + videoId,
            })
            setStatus({})
          } else {
            setStatus({ error: 'upload.modal.error.invalid' })
          }
        } else if (url.indexOf('vimeo.com') !== -1) {
          const videoId = url.split('/').pop()
          if (videoId) {
            setValues({
              ...values,
              hosting: VIDEO_HOSTING.VIMEO,
              videoId,
              videoUrl: HOSTING_URLS.VIMEO + videoId,
            })
            setStatus({})
          } else {
            setStatus({ error: 'upload.modal.error.invalid' })
          }
        } else if (!values.hosting) {
          setStatus({ error: 'upload.modal.error.invalid' })
        }
      }
    },
    [setStatus, setValues]
  )

  const handleDropFile = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPreview({
        index: 0,
        file,
        name: file.name,
        preview: window.URL.createObjectURL(file),
      })
      setFieldValue('file', file)
    }
  }

  useEffect(() => {
    if (values.videoUrl) {
      updateVideoDebounce(() => changeValues(values))
    } else if (values.videoId || values.hosting) {
      setValues({
        ...values,
        videoId: undefined,
        hosting: undefined,
      })
    }
  }, [changeValues, setValues, values, values.videoUrl])

  return [preview, handleDeletePreview, handleDropFile, onDropRejected]
}
export function EditVideoForm(
  {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    values,
    setValues,
    setStatus,
    setFieldValue,
    openErrorModal,
    formAs,
    srcSetWebpMap,
  },
  { intl: { formatMessage } }
) {
  const accept = 'image/jpeg, image/png, image/gif'
  const { permissions } = useSubscription()

  const error = (errors && Object.keys(errors).length) || (status && !!status.error)

  const [preview, handleDeletePreview, handleDropFile, onDropRejected] = useVideo(
    values,
    setValues,
    setStatus,
    setFieldValue,
    openErrorModal,
    srcSetWebpMap
  )
  return (
    <Semantic.Form
      noValidate
      error={error}
      loading={isSubmitting && preview && preview.progress === 100}
      onSubmit={handleSubmit}
      as={formAs}
    >
      <Message error={error} errors={errors} content={status && status.error} />
      <Header>{formatMessage({ id: 'upload.video.title' })}</Header>
      <VideoInput>
        <FormInput name='videoUrl' label={formatMessage({ id: 'upload.video.label' })} />
      </VideoInput>

      <HeaderFlex>{formatMessage({ id: 'upload.video.ratio' })}</HeaderFlex>
      <Flex>
        <FlexNode
          active={values.videoRatioX === 16 && values.videoRatioY === 9}
          style={{
            width: '82px',
            height: '46px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 16,
              videoRatioY: 9,
            })
          }}
        >
          <div>16:9</div>
        </FlexNode>
        <FlexNode
          active={values.videoRatioX === 1 && values.videoRatioY === 1}
          style={{
            width: '46px',
            height: '46px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 1,
              videoRatioY: 1,
            })
          }}
        >
          <div>1:1</div>
        </FlexNode>
        <FlexNode
          active={values.videoRatioX === 4 && values.videoRatioY === 5}
          style={{
            width: '46px',
            height: '58px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 4,
              videoRatioY: 5,
            })
          }}
        >
          <div>4:5</div>
        </FlexNode>
        <FlexNode
          active={values.videoRatioX === 5 && values.videoRatioY === 4}
          style={{
            width: '58px',
            height: '46px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 5,
              videoRatioY: 4,
            })
          }}
        >
          <div>5:4</div>
        </FlexNode>
        <FlexNode
          active={values.videoRatioX === 2 && values.videoRatioY === 3}
          style={{
            width: '46px',
            height: '68px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 2,
              videoRatioY: 3,
            })
          }}
        >
          <div>2:3</div>
        </FlexNode>
        <FlexNode
          active={values.videoRatioX === 3 && values.videoRatioY === 2}
          style={{
            width: '68px',
            height: '46px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 3,
              videoRatioY: 2,
            })
          }}
        >
          <div>3:2</div>
        </FlexNode>
        <FlexNode
          active={values.videoRatioX === 9 && values.videoRatioY === 16}
          style={{
            width: '46px',
            height: '82px',
          }}
          onClick={() => {
            setValues({
              ...values,
              videoRatioX: 9,
              videoRatioY: 16,
            })
          }}
        >
          <div>9:16</div>
        </FlexNode>
      </Flex>
      {values.videoId && (
        <RadioGroup>
          <RadioField>
            <FormRadio name='customPreview' value={false} />
            <span>
              {formatMessage({
                id: 'upload.video.automaticPreview',
              })}
            </span>
          </RadioField>
          <RadioField>
            <PermissionsOverlay isAllowed={permissions.CUSTOM_VIDEO_PLACEHOLDER}>
              <FormRadio name='customPreview' value />
            </PermissionsOverlay>
            <span>
              {formatMessage({
                id: 'upload.video.customPreview',
              })}
            </span>
          </RadioField>
        </RadioGroup>
      )}
      {values.videoId && values.customPreview && (
        <UploadWrapper>
          {preview?.preview ? (
            <div className='image-preview'>
              <img src={preview.preview} alt='' />
              <DeleteButton onClick={handleDeletePreview}>
                <TrashIcon />
              </DeleteButton>
            </div>
          ) : (
            <Dropzone
              accept={accept}
              onDrop={handleDropFile}
              multiple={false}
              maxSize={MAX_PHOTO_SIZE}
              onDropRejected={(files) => onDropRejected(files, accept)}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()} className='input'>
                  <input {...getInputProps()} />
                  <FileUploadIcon color='lightGray' />
                  <span className='dropzone-header'>
                    {formatMessage({
                      id: 'upload.video.text.title',
                    })}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({
                          id: 'upload.video.text.browse',
                        }),
                      }}
                    />
                  </span>
                  <span
                    className='dropzone-footer'
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'upload.video.text.info',
                      }),
                    }}
                  />
                </div>
              )}
            </Dropzone>
          )}
        </UploadWrapper>
      )}
      {values.videoId && !values.customPreview && (
        <AutomaticVideoPreview videoId={values.videoId} hosting={values.hosting} />
      )}
      {values.videoId && (
        <Button
          type='submit'
          disabled={isSubmitting || error || !values.videoId || (values.customPreview && !preview)}
          content={formatMessage({
            id: 'upload.video.button.save',
          })}
        />
      )}
    </Semantic.Form>
  )
}

EditVideoForm.contextTypes = {
  intl: PropTypes.object.isRequired,
}
