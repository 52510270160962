import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  width: 100%;
`

export const Menu = styled.div`
  height: 36px;
  box-sizing: border-box;
  z-index: 0;

  margin-left: 16px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${(props) => (props.showBackArrow ? 'space-between' : 'flex-start')};
  ${(props) => props.showBackArrow && 'height: 35px; border-bottom: 1px solid #E1E2E6;'}

  ${media.phone`
        height: 50px;
    `}

    ${media.tablet`
      height: 60px;
      margin: 11px 18px 17px 18px;
    `}

    ${media.laptop`
       height: 35px;
       border-bottom: 1px solid #E1E2E6;
       margin: 0 30px;
       &:hover {
          cursor: ${(props) => (props.enableHover ? 'pointer' : 'default')};
          border-bottom: ${(props) =>
            props.enableHover ? '2px solid #343840' : '1px solid #E1E2E6'};
       }
    `}
`

export const ArrowBackContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  & svg {
    margin-right: 14px;
  }
`

export const MenuItem = styled.div`
  white-space: nowrap;
  height: 35px;
  margin-bottom: -2px;
  cursor: pointer;
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;

  color: ${(props) => (props.active ? '#343840' : '#666A73')};
  transition: color 100ms;

  & + & {
    margin-left: 30px;
  }

  ${(props) =>
    props.hide
      ? 'visibility: hidden;'
      : `&:hover {
        color: #343840;
        border-bottom: 2px solid #343840;
    }
  `}
  ${media.max.phone`
       font-size: 16px;
   `}

  ${media.phone`
       font-size: 20px;
   `}

  ${media.tablet`
       height: 35px;
       font-size: 13px;
       border-bottom: ${(props) => (props.active ? '2px solid #343840' : 'none')};
   `}
`

export const Pane = styled.div`
  margin-top: ${(props) => (props.isPortfolio ? '10px' : '20px')};
`
