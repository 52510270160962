import styled from 'styled-components'
import { Form as FormikForm } from 'formik'
import { Field, Paragraph } from '../nodes'
import { media } from 'packages/components/media'

export const Form = styled(FormikForm)`
  width: 474px;
  max-width: 100%;

  ${media.max.tablet`
    width: 100%;
    margin-top: 24px;

        > button {
            margin-bottom: 40px !important;
        }
    `}
`

export const CopyToClipboardButtons = styled.div`
  & > *:not(:first-child) {
    margin-top: 15px;
  }

  > b:first-child {
    margin-left: 156px;
    font-family: sans-serif;
    line-height: 1.15;
    vertical-align: middle;
    color: black;
  }

  ${media.max.phone`
        > b:first-child {
            margin-left: 0;
        }
    `}
`

export const CopyToClipboardButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
`

export const StylizedText = styled.span`
  color: #58c367;
  word-break: break-all;
`

export const ToggleField = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.max.phone`
        ${Paragraph} {
            font-size: 15px;
        }
    `}
`
