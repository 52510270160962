import styled from 'styled-components'

export const MessageContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding: 14px;
  color: ${(props) => (props.error ? '#FF002E' : '#343840')};
  background: ${(props) => (props.error ? '#fde6eb' : '#eff9f0')};
  border-radius: 2px;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;

  > div {
    margin-left: 32px;
    > span {
      display: block;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 14px;
  }
`
