import React from 'react'
import PropTypes from 'prop-types'

export const WindowIcon = ({ className, onClick }) => (
  <svg
    className={className}
    width='40'
    height='40'
    viewBox='-9 -11 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      className='iconBg'
      cx='11'
      cy='9'
      r='20'
      fill='white'
      onClick={onClick}
      data-testid='main-page-preview-desktop'
    />
    <path
      className='main'
      onClick={onClick}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 18H6V16H8V15H2C0.89543 15 0 14.1046 0 13V2C0 0.89543 0.89543 0 2 0H20C21.1046 0 22 0.89543 22 2V13C22 14.1046 21.1046 15 20 15H14V16H16V18ZM2 2V13H20V2H2Z'
    />
  </svg>
)

WindowIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

WindowIcon.defaultProps = {
  className: '',
  onClick: () => {},
}
