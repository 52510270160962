import styled from 'styled-components'

export const StyledTextareaContainer = styled.div`
  margin-top: 20px;

  textarea {
    min-height: 100px !important;
  }
`

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`

export const DescriptionWrapper = styled.div`
  position: relative;
  z-index: 0;
  .fr-box.fr-basic .fr-element {
    padding: 12px 14px;
  }

  .fr-second-toolbar > a {
    display: none;
  }
  .fr-toolbar .fr-btn-wrap.fr-hidden,
  .fr-popup .fr-btn-wrap.fr-hidden,
  .fr-modal .fr-btn-wrap.fr-hidden {
    display: block;
  }
  .fr-toolbar {
    & > .fr-btn-grp {
      margin: 3px 3px 0 3px !important;
    }
    & .fr-btn-grp,
    .fr-command.fr-btn {
      margin: 0;
    }
  }
`

export const DescriptionLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`
