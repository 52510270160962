import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useCallback } from 'react'
import Dropzone from 'react-dropzone'
import { injectIntl } from 'react-intl'
import { Button } from 'packages/components/buttons'
import {
  CenteredDropModal,
  PreviewBlock,
  PreviewContainer,
  PreviewWrapper,
  UploadDropZone,
  UploadDropZoneContent,
  UploadError,
  UploadPicture,
  UploadPictureInfo,
  UploadWrapper,
} from '../../pageUpdate/nodes'
import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'
import { FileUploadIcon, TrashIcon } from 'packages/components/icons'
import { SmallTitle } from '../../nodes'

function emptyFn() {}

const dropModalStyle = {
  height: '540px',
  width: '670px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '20px',
  paddingBottom: '20px',
}

function UploadImageBlock({
  title,
  intl: { formatMessage },
  isSubmitting,
  photoBlockProperty,
  onDropFile = emptyFn,
  onDropReject = emptyFn,
  fileSrc,
  fileName,
  onDeleteImageClick = emptyFn,
  isFileReady,
  uploadModalTitle,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [showError, setShowError] = useState(false)

  const onModalOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onModalClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleDropFile = useCallback(
    (files) => {
      if (!files.length) {
        return
      }
      onDropFile(files)
      onModalClose()
      if (showError) setShowError(false)
    },
    [onDropFile, onModalClose, showError]
  )

  const renderDropModal = () => {
    const accept = 'image/jpeg, image/gif, image/png'
    return (
      <CenteredDropModal
        open={isOpen}
        onClose={onModalClose}
        styles={dropModalStyle}
        centered
        showCloseIcon={false}
        showHeaderCloseIcon
      >
        {uploadModalTitle ? (
          <SmallTitle>{formatMessage({ id: uploadModalTitle })}</SmallTitle>
        ) : null}
        <UploadWrapper>
          <Dropzone
            accept={accept}
            onDrop={handleDropFile}
            multiple={false}
            maxSize={MAX_IMAGE_SIZE}
            onDropRejected={(files) => {
              if (files.length > 1) {
                setShowError(true)
              }
              onDropReject(files, accept)
            }}
          >
            {({ getInputProps, getRootProps, isDragActive }) => (
              <div {...getRootProps()} className='input'>
                {showError && (
                  <UploadError>
                    {formatMessage({
                      id: 'upload.video.drop.multiple.error',
                    })}
                  </UploadError>
                )}

                <UploadDropZoneContent>
                  <input {...getInputProps()} />
                  <FileUploadIcon color='lightGray' />
                  <span className='dropzone-header'>
                    {formatMessage({
                      id: 'upload.video.text.title',
                    })}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({
                          id: 'upload.video.text.browse',
                        }),
                      }}
                    />
                  </span>
                  <span
                    className='dropzone-footer'
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'upload.video.text.info',
                      }),
                    }}
                  />
                  <UploadDropZone show={isDragActive}>
                    {formatMessage({
                      id: 'newDesign.page.update.drop.image',
                    })}
                  </UploadDropZone>
                </UploadDropZoneContent>
              </div>
            )}
          </Dropzone>
        </UploadWrapper>
      </CenteredDropModal>
    )
  }

  const renderPreview = () => {
    if (!fileSrc) return null
    return (
      <PreviewBlock>
        <PreviewContainer>
          <PreviewWrapper>
            <img alt='' src={fileSrc} />
            {/* <ProgressContainer progress={progress}/> */}
          </PreviewWrapper>
        </PreviewContainer>
        <div>
          <span>{isFileReady && fileName ? `/..${fileName}` : ''}</span>
          <div onClick={onDeleteImageClick}>
            <span>
              {formatMessage({
                id: 'newDesign.page.update.button.delete',
              })}
            </span>
            <TrashIcon />
          </div>
        </div>
      </PreviewBlock>
    )
  }

  return (
    <>
      {renderDropModal()}
      <UploadPicture hideButton={photoBlockProperty}>
        <UploadPictureInfo>
          <SmallTitle>
            {formatMessage({
              id: title,
            })}
          </SmallTitle>
          {/*{description ? (*/}
          {/*    <Description>*/}
          {/*        {formatMessage({*/}
          {/*            id: description,*/}
          {/*        })}*/}
          {/*    </Description>*/}
          {/*) : null}*/}
        </UploadPictureInfo>
        <Button type='button' disabled={isSubmitting} view='secondaryBlack' onClick={onModalOpen}>
          {formatMessage({
            id: 'newDesign.page.update.button.uploadImage',
          })}
        </Button>
      </UploadPicture>
      {renderPreview()}
    </>
  )
}

export default injectIntl(UploadImageBlock)

UploadImageBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSubmitting: PropTypes.bool,
  photoBlockProperty: PropTypes.object,
  onDropFile: PropTypes.func.isRequired,
  onDropReject: PropTypes.func.isRequired,
  fileSrc: PropTypes.string,
  fileName: PropTypes.string,
  onDeleteImageClick: PropTypes.func,
  isFileReady: PropTypes.bool,
  progress: PropTypes.number,
  uploadModalTitle: PropTypes.string,
}
