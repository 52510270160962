import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import get from 'lodash/get'

import updatePassword from '@graphql/gql/common/updatePassword.gql'
import UpdatePasswordForm from '@configurator/components/billing/modals/changePassword/forms/UpdatePasswordForm'

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('changePassword.error.required'),
  newPassword: Yup.string().required('changePassword.error.required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'changePassword.error.password.match')
    .required('changePassword.error.required'),
})

export default compose(
  graphql(updatePassword, {
    props({ mutate }) {
      return {
        async updatePasswordMutation({ newPassword, oldPassword }) {
          try {
            return await mutate({
              variables: {
                newPassword,
                oldPassword,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => {
      return {
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      { newPassword, oldPassword },
      { setSubmitting, setStatus, props: { updatePasswordMutation } }
    ) => {
      let defaultError = 'error.desc'
      try {
        const res = await updatePasswordMutation({
          newPassword,
          oldPassword,
        })
        setSubmitting(false)
        let {
          data: { updatePassword: { errors, success } = {} },
        } = res
        if (success) {
          return setStatus({ success: true })
        }
        setSubmitting(false)
        setStatus({ error: get(errors, '_error', defaultError) })
      } catch (err) {
        setSubmitting(false)
        setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      }
    },
  })
)(UpdatePasswordForm)
