import styled from 'styled-components'

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
`

export const WrapperItem = styled.div`
  margin-bottom: 30px;

  & > div {
    margin-bottom: 17px;
  }
`

export const SectionLabel = styled.label`
  display: block;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 17px;
  font-weight: 700;
  color: #343840;
`
