import { createActions } from 'redux-actions'

export const {
  subscription: { openErrorModal, closeErrorModal },
} = createActions({
  SUBSCRIPTION: {
    OPEN_ERROR_MODAL: (payload) => ({ ...payload, openErrorModal: true }),
    CLOSE_ERROR_MODAL: () => {},
  },
})
