import React from 'react'
import PropTypes from 'prop-types'

export const PdfIcon = ({ className, onClick }) => (
  <svg
    className={className}
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      className='iconBg'
      cx='20'
      cy='20'
      r='20'
      fill='white'
      onClick={onClick}
      data-testid='main-page-open-pdf'
    />
    <path className='main' d='M18.55 22H16L20 26L24 22H21.45V19H18.55V22Z' onClick={onClick} />
    <path
      className='main'
      d='M14.435 10C14.9169 10 15.3354 10.0914 15.6905 10.2743C16.0456 10.4514 16.3195 10.7086 16.5123 11.0457C16.705 11.3771 16.8014 11.7686 16.8014 12.22C16.8014 12.6714 16.705 13.0629 16.5123 13.3943C16.3195 13.7257 16.0456 13.9829 15.6905 14.1657C15.3354 14.3429 14.9169 14.4314 14.435 14.4314H13.5066V16H12V10H14.435ZM14.3436 13.0943C14.6531 13.0943 14.8864 13.02 15.0437 12.8714C15.2009 12.7171 15.2796 12.5 15.2796 12.22C15.2796 11.94 15.2009 11.7229 15.0437 11.5686C14.8864 11.4143 14.6531 11.3371 14.3436 11.3371H13.5066V13.0943H14.3436Z'
      onClick={onClick}
    />
    <path
      className='main'
      d='M17.536 10H20.0547C20.638 10 21.1555 10.1229 21.6069 10.3686C22.0584 10.6143 22.4084 10.9629 22.657 11.4143C22.9056 11.8657 23.0299 12.3943 23.0299 13C23.0299 13.6057 22.9056 14.1343 22.657 14.5857C22.4084 15.0371 22.0584 15.3857 21.6069 15.6314C21.1555 15.8771 20.638 16 20.0547 16H17.536V10ZM19.9938 14.6457C20.4503 14.6457 20.8156 14.5 21.0895 14.2086C21.3685 13.9171 21.508 13.5143 21.508 13C21.508 12.4857 21.3685 12.0829 21.0895 11.7914C20.8156 11.5 20.4503 11.3543 19.9938 11.3543H19.0426V14.6457H19.9938Z'
      onClick={onClick}
    />
    <path
      className='main'
      d='M25.3292 11.3114V12.6314H27.6804V13.9429H25.3292V16H23.8225V10H28V11.3114H25.3292Z'
      onClick={onClick}
    />
    <line className='main stroke' x1='12' y1='29' x2='28' y2='29' strokeWidth='2' />
  </svg>
)

PdfIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

PdfIcon.defaultProps = {
  className: '',
  onClick: () => {},
}
