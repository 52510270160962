import { compose } from 'redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { connect } from 'react-redux'

import mostViewedGet from '@graphql/gql/website/mostViewedGet.gql'

import Wrapper from '@configurator/components/statistics/mostViewed/Wrapper'

export const subIdKey = 'idsubdatatable_in_db'
export const placeholderData = [
  {
    label: 'image',
    [subIdKey]: 2,
  },
  {
    label: 'category',
    [subIdKey]: 1,
  },
  {
    label: 'album',
    [subIdKey]: 3,
  },
]

export const mostViewedWrapper = (Component) =>
  compose(
    connect((state) => ({ matomoOptions: state.get('periodPicker').toJS() })),
    graphql(mostViewedGet, {
      skip: ({ matomoOptions }) => !matomoOptions.idSite,
      options: ({ matomoOptions }) => ({
        variables: matomoOptions,
        ssr: false,
      }),
      props({ data: { loading, mostViewedGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(mostViewedGet)) {
          return { loading }
        }
        const { data = placeholderData } = mostViewedGet
        return {
          subIds: isArray(data)
            ? {
                image: get(
                  data.find((i) => i.label === 'image'),
                  subIdKey
                ),
                category: get(
                  data.find((i) => i.label === 'category'),
                  subIdKey
                ),
                album: get(
                  data.find((i) => i.label === 'album'),
                  subIdKey
                ),
              }
            : {},
        }
      },
    })
  )(Component)

export default mostViewedWrapper(Wrapper)
