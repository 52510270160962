import transform from 'lodash/transform'

const BULK_SIZE = 3

export async function bulkCall(
  mutations,
  bulk = [],
  makeVariables = (el) => el,
  bulkSize = BULK_SIZE,
  processResults = (el, mutationsResult) => ({ el, mutationsResult })
) {
  if (!mutations) {
    throw new Error('Mutations is undefined')
  }
  try {
    let pack = []
    let packs = []
    for (let i = 0; i < bulk.length; i++) {
      pack.push({
        query: { variables: makeVariables(bulk[i]) },
        index: i,
      })
      if ((i + 1) % bulkSize === 0) {
        packs.push(pack)
        pack = []
      }
    }
    if (pack.length) {
      packs.push(pack)
    }
    let result = []
    for (let pack of packs) {
      try {
        let res = await Promise.all(
          pack.map(async (el) => {
            const mutationsResult = await mutations(el.query)
            processResults(el, mutationsResult)
            return mutationsResult
          })
        )
        result = transform(
          pack,
          (state, { index }, i) => {
            state[index] = { ...res[i], index }
          },
          result
        )
      } catch (e) {
        result = transform(
          pack,
          (state, { index }) => {
            state[index] = { error: e, index }
          },
          result
        )
      }
    }
    return result
  } catch (e) {
    return e
  }
}
