import PropTypes from 'prop-types'
import React from 'react'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { Modal } from 'packages/components/modal'
import { Container } from './nodes'
import { EditVideoForm } from './EditVideoForm'

const UploadVideo = ({
  handleSubmit,
  errors,
  status,
  isSubmitting,
  values,
  setValues,
  setStatus,
  setFieldValue,
  onClose,
  open,
  openErrorModal,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <EditVideoForm
          handleSubmit={handleSubmit}
          errors={errors}
          status={status}
          isSubmitting={isSubmitting}
          values={values}
          setValues={setValues}
          setStatus={setStatus}
          setFieldValue={setFieldValue}
          openErrorModal={openErrorModal}
        />
      </Container>
    </Modal>
  )
}

UploadVideo.propTypes = {
  onClose: PropTypes.func.isRequired,
  refetchPagePreview: PropTypes.func,
  open: PropTypes.bool,
  openErrorModal: PropTypes.func,
  ...formProptypes,
}

UploadVideo.defaultProps = {
  open: false,
  refetchPagePreview: () => null,
  openErrorModal: () => null,
}

export default UploadVideo
