import styled from 'styled-components'
import { Modal } from 'packages/components/modal'
import { Button } from 'packages/components/buttons'
import { FormTextArea } from '../inputs'

export const ModalContainer = styled(Modal)`
  position: initial !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  color: #242426;
`

export const StTitle = styled.div`
  font-size: ${({ small }) => (small ? '16px' : '24px')};
  line-height: 150%;
  font-weight: bold;
  padding: 0;
  border: none;
`

export const StModalBody = styled.div`
  font-size: 15px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

export const StImgInfoBlock = styled.div`
  display: flex;
  gap: 50px;
  margin: 20px 0;
`

export const StImgInfoParams = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`

export const StImgInfoParamsRow = styled.div`
  display: flex;
  gap: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StImgParam = styled.span`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  gap: 7px;
  svg {
    margin-bottom: 2px;
  }
`

export const StFooter = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
  }
`

export const StImg = styled.div`
  background-color: #e1e2e5;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const StAltBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const StTabsContainer = styled.div`
  display: flex;
  padding: 3px;
  border: 1px solid #e1e2e5;
  width: 100%;
  border-radius: 4px;
`

export const StTabBtn = styled(Button)`
  width: 50%;
  height: 40px !important;
  transition: all 0.2s ease-in-out !important;
  background-color: ${({ view }) => view === 'secondaryBlack' && `#242426`} !important;
  color: ${({ view }) => (view === 'secondaryWhite' ? `#666A73` : '#fff')} !important;
  &:active {
    transform: translateY(1px) !important;
  }
`

export const StTextarea = styled(FormTextArea)`
  width: 100%;
  textarea {
    width: 100%;
    border-radius: 4px !important;
    resize: none;
    &::placeholder {
      color: #9fa1a6;
    }
  }
`
export const StGenerate = styled.div`
  width: 100%;
  display: flex;
  font-weight: 600;
  font-size: 13px;
  margin-top: -16px;
  cursor: pointer;
  align-items: center;

  > svg {
    max-width: 18px;
    max-height: 18px;
    margin-right: 6px;
  }
`

export const StHint = styled.div`
  border-left: 2px solid #58c367;
  background-color: #eef9f0;
  padding: 12px;
  border-radius: 4px;
  p {
    line-height: 1.5;
    max-width: 500px;
  }
`
