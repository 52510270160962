import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'

import get from 'lodash/get'
import clone from 'lodash/clone'
import slice from 'lodash/slice'
import concat from 'lodash/concat'
import maxBy from 'lodash/maxBy'

import { useBeforeunload } from 'react-beforeunload'

import { Button } from 'packages/components/buttons'
import { bulkCall } from 'packages/components/photoUpload/bulkCall'
import { MESSAGE_TYPE } from 'packages/enum'
import { MAX_PHOTO_SIZE } from '@configurator/constants/upload'
import { FileUploadIcon } from 'packages/components/icons/file'

import PreviewList from '../photoPreview/PhotoPreview'
import {
  UploadContainer,
  UploadWrapper,
  Container,
  MobileButtonsContainer,
  Modal,
  StyledRadioContainer,
  UploadStyledModalHeader,
} from './nodes'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'
import { Radio } from 'packages/components/inputs'
import { StyledRadio } from '@configurator/components/modals/pageCreate/nodes'
import { ButtonRow } from 'packages/components/modal/nodes'

const getErrorMessage = (code) => {
  switch (code) {
    case 400:
      return 'upload.error.subscription.short'
    case 413:
      return 'upload.error.fileSize.short'
    default:
      return 'upload.error.description.short'
  }
}

let localLastIndex = -1

const UploadPhotos = (
  {
    albumId,
    photos,
    uploads,
    openErrorModal,
    lockModalProgress,
    unLockModalProgress,
    websiteOnePhotoUpload,
    onClose,
    onPhotosUpload,
    openPermissionModal,
    open,
    loading,
  },
  { intl: { formatMessage } }
) => {
  useBeforeunload(() =>
    formatMessage({
      id: 'upload.photos.beforeUnloadMessage',
    })
  )

  const [files, setFiles] = useState([])
  const [order, setOrder] = useState('end')

  const [isFilesReady, setIsFilesReady] = useState(false)

  useEffect(() => {
    lockModalProgress()
    return () => unLockModalProgress()
  }, [lockModalProgress, unLockModalProgress])

  useEffect(() => {
    setFiles((prevFiles) =>
      prevFiles.map((el) => ({
        ...el,
        progress: Math.max(uploads.get(el.name, 0), el.progress || 0),
      }))
    )
  }, [uploads])

  const previewPhotos = (newFiles) => {
    if (newFiles && newFiles.length) {
      const lastIndex = 1 + (clone(files).sort((a, b) => b.index - a.index)[0] || {}).index || 0
      const updatedFiles = newFiles.map((file, index) => ({
        file,
        index: index + lastIndex,
        name: file.name,
        preview: window.URL.createObjectURL(file),
      }))
      setFiles(files.concat(updatedFiles))
      setIsFilesReady(true)
    }
  }

  const onDropRejected = (files, accept) => {
    if (files.find((el) => el.size > MAX_PHOTO_SIZE)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: MAX_PHOTO_SIZE / 1024 / 1024 },
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
      })
    }
  }

  const onDeletePhoto = (index) => {
    const filteredFiles = clone(files).filter((photo) => photo.index !== index)

    setFiles(filteredFiles)

    if (!filteredFiles.length) {
      return setIsFilesReady(false)
    }
  }

  const deleteAll = () => {
    setFiles([])
    setIsFilesReady(false)
  }

  const onClickAddPhotos = () => setIsFilesReady(false)

  const onDropItem = ({ index, sourceIndex }) => {
    if (index !== sourceIndex) {
      const sourceItem = files.find((el) => el.index === index)
      const filteredFiles = clone(files).filter((file) => file.index !== index)
      const ind = filteredFiles.findIndex((el) => el.index === sourceIndex)
      const leftPhotos = slice(filteredFiles, 0, ind)
      const rightPhotos = slice(filteredFiles, ind)
      const newFiles = concat(leftPhotos, sourceItem, rightPhotos)
      setFiles(newFiles)
    }
  }

  const upload = async () => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        loading: true,
        progress: file.progress || 0,
      }))
    )

    const remoteLastIndex = maxBy(photos, 'index')?.index

    // В случае, когда после загрузки изображений индекс последнего не приходит от бэкенда,
    // устанавливаем его локально для правильной последовательности загрузки изображений.
    let lastIndex =
      localLastIndex > 0
        ? remoteLastIndex > localLastIndex
          ? remoteLastIndex
          : localLastIndex
        : remoteLastIndex

    const bulkFiles = files.map((file, id) => {
      lastIndex = !id && !lastIndex ? 512 : lastIndex + 1024

      return { ...file, lastIndex }
    })

    if (bulkFiles.length) {
      localLastIndex = bulkFiles[bulkFiles.length - 1].lastIndex
    }

    let newFiles = []

    const result = await bulkCall(
      websiteOnePhotoUpload,
      bulkFiles,
      (data) => ({
        file: data.file,
        index: order === 'start' ? 0 : data.lastIndex,
        albumId,
      }),
      3,
      (el, mutationsResult) => {
        // после каждой загрузки фильтруем загруженные и оставляем ошибочные
        setFiles((prevFiles) => {
          newFiles = prevFiles
            .map((file) => {
              if (file.index !== el.index) {
                return file
              }
              const newFile = {
                ...file,
                loading: false,
                progress: 0,
              }
              const error = get(mutationsResult, 'data.websiteOnePhotoUpload.errors._error')
              const data = get(mutationsResult, 'data.websiteOnePhotoUpload.data')

              if (error || [400, 413].includes(data)) {
                // устанавливаем ошибки на соответствующие фото
                return {
                  ...newFile,
                  error: formatMessage({
                    id: getErrorMessage(error || data),
                  }),
                }
              }

              ANALYTICS.photoUpload()
              return newFile
            })
            .filter((file) => file.loading || file.error || file.loading === undefined)
          return newFiles
        })
      }
    )

    // если лимит загрузок исчерпан
    if (result.filter((el) => get(el, 'data.websiteOnePhotoUpload.data') === 400).length) {
      onClose()
      openPermissionModal()
    }

    if (newFiles.length === 0) {
      // window.location.reload()
      onClose()
    }

    // предотвратит window.frames["preview-frame"]?.postMessage на незагруженный iframe
    const uploadIfFrameLoaded = (e) => {
      if (e.data?.loaded) {
        window.frames['preview-frame']?.postMessage(
          JSON.stringify({
            name: MESSAGE_TYPE.UPDATE_PAGE,
            albumId,
          }),
          '*'
        )

        onPhotosUpload()

        setFiles((prevFiles) => prevFiles.map((el, index) => ({ ...el, index })))
        window.removeEventListener('message', uploadIfFrameLoaded)
      }
    }

    window.addEventListener('message', uploadIfFrameLoaded)

    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.FRAME_LOADED,
      }),
      '*'
    )
  }

  const isUploadDisabled = files.filter((el) => el.loading).length
  const renderButtons = () =>
    isFilesReady ? (
      <>
        <Button
          content={formatMessage({
            id: 'upload.photos.button.removeAll',
          })}
          view='secondaryGray'
          disabled={isUploadDisabled}
          onClick={deleteAll}
        />
        <Button
          content={formatMessage({
            id: 'upload.photos.button.upload',
          })}
          disabled={isUploadDisabled}
          onClick={upload}
        />
      </>
    ) : (
      <Button
        content={formatMessage({
          id: 'upload.photos.button.viewFiles',
        })}
        view='secondaryGray'
        onClick={() => setIsFilesReady(true)}
      />
    )

  const accept = 'image/jpeg, image/png, image/gif'
  return (
    <Modal width='974px' open={open} onClose={onClose} mobileWide>
      <Container>
        {files.length ? (
          <UploadStyledModalHeader>
            {formatMessage({ id: 'upload.photos.title' }, { count: files.length })}
            <StyledRadioContainer>
              <StyledRadio>
                <Radio
                  radio
                  checked={order === 'start'}
                  value='start'
                  onChange={() => setOrder('start')}
                />
                <span>{formatMessage({ id: 'upload.photos.orderStart' })}</span>
              </StyledRadio>
              <StyledRadio>
                <Radio
                  radio
                  checked={order === 'end'}
                  value='end'
                  onChange={() => setOrder('end')}
                />
                <span>{formatMessage({ id: 'upload.photos.orderEnd' })}</span>
              </StyledRadio>
            </StyledRadioContainer>
            <ButtonRow>{renderButtons()}</ButtonRow>
          </UploadStyledModalHeader>
        ) : null}
        <UploadWrapper compact={files.length}>
          {isFilesReady && files.length ? (
            <UploadContainer>
              <PreviewList
                photos={files}
                hideCheckbox
                loading={loading}
                showDelete
                onDelete={onDeletePhoto}
                onClickAdd={onClickAddPhotos}
                onDropItem={onDropItem}
              />
            </UploadContainer>
          ) : (
            <Dropzone
              accept={accept}
              onDrop={previewPhotos}
              maxSize={MAX_PHOTO_SIZE}
              onDropRejected={(files) => onDropRejected(files, accept)}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()} className='input'>
                  <input {...getInputProps()} />
                  <FileUploadIcon color='lightGray' />
                  <span className='dropzone-header'>
                    {formatMessage({
                      id: 'upload.video.text.title',
                    })}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({
                          id: 'upload.video.text.browse',
                        }),
                      }}
                    />
                  </span>
                  <span
                    className='dropzone-footer'
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'upload.video.text.info',
                      }),
                    }}
                  />
                </div>
              )}
            </Dropzone>
          )}
        </UploadWrapper>
        {!!files.length && <MobileButtonsContainer>{renderButtons()}</MobileButtonsContainer>}
      </Container>
    </Modal>
  )
}

UploadPhotos.propTypes = {
  open: PropTypes.bool,
  albumId: PropTypes.number.isRequired,
  uploads: PropTypes.object.isRequired,
  lockModalProgress: PropTypes.func.isRequired,
  unLockModalProgress: PropTypes.func.isRequired,
  websiteOnePhotoUpload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPhotosUpload: PropTypes.func,
  openPermissionModal: PropTypes.func,
  photos: PropTypes.array,
  openErrorModal: PropTypes.func,
  loading: PropTypes.bool,
}

UploadPhotos.defaultProps = {
  open: false,
  photos: [],
  onPhotosUpload: () => null,
  openPermissionModal: () => null,
  openErrorModal: () => null,
}

UploadPhotos.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default UploadPhotos
