import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const GridIcon = ({ color }) => (
  <svg width='6' height='14' viewBox='0 0 6 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 5C0 4.44772 0.447715 4 1 4C1.55228 4 2 4.44772 2 5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5ZM0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1ZM0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9ZM4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5ZM4 1C4 0.447715 4.44772 0 5 0C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2C4.44772 2 4 1.55228 4 1ZM4 9C4 8.44771 4.44772 8 5 8C5.55228 8 6 8.44771 6 9C6 9.55229 5.55228 10 5 10C4.44772 10 4 9.55229 4 9Z'
      fill={iconColors[color]}
    />
    <path
      d='M0 13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13C2 13.5523 1.55228 14 1 14C0.447715 14 0 13.5523 0 13ZM4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13Z'
      fill={iconColors[color]}
    />
  </svg>
)

GridIcon.propTypes = {
  ...iconColorPropTypes,
}

GridIcon.defaultProps = {
  color: 'dark',
}
