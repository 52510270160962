import PropTypes from 'prop-types'
import { iconColorPropTypes, PdfIcon } from '../../icons'
import { StyledSVG } from '../svgStyles'

export const PdfButton = StyledSVG(PdfIcon)

PdfButton.propTypes = {
  ...iconColorPropTypes,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

PdfButton.defaultProps = {
  color: 'darkGray',
  active: true,
  className: '',
  onClick: () => {},
}
