import React, { useMemo, useEffect, useState } from 'react'
import { FocusPicker } from 'image-focus'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

import { Container } from './nodes'

const ImageFocusPointSelector = ({ src, onChange, xFocalPoint, yFocalPoint }) => {
  const [ref, setRef] = useState(null)

  const debouncedFn = useMemo(() => debounce(onChange, 150), [onChange])

  const picker = useMemo(() => {
    if (ref) {
      return new FocusPicker(ref, {
        focus: { x: xFocalPoint, y: yFocalPoint },
        onChange: debouncedFn,
      })
    }
    return undefined
  }, [debouncedFn, ref, xFocalPoint, yFocalPoint])

  useEffect(() => {
    if (picker?.focus?.x !== xFocalPoint || picker?.focus?.y !== yFocalPoint) {
      picker?.setFocus({ x: xFocalPoint, y: yFocalPoint })
    }
  }, [picker, xFocalPoint, yFocalPoint])

  useEffect(() => {
    return () => {
      if (picker) {
        picker.disable()
      }
    }
  }, [picker])

  return (
    <Container>
      <img ref={setRef} src={src} alt='' />
    </Container>
  )
}

ImageFocusPointSelector.propTypes = {
  src: PropTypes.string.isRequired,
  xFocalPoint: PropTypes.number,
  yFocalPoint: PropTypes.number,
  onChange: PropTypes.func,
}

ImageFocusPointSelector.defaultProps = {
  xFocalPoint: '0',
  yFocalPoint: '0',
  onChange: () => null,
}

export default ImageFocusPointSelector
