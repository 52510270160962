import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { changeView, setView } from './actions'

const defaultState = fromJS({
  expandedView: false,
  typeView: null,
})

export default handleActions(
  {
    [changeView]: {
      next(state, { payload }) {
        return state.set('expandedView', payload?.expandedView ?? !state.get('expandedView'))
      },
    },
    [setView]: {
      next(state, { payload }) {
        return state.set('typeView', payload)
      },
    },
  },
  defaultState
)
