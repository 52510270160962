import { useState, useRef, useEffect } from 'react'

const useFocusInputs = (initialValue, numberOfInputs) => {
  const inputRefs = useRef([])

  const [values, setValues] = useState(Array(numberOfInputs).fill(initialValue))

  const handleChange = (index) => (e) => {
    const inputValue = e.target.value
    let newValues = [...values]

    // Handle pasting multiple characters
    if (inputValue.length > 1) {
      inputValue.split('').forEach((char, i) => {
        if (!isNaN(char) && char !== ' ') {
          newValues[index + i] = char
        }
      })
    } else {
      const nextValue = inputValue.slice(-1)

      if (!isNaN(nextValue) && nextValue !== ' ') {
        newValues[index] = nextValue
      }
    }

    setValues(newValues)
  }

  const focusNextInput = (index) => {
    if (index < numberOfInputs - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const focusPrevInput = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus()
    }
  }

  useEffect(() => {
    values.forEach((value, index) => {
      if (value) {
        focusNextInput(index)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const resetInputs = () => {
    setValues(Array(numberOfInputs)?.fill(initialValue))

    inputRefs?.current[0]?.focus()
  }

  return {
    inputRefs,
    values,
    handleChange,
    onKeyUp: (index) => (e) => {
      if (e.key === 'Backspace' && !values[index]) {
        focusPrevInput(index)
      }
    },
    resetInputs,
  }
}

export default useFocusInputs
