import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import concat from 'lodash/concat'
import get from 'lodash/get'
import moment from 'moment'

import { bulkCall } from 'packages/components/photoUpload/bulkCall'
import { MESSAGE_TYPE, URLS } from 'packages/enum'
import { Button } from 'packages/components/buttons'
import { getDomain } from 'packages/helpers/Helper'

import { useSubscription } from '@configurator/providers/subscription'
import { ButtonRow, CloseIcon, ModalWrapper } from './nodes'
import {
  close as closeSubscriptionModal,
  open as openSubscriptionModal,
} from 'packages/redux/modules/modalConfirmDelete/actions'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.css')
require('froala-editor/js/plugins.pkgd.min.js')
require('froala-editor/js/froala_editor.pkgd.min.js')
require('./imageIndentPlugin')

const froalaConfig = {
  key: __FROALA_API_KEY__,
  zIndex: 1000,
  imageUpload: true,
  pastePlain: true,
  imageAllowedTypes: ['jpeg', 'jpg', 'png'],
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  imageEditButtons: [
    'imageAlign',
    'imageCaption',
    'imageRemove',
    'imageLink',
    'linkOpen',
    'linkEdit',
    'linkRemove',
    '-',
    'imageDisplay',
    'imageStyle',
    'imageSize',
    'imageIndent',
  ],
  videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  linkInsertButtons: ['linkBack'],
  toolbarButtons: {
    moreText: {
      buttons: ['bold', 'italic', 'underline', 'clearFormatting'],
      buttonsVisible: 4,
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'alignRight',
        'formatOLSimple',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'lineHeight',
        'outdent',
        'indent',
        'quote',
      ],
      buttonsVisible: 4,
    },
    moreRich: {
      buttons: [
        'insertLink',
        'insertImage',
        'insertVideo',
        'insertTable',
        'emoticons',
        'specialCharacters',
        'embedly',
        'insertHR',
      ],
      buttonsVisible: 3,
    },
    moreOther: {
      buttons: ['html'],
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'selectAll', 'getPDF'],
      align: 'right',
      buttonsVisible: 3,
    },
  },
  quickInsertButtons: ['image', 'ul', 'ol', 'hr'],
}

const froalaInlineConfig = {
  key: __FROALA_API_KEY__,
  zIndex: 1000,
  pastePlain: true,
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  linkInsertButtons: ['linkBack'],
  toolbarButtons: {
    moreText: {
      buttons: ['bold', 'italic', 'underline', 'clearFormatting'],
      buttonsVisible: 4,
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'formatOLSimple',
        'paragraphFormat',
        'lineHeight',
        'outdent',
        'indent',
      ],
      buttonsVisible: 4,
    },
    moreRich: {
      buttons: ['insertLink'],
      buttonsVisible: 1,
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'selectAll'],
      align: 'right',
      buttonsVisible: 3,
    },
  },
  quickInsertButtons: ['ul', 'ol', 'hr'],
  charCounterMax: 6000,
  toolbarSticky: false,
}

const TextEditor = ({
  open,
  onClose,
  blockId,
  text,
  fontFamily,
  fontSize,
  textColor,
  backgroundColor,
  fullscreen,
  lockModalProgress,
  unLockModalProgress,
  openErrorModal,
  loaderStart,
  loaderStop,
  refetchPagePreview,
  blockUpdate,
  updateBlockWithPhoto,
  inline,
  handleChangeText,
  intl: { formatMessage },
}) => {
  const insertedImage = useRef()
  const imagesForUpload = useRef([])
  const [model, setModel] = useState(text)
  const { permissions } = useSubscription()
  const dispatch = useDispatch()

  useEffect(() => {
    setModel(text)
    imagesForUpload.current = []
  }, [text])

  useEffect(() => {
    handleChangeText(model)
  }, [handleChangeText, model])

  useEffect(() => {
    imagesForUpload.current = []
  }, [open])

  const handleClose = () => {
    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.UPDATE_PAGE,
      }),
      '*'
    )
    onClose()
  }

  const hasHTTP = (str) => !!str.match(/https?:\/\//)

  const permissionModalDispatcher = useCallback(
    (
      headerMessageId = 'permission.modal.pro.max.header',
      subMessageId = 'permission.modal.pro.max.subMessage',
      yesMessageId = 'permission.modal.buttonMessage'
    ) => {
      dispatch(
        openSubscriptionModal({
          headerMessageId,
          subMessageId,
          yesMessageId,
          onClickYes: () => {
            dispatch(push(URLS.website_billing))
            dispatch(closeSubscriptionModal())
          },
          hideCancelButton: true,
          styles: { width: '450px' },
        })
      )
    },
    [dispatch]
  )

  const handleModelSave = async () => {
    loaderStart()
    lockModalProgress()

    let updatedModel = model
    let result = await bulkCall(
      updateBlockWithPhoto,
      // Что бы при копировании текстов с картинками, картинки тоже подключались
      await Promise.all(
        imagesForUpload.current.map(async (file) =>
          file.file
            ? file
            : {
                ...file,
                file: await fetch(file.src).then((r) => r.blob()),
              }
        )
      ),
      ({ file, key }) => ({
        blockId,
        file: file,
        propertyName: key,
      }),
      3
    )
    unLockModalProgress()

    if (result.filter((el) => get(el, 'data.blockUpdateWithPhoto.success')).length) {
      const pageResult = await refetchPagePreview()

      const blockProperties = get(
        pageResult,
        'data.previewPageGet.data.structure.blocks[0].blockProperties',
        []
      )
      for (let image of imagesForUpload.current) {
        const src = getDomain(
          (blockProperties.find((el) => el.key === `${image.key}MediaUrl`) || {}).value
        )

        updatedModel = updatedModel.replace(image.src, src)
      }
    }

    if (hasHTTP(updatedModel) && !permissions.TEXT_PAGE_LINK) {
      permissionModalDispatcher()
      loaderStop()
      return
    }
    const updateRes = await blockUpdate({
      blockId,
      text: updatedModel,
    })
    setModel(updatedModel)
    imagesForUpload.current = []
    loaderStop()

    if (result.filter((el) => !get(el, 'data.blockUpdateWithPhoto.success')).length) {
      openErrorModal({
        headerMessageId: 'upload.error.header',
        yesMessageId: 'upload.error.ok',
        subMessageId: 'upload.error.description',
      })
    } else if (get(updateRes, 'data.textBlockUpdate.success')) {
      onClose()
    }
  }

  const renderEditor = useCallback(() => {
    const FroalaEditorComponent = require('react-froala-wysiwyg').default
    return (
      <>
        <FroalaEditorComponent
          model={model}
          onModelChange={(text) => setModel(text)}
          pluginsEnabled={[
            'align',
            'link',
            'image',
            'video',
            'imageIndentPlugin',
            'emoticons',
            'table',
            'spell',
            'quote',
          ]}
          config={{
            ...froalaConfig,
            events: {
              'image.beforeUpload': (images) => {
                insertedImage.current = images[0]
              },
              'image.inserted': ($img) => {
                imagesForUpload.current = concat(imagesForUpload.current, [
                  {
                    file: insertedImage.current,
                    src: $img.attr('src'),
                    key: moment().valueOf(),
                  },
                ])
                insertedImage.current = undefined
              },
              'commands.before': (cmd) => {
                if (cmd === 'insertLink' && !permissions.TEXT_PAGE_LINK) {
                  permissionModalDispatcher()
                  const linkButton = document.querySelector("[data-title='Insert Link (Ctrl+K)']")
                  linkButton.classList.remove('fr-btn-active-popup')
                  return false
                }
              },
              'paste.before': (event) => {
                const pastedData = event.clipboardData.getData('Text')
                if (hasHTTP(pastedData) && !permissions.TEXT_PAGE_LINK) {
                  permissionModalDispatcher()
                  return false
                }
              },
            },
          }}
        />
      </>
    )
  }, [model, permissionModalDispatcher, permissions.TEXT_PAGE_LINK])

  const inlineRender = useCallback(() => {
    const FroalaEditorComponent = require('react-froala-wysiwyg').default

    return (
      <FroalaEditorComponent
        model={model}
        onModelChange={(text) => setModel(text)}
        pluginsEnabled={['align', 'link']}
        config={{
          ...froalaInlineConfig,
          events: {
            initialized: () => {
              const css = 'body > .fr-tooltip, .fr-popup {filter: none !important}'
              const head = document.head || document.getElementsByTagName('head')[0]
              const style = document.createElement('style')
              head.appendChild(style)
              style.appendChild(document.createTextNode(css))
            },
          },
        }}
      />
    )
  }, [model])

  return inline ? (
    inlineRender()
  ) : (
    <ModalWrapper
      open={open}
      fullscreen={fullscreen}
      fontFamily={fontFamily}
      fontSize={fontSize}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <CloseIcon onClick={handleClose} />
      <ButtonRow>
        <Button
          content={formatMessage({ id: 'textEditor.button.cancel' })}
          view='primaryGray'
          onClick={handleClose}
        />
        <Button
          content={formatMessage({ id: 'textEditor.button.save' })}
          onClick={handleModelSave}
          disabled={model === text}
        />
      </ButtonRow>
      {open && renderEditor()}
    </ModalWrapper>
  )
}

TextEditor.propTypes = {
  blockId: PropTypes.number,
  pageUrl: PropTypes.string,
  open: PropTypes.bool,
  fullscreen: PropTypes.bool,
  text: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  intl: intlShape,
  onClose: PropTypes.func,
  inline: PropTypes.bool,
  handleChangeText: PropTypes.func,
}

TextEditor.defaultProps = {
  text: '',
  open: false,
  fullscreen: false,
  fontFamily: undefined,
  fontSize: undefined,
  textColor: undefined,
  onClose: () => null,
  inline: false,
  handleChangeText: () => null,
}

export default injectIntl(TextEditor)
