import React from 'react'

// в макете называется size-small
export const SizeSmallIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.33333 5.66667L13 1M13 1H9M13 1V5M5.66667 8.33333L1 13M1 13H5M1 13L1 9'
      stroke='#242426'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

SizeSmallIcon.propTypes = {}

SizeSmallIcon.defaultProps = {}
