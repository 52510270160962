import styled, { css } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { media } from 'packages/components/media'

export const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }

  .Toastify__toast {
    width: 100%;
    max-width: 340px;
    min-height: 55px;
    font-size: 15px;
    text-align: center;
    color: #343840;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 6px;

    ${({ position }) =>
      position === 'bottom-left' &&
      css`
        &:last-child {
          margin-bottom: 35px;
        }
      `}

    &--success {
      border-color: #58c367;
    }

    &--error {
      border-color: red;
    }

    &--info {
      border-color: yellow;
    }

    &--warning {
      border-color: orange;
    }

    ${media.max.phone`
            margin: 1rem auto;
        `}
  }

  .Toastify__toast-body {
  }

  .Toastify__progress-bar {
  }
`
