import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { useQuery, useMutation } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql'
import websiteMetaUpdateMutation from '@graphql/gql/website/websiteMetaUpdate.gql'
import websiteScriptGetQuery from '@graphql/gql/website/websiteScriptGet.gql'
import websiteScriptUpdateMutation from '@graphql/gql/website/websiteScriptUpdate.gql'
import { SCRIPT_TYPE } from 'packages/enum'
import { Toggle, FormInput } from 'packages/components/inputs'
import { Button } from 'packages/components/buttons'
import Loader from '../../loader/Loader'
import CopyToClipboardButton from '../../copyToClipboardButton'
import {
  Description,
  Field,
  Paragraph,
  StyledInput,
  StyledLink,
  SubTitle,
  // ToggleWrapper,
  WrapperButton,
} from '../nodes'
import { Form, ToggleField, CopyToClipboardButtons, StylizedText } from './nodes'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'

const VerificationForm = ({
  intl: { formatMessage },
  data,
  loaderStart,
  loaderStop,
  openErrorModal,
}) => {
  const { loading: scriptLoading, data: { websiteScriptGet } = {} } = useQuery(
    websiteScriptGetQuery,
    {
      variables: {
        type: SCRIPT_TYPE.GOOGLE_VERIFICATION_TAG,
      },
      fetchPolicy: 'network-only',
    }
  )

  const { permissions } = useSubscription()

  const [websiteMetaUpdate] = useMutation(websiteMetaUpdateMutation)

  const [websiteScriptUpdate] = useMutation(websiteScriptUpdateMutation)

  if (scriptLoading) {
    return <Loader />
  }

  if (isEmpty(websiteScriptGet) || !isEmpty(websiteScriptGet.errors)) {
    return <div>Error</div>
  }

  const websiteScriptData = websiteScriptGet.data
  const websiteScriptContent = websiteScriptData.content || ''

  const initValues = {
    siteVerificationMetaTag: websiteScriptContent,
  }
  const validationSchema = Yup.object().shape({
    siteVerificationMetaTag: Yup.string().nullable(),
  })
  const defaultError = 'error.desc'

  const toggleSeoGenerateFiles = async () => {
    try {
      loaderStart()

      const websiteMetaUpdateRes = await websiteMetaUpdate({
        variables: {
          websiteId: data.id,
          seoGenerateFiles: !data.seoGenerateFiles,
        },
        refetchQueries: [
          {
            query: websiteMetaGetQuery,
            variables: {},
          },
        ],
      })

      if (!get(websiteMetaUpdateRes, 'data.websiteMetaUpdate.success')) {
        loaderStop()
        return openErrorModal({
          headerMessageId: 'error.header',
          subMessageId:
            !get(websiteMetaUpdateRes, 'data.websiteMetaUpdate.errors._error') || defaultError,
          yesMessageId: 'OK',
        })
      }

      loaderStop()
    } catch (err) {
      loaderStop()
      return openErrorModal({
        headerMessageId: 'error.header',
        subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
        yesMessageId: 'OK',
      })
    }
  }

  const toggleSeoEnableOpenAiBlock = async () => {
    try {
      loaderStart()

      const websiteMetaUpdateRes = await websiteMetaUpdate({
        variables: {
          websiteId: data.id,
          seoEnableOpenAiBlock: !data.seoEnableOpenAiBlock,
        },
        refetchQueries: [
          {
            query: websiteMetaGetQuery,
            variables: {},
          },
        ],
      })

      if (!get(websiteMetaUpdateRes, 'data.websiteMetaUpdate.success')) {
        loaderStop()
        return openErrorModal({
          headerMessageId: 'error.header',
          subMessageId:
            !get(websiteMetaUpdateRes, 'data.websiteMetaUpdate.errors._error') || defaultError,
          yesMessageId: 'OK',
        })
      }

      loaderStop()
    } catch (err) {
      loaderStop()
      return openErrorModal({
        headerMessageId: 'error.header',
        subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
        yesMessageId: 'OK',
      })
    }
  }

  const handleSubmitForm = async (values, { setSubmitting }) => {
    try {
      loaderStart()

      const websiteScriptUpdateRes = await websiteScriptUpdate({
        variables: {
          websiteId: data.id,
          type: SCRIPT_TYPE.GOOGLE_VERIFICATION_TAG,
          content: values.siteVerificationMetaTag,
          scriptPosition: 'HEADER',
        },
        refetchQueries: [
          {
            query: websiteScriptGetQuery,
            variables: {
              type: SCRIPT_TYPE.GOOGLE_VERIFICATION_TAG,
            },
            fetchPolicy: 'network-only',
          },
        ],
      })

      if (!get(websiteScriptUpdateRes, 'data.websiteScriptUpdate.success')) {
        loaderStop()
        setSubmitting(false)
        return openErrorModal({
          headerMessageId: 'error.header',
          subMessageId:
            !get(websiteScriptUpdateRes, 'data.websiteScriptUpdate.errors._error') || defaultError,
          yesMessageId: 'OK',
        })
      }

      loaderStop()
      setSubmitting(false)
      toast.success(formatMessage({ id: 'seo.toast.success' }), { position: 'top-center' })
    } catch (err) {
      loaderStop()
      setSubmitting(false)
      return openErrorModal({
        headerMessageId: 'error.header',
        subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
        yesMessageId: 'OK',
      })
    }
  }

  const renderForm = ({ values, isSubmitting }) => {
    const submitDisabled =
      isEqual(initValues, {
        siteVerificationMetaTag: values.siteVerificationMetaTag,
      }) || isSubmitting

    const Link = ({ uri }) => (
      <StyledLink>
        <span>https://</span>
        {<StylizedText>{data.premiumDomain || data.domain}</StylizedText>}
        <span>
          {!data.premiumDomain && '.vsble.me'}
          {uri}
        </span>
      </StyledLink>
    )

    return (
      <Form>
        <PermissionsOverlay proMax={true} isAllowed={permissions.GOOGLE_SITE_VERIFICATION}>
          <Field>
            <Paragraph mb='30' type='h1' fz='24px' data-intercom-target='GoogleVerify'>
              {formatMessage({ id: 'seo.googleVerification.title' })}
            </Paragraph>

            <SubTitle>{formatMessage({ id: 'seo.googleVerification.title' })}</SubTitle>

            <Description>{formatMessage({ id: 'seo.googleVerification.desc' })}</Description>

            <StyledInput>
              <FormInput
                label={formatMessage({
                  id: 'seo.googleVerification.input.label',
                })}
                name='siteVerificationMetaTag'
              />
            </StyledInput>
          </Field>

          <WrapperButton>
            <Button
              type='submit'
              view='primary'
              disabled={submitDisabled}
              content={formatMessage({ id: 'seo.save' })}
            />
          </WrapperButton>
        </PermissionsOverlay>

        <PermissionsOverlay proMax={true} isAllowed={permissions.SITEMAP_ROBOTS}>
          {data.seoGenerateFiles ? (
            <ToggleField>
              <Paragraph type='h1' fz='18px' pr='20'>
                {formatMessage({ id: 'seo.enableOpenAiBlock.title' })}
              </Paragraph>

              <Toggle checked={data.seoEnableOpenAiBlock} onChange={toggleSeoEnableOpenAiBlock} />
            </ToggleField>
          ) : null}

          <ToggleField>
            <Paragraph
              type='h1'
              fz='18px'
              pr='20'
              data-intercom-target={'GenerateSitemapAndRobots'}
            >
              {formatMessage({ id: 'seo.generateFiles.title' })}
            </Paragraph>
            <div>
              <Toggle checked={data.seoGenerateFiles} onChange={toggleSeoGenerateFiles} />
            </div>
          </ToggleField>

          <CopyToClipboardButtons>
            <CopyToClipboardButton
              textToCopy={`robots.txt`}
              successMessage={formatMessage({ id: 'seo.toast.success' })}
            >
              <Link uri={'/robots.txt'} />
            </CopyToClipboardButton>

            <CopyToClipboardButton
              textToCopy={`sitemap.xml`}
              successMessage={formatMessage({ id: 'seo.toast.success' })}
            >
              <Link uri={'/sitemap.xml'} />
            </CopyToClipboardButton>
          </CopyToClipboardButtons>
        </PermissionsOverlay>
      </Form>
    )
  }

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={initValues}
      onSubmit={handleSubmitForm}
      render={({ values, isSubmitting }) => renderForm({ values, isSubmitting })}
    />
  )
}

VerificationForm.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.object.isRequired,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  openErrorModal: PropTypes.func.isRequired,
}

VerificationForm.defaultProps = {}

export default injectIntl(VerificationForm)
