import styled, { css } from 'styled-components'
import Image from 'packages/components/image/Image'
import { Button } from 'packages/components/buttons'
import { media } from 'packages/components/media'

export const VideoPlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 2px;
  }
`

export const Container = styled.div`
  width: ${
    (props) =>
      `calc((100% - ${props.columnCount * 2 * 4}px) / ${
        props.columnCount
      })` /*24margin * 4px = 96px*/
  };
  min-width: ${(props) => `calc((100% - ${props.columnCount * 2 * 4}px) / ${props.columnCount})`};
  margin: ${(props) => (props.isDragging ? '0' : '4px')};
  /* transition: all 0.2s cubic-bezier(0.2, 0, 0, 1) 0s; */
  cursor: pointer;
  text-align: center;
  height: 104px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  background-color: #ededed;
  ${(props) =>
    props.isOver &&
    css`
      background-color: rgba(0, 255, 0, 0.1);
    `}
  border-radius: 2px;

  -webkit-box-shadow: 0 30px 50px -40px rgba(0, 0, 0, 1) inset;
  -moz-box-shadow: 0 30px 50px -40px rgba(0, 0, 0, 1) inset;
  box-shadow: 0 30px 50px -40px rgba(0, 0, 0, 1) inset;

  ${(props) =>
    props.cover &&
    `
  border: 2px solid #58C367;
  border-bottom: none;
  `}

  &:hover {
    -webkit-box-shadow: 0 100px 100px 100px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 0 100px 100px 100px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0 100px 100px 100px rgba(0, 0, 0, 0.2) inset;

    picture {
      opacity: 0.8;
    }

    ${VideoPlay} {
      filter: brightness(80%);
    }
  }

  img {
    object-fit: contain !important;
  }
`

export const SettingsButton = styled.span`
  position: relative;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: white;
  border-radius: 8px;

  opacity: 0;

  &:hover {
    > svg {
      > path {
        fill: #242426;
      }
    }
  }
`

export const AlbumItemInner = styled.div`
  transition: opacity 0.2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.isDragging || props.isOver ? 0 : 1)};

  &:hover {
    ${SettingsButton} {
      opacity: 1;
    }
  }
`

export const AlbumCoverText = styled.div`
  background: #58c367;
  color: white;
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  height: 14px !important;
  font-size: 9px;
  line-height: 1.8em;
  letter-spacing: 1px;
  align-self: center;
`

export const ItemInfo = styled.div`
  color: white;
  position: absolute;
  left: 2px;
  top: 2px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const ImageComponent = styled(Image)`
  padding: 4px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-height: 140px;
`

export const DropdownContainer = styled.div`
  position: absolute !important;
  top: 4px !important;
  right: 4px !important;
`

export const ModalContent = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  textarea {
    width: 100% !important;
    height: 135px !important;
  }
`

export const SubmitButton = styled(Button)`
  width: 165px !important;
  height: 45px !important;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #343840;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 20px;

  ${media.max.phone`
        ${SubmitButton} {
            display: none !important;
        }
    `}
`

export const DropZone = styled.div`
  width: ${(props) =>
    props.show ? `calc((100% - ${props.columnCount * 2 * 4}px) / ${props.columnCount})` : '0px'};
  transition: ${(props) => (!props.fast ? 'width 0.2s cubic-bezier(0.2, 0, 0, 1) 0s' : '')};
  opacity: 0.1;
  z-index: 10;
  background: ${(props) => (props.lastItem && !props.isOver ? 'inherit' : 'green')};
  display: ${(props) => (props.isDragging ? 'none' : 'flex')};
  height: 140px;
  //display: flex;
`

export const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileSubmitContainer = styled.div`
  display: none;

  ${media.max.phone`
        display: block;
        margin-top: 10px;
    `}
`

export const ImageLimitBannerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const ImageLimitBanner = styled.div`
  width: 62px;
  min-height: 30px;
  background-color: #0076b6;
  color: #fff;
  border-radius: 7px;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MarkerIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  & > svg {
    width: 14px;
    height: 14px;

    ${(props) =>
      props.size &&
      `
    width: ${props.size}px;
    height: ${props.size}px;
    `}
  }
`

export const ToggleWrapper = styled.span`
  position: absolute;
  top: calc(50% + 3px);
  transform: translateY(-50%);
  right: 14px;

  .toggle.disabled {
    input[disabled] ~ label {
      cursor: pointer !important;
      opacity: 1 !important;
    }
  }
`

export const DropdownSeparator = styled.span`
  width: 100%;
  height: 1px;
  background: #ededed;
  margin: 4px 0 8px;
`

export const WideDropdownItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: ${(props) => (props.isActive ? '#58c367' : '#ededed')};
  color: ${(props) => (props.isActive ? '#ffffff' : '#242426')};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  margin-bottom: ${(props) => (props.isLastButton ? 0 : 4)}px;

  > svg path {
    stroke: ${(props) => (props.isActive ? '#ffffff' : '#242426')};
  }
`

export const WideDropdownText = styled.span`
  margin-right: 8px;
`
