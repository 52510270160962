import React, { useState, useMemo } from 'react'
import { intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { getDomain, getWebpValues } from 'packages/helpers/Helper'
import { useItemsOptimisticRequests } from 'packages/helpers/useItemsOptimisticRequests'
import { MESSAGE_TYPE, UPDATE_APOLLO_TYPES, URLS } from 'packages/enum'
import { ORDER_UPDATE_TIMEOUT, MAX_ERRORS_COUNT } from '@configurator/constants/pages'
import { Semantic } from 'packages/components'
import { Button } from 'packages/components/buttons'
import { ImageIcon, VideoIcon } from 'packages/components/icons/basic'
import { PermissionModal } from 'packages/components/permissionsOverlay/permissionModal'
import UploadVideoModal from '@configurator/containers/UploadVideoContainer'
import UploadPhotosModal from '@configurator/containers/UploadPhotosContainer'
import AlbumItem from './item/AlbumItem'
import CustomDragLayer from './customDragLayer/CustomDragLayer'
import { Container, UploadContainer } from './nodes'
import { LoaderContainer } from './item/nodes'
import { useMediaQuery } from 'packages/helpers/useMediaQuery'
import { sizes } from 'packages/components/media'
import { useSubscription } from '@configurator/providers/subscription'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useRef } from 'react'

function getRowCount(itemCount, columns = 3) {
  while (itemCount % columns !== 0) {
    itemCount++
  }
  return itemCount / columns
}

function move(array, oldIndex, newIndex) {
  if (newIndex >= array.length) {
    newIndex = array.length - 1
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
  return array
}

function moveElement(array, index, offset) {
  const newIndex = index + offset
  return move(array, index, newIndex)
}

const Album = ({
  photos: initialPhotos = [],
  albumId,
  // type,
  onImageReplace,
  onImageDelete,
  onAlbumUpdate,
  moveAlbumItemToAlbum,
  albumItemUpdate,
  altImageRecognition,
  hideUpload,
  hideCover,
  hideText,
  refetchPagePreview,
  refetchWebsitePhotosCount,
  onAlbumOrderUpdate,
  loaderStart,
  loaderStop,
  openInfoModal,
  closeInfoModal,
  forceRefetchPagePreview,
  intl: { formatMessage },
  expandedView,
  onImageResize,
}) => {
  const isTablet = useMediaQuery(`(min-width: ${sizes.phone}px) and (max-width: ${sizes.tablet}px)`)
  const dispatch = useDispatch()
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState()
  const [uploadVideoModalOpen, setUploadVideoModalOpen] = useState()
  const [permissionModalOpen, setPermissionModalOpen] = useState()
  const [offlineAlbumItems, setOfflineAlbumItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [draggingItemId, setDraggingItemId] = useState(null)
  const { permissions } = useSubscription()

  const {
    isRequestLoading,
    isOrderChanged,
    data: photos,
    requestErrors,
    setData: setPhotos,
    setIsOrderChanged,
    setSendData: setSendItemIds,
    setCanSendRequest,
    onOrderUpdate,
    setNextLocation,
    setRefetchAfterOrderUpdate,
  } = useItemsOptimisticRequests({
    items: initialPhotos,
    refetchItems: refetchPagePreview,
    optimisticRequest: ({ itemIds }) => onAlbumOrderUpdate({ photoIds: itemIds, albumId }),
    loaderStart,
    loaderStop,
    openInfoModal,
    closeInfoModal,
    requestTimeout: ORDER_UPDATE_TIMEOUT,
    maxErrorsCount: MAX_ERRORS_COUNT,
    successMessage: formatMessage({ id: 'album.items.info.success' }),
    loaderMessage: formatMessage({ id: 'album.items.loading' }),
    errorMessage: formatMessage({ id: 'album.items.info.error' }),
    optimisticRequestName: 'albumItemsOrderUpdate',
  })

  const isOfflineLimit = offlineAlbumItems.length >= photos.length - 1

  const prevPhotos = useRef(photos)

  const handleUpdateApolloState = ({ dropIndex, dragIndex }) => {
    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.UPDATE_APOLLO_STATE,
        type: UPDATE_APOLLO_TYPES.album,
        dropIndex,
        dragIndex,
      }),
      '*'
    )
  }

  const handleDropAlbumItem = ({ itemId }) => {
    const dragIndex = prevPhotos.current.findIndex((i) => i.id === itemId)
    const dropIndex = photos.findIndex((i) => i.id === itemId)
    if (dragIndex !== -1 && dragIndex === dropIndex) return

    if (forceRefetchPagePreview) {
      setRefetchAfterOrderUpdate(true)
    }

    // setPhotos(photos);
    setIsOrderChanged(true)
    setSendItemIds(photos)
    prevPhotos.current = photos

    if (
      (photos[dragIndex]?.hidden === true && photos[dropIndex]?.hidden === true) ||
      photos[dropIndex]?.hidden === true
    ) {
      return
    }

    let newDragIndex = dragIndex
    let newDropIndex = dropIndex

    for (let i = 0; i <= Math.max(dragIndex, dropIndex); i++) {
      const photo = photos[i]

      if (photo.hidden === true) {
        if (dragIndex < dropIndex) {
          if (i < dragIndex) {
            newDragIndex = newDragIndex - 1
          }

          newDropIndex = newDropIndex - 1
        } else {
          if (i < dropIndex) {
            newDropIndex = newDropIndex - 1
          }

          newDragIndex = newDragIndex - 1
        }
      }
    }

    if (newDragIndex === newDropIndex) {
      return
    }

    if (!forceRefetchPagePreview) {
      handleUpdateApolloState({ dropIndex: newDropIndex, dragIndex: newDragIndex })
    }
  }

  const handleChangeLocation = (location) => {
    if (isOrderChanged || isRequestLoading) {
      setCanSendRequest(false)
      loaderStart({
        content: formatMessage({ id: 'album.items.loading' }),
      })
      if (!isRequestLoading) {
        setSendItemIds(photos)
        onOrderUpdate(photos)
      }
      setNextLocation(location)
      return false
    }
    return true
  }

  const handlePhotosUpload = async () => {
    setIsLoading(true)
    await refetchPagePreview()
    await refetchWebsitePhotosCount()
    setIsLoading(false)
  }

  const columnCount = useMemo(
    () => (expandedView ? 12 : isTablet ? 6 : 3),
    [expandedView, isTablet]
  )

  const rowCount = useMemo(
    () => getRowCount(photos.length, columnCount),
    [columnCount, photos.length]
  )

  const moveItem = (sourceId, destinationId) => {
    const sourceIndex = photos.findIndex((item) => item.id === sourceId)
    const destinationIndex = photos.findIndex((item) => item.id === destinationId)

    if (sourceIndex === -1 || destinationIndex === -1) {
      return
    }

    const offset = destinationIndex - sourceIndex
    const res = moveElement([...photos], sourceIndex, offset)
    setPhotos(res)
  }

  const handleCantDrop = () => {
    if (photos.length > prevPhotos.current.length) {
    } else {
      setPhotos(prevPhotos.current)
    }
  }

  return (
    <>
      <Prompt
        when={isOrderChanged || isRequestLoading || requestErrors.length < MAX_ERRORS_COUNT}
        message={handleChangeLocation}
      />
      {!hideUpload && (
        <UploadContainer expandedView={expandedView}>
          <PermissionsOverlay isAllowed={permissions.UPLOAD_PHOTO}>
            <Button
              fluid
              view='secondaryBlack'
              icon={<ImageIcon />}
              onClick={() => setUploadPhotoModalOpen(true)}
              content={formatMessage({
                id: 'album.label.addPhotos',
              })}
            />
          </PermissionsOverlay>
          <Button
            data-intercom-target='AddVideo'
            fluid
            view='secondaryWhite'
            icon={<VideoIcon color='dark' />}
            onClick={() => setUploadVideoModalOpen(true)}
            content={formatMessage({ id: 'album.label.addVideo' })}
          />
        </UploadContainer>
      )}
      <Container
        // Что бы при днд картинка не дергалась, когда убираем элемент из отображения при перетаскивании
        // 140 высота картинки, 6=4*2 margin картинок
        minHeight={rowCount * 140 + 8 * rowCount}
      >
        <CustomDragLayer draggingItemId={draggingItemId} />
        {photos.length > 0
          ? photos.map((item, index) => {
              const img = getWebpValues({
                properties: item.properties,
                type: 'THUMB',
              })
              const src = item.properties ? img.srcImage : item.lightboxUrl
              const srcSetWebpMap = item.properties ? img.srcSetWebpMap : undefined

              const handleMoveAlbumItemToAlbum = (albumId) =>
                moveAlbumItemToAlbum({
                  itemId: item.id,
                  albumId,
                })

              const handleAlbumItemReplace = (file) => onImageReplace({ id: item.id, file })

              const handleAlbumItemResize = (file) =>
                onImageResize({
                  albumId,
                  file,
                  index: Math.round((item.index + (photos[index - 1]?.index ?? 0)) / 2),
                })

              const handleAlbumItemDelete = () => onImageDelete({ photoId: item.id }, !!item.cover)

              const handleAlbumUpdate = (params) => onAlbumUpdate({ ...params, albumId })

              const handleAlbumItemUpdate = (params) =>
                albumItemUpdate({
                  ...params,
                  albumItemId: item.id,
                })

              const handleRemoveOfflineAlbumItem = () => {
                setOfflineAlbumItems((prev) => prev.filter((id) => id !== item.id))
                handleAlbumItemUpdate({ hidden: false })
              }

              const handleAddOfflineAlbumItem = () => {
                setOfflineAlbumItems((prev) => [...prev, item.id])
                if (item.cover) {
                  handleReplaceCover()
                }
                handleAlbumItemUpdate({ hidden: true })
              }

              const handleReplaceCover = () => {
                let nextCoverItemId
                for (const photo of photos) {
                  if (!offlineAlbumItems.includes(photo.id) && photo.id !== item.id) {
                    nextCoverItemId = photo.id
                    break
                  }
                }
                handleAlbumUpdate({
                  mainPhotoId: nextCoverItemId,
                })
              }

              const isOffline = offlineAlbumItems.includes(item.id) || (item.hidden && !item.cover)

              const handleOfflineAlbumItem = () => {
                if (isOffline) {
                  handleRemoveOfflineAlbumItem(item.id)
                } else {
                  handleAddOfflineAlbumItem(item.id)
                }
              }

              return (
                <AlbumItem
                  key={`albumItem_${item.id}`}
                  setDraggingItemId={setDraggingItemId}
                  draggingItemId={draggingItemId}
                  handleCantDrop={handleCantDrop}
                  onMoveItem={moveItem}
                  hideCover={hideCover}
                  hideText={hideText}
                  photoId={item.photoId}
                  offline={isOffline}
                  cover={item.cover}
                  id={item.id}
                  // photo or video
                  itemType={item.itemType}
                  hidden={item.hidden}
                  description={item.description}
                  disabledByLimit={item.disabledByLimit}
                  isOfflineLimit={isOfflineLimit}
                  // image size
                  aspectRatio={item.lightboxWidth / item.lightboxHeight}
                  imgWidth={item.lightboxWidth}
                  imgHeight={item.lightboxHeight}
                  originalFileSize={item.originalFileSize}
                  originalFileName={item.originalFileName}
                  src={getDomain(src)}
                  srcSetWebpMap={srcSetWebpMap}
                  index={index}
                  lastItem={index === photos.length - 1}
                  xFocalPoint={item.xfocalPoint}
                  yFocalPoint={item.yfocalPoint}
                  onOfflineAlbumItem={handleOfflineAlbumItem}
                  onItemMoveToAlbum={handleMoveAlbumItemToAlbum}
                  onImageReplace={handleAlbumItemReplace}
                  onImageResize={handleAlbumItemResize}
                  onImageDelete={handleAlbumItemDelete}
                  onAlbumUpdate={handleAlbumUpdate}
                  onAlbumItemUpdate={handleAlbumItemUpdate}
                  altImageRecognition={altImageRecognition}
                  onDropImage={handleDropAlbumItem}
                  loaderStart={loaderStart}
                  loaderStop={loaderStop}
                  expandedView={expandedView}
                  setPhotos={setPhotos}
                  columnCount={columnCount}
                  altTextDescriptive={item.altTextDescriptive}
                  altText={item.altText}
                  videoId={item.videoId}
                  albumId={item.albumId}
                  hosting={item.hosting}
                  customPreview={item.customPreview}
                  videoRatioX={item.videoRatioX}
                  videoRatioY={item.videoRatioY}
                />
              )
            })
          : null}
      </Container>
      {isLoading && (
        <LoaderContainer>
          <Semantic.Loader active size='large' />
        </LoaderContainer>
      )}
      {permissionModalOpen && (
        <PermissionModal
          headerMessageId='permission.modal.pro.header'
          subMessageId='permission.modal.pro.subMessage'
          onClose={() => setPermissionModalOpen(false)}
          onButtonClick={() => {
            setPermissionModalOpen(false)
            dispatch(push(URLS.website_billing))
          }}
          open
        />
      )}
      {uploadPhotoModalOpen && (
        <UploadPhotosModal
          albumId={albumId}
          onPhotosUpload={handlePhotosUpload}
          photos={photos}
          onClose={() => setUploadPhotoModalOpen(false)}
          openPermissionModal={() => setPermissionModalOpen(true)}
          open
        />
      )}
      {uploadVideoModalOpen && (
        <UploadVideoModal
          albumId={albumId}
          initialValues={{
            customPreview: false,
            videoRatioX: 16,
            videoRatioY: 9,
          }}
          open
          onClose={() => setUploadVideoModalOpen(false)}
          refetchPagePreview={refetchPagePreview}
        />
      )}
    </>
  )
}

Album.propTypes = {
  photos: PropTypes.array.isRequired,
  albumId: PropTypes.number.isRequired,
  onImageReplace: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onAlbumUpdate: PropTypes.func.isRequired,
  moveImageToWebsite: PropTypes.func.isRequired,
  moveAlbumItemToAlbum: PropTypes.func.isRequired,
  albumItemUpdate: PropTypes.func.isRequired,
  altImageRecognition: PropTypes.func.isRequired,
  onAlbumOrderUpdate: PropTypes.func.isRequired,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  closeInfoModal: PropTypes.func.isRequired,
  onImageResize: PropTypes.func.isRequired,
  hideUpload: PropTypes.bool,
  hideCover: PropTypes.bool,
  hideText: PropTypes.bool,
  refetchPagePreview: PropTypes.func,
  refetchWebsitePhotosCount: PropTypes.func,
  forceRefetchPagePreview: PropTypes.bool,
  intl: intlShape.isRequired,
  expandedView: PropTypes.bool,
}

Album.defaultProps = {
  hideUpload: false,
  hideCover: false,
  hideText: false,
  forceRefetchPagePreview: false,
  refetchPagePreview: () => null,
  refetchWebsitePhotosCount: () => null,
  expandedView: false,
}

export default Album
