import { compose } from 'redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { connect } from 'react-redux'
import isArray from 'lodash/isArray'

import mostViewedPageGet from '@graphql/gql/website/mostViewedPageGet.gql'

import Page from '@configurator/components/statistics/mostViewed/Page'

export const pageWrapper = (Component) =>
  compose(
    connect((state) => ({ matomoOptions: state.get('periodPicker').toJS() })),
    graphql(mostViewedPageGet, {
      skip: ({ idSubtable }) => !idSubtable,
      options: ({ matomoOptions, idSubtable }) => ({
        variables: { ...matomoOptions, idSubtable },
        ssr: false,
      }),
      props({ data: { loading, mostViewedPageGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(mostViewedPageGet)) {
          return { loading }
        }
        const { data = [] } = mostViewedPageGet
        return isArray(data)
          ? {
              item: get(
                data.reduce(
                  (prev, current) => (prev.nb_visits > current.nb_visits ? prev : current),
                  { label: '', nb_visits: 0 }
                ),
                'label'
              ),
              list: data.map((i) => ({ name: i.label, value: i.nb_visits })),
            }
          : {}
      },
    })
  )(Component)

export default pageWrapper(Page)
