import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;

  > img {
    position: fixed;
    object-fit: contain;
    width: 80%;
    max-width: calc(100% - 344px);
  }

  > div {
    width: 360px;
    height: 370px;
    background: #fff;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #565656;
    font-size: 15px;

    > span {
      text-align: center;
      padding: 0 3rem;

      :first-child {
        font-size: 22px;
        padding: 0 40px;
        font-weight: bold;
        text-align: center;
      }

      :nth-child(2) {
        font-weight: bold;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      > span {
        text-align: center;
        padding: 0 3rem;
      }
    }

    .ui.button {
      font-size: 20px !important;
      font-weight: bold !important;
      padding: 20px 60px !important;
      border-radius: 3px !important;
    }
  }
`
