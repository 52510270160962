import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { HOSTING_URLS, MESSAGE_TYPE, VIDEO_HOSTING } from 'packages/enum'
import { Modal } from 'packages/components/modal'
import { Form, Formik } from 'formik'
import { Container } from './nodes'
import { openErrorModal } from '@configurator/redux/modules/subscription/actions'
import { useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import websiteVideoUpdate from '@graphql/gql/website/websiteVideoUpdate.gql'
import { useMutation } from 'react-apollo'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import { useParams } from 'react-router'
import { start as startLoader, stop as stopLoader } from 'packages/redux/modules/pageLoader/actions'
import { EditVideoForm } from '@configurator/components/upload/uploadVideo/EditVideoForm'

const UpdateVideoModal = ({
  onClose,
  open,
  hosting = '',
  videoId = '',
  videoUrl = '',
  srcSetWebpMap = '',
  customPreview = false,
  id,
  albumId,
  videoRatioX,
  videoRatioY,
}) => {
  const initialValues = {
    hosting,
    videoId,
    videoUrl,
    customPreview,
    videoRatioX,
    videoRatioY,
  }

  const dispatch = useDispatch()
  const params = useParams()
  const pageId = decodeURIComponent(params.pageId)

  const loaderStart = () => dispatch(startLoader())
  const loaderStop = () => dispatch(stopLoader())

  const [updateVideo] = useMutation(websiteVideoUpdate, {
    refetchQueries: [{ query: previewPageGet, variables: { id: pageId } }],
  })

  const handleSubmit = async (values, actions) => {
    try {
      loaderStart()

      if (values.customPreview && !values.file) {
        values.customPreview = undefined
      }

      const {
        data: {
          websiteVideoUpdate: { errors, success },
        },
      } = await updateVideo({
        variables: { albumId, id, ...values },
      })
      if (!success) {
        actions.setStatus({ error: errors.error })
      } else {
        window.frames['preview-frame']?.postMessage(
          JSON.stringify({
            name: MESSAGE_TYPE.UPDATE_META,
          }),
          '*'
        )
        onClose()
      }
    } finally {
      loaderStop()
    }
  }
  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          render={(props) => {
            return (
              <Form>
                <UpdateVideoForm
                  values={props.values}
                  initialValues={props.initialValues}
                  setFieldValue={props.setFieldValue}
                  setValues={props.setValues}
                  isSubmitting={props.isSubmitting}
                  status={props.status}
                  setStatus={props.setStatus}
                  srcSetWebpMap={srcSetWebpMap}
                />
              </Form>
            )
          }}
        ></Formik>
      </Container>
    </Modal>
  )
}

UpdateVideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

UpdateVideoModal.defaultProps = {
  open: false,
  refetchPagePreview: () => null,
  openErrorModal: () => null,
}

UpdateVideoModal.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default UpdateVideoModal

const UpdateVideoForm = injectIntl(
  ({
    values,
    errors,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    status,
    setStatus,
    srcSetWebpMap,
  }) => {
    useEffect(() => {
      setValues(initialValues)
      if (initialValues.hosting && initialValues.videoId) {
        const videoUrl =
          initialValues.hosting === VIDEO_HOSTING.YOUTUBE
            ? HOSTING_URLS.YOUTUBE + initialValues.videoId
            : HOSTING_URLS.VIMEO + initialValues.videoId
        setFieldValue('videoUrl', videoUrl)
      }
    }, [initialValues, setValues, setFieldValue])

    return (
      <EditVideoForm
        formAs='div'
        errors={errors}
        status={status}
        isSubmitting={isSubmitting}
        values={values}
        setValues={setValues}
        setStatus={setStatus}
        setFieldValue={setFieldValue}
        openErrorModal={openErrorModal}
        srcSetWebpMap={srcSetWebpMap}
      />
    )
  }
)
