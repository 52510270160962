import styled from 'styled-components'

const BACKGROUND_COLOR = '#EDEDED'
const BACKGROUND_HOVER_COLOR = '#E1E2E6'
const STRUCTURE_LINES_COLOR = '#666A73'

export const Container = styled.div`
  background: ${BACKGROUND_COLOR};
`

export const Button = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background: ${BACKGROUND_HOVER_COLOR};
  }

  > svg {
    margin-right: 13px;
  }
`

export const StructureBlock = styled.div`
  position: relative;
  width: 82px;
  height: inherit;

  ::before {
    content: '';
    display: block;
    position: absolute;
    right: 30px;
    top: -8px;
    height: ${(props) => (props.empty ? 'calc(21px + 8px)' : 'calc(100% + 8px)')};
    border-left: 1px solid ${STRUCTURE_LINES_COLOR};
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    border-top: 1px solid ${STRUCTURE_LINES_COLOR};
  }
`
