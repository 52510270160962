import React, { useEffect, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Link } from 'react-router-dom'
import isBrowser from 'packages/utils/isBrowser'
import { viewTypes } from 'packages/components/const'
import {
  getDomain,
  getFeatureProperty,
  getLoaderShortProperty,
  getPropertyMediaUrl,
} from 'packages/helpers/Helper'
import { useIsMobile, useWindowSize } from 'packages/helpers/useWindowSize'
import {
  FEATURE_PROPERTY_KEYS,
  MESSAGE_TYPE,
  URLS,
  STYLE_FEATURE_PROPERTIES,
  // ABO_STATUS,
  // PLAN_TYPE,
  // ABO_STATUS,
} from 'packages/enum'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import EditTextModal from '@configurator/containers/TextEditorContainer'
import { LockIcon } from '../../icons/basic'
// import { useSubscription } from '@configurator/providers/subscription'
import {
  Wrapper,
  Header,
  Banner,
  BannerIconContainer,
  BannerContent,
  Circles,
  Domain,
  Content,
  Loader,
} from './nodes'
import circlesIcon from '../img/circles.svg'
import logoIcon from '../img/logo_red.svg'
import { loaderDefaultProps, loaderVariants } from 'packages/components/customLoader/const'
import { CustomLoader } from 'packages/components/customLoader'
// import { Button } from 'packages/components/buttons'
import { useDispatch } from 'react-redux'
import { createAction } from 'redux-actions'
import { SET_LOGO_MARGINS } from '@configurator/constants/Preview'

const Preview = ({
  view,
  meta,
  structure,
  pageUrl,
  faviconHashPath,
  history,
  loading,
  intl: { formatMessage },
}) => {
  const dispatch = useDispatch()
  const size = useWindowSize()
  const [editTextModalOpen, setEditTextModalOpen] = useState(false)
  const [previewFavicon, setPreviewFavicon] = useState()
  const [iframeLoading, setIframeLoading] = useState()
  const [iframeHeight, setIframeHeight] = useState(undefined)
  const [isBannerActive, setIsBannerActive] = useState(true)
  const [loaderProps, setLoaderProps] = useState()
  const isMobile = useIsMobile()

  // const {
  //   info: { aboStatus },
  // } = useSubscription()

  const favicon = useMemo(() => {
    if (previewFavicon) {
      return previewFavicon
    }
    return faviconHashPath ? getDomain(faviconHashPath) : logoIcon
  }, [faviconHashPath, previewFavicon])

  useEffect(() => {
    setIframeLoading(true)
    setIsBannerActive(true)
  }, [pageUrl])

  useEffect(() => {
    if (meta) {
      const fontId = getFeatureProperty(
        meta.features,
        FEATURE_PROPERTY_KEYS.style,
        STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID
      )
      const loaderVariant = getFeatureProperty(
        meta.features,
        FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_VARIANT,
        getLoaderShortProperty(FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_VARIANT)
      )
      const loaderColor = getFeatureProperty(
        meta.features,
        FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_COLOR,
        getLoaderShortProperty(FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_COLOR)
      )
      const customLoaderUrl = getFeatureProperty(
        meta.features,
        FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_CUSTOM,
        getPropertyMediaUrl(getLoaderShortProperty(FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_CUSTOM))
      )
      setLoaderProps({
        variant: !loaderVariant ? loaderVariants.default : loaderVariant,
        color: !loaderColor ? loaderDefaultProps.color : loaderColor,
        width: loaderDefaultProps.width,
        height: loaderDefaultProps.height,
        customLoaderUrl: customLoaderUrl && getDomain(customLoaderUrl),
      })
      if (fontId) {
        WebFont.load({
          google: {
            families: [
              getFeatureProperty(
                meta.features,
                FEATURE_PROPERTY_KEYS.style,
                STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID
              ),
            ],
          },
        })
      }
    }
  }, [meta])

  useEffect(() => {
    const handler = (event) => {
      try {
        let data = JSON.parse(event.data)
        if (data.name === MESSAGE_TYPE.CHANGE_ROUTE) {
          // setIframeLoading(true);
          if (data.value === '/') {
            history.push('/website/config/')
          } else if (location.pathname.indexOf(URLS.website_config_style) !== -1) {
            history.push(`${URLS.website_config_style}/${encodeURIComponent(data.value || '')}`)
          } else if ((data.value || '').split('/').length > 2) {
            history.push(`${URLS.website_config}/blocks/${encodeURIComponent(data.value || '')}`)
          } else {
            history.push(`${URLS.website_config}/page${data.value || '/'}`)
          }
        } else if (data.name === MESSAGE_TYPE.OPEN_EDIT_PAGE) {
          setEditTextModalOpen(true)
        } else if (data.name === MESSAGE_TYPE.CURRENT_TEMPLATE_MARGINS) {
          dispatch(createAction(SET_LOGO_MARGINS)(data.value))
        } else if (data.name === MESSAGE_TYPE.SET_FAVICON_PREVIEW) {
          setPreviewFavicon(data.faviconPreview)
        }
      } catch (e) {
        // do nothing
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, [dispatch, history])

  useEffect(() => {
    const blocks = pageUrl.split('/').filter((item) => !!item)
    // if location contains "/" - it is details view.
    const isDetailView = blocks.length > 1

    // предотвратит window.frames["preview-frame"]?.postMessage на незагруженный iframe
    const sendOnLoad = (e) => {
      if (e.data?.loaded) {
        if (isDetailView) {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.CHECK_PATH,
              path: pageUrl,
            }),
            '*'
          )
        }
      }
    }

    window.addEventListener('message', sendOnLoad)

    return () => window.removeEventListener('message', sendOnLoad)
  }, [pageUrl])

  const pageUrlPreview = `${pageUrl}?preview=true`

  // минимальная ширина правой части - 800, левая часть + отступы - 420
  const minWidth = 800 + 420
  const transform =
    (Math.max(minWidth, size.clientWidth) - 420) / (view === viewTypes.desktop ? 1440 : 1920)

  const wrapperRef = useRef()

  useEffect(() => {
    const callResize = (() => {
      let refHeight = 0
      return () => {
        if (refHeight !== get(wrapperRef, 'current.clientHeight', 0)) {
          refHeight = get(wrapperRef, 'current.clientHeight', 0)
          // берем высоту врапера, вычитаем высоту строки поиска и пропорционально расширяем
          setIframeHeight((refHeight - 48) * (1 / transform))
        }
      }
    })()
    let intervalId = setInterval(callResize, 16)
    return () => {
      return intervalId && clearInterval(intervalId)
    }
  }, [transform])

  if (!isBrowser || !meta) {
    return null
  }

  const isLoading = loading || iframeLoading

  const handleBannerClose = () => setIsBannerActive(false)

  const renderBanner = () => {
    if (!isBannerActive || isLoading) {
      return null
    }

    if (structure.startPage && meta.slideshowEnabled) {
      return null
    }

    if (!meta.visible) {
      return (
        <Banner view='error' onClose={handleBannerClose}>
          <BannerContent>
            <span
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'preview.banner.websiteOffline.title',
                }),
              }}
            />
          </BannerContent>
        </Banner>
      )
    }
    if (!isNil(structure.visible) && !structure.visible) {
      return (
        <Banner view='error' onClose={handleBannerClose}>
          <BannerContent>
            <span
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'preview.banner.pageOffline.title',
                }),
              }}
            />
          </BannerContent>
        </Banner>
      )
    }
    if (structure.hasPassword) {
      return (
        <Banner view='error' onClose={handleBannerClose}>
          <BannerContent style={{ justifyContent: 'space-between' }}>
            <div>
              <div>
                <span>{formatMessage({ id: 'preview.banner.pageProtected.title' })}</span>
              </div>
              <div>
                <BannerIconContainer>
                  <LockIcon color='gray' />
                </BannerIconContainer>
                <span>{formatMessage({ id: 'preview.banner.pageProtected.iconDesc' })}</span>
              </div>
            </div>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: 'preview.banner.pageProtected.subTitle',
                  }),
                }}
              />
            </div>
          </BannerContent>
        </Banner>
      )
    }
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Header>
        <Circles>
          <img src={circlesIcon} alt='' />
        </Circles>
        <Domain>
          {meta.domain && (
            <>
              <img src={favicon} alt='' />
              <div>
                https://
                <b>{meta.premiumDomain || `${meta.domain}.vsble.me`}</b>
                {pageUrl || ''}
              </div>
              {!meta.premiumDomain && (
                <Link to={`${URLS.website_settings}?tab=domain`}>Connect your domain</Link>
              )}
            </>
          )}
        </Domain>
      </Header>
      {isLoading && (
        <Loader>
          <CustomLoader {...loaderProps} />
        </Loader>
      )}
      {renderBanner()}
      <Content
        clientWidth={size.clientWidth}
        view={view}
        width={
          transform < 1 && view === viewTypes.desktop
            ? '1440px'
            : transform < 1 && view === viewTypes.desktopLarge
            ? '1920px'
            : undefined
        }
        height={
          transform < 1 && (view === viewTypes.desktop || view === viewTypes.desktopLarge)
            ? '929px'
            : 'calc( 100% - 48px )'
        }
      >
        {meta.domain && (
          <iframe
            style={
              transform < 1 &&
              (view === viewTypes.desktop || view === viewTypes.desktopLarge) &&
              !isMobile
                ? {
                    transform: `scale(${transform})`,
                    position: 'absolute',
                    top: `${(iframeHeight * (transform - 1)) / 2}px`,
                    left: `${(transform - 1) * 50}%`,
                    height: `${iframeHeight}px`,
                  }
                : {}
            }
            title='preview-frame'
            name='preview-frame'
            frameBorder='0'
            allowFullScreen=''
            src={
              __PRODUCTION__
                ? `https://vsble-edit-${meta.domain}.${__DOMAIN__.replace(
                    'www.',
                    ''
                  )}${pageUrlPreview}`
                : `${__PORTFOLIO_URL__}${pageUrlPreview}`
            }
            onLoad={() => setIframeLoading(false)}
          />
        )}
      </Content>
      {editTextModalOpen && (
        <EditTextModal
          open
          fullscreen
          onClose={() => setEditTextModalOpen(false)}
          blockId={get(structure, 'blocks[0].id')}
          text={get(structure, 'blocks[0].text')}
          pageUrl={get(structure, 'blocks[0].page.url')}
          fontFamily={getFeatureProperty(
            meta.features,
            FEATURE_PROPERTY_KEYS.style,
            STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME
          )}
          textColor={getFeatureProperty(
            meta.features,
            FEATURE_PROPERTY_KEYS.style,
            STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR
          )}
          fontSize={getFeatureProperty(
            meta.features,
            FEATURE_PROPERTY_KEYS.style,
            STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE
          )}
          fontWeight={getFeatureProperty(
            meta.features,
            FEATURE_PROPERTY_KEYS.style,
            STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_WEIGHT
          )}
          backgroundColor={getFeatureProperty(
            meta.features,
            FEATURE_PROPERTY_KEYS.style,
            STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR
          )}
        />
      )}
    </Wrapper>
  )
}

Preview.propTypes = {
  intl: intlShape.isRequired,
  view: PropTypes.oneOf(Object.values(viewTypes)),
  meta: PropTypes.object,
  structure: PropTypes.object,
  pageUrl: PropTypes.string,
  loading: PropTypes.bool,
  faviconHashPath: PropTypes.string,
  history: PropTypes.object.isRequired,
}

Preview.defaultProps = {
  view: 'desktop',
  structure: {},
  pageUrl: '',
  faviconHashPath: '',
}

export default injectIntl(Preview)
