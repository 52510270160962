import { Toggle } from 'packages/components/inputs'
import PropTypes from 'prop-types'
import React from 'react'
import { intlShape, injectIntl } from 'react-intl'

import {
  PrivacyContainer,
  PrivacyColumn,
  ItemContent,
  Toggler,
  ItemDescription,
  Box,
  Paragraph,
  WrapperBox,
} from '../nodes'

const PrivacySection = ({
  onChange: { toggleCookieBar, toggleBlockFonts },
  values: { cookies, fonts },
  intl: { formatMessage },
  isMobile,
}) => {
  return (
    <WrapperBox isMobile={isMobile}>
      <PrivacyContainer>
        <PrivacyColumn>
          <Box isMobile={isMobile}>
            <Paragraph
              type='h2'
              mb={17}
              fw={700}
              color='#242426'
              data-intercom-target={'CookieBar'}
            >
              {formatMessage({ id: 'website.settings.privacy.text1' })}
            </Paragraph>
            <ItemContent>
              <ItemDescription>
                {formatMessage({ id: 'website.settings.privacy.text2' })}
              </ItemDescription>
              <Toggler isActive={cookies}>
                <span>
                  {formatMessage({
                    id: `website.settings.privacy.cookies.${cookies ? 'enabled' : 'disabled'}`,
                  })}
                </span>
                <Toggle checked={cookies} onChange={toggleCookieBar} />
              </Toggler>
            </ItemContent>
          </Box>
          <Box isMobile={isMobile}>
            <Paragraph
              type='h2'
              mb={17}
              fw={700}
              color='#242426'
              data-intercom-target={'BlockFonts'}
            >
              {formatMessage({ id: 'website.settings.privacy.text3' })}
            </Paragraph>
            <ItemContent>
              <ItemDescription>
                {formatMessage({ id: 'website.settings.privacy.text4' })}
              </ItemDescription>
              <Toggler isActive={fonts}>
                <span>
                  {formatMessage({
                    id: `website.settings.privacy.fonts.${fonts ? 'enabled' : 'disabled'}`,
                  })}
                </span>
                <Toggle checked={fonts} onChange={toggleBlockFonts} />
              </Toggler>
            </ItemContent>
          </Box>
        </PrivacyColumn>
      </PrivacyContainer>
    </WrapperBox>
  )
}

export default injectIntl(PrivacySection)

PrivacySection.propTypes = {
  intl: intlShape.isRequired,

  values: PropTypes.shape({
    cookies: PropTypes.bool.isRequired,
    fonts: PropTypes.bool.isRequired,
  }).isRequired,

  onChange: PropTypes.shape({
    toggleCookieBar: PropTypes.func.isRequired,
    toggleBlockFonts: PropTypes.func.isRequired,
  }).isRequired,

  isMobile: PropTypes.bool,
}
