import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { renderRoutes } from 'react-router-config'
import { Route } from 'react-router'

import { URLS } from 'packages/enum'
import { Wrapper, SingInRow, LogoRow } from '@landing/components/common/nodes'
import { Button } from 'packages/components/buttons'

import mainLogoDark from 'packages/components/header/img/logo-dark.svg'

const Layout = ({ route, intl: { formatMessage } }) => {
  return (
    <Wrapper>
      <LogoRow href='https://www.vsble.me/'>
        <img src={mainLogoDark} alt='Logo Vsble' />
      </LogoRow>
      {renderRoutes(route.routes)}
      <Route
        exact
        path={[URLS.website_create_account, URLS.website_create_free_account]}
        children={({ match }) => (
          <SingInRow>
            <span>
              {formatMessage({
                id: match ? 'landing.singIn.label' : 'landing.singUp.label',
              })}
            </span>
            <Link to={match ? URLS.website_login : URLS.website_create_account}>
              <Button
                fluid
                type='button'
                content={formatMessage({
                  id: match ? 'landing.singIn.button.label' : 'landing.singUp.button.label',
                })}
                view='secondaryBlack'
              />
            </Link>
          </SingInRow>
        )}
      />
    </Wrapper>
  )
}

Layout.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(Layout)
