import styled from 'styled-components'
import { Modal } from 'packages/components/modal'
import { Button } from 'packages/components/buttons'
import { Toggle } from 'packages/components/inputs'
import { media } from 'packages/components/media'

export const ModalContainer = styled(Modal)`
  position: initial !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-align: left;
  color: #242426;

  @media (max-width: 480px) {
    min-width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
`

export const Header = styled.h3`
  margin: 0;
  font-size: 36px;
  margin-bottom: 32px;
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -40px;
  flex-direction: column;
  padding: 0px 40px 40px 40px;
  gap: 25px;

  @media (max-width: 1024px) {
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 0;
    }
  }
`

export const StyledToggle = styled(Toggle)`
  margin: 0 16px;

  & > label {
    margin-top: 4px;

    &::before {
      background-color: #58c367 !important;
    }
  }
`

export const ToggleContainer = styled.div`
  font-size: 15px;
  margin-top: -6px;
  white-space: nowrap;
`

export const Step = styled.div`
  flex: 1;
  height: 100%;
`

export const TextGray = styled.div`
  margin-top: 14px;
  color: #9a9a9a;
  text-align: center;
`

export const StepTitle = styled.h5`
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 20px;

  ${media.max.phone`
        flex-direction: column;

        ${ToggleContainer} {
            margin-top: 10px;
        }
    `}
`

export const StepContent = styled.div`
  height: 100%;
  margin-top: 16px;

  ${media.max.phone`
        height: auto;
    `}
`

export const PlanSelectors = styled.div`
  margin: 14px 0 0 -4px;
  display: flex;
  gap: 8px;
  & > * {
    margin: 0px 0 0 4px;
  }

  ${media.max.phone`
        flex-direction: column;
    `}
`

export const PlanSelector = styled.div`
  display: ${({ isLight }) => (isLight ? `none` : `flex`)};
  height: 82px;
  padding: 12px 12px 12px 16px;
  font-size: 16px;
  background-color: #f6f6f6;
  border-radius: 6px;
  cursor: pointer;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  border: 3px solid ${({ selected }) => (selected ? '#58c367' : 'transparent')};

  ${media.max.phone`
        height: 100%;
        flex-direction: row;
        align-items: center;
        gap: 14px;
    `}
`

export const IntervalSelectors = styled.div`
  gap: 8px;
  display: flex;
  width: 100%;
  margin-top: 16px;

  ${media.max.phone`
        flex-direction: column;
    `}
`

export const IntervalSelector = styled.div`
  display: flex;
  height: 80px;
  padding: 12px 12px 12px 16px;
  font-size: 16px;
  background-color: #f6f6f6;
  border-radius: 6px;
  cursor: pointer;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  flex: 1;
  border: 3px solid ${({ selected }) => (selected ? '#58c367' : 'transparent')};
  ${({ disabled }) => disabled && `color: #9fa1a6; pointer-events: none;`};

  b {
    font-size: 16px;
  }
`

export const IntervalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const IntervalRenewsInfo = styled.p`
  font-size: 12px;
`

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ui.radio {
    width: 24px !important;
    height: 24px !important;
    label {
      position: relative;
      &::before {
        width: 24px !important;
        height: 24px !important;
      }
      &:after {
        display: none;
      }
    }
  }
`

export const SelectorLabel = styled.label`
  font-weight: bold;
  cursor: pointer;
  flex: 100%;
`

export const RadioWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:after {
    background-color: #58c367;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    max-height: 24px;
    max-width: 24px;
    border-radius: 50%;
    content: '';
    z-index: 1;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transform: scale(${({ selected }) => (selected ? 1 : 0)});
    transition: transform 0.2s ease-in-out;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px !important;
    height: 12px !important;
    z-index: 2;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
  }
`

export const PaymentContainer = styled.div`
  display: flex;
  font-size: 16px;

  & b,
  & span {
    white-space: nowrap;
  }

  ${media.max.phone`
        flex-direction: column;
    `}
`

export const TotalContainer = styled.div`
  display: flex;
  margin-top: 20px;

  & > * {
    width: 45%;
  }

  ${media.max.phone`
        & > *:first-child {
            width: 65%;
        }
    `}
`

export const BottomPlanInfoContainer = styled.div`
  display: flex;
  margin-top: 8px;

  & > * {
    width: 45%;
  }

  @media (max-width: 480px) {
    & > *:first-child {
      display: none;
    }

    & > * {
      width: 100%;
    }
  }
`

export const BottomPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;

  & > * {
    margin-top: 8px;
    color: #666a73;
  }
`

export const StyledButton = styled(Button)`
  height: 45px;
  ${({ fullWidth }) => fullWidth && `width: 100%`}
`

export const ButtonsContainer = styled.div`
  margin-top: 30px;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  & > p {
    width: 45%;
    margin: 0;
    padding-right: 20px;
  }

  & > ${StyledButton} {
    width: 55%;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    & > p {
      padding: 0 0 10px 0;
    }

    & > p,
    & > ${StyledButton} {
      width: 100%;
    }
  }
`

export const DowngradeContainer = styled.div`
  margin: 30px 0;

  > span {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    > span {
      font-size: 13px;
      line-height: 170%;
    }
  }

  .ui.button {
    width: 100%;
    height: 45px;
    margin-top: 30px !important;
  }
`

export const SplitDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;

  > span {
    color: #666a73;
    font-size: 13px;
    line-height: 140%;
    margin: 0 10px;
  }

  > div {
    flex: 1;
    border-top: 1px solid #e1e2e6;
  }
`

export const SavedForYear = styled.span`
  font-weight: bold;
  color: #fff;
  background-color: #00c2ff;
  font-size: 10px;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
`
