import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const HornIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8539_78004)'>
      <path
        d='M6.32812 13.4062H2.10938C1.3327 13.4062 0.703125 12.7767 0.703125 12V8.25C0.703125 7.47333 1.3327 6.84375 2.10938 6.84375H6.32812M6.32812 13.4062V6.84375M6.32812 13.4062L15.2344 18.0938V2.15625L6.32812 6.84375M6.32812 13.4062H3.04688L1.55672 20.857C1.35764 21.8525 2.11903 22.7812 3.13425 22.7812C3.90112 22.7812 4.56136 22.24 4.71178 21.488L6.32812 13.4062ZM15.2344 19.0312V1.21875M20.9531 10.125H23.2969M20.4844 6.375L22.5141 5.20312M20.4844 13.875L22.5141 15.0469M17.2119 13.0254L15.2344 14.3438V5.90625L17.2119 7.22461C17.4688 7.39583 17.6794 7.62782 17.825 7.89997C17.9707 8.17212 18.0469 8.47603 18.0469 8.7847V11.4652C18.0469 11.7739 17.9707 12.0778 17.825 12.35C17.6794 12.6222 17.4688 12.8542 17.2119 13.0254Z'
        stroke={iconColors[color]}
        strokeWidth='1.40625'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8539_78004'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

HornIcon.propTypes = {
  ...iconColorPropTypes,
}

HornIcon.defaultProps = {
  color: 'white',
}
