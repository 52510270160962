import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Elements, StripeProvider } from 'react-stripe-elements'

import { HeaderStyled } from 'packages/components/layout/nodes'
import { Accordion } from 'packages/components/Accordion'
import { Tabs } from 'packages/components/shared'
import { useIsMobile } from 'packages/helpers/useWindowSize'
import LoginInfoContainer from '@configurator/containers/billing/LoginInfoContainer'
import DeleteAccount from '@configurator/containers/billing/DeleteAccountContainer'
import RemoveBranding from '@configurator/components/billing/removeBranding'
// import AddressContainer from '@configurator/containers/billing/AddressContainer'
import BillingHistoryContainer from '@configurator/containers/billing/BillingHistoryContainer'
import SubscriptionContainer from '@configurator/containers/billing/SubscriptionContainer'

import {
  Container,
  Header,
  BillingDesktop,
  BillingMobile,
  WrapperTabs,
  Content,
  Items,
} from './nodes'

const tabs = [
  { id: 1, title: 'accountBilling.account.title', slug: 'account' },
  { id: 2, title: 'accountBilling.billing.title', slug: 'billing' },
]

const Billing = ({ config, intl: { formatMessage } }) => {
  const isMobile = useIsMobile()

  const [activeSlug, setActiveSlug] = useState(tabs[0].slug)

  if (isMobile) {
    const accordionItems = [
      {
        slug: 'account',
        title: formatMessage({
          id: 'accountBilling.account.title',
        }),
        content: (
          <Items>
            <LoginInfoContainer />

            <DeleteAccount />

            <RemoveBranding />
          </Items>
        ),
      },
      {
        slug: 'billing',
        title: formatMessage({
          id: 'accountBilling.billing.title',
        }),
        content: config && (
          <StripeProvider apiKey={config || null}>
            <Elements>
              <Items>
                <SubscriptionContainer />

                <BillingHistoryContainer />
              </Items>
            </Elements>
          </StripeProvider>
        ),
      },
    ]

    const onSelectAccordionItem = (slug) => {
      setActiveSlug(slug === activeSlug ? null : slug)
    }

    return (
      <>
        <HeaderStyled backArrow />

        <Container>
          <Header>{formatMessage({ id: 'accountBilling.header' })}</Header>

          <BillingMobile>
            <Accordion
              active={activeSlug}
              setActive={onSelectAccordionItem}
              items={accordionItems}
            />
          </BillingMobile>
        </Container>
      </>
    )
  }

  if (isMobile === false) {
    const handleRenderContent = () => {
      switch (activeSlug) {
        case 'account':
          return (
            <Items>
              <LoginInfoContainer />

              <DeleteAccount />

              <RemoveBranding />
            </Items>
          )

        case 'billing':
          return (
            config && (
              <StripeProvider apiKey={config || null}>
                <Elements>
                  <Items>
                    <SubscriptionContainer />

                    <BillingHistoryContainer />
                  </Items>
                </Elements>
              </StripeProvider>
            )
          )

        default:
          return null
      }
    }

    return (
      <>
        <HeaderStyled backArrow />

        <Container>
          <Header>{formatMessage({ id: 'accountBilling.header' })}</Header>

          <BillingDesktop>
            <WrapperTabs>
              <Tabs tabs={tabs} active={activeSlug} setActive={setActiveSlug} />
            </WrapperTabs>

            <Content>{handleRenderContent()}</Content>
          </BillingDesktop>
        </Container>
      </>
    )
  }

  return null
}

Billing.propTypes = {
  intl: intlShape.isRequired,
  config: PropTypes.string,
}

Billing.defaultProps = {}

export default injectIntl(Billing)
