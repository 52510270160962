/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const FileStarIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5 5.18867V8.08005C13.5 8.47209 13.5 8.6681 13.5817 8.81784C13.6537 8.94956 13.7684 9.05664 13.9095 9.12375C14.0699 9.20005 14.28 9.20005 14.7 9.20005H17.7979M11.4 19H9.6C8.33988 19 7.70982 19 7.22852 18.7711C6.80516 18.5698 6.46095 18.2485 6.24524 17.8534C6 17.4042 6 16.8161 6 15.64V8.36C6 7.18389 6 6.59583 6.24524 6.14662C6.46095 5.75148 6.80516 5.43022 7.22852 5.22889C7.70982 5 8.33988 5 9.6 5H13.5L18 9.2V12.64M16 14L16.927 15.9748L19 16.2934L17.5 17.8297L17.854 20L16 18.9748L14.146 20L14.5 17.8297L13 16.2934L15.073 15.9748L16 14Z'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

FileStarIcon.propTypes = {
  ...iconColorPropTypes,
}

FileStarIcon.defaultProps = {
  color: 'dark',
}
