import React from 'react'
import { Cookies } from 'react-cookie'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { COOKIE, PERIOD } from 'packages/enum'
import { localSwitcherContainerWrapper } from '@landing/containers/LocalSwitcherContainer'
import { Semantic } from 'packages/components'
import { fetchMessages } from 'packages/intl'
import { LocalSwitcherBlock } from './nodes'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'

const cookies = new Cookies()

const options = [
  { key: 'en', value: 'en', text: 'English' },
  { key: 'de', value: 'de', text: 'Deutsch' },
  { key: 'fr', value: 'fr', text: 'Français' },
  { key: 'es', value: 'es', text: 'Español' },
  { key: 'it', value: 'it', text: 'Italiano' },
  { key: 'pt', value: 'pt', text: 'Português' },
]

function Item({ text, onClick }) {
  return (
    <div className='item' onClick={onClick}>
      {text}
    </div>
  )
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export function LocalSwitcher({ locale, serviceType, updateIntl }) {
  const handleChange = async (e, { value }) => {
    cookies.set(COOKIE.locale, value, {
      expires: PERIOD.neverExpires,
    })
    ANALYTICS.switchLang(value)
    const messages = await fetchMessages({
      locale: value,
      type: serviceType,
    })
    updateIntl(messages)
  }

  return (
    <LocalSwitcherBlock onClick={(e) => e.preventDefault()} className='item'>
      <div>
        <FormattedMessage id='user.menu.language' />
      </div>
      <Semantic.Dropdown options={options} value={locale} onChange={handleChange} />
    </LocalSwitcherBlock>
  )
}

LocalSwitcher.propTypes = {
  locale: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  updateIntl: PropTypes.func.isRequired,
}

export default localSwitcherContainerWrapper(LocalSwitcher)
