import React from 'react'
import PropTypes from 'prop-types'
import { Semantic } from 'packages/components'
import { Text } from './nodes'

const Loader = ({ open, content, inverted }) => {
  if (!open) {
    return null
  }
  return (
    <Semantic.Dimmer active page blurring inverted={inverted}>
      <Semantic.Loader active size='big'>
        <Text>{content}</Text>
      </Semantic.Loader>
    </Semantic.Dimmer>
  )
}

Loader.propTypes = {
  open: PropTypes.bool,
  inverted: PropTypes.bool,
  content: PropTypes.string,
}

Loader.defaultProps = {
  open: false,
  inverted: false,
  content: '',
}

export default Loader
