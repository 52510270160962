import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  ${media.max.tablet`
        padding: 0 0 0 16px;
    `}
`

export const Preview = styled.div``

export const AlbumTitle = styled.span`
  font-size: 15px;
  line-height: 140%;
  font-weight: bold;
  color: #343840;
`

export const AlbumTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  > div {
    margin-left: auto;
  }
`

export const ChooseViewContainer = styled.div`
  cursor: pointer;
  svg {
    margin-left: 12px;
    vertical-align: middle;
  }

  ${media.max.tablet`
        display: none;
    `}
`
