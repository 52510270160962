import React from 'react'
import PropTypes from 'prop-types'
import { Container, Wrapper, CloseButton } from './nodes'
import { CloseIcon } from '../../icons/basic'

const Banner = ({ view, onClose, children, wrapperStyle, lightClose, ...rest }) => {
  return (
    <Container {...rest} view={view}>
      <Wrapper style={wrapperStyle}>
        {children}
        {onClose && (
          <CloseButton lightClose onClick={onClose}>
            <CloseIcon color='white' isLight={lightClose} />
          </CloseButton>
        )}
      </Wrapper>
    </Container>
  )
}

Banner.propTypes = {
  view: PropTypes.oneOf(['success', 'error', 'default']),
  onClose: PropTypes.func,
  lightClose: PropTypes.bool,
  wrapperStyle: PropTypes.object,
}

Banner.defaultProps = {
  view: 'default',
  lightClose: false,
  wrapperStyle: {},
}

export default Banner
