import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const CheckIcon = ({ color }) => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.98941 5.29253L6.93916 10.2423L5.52494 11.6565L0.575195 6.70675L1.98941 5.29253Z'
      fill={iconColors[color]}
    />
    <path
      d='M5.52494 8.82805L14.0102 0.342773L15.4244 1.75699L6.93916 10.2423L5.52494 8.82805Z'
      fill={iconColors[color]}
    />
  </svg>
)

CheckIcon.propTypes = {
  ...iconColorPropTypes,
}

CheckIcon.defaultProps = {
  color: 'green',
}
