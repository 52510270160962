import { connect } from 'react-redux'
import { compose } from 'redux'
import { updateIntl } from 'react-intl-redux'

export const localSwitcherContainerWrapper = compose(
  connect(
    (state) => ({
      serviceType: state.getIn(['intl', 'type']),
      locale: state.getIn(['intl', 'locale']),
    }),
    (dispatch) => ({
      updateIntl: (switchLocale) => dispatch(updateIntl(switchLocale)),
    })
  )
)
