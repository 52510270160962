import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const VideoIcon = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'>
    <rect width='100%' height='100%' />
    <g>
      <path
        d='M16.0408162.0408162h-14c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-14c0-1.103-.897-2-2-2zm-14 16v-14h14l.002 14h-14.002z'
        fill={iconColors[color]}
      />
      <path d='m12.0408162 9.0408162-5.25 3.4641v-6.9282l5.25 3.4641z' fill={iconColors[color]} />
    </g>
  </svg>
)

VideoIcon.propTypes = {
  ...iconColorPropTypes,
}

VideoIcon.defaultProps = {
  color: 'white',
}
