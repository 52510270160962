import { compose } from 'redux'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'

import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'

import ApplyCodeModal from '@configurator/components/pages/applyCodeModal'

const validationSchema = Yup.object().shape({
  code: Yup.string().nullable(),
})

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      lockModalProgress: () => dispatch(lockModal(true)),
      unLockModalProgress: () => dispatch(lockModal(false)),
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId, subMessageValues }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
            subMessageValues,
            hideCancelButton: true,
          })
        ),
    })
  ),
  withFormik({
    mapPropsToValues: ({ initialValues }) => {
      return {
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async ({ code }, { setSubmitting, setStatus, props: { applyCode } }) => {
      let defaultError = 'siteName.update.error.header'

      try {
        applyCode(code)
        setSubmitting(false)
      } catch (err) {
        setSubmitting(false)
        setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      }
    },
  })
)(ApplyCodeModal)
