import React from 'react'
import { injectIntl, intlShape } from 'react-intl'

import { HeaderStyled } from 'packages/components/layout/nodes'
import {
  Container,
  Header,
  Teaser,
  TeaserText,
  Logo,
  FormWrapper,
  Grid,
  Notes,
  StyledTextareaContainerLabel,
  StyledTextareaContainer,
  NoteHeader,
  Step,
  StepContent,
  StepNumber,
  StepWrapper,
  Paragraph,
  VideoLink,
} from '../nodes'
import Image from 'packages/components/gatsby-image'
import optinly from '../../assets/optinly.png'

import { Button } from 'packages/components/buttons'
import { Semantic } from 'packages/components'
import { FormTextArea } from 'packages/components/inputs'

import { wrapper } from '../../../../containers/integrations/IntegrationsDetails'

const Optinly = ({
  handleSubmit,
  errors,
  status,
  isSubmitting,
  loading,
  intl: { formatMessage },
}) => {
  const error = Object.keys(errors).length || (status && !!status.error)

  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header>
          {formatMessage({
            id: 'integrations.optinly.header',
          })}
        </Header>
        <Teaser>
          <Logo>
            <Image
              fluid={{
                src: optinly,
                aspectRatio: 38 / 15,
              }}
              alt=''
              fadeIn
              imgStyle={{ objectFit: 'contain' }}
              loading='lazy'
              backgroundColor='white'
            />
          </Logo>
          <TeaserText small>
            {formatMessage({
              id: 'integrations.optinly.teaser',
            })}
          </TeaserText>
          <div>
            <Button
              as='a'
              href={'https://app.optinly.com/'}
              target='_blank'
              content={formatMessage({
                id: 'integration.start.free',
              })}
            />
          </div>
        </Teaser>
        <Grid>
          <div>
            <FormWrapper>
              <Semantic.Form
                noValidate
                error={error}
                loading={isSubmitting || loading}
                onSubmit={handleSubmit}
              >
                <StyledTextareaContainerLabel>
                  {formatMessage({
                    id: 'integration.footer.code',
                  })}
                </StyledTextareaContainerLabel>

                <StyledTextareaContainer>
                  <FormTextArea
                    name='content'
                    placeholder={formatMessage({
                      id: 'integrations.optinly.code.here',
                    })}
                  />
                </StyledTextareaContainer>
                <Button
                  type='submit'
                  content={formatMessage({
                    id: 'integration.save',
                  })}
                  disabled={isSubmitting}
                />
              </Semantic.Form>
            </FormWrapper>
          </div>
          <div>
            <Notes>
              <NoteHeader>
                {formatMessage({
                  id: 'integrations.optinly.note.header',
                })}
              </NoteHeader>
              <StepWrapper>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integrations.optinly.step.1',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: 'integrations.optinly.note.1',
                          }),
                        }}
                      />
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>
                        {formatMessage({
                          id: 'integrations.optinly.step.2',
                        })}
                      </span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integrations.optinly.note.2',
                      })}
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>3</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integrations.optinly.note.3',
                      })}
                    </Paragraph>

                    <VideoLink href={'https://vimeo.com/633684762'} target={'_blank'}>
                      {formatMessage({
                        id: 'integration.watch.tutorial',
                      })}
                    </VideoLink>
                  </StepContent>
                </Step>
              </StepWrapper>
            </Notes>
          </div>
        </Grid>
      </Container>
    </>
  )
}

Optinly.propTypes = {
  intl: intlShape.isRequired,
}

Optinly.defaultProps = {}

export default wrapper(injectIntl(Optinly), 'optinly')
