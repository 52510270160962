import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql'
import { applyFilter } from '@configurator/redux/modules/periodPicker/actions'

import Statistics from './layout'

export default compose(
  connect(() => ({}), { applyFilter }),
  graphql(websiteMetaGet, {
    options() {
      return {
        variables: {},
        ssr: false,
      }
    },
    props({ data: { loading, websiteMetaGet } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(websiteMetaGet)) {
        return
      }
      const { data } = websiteMetaGet

      return { idSite: get(data, 'matomoId') }
    },
  })
)(Statistics)
