import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { createAction } from 'redux-actions'
import { push } from 'connected-react-router'
import { URLS } from 'packages/enum'
import isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router'

import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql'
import websitePageDelete from '@graphql/gql/website/websitePageDelete.gql'
import websiteAlbumDelete from '@graphql/gql/website/websiteAlbumDelete.gql'
import websitePageMove from '@graphql/gql/website/websitePageMove.gql'
import websiteAlbumUpdate from '@graphql/gql/website/websiteAlbumUpdate.gql'
import albumsOrderUpdate from '@graphql/gql/website/albumsOrderUpdate.gql'

import { nameSelector } from 'packages/selectors/User'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import { close, open } from 'packages/redux/modules/modalYesNo/actions'
import {
  UPDATE_PREVIEW_META_DATA,
  UPDATE_PREVIEW_STRUCTURE_DATA,
} from '@configurator/constants/Preview'
import Navigation from '@configurator/components/navigation/Navigation'

export default compose(
  withRouter,
  connect(
    (state) => ({
      name: nameSelector(state),
      rawText: state.getIn(['preview', 'structure', 'blocks', '0', 'rawText']),
      slideshowEnabled: state.getIn(['preview', 'meta', 'slideshowEnabled']),
    }),
    (dispatch) => ({
      updateMeta: (payload) => dispatch(createAction(UPDATE_PREVIEW_META_DATA)(payload)),
      updateStructure: (payload) => dispatch(createAction(UPDATE_PREVIEW_STRUCTURE_DATA)(payload)),
      redirectToPage: (url) =>
        dispatch(push(`${URLS.website_config}/page/${encodeURIComponent(url)}`)),
      redirectToConfig: () => dispatch(push(URLS.website_config)),
      redirectTo: (pathname) => dispatch(push(pathname)),
      loaderStart: ({ content } = {}) => dispatch(loaderStart({ content })),
      loaderStop: () => dispatch(loaderStop()),
      closeInfoModal: () => dispatch(close()),
      openInfoModal: ({
        hideHeader,
        headerMessageId,
        subMessageId,
        yesMessageId,
        cancelMessageId,
        onClickCancel,
        onClickYes,
        buttons,
      }) =>
        dispatch(
          open({
            headerMessageId,
            hideHeader,
            subMessageId,
            yesMessageId,
            cancelMessageId,
            close,
            onClickYes,
            onClickCancel,
            buttons,
          })
        ),
    })
  ),
  graphql(albumsOrderUpdate, {
    props({ mutate }) {
      return {
        async onAlbumsOrderUpdate({ data }) {
          try {
            return await mutate({
              variables: {
                order: data,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websitePagesGet, {
    options() {
      return {
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websitePagesGet } }) {
      if (loading) {
        return { navigationLoading: loading }
      }
      if (isEmpty(websitePagesGet)) {
        return { navigationLoading: loading }
      }
      const { data } = websitePagesGet

      return {
        navigation: data,
      }
    },
  }),
  graphql(previewPageGet, {
    options({ match }) {
      const decodedPageId = match.params.pageId
        ? decodeURIComponent(match.params.pageId)
        : undefined

      return {
        variables: {
          id: decodedPageId,
        },
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({
      data: { loading, previewPageGet, refetch },
      ownProps: { disablePageFetch, updateMeta, updateStructure },
    }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(previewPageGet)) {
        return
      }
      const { data } = previewPageGet

      // update preview
      // "skip" in apoolo client doesn't work properly.
      if (!disablePageFetch) {
        // конфликтуют апдейты стейта
        // Cannot update a component (`Header`) while rendering a different component (`Query`)
        setTimeout(() => {
          updateMeta(data.meta)
          updateStructure(data.structure)
        })
      }

      return {
        features: data.meta.features || [],
        refetchPreviewMetaData: refetch,
      }
    },
  }),
  graphql(websitePageDelete, {
    props({ mutate, ownProps }) {
      return {
        async deletePage({ pageId }) {
          try {
            return await mutate({
              variables: {
                pageId,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                  variables: {
                    pageId: decodeURIComponent(ownProps.pageId || ''),
                  },
                },
                {
                  query: previewPageGet,
                },
              ],
              awaitRefetchQueries: true,
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteAlbumDelete, {
    props({ mutate, ownProps }) {
      return {
        async deleteAlbum({ albumId }) {
          try {
            return await mutate({
              variables: {
                albumId,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                  variables: {
                    pageId: decodeURIComponent(ownProps.pageId || ''),
                  },
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websitePageMove, {
    props({ mutate, ownProps }) {
      return {
        async movePage({ leftId, rightId, itemId, changeNavigation }) {
          try {
            return await mutate({
              variables: {
                leftId,
                rightId,
                itemId,
                changeNavigation,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                  variables: {
                    pageId: decodeURIComponent(ownProps.pageId || ''),
                  },
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteAlbumUpdate, {
    props({ mutate }) {
      return {
        async updateAlbum({ albumId, pageId }) {
          try {
            return await mutate({
              variables: { albumId, pageId },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  })
)(Navigation)
