import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const Title = styled.span`
  margin: 0 10px;
  font-size: 13px;
  color: #666a73;
  text-transform: uppercase;
  white-space: nowrap;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e1e2e6;
`
