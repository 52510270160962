import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const SearchMinusIcon = ({ color }) => (
  <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5766 14.9996L7.80956 9.23358C5.65338 10.656 2.77057 10.216 1.13689 8.21513C-0.496801 6.21427 -0.351348 3.30171 1.47356 1.47358C3.30137 -0.35203 6.21426 -0.498113 8.21555 1.13546C10.2168 2.76904 10.6571 5.65217 9.23456 7.80858L14.9996 13.5756L13.5766 14.9996ZM5.03356 2.01357C3.59281 2.01266 2.35204 3.0296 2.07004 4.44248C1.78805 5.85537 2.54337 7.2707 3.87408 7.82293C5.2048 8.37516 6.7403 7.91049 7.54154 6.71309C8.34279 5.51569 8.18662 3.91904 7.16856 2.89958C6.60381 2.33092 5.835 2.01187 5.03356 2.01357ZM17.9996 3.99958H11.9996V1.99958H17.9996V3.99958Z'
      fill={iconColors[color]}
    />
  </svg>
)

SearchMinusIcon.propTypes = {
  ...iconColorPropTypes,
}

SearchMinusIcon.defaultProps = {
  color: 'default',
}
