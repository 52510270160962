import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { injectIntl, intlShape } from 'react-intl'
import isEqual from 'lodash/isEqual'
import { Formik, Form as FormikForm } from 'formik'

import { FormInput, FormTextArea } from 'packages/components/inputs'
import { Button } from 'packages/components/buttons'
import {
  Field,
  Paragraph,
  SearchPreview,
  SearchPreviewDomain,
  SearchPreviewDescription,
  SearchPreviewTitle,
  RightPanelBlock,
  Description,
  Container,
  TitleRightPanelBlock,
  SettingsWrapper,
  SaveWrapper,
} from '../nodes'
import Favicon from '../favicon'
import OpenGraph from '../openGraph'

const SettingsContent = ({
  intl: { formatMessage },
  lockModalProgress,
  unLockModalProgress,
  loaderStart,
  loaderStop,
  openErrorModal,
  defaultValues,
  data,
  updatePage,
  selectedPageData,
  domain,
  handleSubmitForm,
}) => {
  const validationSchema = Yup.object().shape({
    seoTitle: Yup.string().nullable(),
    seoDescription: Yup.string().nullable(),
  })

  const renderLeftPanel = ({ values, isSubmitting, setSubmitting }) => {
    const isDisabledSeoSave = isEqual(defaultValues, {
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
    })

    const form = (
      <Container>
        <SettingsWrapper>
          <Paragraph mb='24' type='h1' fz='24px'>
            {formatMessage({ id: 'seo.google.website.settings' })}
          </Paragraph>

          <Description mb='40'>
            {formatMessage({ id: 'seo.description.website.settings' })}
          </Description>

          <Field>
            <FormInput
              data-intercom-target={'BrowserTabTitle'}
              label={formatMessage({ id: 'seo.title.label' })}
              name='seoTitle'
              placeholder={formatMessage({
                id: 'seo.title.placeholder',
              })}
            />
          </Field>

          <Field mb='0'>
            <FormTextArea
              data-intercom-target={'BrowserTabDescription'}
              label={formatMessage({ id: 'seo.description.label' })}
              name='seoDescription'
              placeholder={formatMessage({
                id: 'seo.description.placeholder',
              })}
            />
          </Field>

          <SaveWrapper>
            <Button
              view='primary'
              disabled={isDisabledSeoSave}
              content={formatMessage({ id: 'seo.save' })}
              type='submit'
            />
          </SaveWrapper>
        </SettingsWrapper>

        <RightPanelBlock>
          <TitleRightPanelBlock>{formatMessage({ id: 'seo.search' })}</TitleRightPanelBlock>

          <SearchPreview>
            <div>
              <SearchPreviewDomain>{domain}</SearchPreviewDomain>

              <SearchPreviewTitle>{values.seoTitle}</SearchPreviewTitle>

              <SearchPreviewDescription>{values.seoDescription}</SearchPreviewDescription>
            </div>
          </SearchPreview>
        </RightPanelBlock>
      </Container>
    )

    return (
      <>
        <FormikForm>{form}</FormikForm>

        <Favicon
          isSubmitting={isSubmitting}
          premiumDomain={data.premiumDomain}
          domainData={data.domain}
          title={values.seoTitle}
          setSubmitting={setSubmitting}
          lockModalProgress={lockModalProgress}
          unLockModalProgress={unLockModalProgress}
          loaderStart={loaderStart}
          loaderStop={loaderStop}
          openErrorModal={openErrorModal}
        />

        <OpenGraph
          isSubmitting={isSubmitting}
          openErrorModal={openErrorModal}
          lockModalProgress={lockModalProgress}
          unLockModalProgress={unLockModalProgress}
          loaderStart={loaderStart}
          loaderStop={loaderStop}
          setSubmitting={setSubmitting}
          updatePage={updatePage}
          values={values}
          selectedPageData={selectedPageData}
          premiumDomain={data.premiumDomain}
          domainData={data.domain}
        />
      </>
    )
  }

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={defaultValues}
      onSubmit={handleSubmitForm}
      render={({ values, isSubmitting, setSubmitting, resetForm }) => {
        return (
          <>
            {renderLeftPanel({
              isSubmitting,
              values,
              resetForm,
              setSubmitting,
            })}
          </>
        )
      }}
    />
  )
}

SettingsContent.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  lockModalProgress: PropTypes.func.isRequired,
  unLockModalProgress: PropTypes.func.isRequired,
  openErrorModal: PropTypes.func.isRequired,
}

SettingsContent.defaultProps = {}

export default injectIntl(SettingsContent)
