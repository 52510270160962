import styled from 'styled-components'
import { Modal } from 'packages/components/modal'

export const ModalContainer = styled(Modal)`
  position: initial !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-align: left !important;
  color: #343840;

  .title {
    font-size: 36px;
    line-height: 140%;
    font-weight: bold;
    padding: 0;
    border: none;
  }

  .body {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 15px;
    line-height: 21px;

    > span {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .ui.button {
      width: 100% !important;
      height: 45px;

      &:first-child {
        margin-right: 5px !important;
      }

      &:last-child {
        margin-left: 5px !important;
      }
    }
  }
`
