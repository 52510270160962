import { Header, StylesButtons } from '@configurator/components/pages/pageStyles/components'
import { useSubscription } from '@configurator/providers/subscription'
import { Form as FormikForm } from 'formik'
import isEqual from 'lodash/isEqual'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { FEATURE_PROPERTY_KEYS, STYLE_FEATURE_PROPERTIES, STYLE_TYPES } from 'packages/enum'
import { getPropertyMediaUrl } from 'packages/helpers/Helper'
import { usePrevious } from 'packages/helpers/usePrevious'
import { formProptypes } from 'packages/utils/formikPropTypes'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import {
  AlbumFieldsBlock,
  AlbumListFieldsBlock,
  AlbumListMobileFieldsBlock,
  AlbumMobileFieldsBlock,
  BackGroundFieldsBlock,
  BurgerFieldsBlock,
  BurgerMenuFieldsBlock,
  BurgerMenuMobileFieldsBlock,
  ContactFieldsBlock,
  FooterFieldsBlock,
  FooterMobileFieldsBlock,
  HeaderFieldsBlock,
  HeaderMobileFieldsBlock,
  HoverFieldsBlock,
  ImageDetailBlock,
  ImageDetailMobileBlock,
  LoaderAnimationBlock,
  LogoFieldsBlock,
  LogoMobileFieldsBlock,
  NavigationFieldsBlock,
  PhotosFieldsBlock,
  PhotosMobileFieldsBlock,
  Slideshow,
  SocialLinksBlock,
  TextFieldsBlock,
} from './formBlocks'

const FormFields = React.memo(
  ({
    selectedStyle,
    formatMessage,
    formBlocksProps,
    templateName,
    scrollableHeader,
    toggleScollableHeader,
    updateSlideshow,
    // updateTemplate,
    goToSlideshowSettings,
  }) => {
    switch (selectedStyle.type) {
      case STYLE_TYPES.logo: {
        return <LogoFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.header: {
        return (
          <HeaderFieldsBlock
            scrollableHeader={scrollableHeader}
            toggleScollableHeader={toggleScollableHeader}
            formatMessage={formatMessage}
            {...formBlocksProps}
          />
        )
      }
      case STYLE_TYPES.headerMobile: {
        return <HeaderMobileFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.logoMobile: {
        return <LogoMobileFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.burgerMenu: {
        return (
          <BurgerMenuFieldsBlock
            scrollableHeader={scrollableHeader}
            toggleScollableHeader={toggleScollableHeader}
            formatMessage={formatMessage}
            {...formBlocksProps}
          />
        )
      }
      case STYLE_TYPES.burgerMenuMobile: {
        return <BurgerMenuMobileFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.navigation: {
        return <NavigationFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.footer: {
        return <FooterFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.footerMobile: {
        return (
          <FooterMobileFieldsBlock
            templateName={templateName}
            formatMessage={formatMessage}
            {...formBlocksProps}
          />
        )
      }
      case STYLE_TYPES.album: {
        return <AlbumFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.albumMobile: {
        return <AlbumMobileFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.albumList: {
        return <AlbumListFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.albumListMobile: {
        return <AlbumListMobileFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.photos: {
        return <PhotosFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.photosMobile: {
        return <PhotosMobileFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.imageDetail: {
        return <ImageDetailBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.imageDetailMobile: {
        return <ImageDetailMobileBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.slideshow: {
        return (
          <Slideshow
            formatMessage={formatMessage}
            updateSlideshow={updateSlideshow}
            goToSlideshowSettings={goToSlideshowSettings}
            {...formBlocksProps}
          />
        )
      }
      case STYLE_TYPES.contacts: {
        return <ContactFieldsBlock {...formBlocksProps} />
      }
      case STYLE_TYPES.text: {
        return <TextFieldsBlock {...formBlocksProps} />
      }
      case STYLE_TYPES.burgerColor: {
        return <BurgerFieldsBlock />
      }
      case STYLE_TYPES.hoverColor: {
        return <HoverFieldsBlock />
      }
      case STYLE_TYPES.pageBackground: {
        return <BackGroundFieldsBlock />
      }
      case STYLE_TYPES.loaderAnimation: {
        return <LoaderAnimationBlock {...formBlocksProps} />
      }
      case STYLE_TYPES.socialLinks: {
        return <SocialLinksBlock {...formBlocksProps} />
      }
      default:
        return null
    }
  }
)

const StyleForm = ({
  isMobile,
  initialValues,
  setValues,
  values,
  setFieldValue,
  selectedStyle,
  setSelectedStyle,
  updateFeatureState,
  resetFeaturesProp,
  openErrorModal,
  closeErrorModal,
  setBackArrowState,
  clearBackArrowState,
  slideshowEnabled,
  handleDiscard,
  templateName,
  intl: { formatMessage },
  scrollableHeader,
  toggleScollableHeader,
  updateTemplate,
  updateSlideshow,
  onChangeTab,
  isV2Template,
  logoValues,
}) => {
  const prevValues = usePrevious(values)
  const { permissions } = useSubscription()

  const goToSlideshowSettings = () => {
    setSelectedStyle(null)
    clearBackArrowState()
    onChangeTab(0)
  }

  const backArrowStateCb = useCallback(() => {
    if (!isEqual(values, initialValues)) {
      openErrorModal({
        onClickYes: () => {
          updateFeatureState({
            key: FEATURE_PROPERTY_KEYS.style,
            values: initialValues,
          })
          handleDiscard()
          setSelectedStyle(null)
          handleDiscard()
          closeErrorModal()
          clearBackArrowState()
        },
        hideHeader: true,
        subMessageId: 'discardChanges.subMessage',
        yesMessageId: 'discardChanges.yesMessage',
        styles: { width: '450px' },
      })
    } else {
      setSelectedStyle(null)
      clearBackArrowState()
    }
  }, [
    clearBackArrowState,
    closeErrorModal,
    initialValues,
    openErrorModal,
    setSelectedStyle,
    updateFeatureState,
    values,
    handleDiscard,
  ])

  useEffect(() => {
    setBackArrowState(isMobile ? 'tabs.tab.mobile' : 'tabs.tab.fontsAndColors', backArrowStateCb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backArrowStateCb, setBackArrowState])

  useEffect(() => {
    if (prevValues && !isEqual(prevValues, values)) {
      updateFeatureState({ key: FEATURE_PROPERTY_KEYS.style, values })
    }
  }, [initialValues, prevValues, updateFeatureState, values])

  const getPropNamesToReset = useCallback(() => {
    switch (selectedStyle.type) {
      case STYLE_TYPES.logo: {
        return [
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_MARGIN_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_MARGIN_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_CUSTOM_LOGO_TOP_PADDING,
          STYLE_FEATURE_PROPERTIES.HEADER_CUSTOM_LOGO_BOTTOM_PADDING,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LOGO_MARGIN_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LOGO_MARGIN_BOTTOM,
        ]
      }
      case STYLE_TYPES.logoMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_MARGIN_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LOGO_MARGIN_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_MARGIN_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_SUBLOGO_MARGIN_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_CUSTOM_LOGO_TOP_PADDING,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_CUSTOM_LOGO_BOTTOM_PADDING,
        ]
      }
      case STYLE_TYPES.header: {
        return [
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_TYPE,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_STYLE,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_PADDING_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_PADDING_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_TEXT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.HEADER_BACKGROUND_OPACITY,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_STYLE,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_PADDING_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_PADDING_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.HEADER_DESKTOP_BURGER_POSITION,
          STYLE_FEATURE_PROPERTIES.BURGER_COLOR,
        ]
      }
      case STYLE_TYPES.headerMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_STYLE,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_ICON_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_PADDING_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_PADDING_BOTTOM,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_MOBILE_BURGER_TEXT_ID,
        ]
      }

      case STYLE_TYPES.burgerMenu: {
        return [
          STYLE_FEATURE_PROPERTIES.BURGER_BACKGROUND_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_CLOSE_ICON_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_STYLE,
          STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_ID,
          STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_FONT_SIZE,
          STYLE_FEATURE_PROPERTIES.BURGER_NAVIGATION_LINKS_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_TOP_PADDING,
          STYLE_FEATURE_PROPERTIES.BURGER_DESKTOP_NAVIGATION_LINKS_BOTTOM_PADDING,
          STYLE_FEATURE_PROPERTIES.BURGER_LOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_SUBLOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_FOOTER_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_FOOTER_SOCIAL_ICON_COLOR,
        ]
      }
      case STYLE_TYPES.burgerMenuMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_SIZE,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_TOP_PADDING,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_BOTTOM_PADDING,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_BACKGROUND_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_CLOSE_ICON_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_FONT_ID,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_STYLE,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_NAVIGATION_LINKS_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_LOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_SUBLOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_FOOTER_COLOR,
          STYLE_FEATURE_PROPERTIES.BURGER_MOBILE_FOOTER_SOCIAL_ICON_COLOR,
        ]
      }
      case STYLE_TYPES.navigation: {
        return [
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_SIZE,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_COLOR,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_ID,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_SOURCE,
        ]
      }
      case STYLE_TYPES.footer: {
        return [
          STYLE_FEATURE_PROPERTIES.FOOTER_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.FOOTER_SIZE,
          STYLE_FEATURE_PROPERTIES.FOOTER_COLOR,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_ID,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.FOOTER_BACKGROUND_COLOR,
          STYLE_FEATURE_PROPERTIES.FOOTER_BACKGROUND_OPACITY,
          STYLE_FEATURE_PROPERTIES.FOOTER_LINK_STYLE,
          STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_YEAR,
          STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_DOMAIN_TITLE,
        ]
      }
      case STYLE_TYPES.footerMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.FOOTER_MOBILE_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.FOOTER_MOBILE_SIZE,
        ]
      }
      case STYLE_TYPES.album: {
        return [
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_ID,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_ID,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_ID,
        ]
      }
      case STYLE_TYPES.albumMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_DESCRIPTION_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_MOBILE_PHOTO_NOTE_SIZE,
        ]
      }
      case STYLE_TYPES.albumList: {
        return [
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_TITLE_FONT_ID,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_DESCRIPTION_FONT_ID,
        ]
      }
      case STYLE_TYPES.albumListMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_LIST_MOBILE_DESCRIPTION_SIZE,
        ]
      }
      case STYLE_TYPES.photos: {
        return [
          STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_COLOR,
          STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.PHOTOS_TITLE_FONT_ID,
          STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_COLOR,
          STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_SIZE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_DESCRIPTION_FONT_ID,
          STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_COLOR,
          STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_SIZE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.PHOTOS_PHOTO_NOTE_FONT_ID,
        ]
      }
      case STYLE_TYPES.photosMobile: {
        return [
          STYLE_FEATURE_PROPERTIES.PHOTOS_MOBILE_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_MOBILE_DESCRIPTION_SIZE,
          STYLE_FEATURE_PROPERTIES.PHOTOS_MOBILE_PHOTO_NOTE_SIZE,
        ]
      }
      case STYLE_TYPES.imageDetail: {
        return [
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_TYPE,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_BACKGROUND_COLOR,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_SIZE,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_COLOR,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_NAME,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_SOURCE,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_FONT_ID,
          STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_TEXT_FONT_WEIGHT,
        ]
      }
      case STYLE_TYPES.imageDetailMobile: {
        return [STYLE_FEATURE_PROPERTIES.IMAGE_DETAIL_MOBILE_TEXT_SIZE]
      }
      case STYLE_TYPES.contacts: {
        return [
          STYLE_FEATURE_PROPERTIES.CONTACTS_SIZE,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.CONTACTS_COLOR,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_ID,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_SOURCE,
        ]
      }
      case STYLE_TYPES.text: {
        return [
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_WEIGHT,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_SOURCE,
        ]
      }
      case STYLE_TYPES.burgerColor: {
        return [STYLE_FEATURE_PROPERTIES.BURGER_COLOR]
      }
      case STYLE_TYPES.hoverColor: {
        return [STYLE_FEATURE_PROPERTIES.HOVER_COLOR]
      }
      case STYLE_TYPES.pageBackground: {
        return [STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR]
      }
      case STYLE_TYPES.loaderAnimation: {
        return [
          STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_COLOR,
          STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_VARIANT,
          STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM,
          getPropertyMediaUrl(STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM),
        ]
      }
      case STYLE_TYPES.socialLinks: {
        return [
          STYLE_FEATURE_PROPERTIES.FOOTER_ICON_TYPE,
          STYLE_FEATURE_PROPERTIES.FOOTER_SOCIAL_ICON_COLOR,
        ]
      }
      default:
        return []
    }
  }, [selectedStyle.type])

  const handleResetForm = useCallback(() => {
    const newValues = {}
    for (let key of Object.keys(values)) {
      newValues[key] = initialValues[key]
    }
    setValues({ ...newValues })
    resetFeaturesProp({ featurePropertyNames: getPropNamesToReset() })
  }, [getPropNamesToReset, initialValues, resetFeaturesProp, setValues, values])

  const formBlocksProps = useMemo(
    () => ({
      selectedStyle,
      values,
      initialValues,
      setFieldValue,
      openErrorModal,
      slideshowEnabled,
      templateName,
      isV2Template,
      logoValues,
    }),
    [
      initialValues,
      openErrorModal,
      selectedStyle,
      setFieldValue,
      values,
      slideshowEnabled,
      templateName,
      isV2Template,
      logoValues,
    ]
  )

  const isAllowed = () => {
    if (selectedStyle.type === STYLE_TYPES.socialLinks) {
      return permissions.SOCIAL_ICONS
    } else if (selectedStyle.type === STYLE_TYPES.loaderAnimation) {
      return permissions.CUSTOM_SPINNER
    } else {
      return permissions.FONTS_COLORS
    }
  }

  return (
    <FormikForm>
      {selectedStyle?.type !== STYLE_TYPES.slideshow && (
        <Header>
          {formatMessage({
            id: selectedStyle.formTitle || selectedStyle.title,
          })}
        </Header>
      )}
      <PermissionsOverlay isAllowed={isAllowed()}>
        <FormFields
          formatMessage={formatMessage}
          selectedStyle={selectedStyle}
          formBlocksProps={formBlocksProps}
          templateName={templateName}
          scrollableHeader={scrollableHeader}
          toggleScollableHeader={toggleScollableHeader}
          updateTemplate={updateTemplate}
          updateSlideshow={updateSlideshow}
          goToSlideshowSettings={goToSlideshowSettings}
        />
        {selectedStyle?.type !== STYLE_TYPES.slideshow && (
          <StylesButtons
            onResetClick={() => handleResetForm()}
            saveDisabled={isEqual(values, initialValues)}
          />
        )}
      </PermissionsOverlay>
    </FormikForm>
  )
}

StyleForm.propTypes = {
  isMobile: PropTypes.bool,
  slideshowEnabled: PropTypes.bool,
  selectedStyle: PropTypes.object.isRequired,
  updateFeatureState: PropTypes.func,
  resetFeaturesProp: PropTypes.func,
  openErrorModal: PropTypes.func,
  closeErrorModal: PropTypes.func,
  handleDiscard: PropTypes.func,
  intl: intlShape.isRequired,
  templateName: PropTypes.string,
  scrollableHeader: PropTypes.shape(),
  toggleScollableHeader: PropTypes.func,
  ...formProptypes,
}

StyleForm.defaultProps = {
  updateFeatureState: () => null,
  resetFeaturesProp: () => null,
  openErrorModal: () => null,
  closeErrorModal: () => null,
  handleDiscard: () => {},
}

export default injectIntl(StyleForm)
