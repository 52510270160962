import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { FormDropdown } from 'packages/components/inputs'
import { formProptypes } from 'packages/utils/formikPropTypes'

import { Button } from 'packages/components/buttons'
import { StyledDropdown, Footer } from './nodes'
const validationSchema = Yup.object().shape({
  text: Yup.string(),
})

export const ContactDisplayModeForm = (
  { onSubmit, initialValues, onClose },
  { intl: { formatMessage } }
) => {
  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values)
      }}
      render={() => {
        return (
          <FormikForm>
            <StyledDropdown>
              <FormDropdown
                name='align'
                label='Align'
                options={[
                  {
                    key: 'Horizontal',
                    value: 'horizontal',
                    text: 'Horizontal',
                  },
                  {
                    key: 'Vertical',
                    value: 'vertical',
                    text: 'Vertical',
                  },
                ]}
              />
            </StyledDropdown>

            <Footer>
              <Button
                type='button'
                content={formatMessage({
                  id: 'newDesign.modal.buttons.cancel',
                })}
                fluid
                view='primaryGray'
                onClick={onClose}
              />
              <Button
                content={formatMessage({
                  id: 'newDesign.modal.buttons.save',
                })}
                onClick={onSubmit}
                fluid
              />
            </Footer>
          </FormikForm>
        )
      }}
    />
  )
}

ContactDisplayModeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAlbumItemUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool,
  ...formProptypes,
}

ContactDisplayModeForm.defaultProps = {
  open: false,
}

ContactDisplayModeForm.contextTypes = {
  intl: PropTypes.object.isRequired,
}
