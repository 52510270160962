import React from 'react'
import { intlShape } from 'react-intl'
import PropTypes from 'prop-types'

import MapChart from './MapChart'

import { Container, Content } from './nodes'

const StatisticMap = ({ countries, loading }) => {
  return (
    <Container>
      <Content>
        <MapChart rawData={countries} loading={loading} />
      </Content>
    </Container>
  )
}

StatisticMap.propTypes = {
  countries: PropTypes.array,
  loading: PropTypes.bool,
}

StatisticMap.contextTypes = { intl: intlShape }

export default StatisticMap
