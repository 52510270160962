import React from 'react'
import { loaderDefaultProps, loaderPropTypes } from '../../const'

import { Container } from './nodes'

export const Hourglass = ({ color, width, height }) => (
  <Container style={{ color }} width={width} height={height} />
)

Hourglass.propTypes = loaderPropTypes

Hourglass.defaultProps = loaderDefaultProps
