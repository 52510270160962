import styled from 'styled-components'

export const AlignCheckboxContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 1px;
  background-color: ${(props) =>
    props.active ? 'rgba(33, 150, 243, 0.1)' : 'rgba(33, 150, 243, 0)'};
  margin-left: 10px;
  padding-left: 2px;
  padding-top: 1px;
  transition: all 0.2s;
`
