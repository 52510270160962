import styled from 'styled-components'

export const OpenGraphPreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const OpenGraphPreviewImg = styled.div`
  height: 250px;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  background-color: #ededed;
  img {
    object-fit: cover;
    width: 100%;
  }
`

export const OpenGraphPreviewInfo = styled.div`
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 0 0 4px 4px;
`

export const OpenGraphPreviewTitle = styled.div`
  font-weight: bold;
  padding-bottom: 2px;
`

export const OpenGraphPreviewDomain = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #c1c3c7;
  padding-top: 10px;
`
