/** https://github.com/react-dnd/shallowequal/blob/master/src/index.ts */

export function shallowEqual(objA, objB, compare, compareContext) {
  var compareResult = compare ? compare.call(compareContext, objA, objB) : void 0
  if (compareResult !== void 0) {
    return !!compareResult
  }

  if (objA === objB) {
    return true
  }

  if (typeof objA !== 'object' || !objA || typeof objB !== 'object' || !objB) {
    return false
  }

  var keysA = Object.keys(objA)
  var keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  var bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB)

  // Test for A's keys different from B.
  for (var idx = 0; idx < keysA.length; idx++) {
    var key = keysA[idx]

    if (!bHasOwnProperty(key)) {
      return false
    }

    var valueA = objA[key]
    var valueB = objB[key]

    compareResult = compare ? compare.call(compareContext, valueA, valueB, key) : void 0

    if (compareResult === false || (compareResult === void 0 && valueA !== valueB)) {
      return false
    }
  }

  return true
}
