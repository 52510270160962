import { useEffect } from 'react'
import { useLocation } from 'react-router'

export const useSearchParam = ({ key, onSuccess, onFailure }) => {
  const { search } = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(search)

    const query = queryParams.get(key)

    if (query) {
      onSuccess(query)
    } else {
      onFailure()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, search])
}
