import styled from 'styled-components'
import { FormTextArea } from 'packages/components/inputs'

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 150%;
  font-weight: bold;

  ${({ align }) => align && `text-align: ${align};`}
`

export const Text = styled.div`
  font-size: 15px;
  line-height: 150%;

  ${({ align }) => align && `text-align: ${align};`}
`

export const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Text}:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const FeaturesContainer = styled.div`
  display: flex;
  margin: 40px 0;

  @media (max-width: 480px) {
    flex-direction: column;
    ${FeaturesColumn}+${FeaturesColumn} {
      margin-top: 24px;
    }
  }
`

export const FeaturesTitle = styled.div`
  margin-bottom: 16px;
  font-size: 17px;
  line-height: 150%;
  font-weight: bold;
`

export const RadioField = styled.div`
  display: flex;

  > span {
    margin-top: 8px;
    margin-left: 10px;
    font-size: 13px;
    line-height: 140%;
  }
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${RadioField}:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const StyledFormTextArea = styled(FormTextArea)`
  margin-top: 31px;

  textarea {
    min-height: 181px;
  }
`

export const SubTitle = styled.div`
  margin-top: 8px;
  font-size: 13px;
  font-weight: normal;
  line-height: 140%;
  text-align: center;
  color: #666a73;
`
