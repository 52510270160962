import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const SearchIcon = ({ color }) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5ZM11.7596 13.6738C10.5498 14.5101 9.08208 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 9.33833 14.3386 11.0222 13.2408 12.3266L17.7071 16.7929L16.2929 18.2071L11.7596 13.6738Z'
      fill={iconColors[color]}
    />
  </svg>
)

SearchIcon.propTypes = {
  ...iconColorPropTypes,
}

SearchIcon.defaultProps = {
  color: 'green',
}
