import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'

import { Button } from 'packages/components/buttons'

import Card from '../Card'
import { Expire, Status, UpgradeButton } from '../../nodes'

const FreeCard = ({ onSelectPlan, planName, trialUsed, startTrial, intl: { formatMessage } }) => {
  return (
    <Card
      planName={planName}
      onSelectPlan={onSelectPlan}
      Status={
        <Status status='active'>
          {formatMessage({ id: 'accountBilling.billing.status.active' })}
        </Status>
      }
      Expire={<Expire>{formatMessage({ id: 'accountBilling.billing.neverExpires' })}</Expire>}
      RightBlock={
        <>
          {trialUsed && (
            <UpgradeButton onClick={onSelectPlan}>
              {formatMessage({ id: 'accountBilling.billing.upgrade' })}
            </UpgradeButton>
          )}

          {!trialUsed && (
            <Button view='secondaryBlack' onClick={startTrial}>
              {formatMessage({ id: 'accountBilling.billing.try.trial' })}
            </Button>
          )}
        </>
      }
    />
  )
}

FreeCard.propTypes = {
  intl: intlShape.isRequired,
  startTrial: PropTypes.func,
  trialUsed: PropTypes.bool,
}

FreeCard.defaultProps = {}

export default injectIntl(FreeCard)
