import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { injectStripe } from 'react-stripe-elements'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import { PLAN_PRICE_INTERVALS } from 'packages/constants'
import {
  // PAYMENT_STEPPER_TYPES,
  PLAN_TYPE,
  PAYMENT_TYPE,
  // EU_COUNTRIES_CODE,
} from 'packages/enum'
import { getPlansByInterval } from '../../helpers'
import { useLazyQuery } from 'react-apollo'
import billingExchangeRateGet from '@graphql/gql/website/billingExchangeRateGet.gql'
// import AddressContainer from "@configurator/containers/billing/AddressContainer";
import { Radio } from 'packages/components/inputs'
import { CheckIcon } from 'packages/components/icons'
import {
  ModalContainer,
  Header,
  Body,
  TextGray,
  Step,
  StepTitle,
  StepContent,
  PlanSelectors,
  PlanSelector,
  RadioContainer,
  PaymentContainer,
  ButtonsContainer,
  ButtonContainer,
  StyledButton,
  SavedForYear,
  RadioWrapper,
  IntervalSelectors,
  IntervalSelector,
  IntervalRenewsInfo,
  IntervalTitle,
  SelectorLabel,
} from './nodes'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'
import { useMediaQuery } from 'packages/helpers/useMediaQuery'

const PaymentByPlan = ({
  plans,
  toType,
  open,
  onClose,
  billingCreateCheckoutSession,
  stripe,
  paymentByInvoiceAllowed,
  intl: { formatMessage },
}) => {
  const [loadExchangeRates, { data }] = useLazyQuery(billingExchangeRateGet)
  const [selectedPlanId, setSelectedPlanId] = useState()
  const [exchangeRates, setExchangeRates] = useState()
  const [selectedPlanType, setSelectedPlanType] = useState(toType)
  // const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD)
  const [isMonthlyPlan, setIsMonthlyPlan] = useState(true)
  // const [currency, setCurrency] = useState()
  // const addressFormRef = useRef();
  const isMobile = useMediaQuery('(max-width:480px)')

  // const currentCountryData = useMemo(() =>
  //     filter(countriesList, country => country.id === countryId)[0], [countriesList, countryId]);

  // const userLanguage = window?.navigator.language.toUpperCase();
  const isEUCountry = true // has(EU_COUNTRIES_CODE, currentCountryData ? currentCountryData?.shortName : userLanguage);

  // const readyToPaymentUSD = exchangeRates && exchangeRates[CURRENCY.USD] && !isEUCountry

  // const withConvertedPrice = useCallback(
  //   (plan) => {
  //     let price = currencyConversion(plan?.price, exchangeRates)
  //     return {
  //       ...plan,
  //       price,
  //     }
  //   },
  //   [exchangeRates]
  // )

  const plansByInterval = useMemo(() => {
    // if (readyToPaymentUSD) {
    //   const convertedPlans = map(plans, (plan) => {
    //     return withConvertedPrice(plan)
    //   })
    //   return getPlansByInterval(convertedPlans)
    // }
    return getPlansByInterval(plans)
  }, [plans])

  const plan = plansByInterval[selectedPlanType]
  const monthPlan = plan.month
  const yearPlan = plan.year

  const periodPlans = useMemo(
    () =>
      filter(
        orderBy(
          map(plansByInterval, (el) => {
            if (isMonthlyPlan) {
              return el.month
            } else {
              if (!el.hasOwnProperty('year')) {
                return null
              }
              return {
                ...el.year,
              }
            }
          }),
          'price'
        ),
        (plan) => plan !== null
      ),
    [isMonthlyPlan, plansByInterval]
  )

  const savedForYear = useMemo(() => {
    if (!plan.month?.price) return
    return Math.floor(plan.month.price * 12 - plan.year?.price)
  }, [plan])

  useEffect(() => {
    if (!isEUCountry) {
      if (!exchangeRates) {
        loadExchangeRates()
      }
    }
  }, [exchangeRates, loadExchangeRates, isEUCountry])

  // useEffect(() => {
  //   if (readyToPaymentUSD) {
  //     setCurrency(CURRENCY.USD)
  //   } else {
  //     setCurrency(CURRENCY.EUR)
  //   }
  // }, [readyToPaymentUSD])

  useEffect(() => {
    if (data) {
      const {
        billingExchangeRateGet: {
          data: { rates },
        },
      } = data
      setExchangeRates(rates)
    }
  }, [data])

  useEffect(() => {
    setSelectedPlanId(isMonthlyPlan ? monthPlan?.id : yearPlan?.id)
  }, [isMonthlyPlan, monthPlan, yearPlan])

  useEffect(() => {
    const plansById = keyBy(plans, 'id')
    const selectedPlan = plansById[selectedPlanId]
    if (selectedPlan) {
      setSelectedPlanType(selectedPlan.planType)
    }
  }, [plans, selectedPlanId])

  useEffect(() => {
    setSelectedPlanType(toType)
  }, [toType])

  const handleRedirectToPayment = async () => {
    ANALYTICS.billingThirdStep()
    const res = await billingCreateCheckoutSession({
      priceId: selectedPlanId,
      clientReferenceId: null,
    })
    const sessionId = get(res, 'data.billingCreateCheckoutSession.data.sessionId')
    sessionId && stripe && stripe.redirectToCheckout({ sessionId })
  }

  // const handleRedirectToInvoice = async () => {
  //     ANALYTICS.billingThirdStep();
  //     if (type === PAYMENT_STEPPER_TYPES.BILLING_UPGRADE) {
  //         await billingUpgradeTrialByInvoice({
  //             priceId: selectedPlanId,
  //         });
  //     } else {
  //         await billingCreateSubscriptionByInvoice({
  //             priceId: selectedPlanId,
  //         });
  //     }
  //     onClose();
  // };

  const handlePlanSelect = (planId) => {
    setSelectedPlanId(planId)
  }

  const handlePay = async (/*type*/) => {
    // setSelectedPaymentType(type)

    await handleRedirectToPayment()
    // addressFormRef.current.handleSubmit();
  }

  const getPlanTypeTextName = (planType) => {
    if (planType === PLAN_TYPE.PRO) {
      return formatMessage({ id: 'subscription.plan.pro.header' })
    } else if (planType === PLAN_TYPE.PROMAX) {
      return formatMessage({ id: 'subscription.plan.promax.header' })
    } else if (planType === PLAN_TYPE.PROLITE) {
      return formatMessage({ id: 'subscription.plan.light.header' })
    }
    return planType
  }

  // const title =
  //     type === PAYMENT_STEPPER_TYPES.BILLING_UPGRADE
  //         ? formatMessage(
  //             { id: "subscription.modal.title.upgrade" }
  //         )
  //         : formatMessage({ id: "subscription.modal.title.trialEnded" });
  return (
    <ModalContainer
      open={open}
      onClose={() => onClose()}
      styles={{ width: '580px', height: 'initial' }}
      mobileWide
    >
      <Header>
        {formatMessage({
          id: 'subscription.modal.step.2.title',
        })}
      </Header>
      <Body>
        {/* <Step>
                    <StepContent>
                        <AddressContainer
                            formRef={addressFormRef}
                            redirectToPayment={handleRedirectToPayment}
                            redirectToInvoice={handleRedirectToInvoice}
                            paymentType={selectedPaymentType}
                            isUpgrade
                        />
                    </StepContent>
                </Step> */}
        <Step>
          {/* <StepTitle>
                        <span>
                            {formatMessage({
                                id: "subscription.modal.step.2.title"
                            })}
                        </span>
                    </StepTitle> */}
          <StepContent>
            <PlanSelectors>
              {periodPlans.map(({ id, interval, price: planPrice, planType, currency }, index) => {
                const isSelected = id === selectedPlanId
                const isYear = interval === PLAN_PRICE_INTERVALS.year
                const price = isYear ? (planPrice / 12).toFixed(2) : Number(planPrice).toFixed(2)

                return (
                  <PlanSelector
                    key={index}
                    selected={isSelected}
                    isLight={false}
                    onClick={() => handlePlanSelect(id)}
                  >
                    {isMobile ? (
                      <>
                        <SelectorLabel>{getPlanTypeTextName(planType)}</SelectorLabel>
                        <PaymentContainer>
                          <b
                            dangerouslySetInnerHTML={{
                              __html: formatMessage(
                                {
                                  id: 'subscription.modal.plan.price.title',
                                },
                                {
                                  currency: currency.toUpperCase(),
                                  price,
                                }
                              ),
                            }}
                          />
                        </PaymentContainer>
                        <RadioContainer>
                          <RadioWrapper selected={isSelected}>
                            <Radio checked={isSelected} radio />
                            <CheckIcon color='white' />
                          </RadioWrapper>
                        </RadioContainer>
                      </>
                    ) : (
                      <>
                        <RadioContainer>
                          <SelectorLabel>{getPlanTypeTextName(planType)}</SelectorLabel>
                          <RadioWrapper selected={isSelected}>
                            <Radio checked={isSelected} radio />
                            <CheckIcon color='white' />
                          </RadioWrapper>
                        </RadioContainer>
                        <PaymentContainer>
                          <b
                            dangerouslySetInnerHTML={{
                              __html: formatMessage(
                                {
                                  id: 'subscription.modal.plan.price.title',
                                },
                                {
                                  currency: currency.toUpperCase(),
                                  price,
                                }
                              ),
                            }}
                          />
                        </PaymentContainer>
                      </>
                    )}
                  </PlanSelector>
                )
              })}
            </PlanSelectors>

            {isMobile && (
              <StepTitle style={{ marginTop: '25px' }}>
                <span>
                  {formatMessage({
                    id: 'subscription.modal.step.3.title.mobile',
                  })}
                </span>
              </StepTitle>
            )}
            <IntervalSelectors>
              <IntervalSelector selected={isMonthlyPlan} onClick={() => setIsMonthlyPlan(true)}>
                <RadioContainer>
                  <b>
                    {formatMessage({
                      id: 'billing.interval.monthly',
                    })}
                  </b>
                  <RadioWrapper selected={isMonthlyPlan}>
                    <Radio checked={isMonthlyPlan} radio />
                    <CheckIcon color='white' />
                  </RadioWrapper>
                </RadioContainer>
                <IntervalRenewsInfo>
                  {formatMessage({
                    id: 'billing.interval.monthly.renews.info',
                  })}
                </IntervalRenewsInfo>
              </IntervalSelector>

              <IntervalSelector selected={!isMonthlyPlan} onClick={() => setIsMonthlyPlan(false)}>
                <RadioContainer>
                  <IntervalTitle>
                    <b>
                      {formatMessage({
                        id: 'billing.interval.yearly',
                      })}
                    </b>
                    {!!savedForYear && (
                      <SavedForYear>
                        {formatMessage(
                          {
                            id: 'subscription.modal.plan.price.savedMoneyInfo',
                          },
                          {
                            currency: periodPlans.length ? periodPlans[0].currency : '',
                            savedForYear: savedForYear,
                          }
                        )}
                      </SavedForYear>
                    )}
                  </IntervalTitle>
                  <RadioWrapper selected={!isMonthlyPlan}>
                    <Radio checked={!isMonthlyPlan} radio />
                    <CheckIcon color='white' />
                  </RadioWrapper>
                </RadioContainer>
                <IntervalRenewsInfo>
                  {formatMessage({
                    id: 'billing.interval.yearly.renews.info',
                  })}
                </IntervalRenewsInfo>
              </IntervalSelector>
            </IntervalSelectors>

            <TextGray>
              {formatMessage({
                id: 'billing.local.price',
              })}
            </TextGray>
            <ButtonsContainer>
              <StyledButton
                content={formatMessage({
                  id: 'subscription.modal.plan.button.payByCard.text',
                })}
                onClick={() => handlePay(PAYMENT_TYPE.CREDIT_CARD)}
                fullWidth={true}
              />
              {paymentByInvoiceAllowed ? (
                <ButtonContainer>
                  <p>
                    {formatMessage({
                      id: 'subscription.modal.plan.button.payByInvoice.desc',
                    })}
                  </p>
                  <StyledButton
                    view='secondaryBlack'
                    content={formatMessage({
                      id: 'subscription.modal.plan.button.payByInvoice.text',
                    })}
                    onClick={() => handlePay(PAYMENT_TYPE.INVOICE)}
                  />
                </ButtonContainer>
              ) : null}
            </ButtonsContainer>
          </StepContent>
        </Step>
      </Body>
    </ModalContainer>
  )
}

PaymentByPlan.propTypes = {
  intl: intlShape.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  marketplaceSettingValid: PropTypes.bool.isRequired,
  billingCreateCheckoutSession: PropTypes.func.isRequired,
  billingUpgradeTrialByInvoice: PropTypes.func.isRequired,
  billingCreateSubscriptionByInvoice: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  paymentByInvoiceAllowed: PropTypes.bool.isRequired,
  plans: PropTypes.array,

  toType: PropTypes.oneOf(Object.values(PLAN_TYPE)),
  countryId: PropTypes.number,
  countriesList: PropTypes.array,
}

PaymentByPlan.defaultProps = {
  plans: [],
  toType: PLAN_TYPE.PRO,
}

export default injectIntl(injectStripe(PaymentByPlan))
