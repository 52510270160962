import React from 'react'
import { injectIntl, intlShape } from 'react-intl'

import { HeaderStyled } from 'packages/components/layout/nodes'
import {
  Container,
  Header,
  Teaser,
  TeaserText,
  Logo,
  FormWrapper,
  Grid,
  FooterText,
  Notes,
  StyledTextareaContainerLabel,
  StyledTextareaContainer,
  NoteHeader,
  Step,
  StepContent,
  StepNumber,
  StepWrapper,
  Paragraph,
  TipsHeader,
  TipsContent,
  VideoLink,
} from '../nodes'
import Image from 'packages/components/gatsby-image'
import ecwid from '../../assets/ecwid.png'

import { Button } from 'packages/components/buttons'
import { Semantic } from 'packages/components'
import { FormTextArea } from 'packages/components/inputs'

import { wrapper } from '../../../../containers/integrations/IntegrationsDetails'

const Ecwid = ({
  handleSubmit,
  errors,
  status,
  isSubmitting,
  loading,
  intl: { formatMessage },
}) => {
  const error = Object.keys(errors).length || (status && !!status.error)

  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header>
          {formatMessage({
            id: 'integration.ecwid.name',
          })}
        </Header>
        <Teaser>
          <Logo>
            <Image
              fluid={{
                src: ecwid,
                aspectRatio: 446 / 114,
              }}
              alt=''
              fadeIn
              imgStyle={{ objectFit: 'contain' }}
              loading='lazy'
              backgroundColor='white'
            />
          </Logo>
          <TeaserText>
            {formatMessage({
              id: 'integration.ecwid.slogan',
            })}
          </TeaserText>
          <div>
            <Button
              as='a'
              href={'http://open.ecwid.com/3rq3FR'}
              target='_blank'
              content={formatMessage({
                id: 'integration.start.free',
              })}
            />
          </div>
        </Teaser>
        <Grid>
          <div>
            <FormWrapper>
              <Semantic.Form
                noValidate
                error={error}
                loading={isSubmitting || loading}
                onSubmit={handleSubmit}
              >
                <StyledTextareaContainerLabel>
                  {formatMessage({
                    id: 'integration.footer.code',
                  })}
                </StyledTextareaContainerLabel>

                <StyledTextareaContainer>
                  <FormTextArea
                    name='content'
                    placeholder={formatMessage({
                      id: 'integration.ecwid.code.here',
                    })}
                  />
                </StyledTextareaContainer>
                <Button
                  type='submit'
                  content={formatMessage({
                    id: 'integration.save',
                  })}
                  disabled={isSubmitting}
                />
              </Semantic.Form>
            </FormWrapper>

            <FooterText>{formatMessage({ id: 'integration.ecwid.join' })}</FooterText>
            <FooterText>{formatMessage({ id: 'integration.ecwid.setup' })}</FooterText>
            <FooterText>
              {formatMessage({
                id: 'integration.ecwid.forever.free',
              })}
            </FooterText>
          </div>
          <div>
            <Notes>
              <NoteHeader>
                {formatMessage({
                  id: 'integration.ecwid.add',
                })}
              </NoteHeader>
              <StepWrapper>
                <Step>
                  <StepNumber>
                    <div>
                      <span>1</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: 'integration.ecwid.note.1',
                          }),
                        }}
                      />
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>2</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integration.ecwid.note.2',
                      })}
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>3</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integration.ecwid.note.3',
                      })}
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>4</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integration.ecwid.note.4',
                      })}
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>5</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integration.ecwid.note.5',
                      })}

                      <VideoLink href={'https://vimeo.com/583278750'} target={'_blank'}>
                        {formatMessage({
                          id: 'integration.watch.tutorial',
                        })}
                      </VideoLink>
                    </Paragraph>
                  </StepContent>
                </Step>
              </StepWrapper>
            </Notes>

            <div>
              <TipsHeader>
                {formatMessage({
                  id: 'integration.ecwid.tips',
                })}
              </TipsHeader>
              <TipsContent
                href={'https://support.ecwid.com/hc/en-us/articles/207100559-Digital-products'}
                target={'_blank'}
              >
                {formatMessage({
                  id: 'integration.ecwid.help.1',
                })}
              </TipsContent>
              <TipsContent
                href={'https://support.ecwid.com/hc/en-us/articles/360007581079-Selling-services'}
                target={'_blank'}
              >
                {formatMessage({
                  id: 'integration.ecwid.help.2',
                })}
              </TipsContent>
              <TipsContent
                href={
                  'https://support.ecwid.com/hc/en-us/articles/115001353329-Printful-Dropshipping-for-custom-printed-products'
                }
                target={'_blank'}
              >
                {formatMessage({
                  id: 'integration.ecwid.help.3',
                })}
              </TipsContent>
              <TipsContent
                href={
                  'https://support.ecwid.com/hc/en-us/articles/115005902569-Adding-products-to-your-store'
                }
                target={'_blank'}
              >
                {formatMessage({
                  id: 'integration.ecwid.help.4',
                })}
              </TipsContent>
              <TipsContent href={'https://support.ecwid.com/hc/en-us'} target={'_blank'}>
                {formatMessage({
                  id: 'integration.ecwid.help.5',
                })}
              </TipsContent>
            </div>
          </div>
        </Grid>
      </Container>
    </>
  )
}

Ecwid.propTypes = {
  intl: intlShape.isRequired,
}

Ecwid.defaultProps = {}

export default wrapper(injectIntl(Ecwid), 'ecwid')
