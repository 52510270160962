import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import { withRouter } from 'react-router'
import * as Yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import {
  open as openDiscardChangesModal,
  close as closeDiscardChangesModal,
} from 'packages/redux/modules/modalConfirmDelete/actions'
import { connect } from 'react-redux'

import { MESSAGE_TYPE } from 'packages/enum'

import websiteScriptUpdate from '@graphql/gql/website/websiteScriptUpdate.gql'
import websiteScriptGet from '@graphql/gql/website/websiteScriptGet.gql'
import Custom from '@configurator/components/integrations/details/custom/Custom'

const validationSchema = Yup.object().shape({
  header: Yup.string().nullable(),
  footer: Yup.string().nullable(),
})

export default compose(
  withRouter,
  connect(
    (state) => ({
      websiteId: state.getIn(['preview', 'meta', 'id']),
    }),
    (dispatch) => ({
      openDiscardChangesModal: ({ subMessageId, yesMessageId, hideHeader, onClickYes, styles }) =>
        dispatch(
          openDiscardChangesModal({
            subMessageId,
            yesMessageId,
            hideHeader,
            onClickYes,
            styles,
          })
        ),
      closeDiscardChangesModal: () => dispatch(closeDiscardChangesModal()),
    })
  ),
  graphql(websiteScriptGet, {
    options() {
      return {
        variables: {
          type: 'custom-footer',
        },
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websiteScriptGet } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(websiteScriptGet)) {
        return
      }
      const { data } = websiteScriptGet

      return {
        footer: data.content,
      }
    },
  }),
  graphql(websiteScriptGet, {
    options() {
      return {
        variables: {
          type: 'custom-header',
        },
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websiteScriptGet } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(websiteScriptGet)) {
        return
      }
      const { data } = websiteScriptGet

      return {
        header: data.content,
      }
    },
  }),
  graphql(websiteScriptUpdate, {
    props({ mutate }) {
      return {
        async scriptUpdate({ type, content, scriptPosition, websiteId }) {
          try {
            return await mutate({
              variables: {
                type,
                content,
                scriptPosition,
                websiteId,
              },
              refetchQueries: [
                {
                  query: websiteScriptGet,
                  variables: {
                    type,
                  },
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: () => {
      return {
        header: '',
        footer: '',
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      { footer, header },
      { setSubmitting, setStatus, props: { scriptUpdate, websiteId } }
    ) => {
      let defaultError = ''
      try {
        const res = await scriptUpdate({
          type: 'custom-footer',
          content: footer,
          scriptPosition: 'BODY',
          websiteId,
        })

        await scriptUpdate({
          type: 'custom-header',
          content: header,
          scriptPosition: 'HEADER',
          websiteId,
        })
        setSubmitting(false)
        let {
          data: { websiteScriptUpdate: { errors, success } = {} },
        } = res
        if (success) {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )
          return
        }
        setStatus({ error: errors._error || defaultError })
      } catch (err) {
        setSubmitting(false)
        setStatus({
          error: __PRODUCTION__ ? defaultError : err.toString(),
        })
      }
    },
  })
)(Custom)
