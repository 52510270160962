/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const EyeIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.21444 13.0604C4.10377 12.8909 4.04843 12.8062 4.01745 12.6755C3.99418 12.5774 3.99418 12.4226 4.01745 12.3245C4.04843 12.1938 4.10377 12.1091 4.21444 11.9396C5.12902 10.5395 7.85134 7 12 7C16.1487 7 18.871 10.5395 19.7856 11.9396C19.8962 12.1091 19.9516 12.1938 19.9825 12.3245C20.0058 12.4226 20.0058 12.5774 19.9825 12.6755C19.9516 12.8062 19.8962 12.8909 19.7856 13.0604C18.871 14.4605 16.1487 18 12 18C7.85134 18 5.12902 14.4605 4.21444 13.0604Z'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 14.8571C13.3465 14.8571 14.438 13.8018 14.438 12.5C14.438 11.1982 13.3465 10.1429 12 10.1429C10.6535 10.1429 9.56201 11.1982 9.56201 12.5C9.56201 13.8018 10.6535 14.8571 12 14.8571Z'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

EyeIcon.propTypes = {
  ...iconColorPropTypes,
}

EyeIcon.defaultProps = {
  color: 'dark',
}
