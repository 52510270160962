import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 30px;
  text-align: center;
`

export const Content = styled.div`
  justify-content: center;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > span {
    margin-bottom: 25px;
    text-align: center;
    line-height: 1.7em;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    width: 100%;
    margin-bottom: 12px !important;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
`
