import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-apollo'

import { Verified } from 'packages/components/icons/verified'
import useFocusInputs from '@configurator/hooks/useFocusInputs'
import ConfirmEmailBanner from '@configurator/components/banners/ConfirmEmailBanner'
import billingAccountGetQuery from '@graphql/gql/website/billingAccountGet.gql'
import sendVerificationCode from '@graphql/gql/common/sendVerificationCode.gql'
import applyVerificationCode from '@graphql/gql/common/applyVerificationCode.gql'
import billingSubscriptionsGet from '@graphql/gql/website/billingSubscriptionsGet.gql'

import ResendCode from './components/ResendCode'

import { Content, ErrorText, InputCode, StyledModal, Text, Title, WrapperFooter } from './nodes'

const ModalEmailVerification = ({ intl: { formatMessage } }) => {
  const [isVerified, setIsVerified] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState('')

  const { data: { billingAccountGet } = {} } = useQuery(billingAccountGetQuery)

  const [sendCode] = useMutation(sendVerificationCode)
  const [applyCode] = useMutation(applyVerificationCode, {
    refetchQueries: [{ query: billingAccountGetQuery }, { query: billingSubscriptionsGet }],
  })

  const { inputRefs, values, handleChange, onKeyUp, resetInputs } = useFocusInputs('', 4)

  const email = billingAccountGet?.data?.email

  const hasCode = values?.every((elem) => elem !== '')

  useEffect(() => {
    let timerId

    const fetchApplyCode = async () => {
      if (hasCode) {
        const {
          data: { applyVerificationCode },
        } = await applyCode({
          variables: {
            code: values.join(''),
          },
        })

        if (!applyVerificationCode?.success) {
          resetInputs()

          setError(
            applyVerificationCode?.errors?._error?.length
              ? applyVerificationCode?.errors?._error
              : 'error.desc'
          )
        }

        if (applyVerificationCode?.success) {
          setIsVerified(true)

          timerId = setTimeout(handleClose, 2000)
        }
      }
    }

    fetchApplyCode()

    return () => {
      clearTimeout(timerId) // Clear setTimeout after unmount.
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCode])

  const handleOpen = async () => {
    setOpen(true)

    await sendCode()
  }

  const handleClose = () => {
    setOpen(false)

    setError('')
  }

  const handleResendCode = async () => await sendCode()

  return (
    <>
      <ConfirmEmailBanner handleOpen={handleOpen} />

      <StyledModal open={open} onClose={handleClose} dimmer='blurring'>
        {!isVerified ? (
          <Content>
            <Title>
              {formatMessage({
                id: 'preview.banner.confirmEmail.modal.title',
              })}
            </Title>

            <Text>
              {formatMessage({
                id: 'preview.banner.confirmEmail.modal.text',
              })}
            </Text>

            <Text>
              {formatMessage({
                id: 'preview.banner.confirmEmail.modal.email',
              })}
              <b> {email}</b>
            </Text>

            <WrapperFooter>
              {values?.map((value, index) => (
                <InputCode
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  value={value}
                  onChange={handleChange(index)}
                  onKeyUp={onKeyUp(index)}
                />
              ))}

              <ResendCode onResend={handleResendCode} />
            </WrapperFooter>

            <ErrorText>
              {error?.length ? (
                <p>
                  {formatMessage({
                    id: error,
                  })}
                </p>
              ) : null}
            </ErrorText>
          </Content>
        ) : (
          <Content isVerified>
            <Verified />

            <Title>
              {formatMessage({
                id: 'preview.banner.confirmEmail.modal.verified',
              })}
            </Title>
          </Content>
        )}
      </StyledModal>
    </>
  )
}

export default injectIntl(ModalEmailVerification)
