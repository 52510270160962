import styled from 'styled-components'

import { Modal } from 'packages/components/modal'

export const CenteredDropModal = styled(Modal)`
  margin: auto !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const UploadWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;

  .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    color: #666a73;
    font-size: 13px;
    line-height: 140%;
    padding: 15px;

    background: #f6f6f6;
    border: 1px dashed #e1e2e6;
    border-radius: 4px;

    .dropzone-header {
      color: #343840;
      font-size: 24px;
      font-weight: bold;
      line-height: 150%;
      margin: 18px 0 0;
      text-align: center;

      > span {
        color: #666a73;
        font-size: 13px;
        line-height: 140%;
        display: block;
        font-weight: normal;
      }
    }

    .dropzone-footer {
      text-align: center;
      position: absolute;
      bottom: 15px;
    }

    &:focus {
      outline-color: rgba(0, 0, 0, 0) !important;
    }
  }
`
