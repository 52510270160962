import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export const views = {
  primary: {
    background: '#58C367',
    backgroundHover: '#34CF49',
    text: 'white',
    textHover: 'white',
    border: '1px solid #58C367',
    borderHover: '1px solid #34CF49',
  },
  primaryText: {
    background: 'initial',
    border: 'none',
    text: '#2EBA76',
    textHover: '#29A76A',
    textDecoration: 'underline',
    padding: 0,
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: '300',
  },
  primaryGray: {
    background: '#EDEDED',
    backgroundHover: '#D3D3D3',
    text: '#343840',
    textHover: '#343840',
    border: 'none',
    borderHover: 'none',
  },
  primaryRed: {
    background: '#FF002E',
    backgroundHover: '#E2042D',
    text: 'white',
    textHover: 'white',
    border: 'none',
    borderHover: 'none',
  },
  secondaryBlack: {
    background: '#343840',
    backgroundHover: '#666A73',
    text: 'white',
    textHover: 'white',
    border: '1px solid #343840',
    borderHover: '1px solid #666A73',
  },
  secondaryGray: {
    background: '#EDEDED',
    backgroundHover: '#F6F6F6',
    text: '#343840',
    textHover: '#343840',
    border: '1px solid #EDEDED',
    borderHover: '1px solid #F6F6F6',
  },
  outline: {
    background: 'white',
    backgroundHover: '#58C367',
    text: '#58C367',
    textHover: 'white',
    border: '1px solid #58C367',
    borderHover: '1px solid #58C367',
  },
  secondaryWhite: {
    background: '#fff',
    backgroundHover: '#fff',
    text: '#343840',
    textHover: '#343840',
    border: '1px solid #fff',
    borderHover: '1px solid #fff',
  },
}

export const viewPropType = PropTypes.oneOf(Object.keys(views))

export const StyledButton = styled(Button)`
  font-family: Sailec, sans-serif !important;
  font-style: normal !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  //box-sizing: border-box !important;
  margin: 0 !important;

  border-radius: 4px !important;

  line-height: ${(props) => views[props.view].lineHeight ?? 'normal'} !important;
  font-weight: ${(props) => views[props.view].fontWeight ?? '140%'} !important;
  font-size: ${(props) => views[props.view].fontSize ?? '13px'} !important;
  padding: ${(props) => views[props.view].padding ?? '14px 25px'} !important;
  color: ${(props) => views[props.view].text} !important;
  border: ${(props) => views[props.view].border} !important;
  background: ${(props) => views[props.view].background} !important;
  text-decoration: ${(props) => views[props.view].textDecoration} !important;

  &:hover {
    color: ${(props) => views[props.view].textHover} !important;
    border: ${(props) => views[props.view].borderHover} !important;
    background: ${(props) => views[props.view].backgroundHover} !important;
  }

  &:active {
    transform: translateY(3px) !important;
  }

  & > svg {
    margin-left: 11px;
  }
`
