import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid rgb(237, 237, 237);
  background: #fff;
  padding: 30px 40px;
  border-radius: 5px;

  @media (max-width: 375px) {
    padding: 20px;
  }
`

export const Header = styled.div`
  color: #343840;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 140%;

  @media (max-width: 375px) {
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;
  }

  > span {
    color: #666a73;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }

  > button {
    color: #c1c3c7;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
