import { withFormik } from 'formik'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'redux'

import websitePhotoListDelete from '@graphql/gql/website/websitePhotoListDelete.gql'
import get from 'lodash/get'
import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'

import PhotosTab from '@configurator/components/slideshow/PhotosTab'
import { isEmpty } from 'lodash'
import throttle from 'lodash/throttle'
import { FEATURE_PROPERTY_KEYS } from 'packages/enum'

import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'

import {
  UPDATE_FEATURE_PROPERTIES,
  UPDATE_PREVIEW_META_DATA,
  UPDATE_PREVIEW_STRUCTURE_DATA,
} from '@configurator/constants/Preview'
import { changeTemplatesColor } from '@configurator/redux/modules/templatesColor/actions'
import featureResetFeaturesProp from '@graphql/gql/website/featureResetFeaturesProp.gql'
import featureResetTemplate from '@graphql/gql/website/featureResetTemplate.gql'
import featureUpdate from '@graphql/gql/website/featureUpdate.gql'
import featureUpdateWithPhoto from '@graphql/gql/website/featureUpdateWithPhoto.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import { createAction } from 'redux-actions'

export const slideshowPhotosWrapper = (Component) =>
  compose(
    connect(
      // mapStateToProps
      (state) => ({
        uploads: state.getIn(['uploadPhotos', 'uploads']),
      }),
      // mapDispatchToProps
      (dispatch) => ({
        updateMeta: throttle(
          (payload) => dispatch(createAction(UPDATE_PREVIEW_META_DATA)(payload)),
          32
        ),
        updateStructure: throttle(
          (payload) => dispatch(createAction(UPDATE_PREVIEW_STRUCTURE_DATA)(payload)),
          32
        ),
        updateFeatureProperties: (payload) =>
          dispatch(createAction(UPDATE_FEATURE_PROPERTIES)(payload)),
        loaderStart: ({ content } = {}) => dispatch(loaderStart({ content })),
        loaderStop: () => dispatch(loaderStop()),
        changeTemplatesColor: (payload) => dispatch(changeTemplatesColor(payload)),
        lockModalProgress: () => dispatch(lockModal(true)),
        unLockModalProgress: () => dispatch(lockModal(false)),
        openErrorModal: ({
          headerMessageId,
          yesMessageId,
          subMessageId,
          hideCancelButton,
          subMessageValues,
          onClickYes,
          hideSubHeader,
          hideHeader,
          size,
          styles,
        }) =>
          dispatch(
            openErrorModal({
              headerMessageId,
              yesMessageId,
              subMessageId,
              hideCancelButton,
              subMessageValues,
              onClickYes,
              hideSubHeader,
              hideHeader,
              size,
              styles,
            })
          ),
        closeErrorModal: () => dispatch(close()),
      })
    ),
    graphql(websitePhotoListDelete, {
      props({ mutate, ownProps: { openErrorModal } }) {
        return {
          async removeAllPhotos({ photoIds }) {
            try {
              const res = await mutate({
                variables: {
                  photoIds,
                },
              })
              if (get(res, 'data.websitePhotoListDelete.errors._error')) {
                openErrorModal({
                  headerMessageId: 'slideshow.removeAll.error.header',
                  yesMessageId: 'slideshow.removeAll.error.ok',
                  subMessageId: get(res, 'data.websitePhotoListDelete.errors._error'),
                  hideCancelButton: true,
                })
              }
              return res
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(featureUpdateWithPhoto, {
      props({ mutate }) {
        return {
          async featureUpdateWithPhoto({ featureId, file, propertyName }) {
            try {
              return await mutate({
                variables: {
                  featureId,
                  file,
                  propertyName,
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(featureUpdate, {
      props({ mutate }) {
        return {
          async featureUpdateFooter({ featureId, properties, enabled }) {
            try {
              return await mutate({
                variables: {
                  featureId,
                  properties,
                  enabled,
                },
              })
            } catch (err) {
              return {
                data: { featureUpdate: { errors: err.message, success: false } },
              }
            }
          },
        }
      },
    }),
    graphql(featureResetTemplate, {
      props({ mutate }) {
        return {
          async featureResetTemplate({ websiteId }) {
            try {
              return await mutate({
                variables: {
                  websiteId,
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(featureResetFeaturesProp, {
      props({ mutate }) {
        return {
          async featureResetFeaturesProp({ featurePropertyNames, featureId, domain }) {
            try {
              return await mutate({
                variables: {
                  featureId,
                  featurePropertyNames,
                  domain,
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(previewPageGet, {
      options() {
        // withRouter использовать нельзя,т.к. компонент прикручен выше в роутинге, чем параметры
        let pageId = ''
        const roots = `${window.location.href}`.split('/')
        const pageIndex = roots.findIndex((root) => root === 'page' || root === 'blocks')
        if (pageIndex >= 0) {
          pageId = roots[pageIndex + 1]
        }
        return {
          variables: {
            id: pageId ? decodeURIComponent(pageId) : undefined,
          },
          fetchPolicy: 'network-only',
        }
      },
      props({
        data: { loading, previewPageGet, refetch },
        ownProps: { updateStructure, updateMeta },
      }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(previewPageGet)) {
          return { loading }
        }
        const { data } = previewPageGet
        // update preview
        setTimeout(() => {
          updateMeta(data.meta)
          updateStructure(data.structure)
        })

        const features = get(data, 'meta.features', [])
        const socialLinks = get(data, 'meta.socialLinks', [])

        return {
          footerOverSlideshow: features.find(
            (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.FOOTER_OVER_SLIDESHOW
          ),
          footerHidden: features.find(
            (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.FOOTER_HIDDEN
          ),
          scrollableHeader: features.find(
            (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER
          ),
          blocks: data.structure,
          featureData:
            features.find((feature) => feature.featureName === FEATURE_PROPERTY_KEYS.style) || {},
          loaderData:
            [
              features.find(
                (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_CUSTOM
              ),
              features.find(
                (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_VARIANT
              ),
              features.find(
                (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_COLOR
              ),
            ] || [],
          refetchPagePreview: refetch,
          websiteId: get(data, 'meta.id'),
          domain: get(data, 'meta.domain'),
          meta: get(data, 'meta'),
          templateParams: {
            template: get(data, 'meta.templateName'),
            align: get(data, 'meta.align')?.toUpperCase(),
            inverted: get(data, 'meta.inverted'),
            slideshowEnabled: get(data, 'meta.slideshowEnabled'),
          },
          socialLinks,
        }
      },
    }),
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        return {
          ...initialValues,
        }
      },
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: false,
    })
  )(Component)

export default slideshowPhotosWrapper(PhotosTab)
