import { Semantic } from 'packages/components'
import styled from 'styled-components'
import { media } from 'packages/components/media'

const CARET_HOVER_COLOR = '#343840'
const BACKGROUND_HOVER_COLOR = '#EDEDED'

export const AccordionTitle = styled(Semantic.Accordion.Title)`
  position: relative;
  font-weight: normal;
  font-size: 13px;
  color: #343840;
  padding: 0 !important;
  ${(props) => (props.active ? `background: ${BACKGROUND_HOVER_COLOR};` : '')}

  &:hover {
    background: ${BACKGROUND_HOVER_COLOR};
  }

  > span {
    padding: 5px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      ${(props) => (props.active ? 'transform: rotate(180deg);' : '')}
    }

    &:hover svg path {
      fill: ${CARET_HOVER_COLOR};
    }
  }
  ${media.tablet`
       margin-left: 10px;
       > span {
         right: 29px;
       }
    `}
  ${media.laptop`
       margin-left: 0;
       > span {
          right: 28px;
       }
    `}
`

export const AccordionContent = styled(Semantic.Accordion.Content)`
  position: relative;
  padding: 0 !important;
`
