import styled, { css } from 'styled-components'
import { FormInput } from 'packages/components/inputs'
import { Position } from '../consts'

export const Root = styled.div`
  &:not(:first-child) {
    padding-top: 2px;
  }

  &:not(:last-child) {
    padding-bottom: 2px;
  }
`

export const Wrapper = styled.div`
  border: ${(props) => (props.$isDragging ? '1px dashed #cfd1d4' : '1px solid #ededed')};
  border-radius: 8px;
  background-color: ${(props) => !props.$isDragging && '#fff'};
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px;

  ${(props) =>
    props.$isDragging &&
    css`
      opacity: 0;
    `}
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
`

export const SocialIcon = styled.div`
  display: flex;
  ${(props) =>
    props.$isShadow &&
    css`
      & > svg {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
      }
    `}
`

export const DragIndicatorIconButton = styled.div`
  display: inline-flex;
  color: #c1c3c7;
  cursor: pointer;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: #666a73;
  }
`

export const Name = styled.span`
  color: #242426;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
`

export const DeleteIconButton = styled.div`
  display: inline-flex;
  padding: 4px;
  margin-right: -4px;
  color: #9fa1a6;
  cursor: pointer;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: #666a73;
  }
`

export const StyledFormInput = styled(FormInput)`
  &,
  .ui.input {
    width: 100%;
  }
`

export const DropSeparator = styled.div`
  height: 3px;
  background-color: #00c2ff;
  margin-top: ${(props) => (props.$position === Position.Before ? -1.5 : 2.5)}px;
  margin-bottom: ${(props) => (props.$position === Position.Before ? 2.5 : -1.5)}px;
`
