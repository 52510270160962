import React, { useEffect, useState } from 'react'
import { useSubscription } from '@configurator/providers/subscription'
import { Container, CloseIcon } from './nodes'
import { injectIntl } from 'react-intl'
import { Button } from 'packages/components/buttons'
import { ABO_STATUS, URLS } from 'packages/enum'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const TrialEndedBanner = ({ intl: { formatMessage } }) => {
  const {
    info: { aboStatus, downgradedFromFreeManually },
    loading,
  } = useSubscription()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (!loading && aboStatus === ABO_STATUS.TRIAL_ENDED && !downgradedFromFreeManually) {
      setOpen(true)
    }
  }, [setOpen, loading, aboStatus, downgradedFromFreeManually])

  if (!open) {
    return null
  }

  return (
    <Container style={{ background: '#3548f5' }}>
      <div>
        <span
          style={{
            color: 'white',
          }}
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: 'preview.banner.websiteOffline.trialEnded.title' }),
          }}
        />

        <Button
          type='button'
          content={formatMessage({
            id: 'preview.banner.websiteOffline.trialEnded.select.plan',
          })}
          onClick={() => dispatch(push(URLS.website_billing_plan))}
        />
        <CloseIcon onClick={() => setOpen(false)}>
          <span
            style={{
              color: 'white',
            }}
          >
            {formatMessage({
              id: 'unpaid.banner.close',
            })}
          </span>
          <svg
            style={{
              fill: 'white',
            }}
            width='14'
            height='14'
            viewBox='0 0 14 14'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'></path>
          </svg>
        </CloseIcon>
      </div>
    </Container>
  )
}

TrialEndedBanner.propTypes = {}

export default injectIntl(TrialEndedBanner)
