import { useQuery } from 'react-apollo'
import billingSubscriptionsGetQuery from '@graphql/gql/website/billingSubscriptionsGet.gql'
import isEmpty from 'lodash/isEmpty'
import { getActiveSubscription } from 'packages/helpers/Helper'

export function useSubscription() {
  const { data: { loading, billingSubscriptionsGet } = {} } = useQuery(billingSubscriptionsGetQuery)

  if (loading) {
    return { loading }
  }

  if (isEmpty(billingSubscriptionsGet)) {
    return { loading: true }
  }

  const { data, errors } = billingSubscriptionsGet

  if (errors === 'No website found') {
    return {
      template: null,
      subscriptionStatus: null,
      currentPeriodEnd: null,
    }
  }

  const subscription = getActiveSubscription(data)

  return {
    template: subscription.template,
    aboStatus: subscription.aboStatus,
    planType: subscription.planType,
    currentPeriodEnd: subscription.currentPeriodEnd,
    interval: subscription.interval,
    cancelAtPeriodEnd: subscription.cancelAtPeriodEnd,
    trialUsed: data?.trialUsed,
    downgradedFromFreeManually: subscription.downgradedFromFreeManually,
  }
}
