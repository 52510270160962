import { iconColorPropTypes, iconColors } from '../const'

export const Remove = ({ width, height, color }) => {
  return (
    <svg
      width={width ?? 17}
      height={height ?? 16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id='Vector'
        d='M16 8C16 10.1217 15.1572 12.1566 13.6572 13.6569C12.1562 15.1571 10.1221 16 8 16C5.87793 16 3.84375 15.1571 2.34277 13.6569C0.842773 12.1566 0 10.1217 0 8C0 5.87827 0.842773 3.84344 2.34277 2.34314C3.84375 0.842865 5.87793 0 8 0C10.1221 0 12.1562 0.842865 13.6572 2.34314C15.1572 3.84344 16 5.87827 16 8ZM5.35352 4.646C5.25977 4.55212 5.13281 4.49936 5 4.49936C4.86719 4.49936 4.74023 4.55212 4.64648 4.646C4.55176 4.7399 4.49902 4.86722 4.49902 5C4.49902 5.13278 4.55176 5.2601 4.64648 5.354L7.29297 8L4.64648 10.646C4.59961 10.6925 4.5625 10.7477 4.53711 10.8084C4.5127 10.8691 4.49902 10.9343 4.49902 11C4.49902 11.0657 4.5127 11.1309 4.53711 11.1916C4.5625 11.2523 4.59961 11.3075 4.64648 11.354C4.74023 11.4479 4.86719 11.5006 5 11.5006C5.06543 11.5006 5.13086 11.4877 5.19141 11.4625C5.25195 11.4374 5.30762 11.4005 5.35352 11.354L8 8.707L10.6465 11.354C10.6924 11.4005 10.748 11.4374 10.8086 11.4625C10.8691 11.4877 10.9346 11.5006 11 11.5006C11.0654 11.5006 11.1309 11.4877 11.1914 11.4625C11.252 11.4374 11.3076 11.4005 11.3535 11.354C11.4004 11.3075 11.4375 11.2523 11.4629 11.1916C11.4873 11.1309 11.501 11.0657 11.501 11C11.501 10.9343 11.4873 10.8691 11.4629 10.8084C11.4375 10.7477 11.4004 10.6925 11.3535 10.646L8.70703 8L11.3535 5.354C11.4004 5.30753 11.4375 5.25232 11.4629 5.19159C11.4873 5.13086 11.501 5.06573 11.501 5C11.501 4.93427 11.4873 4.86914 11.4629 4.80841C11.4375 4.74768 11.4004 4.69247 11.3535 4.646C11.3076 4.59952 11.252 4.56262 11.1914 4.53748C11.1309 4.51233 11.0654 4.49936 11 4.49936C10.9346 4.49936 10.8691 4.51233 10.8086 4.53748C10.748 4.56262 10.6924 4.59952 10.6465 4.646L8 7.293L5.35352 4.646Z'
        fill={iconColors[color]}
        fill-opacity='1.000000'
        fill-rule='nonzero'
      />
    </svg>
  )
}

Remove.propTypes = {
  ...iconColorPropTypes,
}

Remove.defaultProps = {
  color: 'red',
}
