import { createActions } from 'redux-actions'

export const {
  modalConfirmDelete: { open, close },
} = createActions({
  MODAL_CONFIRM_DELETE: {
    open: (payload) => ({ ...payload, open: true }),
    close: () => {},
  },
})
