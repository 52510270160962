import React, { useEffect } from 'react'
import { renderRoutes } from 'react-router-config'
import isEmpty from 'lodash/isEmpty'
import { Cookies } from 'react-cookie'
import { push } from 'connected-react-router'
import { useQuery } from 'react-apollo'
import { useDispatch } from 'react-redux'
import { Layout } from 'packages/components'
import routes from '@configurator/routes'
import billingAccountGetQuery from '@graphql/gql/website/billingAccountGet.gql'
import { COOKIE, URLS } from 'packages/enum'
import { set as setUserProfile } from 'src/packages/redux/modules/user/actions'

function useSetProfile() {
  const { data: { loading, billingAccountGet } = {} } = useQuery(billingAccountGetQuery)
  const dispatch = useDispatch()

  useEffect(() => {
    if (window.location.pathname === URLS.root) {
      dispatch(push(URLS.website_login))
    }

    if (!loading && !isEmpty(billingAccountGet) && window.location.pathname.includes('/website')) {
      // Если получили ошибку, считаем, что нет авторизации и уходим
      if (!billingAccountGet.success) {
        if (new Cookies().get(COOKIE.token)) {
          new Cookies().remove(COOKIE.token, { path: '/' })
          // delete old cookie from prod domains
          new Cookies().remove(COOKIE.token, {
            path: '/',
            domain: 'artbrief-websites-dev.com',
          })
          new Cookies().remove(COOKIE.token, {
            path: '/',
            domain: 'vsble-dev.com',
          })
          new Cookies().remove(COOKIE.token, {
            path: '/',
            domain: 'vsble.me',
          })
          new Cookies().remove(COOKIE.token, {
            path: '/',
            domain: 'www.vsble.me',
          })
        }
        dispatch(push(URLS.website_create_account))
      } else {
        const { data } = billingAccountGet
        dispatch(setUserProfile(data))
      }
    }
  }, [billingAccountGet, dispatch, loading])
}

const Component = (props) => {
  useSetProfile()

  return <Layout {...props}>{renderRoutes(routes)}</Layout>
}

export default Component
