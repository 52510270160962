/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const InfoCircleIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 14.8V12M12 9.2H12.007M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

InfoCircleIcon.propTypes = {
  ...iconColorPropTypes,
}

InfoCircleIcon.defaultProps = {
  color: 'dark',
}
