import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const FolderOpenIcon = ({ color }) => (
  <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.165 16.551C0.351 16.831 0.664 17 1 17H16C16.4 17 16.762 16.762 16.919 16.394L19.919 9.394C20.052 9.085 20.02 8.731 19.835 8.45C19.649 8.169 19.336 8 19 8H18V4C18 2.897 17.103 2 16 2H10.9L8.616 0.213C8.44 0.0750001 8.224 0 8 0H2C0.897 0 0 0.897 0 2V16H0.00699997C0.00699997 16.192 0.056 16.385 0.165 16.551ZM15.341 15H2.517L4.66 10H17.484L15.341 15ZM16 4V8H4C3.6 8 3.238 8.238 3.081 8.606L2 11.129V4H16Z'
      fill={iconColors[color]}
    />
  </svg>
)

FolderOpenIcon.propTypes = {
  ...iconColorPropTypes,
}

FolderOpenIcon.defaultProps = {
  color: 'dark',
}
