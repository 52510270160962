import styled from 'styled-components'

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 25px;
  button {
    flex: 1;
  }
`
