import styled, { css } from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  max-width: 1270px;
  width: 100%;
  margin: 0 auto 30px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  > div {
    &:first-child {
      flex-grow: 1;
      > div {
      }
    }

    &:last-child {
      width: 100%;

      > div {
        border-radius: 6px;
      }
    }
  }

  ${media.max.desktop`
        padding: 0 20px;
  `}

  ${media.max.laptop`
        padding: 0px 40px;
  `}

  @media (max-width: 827px) {
    padding: 0px 30px;
  }

  ${media.max.phone`
    padding: 15px 16px 46px;
  `}
`

export const Header = styled.div`
  max-width: 1270px;
  margin: 30px auto 50px auto;
  padding: 0 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;

  /* Dark gray */
  color: #242426;

  ${media.max.desktop`
        padding: 0 20px;
  `}

  ${media.max.laptop`
        padding: 0 40px;
  `}

  @media (max-width: 827px) {
    padding: 0px 30px;
  }

  ${media.max.tablet`
      font-size: 26px;
      margin: 30px auto 25px auto;
  `}

  ${media.max.phone`
        font-size: 24px;
        line-height: 150%;
        padding: 0 16px;
  `}
`

export const WrapperSettings = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);

  ${media.max.tablet`
     min-height: calc(100vh - 171px);
  `}
`

export const SettingsDesktop = styled.div`
  display: flex;
  gap: 110px;

  ${media.max.laptop`
        gap: 70px;
    `}

  ${media.max.tablet`
      display: none;
  `}

  @media (max-width: 1000px) {
    gap: 20px;
  }
`

export const SettingsMobile = styled.div`
  display: none;

  ${media.max.tablet`
      display: flex;
    `}
`

export const WrapperAccordion = styled.div`
  width: 100%;
`

export const Content = styled.div`
  flex: 1;
  border-left: 1px solid #e1e2e6;
  border-radius: 0px !important;
  padding: 15px 30px 0 50px;
  overflow-y: auto;
  overflow-x: hidden;

  ${media.max.laptop`
    padding: 15px 40px 0 40px;
  `}
`

export const WrapperTabs = styled.div`
  padding-top: 15px;
  width: 172px;
`

export const WrapperBox = styled.div`
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 25px 0;
    `}
`

export const Box = styled.div`
  padding: 30px 40px;
  background-color: #fff;
  border-radius: 5px;

  ${({ isPremiumDomain }) =>
    isPremiumDomain &&
    css`
      padding: 40px;
      margin-bottom: 40px;
    `}

  ${({ isHasntDomain }) =>
    isHasntDomain &&
    css`
      padding: 40px;
      background-color: transparent;
      border: 1px solid #bcbcbc;
    `}

    ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 20px 24px;
      ${Paragraph} {
        font-size: 15px;
      }
    `}
`
export const WrapperButtonDesktop = styled.div`
  & > button {
    width: 133px;
    height: 48px;
    font-size: 13px;
    padding: 17px 20px 15px 20px !important;

    ${({ isPremiumDomain }) =>
      isPremiumDomain &&
      css`
        width: 196px;
      `}

    ${({ isManageDomain }) =>
      isManageDomain &&
      css`
        width: 196px;
        background-color: #343840 !important;
        border: 1px solid #343840 !important;
        opacity: 0.9;

        &:hover {
          opacity: 1;
          background-color: #343840 !important;
          border: 1px solid #343840 !important;
        }
      `}
  }

  @media (max-width: 967px) {
    display: none;

    ${({ isPremiumDomain }) =>
      isPremiumDomain &&
      css`
        display: block;
      `}
  }
`
export const WrapperButtuns = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1010px) {
    flex-direction: column;
  }
`

export const WrapperButtonMobile = styled.div`
  display: none;

  @media (max-width: 967px) {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    & > button {
      width: 133px;
      height: 48px;
      font-size: 13px;
      padding: 17px 20px 15px 20px !important;
    }
  }
`

export const ConnectedStatus = styled.div`
  color: #75c071;
`

export const Footer = styled.div`
  margin-top: auto;
  padding: 18px 0 16px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  font-size: 12px;
  font-weight: 300;

  /* Dark gray */
  color: #242426;
`

export const Paragraph = styled.div`
  ${(props) =>
    props.type === 'h1' &&
    css`
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
    `}

  ${(props) =>
    props.type === 'h2' &&
    css`
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
    `}

    > a {
    color: #58c367;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid #58c367;
    font-weight: 700;
  }
  display: inline-block;
  position: relative;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  max-width: ${(props) => `${props.mw}px` || 'auto'};
  font-weight: ${(props) => props.fw || '400'};
  color: ${(props) => props.color || '#343840'};
  font-size: ${(props) => props.fz || 'none'};
  text-align: ${(props) => props.textAlign || 'none'};
  line-height: ${(props) => props.lh || 'normal'};

  ${({ isPremiumDomain }) =>
    isPremiumDomain &&
    css`
      font-size: 20px !important;
    `}
`
export const BadgePro = styled.div`
  position: absolute;
  right: -40px;
  top: 3px;
  font-size: 8px;
  font-weight: 700;
  color: #666a73;
  text-transform: uppercase;
  padding: 3px 7px;
  width: 35px;
  height: 16px;
  border-radius: 22px;
  background-color: #f3f3f4;
  border: 1px solid #bcbcbc;

  @media (max-width: 930px) {
    position: static;
  }
`

export const Field = styled.div`
  > div {
    width: 85%;
    &:nth-child(2) {
      display: flex;
      justify-content: flex-start;
      > span {
        margin-right: 15px;
        color: ${(props) => (props.isActive ? '#58c367 ' : '#BCBCBC')};
        font-size: 15px;
        font-weight: 700;
      }
    }

    ${media.max.tablet`
            width: auto;

            &:nth-child(2) {
                > span {
                    margin-left: 16px;
                    white-space: nowrap;
                }
            }
        `}

    ${media.max.phone`
            &:nth-child(2) {
                flex-direction: column-reverse;
                align-items: flex-end;
                > span {
                    margin: 8px 0 0 16px;
                }
            }
        `}
  }
`

export const VisibilityTitle = styled.div`
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 17px;
  font-weight: bold;

  /* Dark gray */
  color: #242426;
`

export const VisibilityDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 28px;
  font-weight: 300;
  line-height: 24px;

  /* Dark gray */
  color: #242426;
`

export const DomainWarning = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  font-weight: 300;

  /* Dark */
  color: #343840;
`

export const DomainWrapper = styled.div`
  display: flex;

  width: 310px;
  max-width: 100%;
  margin-top: 6px;

  > div {
    max-width: 100%;

    &:first-child {
      flex-grow: 1;

      .input {
        width: 100%;
      }
    }

    &:last-child {
      padding-left: 10px;
    }
  }

  ${({ isPremiumDomain }) =>
    isPremiumDomain &&
    css`
      width: 100%;

      @media (max-width: 1250px) {
        padding-right: 90px;
      }

      ${media.max.phone`
            flex-direction: column;
            padding-right: 80px;

            > div {
                &:last-child {
                    padding-left: 0;
                }
            }
        `}
    `}

  ${({ isCustomDomain }) =>
    isCustomDomain &&
    css`
      ${media.max.phone`
            padding-right: 0;
        `}
    `}
`

export const WrapperInput = styled.div`
  width: fit-content !important;
  flex: 0 !important;

  ${({ isCustomDomain }) =>
    isCustomDomain &&
    css`
      flex: 0 !important;
      & > div > div > div > {
        display: block !important;

        input {
          border-radius: 5px 0 0 5px !important;
          width: 206px !important;
        }
      }
    `}

  ${({ isPremiumDomain }) =>
    isPremiumDomain &&
    css`
      position: relative;
      padding-left: 0 !important;
      margin-bottom: 16px;

      & > div > div > div > {
        display: block !important;

        input {
          border-radius: 5px 0 0 5px !important;
          width: 682px !important;
          max-width: 100% !important;
        }
      }
    `}
`

export const InputText = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding: 11px 0;
  height: 40px;
  width: 94px;
  border-top: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-radius: 0 5px 5px 0;

  /* Dark gray */
  color: #242426;
`

export const BorderDesktop = styled.div`
  width: 1px;
  height: 42px;
  margin: 0 10px;
  background-color: #e1e2e6;

  @media (max-width: 1100px) {
    display: none;
  }
`
export const BorderMobile = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    height: 1px;
    margin: 30px 0 25px;
    background-color: #e1e2e6;
  }
`

export const DomainPreviewDesktop = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  margin-top: 10px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  /* Dark gray */
  color: #242426;

  > span {
    color: #58c367;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`

export const DomainPreviewMobile = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: block;
    font-weight: bold;
    font-size: 17px;
    line-height: 150%;
    /* Dark gray */
    color: #242426;

    > span {
      color: #58c367;
    }
  }
`

export const DomainSuffix = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  margin-top: 12px;
  margin-right: 25px;
  /* Dark */
  color: #343840;

  > div {
    display: flex;
  }

  ${media.max.phone`
        margin-right: 0;

        > div {
            justify-content: flex-start;

            > div {
                width: auto;
            }
        }
    `}
`

export const DomainStatus = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 0.28571429rem;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  padding-left: 0.5rem;
  padding-right: 13px;

  ${(props) => (props.success ? 'color: #58C367;' : '')}
  ${(props) => (props.warning ? 'color: #ec9435;' : '')}
    ${(props) => (props.error ? 'color: #FF002E;' : '')}
    > i.green.icon {
    color: #00bd24 !important;
  }
`

export const DomainDeleteButton = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    /* Red */
    color: #ff002e;
  }
`

export const DomainRetryButton = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 22px;

  svg {
    margin-right: 7px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    /* Red */
    color: #58c367;
  }
`

export const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  ${media.max.tablet`
        flex-direction: column;
    `}
`

export const StepNumber = styled.div`
  min-width: 44px;

  > div > span {
    line-height: 27px;
    font-weight: bold;
    font-size: 15px;
    color: #1f1f1f;
  }
`

export const StepContent = styled.div`
  flex-grow: 1;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  &:nth-child(1) {
    margin-right: 40px;
  }

  ${media.max.tablet`
        width: 100%;
    `}
`

export const DomainFormContainer = styled.div``

export const DomainContainer = styled.div`
  > ${Paragraph},${DomainFormContainer} {
    width: 100%;
  }

  ${media.max.tablet`
        flex-direction: column;

        > ${Paragraph}, ${DomainFormContainer} {
            width: 100%;
        }
    `}
`

export const DomainRightContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  > button {
    align-self: flex-start;
    min-width: 155px;
  }

  ${media.max.phone`
        flex-direction: column;

        > button {
            width: 100%;
            margin-top: 20px !important;
        }
    `}
`

export const PremiumHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${media.max.tablet`
        ${Paragraph} {
            font-size: 20px;
        }
    `}

  ${media.max.phone`
        align-items: center;

        ${Paragraph} {
            width: 100%;
        }
    `}
`
export const WrapperDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 1220px) {
    & > div {
      width: 45%;
      margin-top: -10px;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    & > div {
      width: 100%;
    }
  }

  ${media.max.tablet`
        ${Paragraph} {
            font-size: 16px;
        }
    `}

  @media (max-width: 460px) {
    ${Paragraph} {
      margin-top: 150px;
    }
  }
`
export const StepTable = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 80%;

  > div {
    display: flex;
    justify-content: space-between;
    > div {
      width: 40%;
    }
  }

  ${media.max.phone`
        grid-row-gap: 8px;

        > div {
            flex-wrap: wrap;

            > div {
                width: 50%;

                span {
                    white-space: nowrap;
                }

                &:last-child {
                    margin-top: 8px;
                }
            }
        }
    `}
`

export const CustomDomain = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #343840;
  > span {
    color: #58c367;
  }

  ${media.max.tablet`
        margin-bottom: 0;
    `}
`

export const CustomDomainPreview = styled.div`
  background-color: #d8dfe3;
  width: 335px;
  height: 45px;
  display: flex;
  border-radius: 5.57214px 0 0 0;
  align-items: center;

  > div {
    display: flex;
    width: 75px;
    flex-shrink: 0;

    &:nth-child(1) {
      justify-content: space-between;
      padding: 0 14px;
      span {
        border-radius: 50%;
        width: 11px;
        height: 11px;
        background-color: #fff;
      }
    }
    &:nth-child(2) {
      width: 100%;
      flex-shrink: 1;
      align-items: center;

      > div {
        &:nth-child(1) {
          width: 22px;
          height: 23px;
          line-height: 26px;
          text-align: center;
          flex-shrink: 0;
          background-color: #024af3;
          color: #fff;
          border-radius: 3px;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 1px;
          margin-right: 7px;

          & > span {
            margin-left: 2px;
          }
        }

        &:nth-child(2) {
          display: flex;
          width: 100%;
          border-radius: 3.48259px 0 0 3.48259px;
          background-color: #fff;
          position: relative;

          > div {
            height: 23px;
            padding-top: 5px;
            text-align: center;
            font-size: 11px;

            &:nth-child(1) {
              padding-top: 0px;
              width: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  ${media.max.phone`
        width: 320px;
    `}
`

export const CustomDomainPreviewContainer = styled.div`
  &:nth-child(1) {
    box-shadow: -12px 20px 19px #a9a9a95c;
    margin-left: auto;

    > div:last-child {
      width: 100%;
      background-color: #fff;
      height: 17px;
    }
  }

  &:nth-child(2) {
    ${CustomDomainPreview} {
      width: 387px;
    }
  }

  ${media.max.phone`
        &:nth-child(2) {
        ${CustomDomainPreview} {
          width: 360px;
        }
      }
    `}
`

export const CustomDomainPreviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1100px) {
    position: static;
    margin-top: 60px;
  }

  @media (max-width: 460px) {
    position: absolute;
    top: 22px;
    right: 50%;
    transform: translateX(50%);
  }
`

export const ChangeIconWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 21px;
  z-index: 1;

  & > svg {
    margin-top: -9px;
  }
`

export const PrivacyContainer = styled.div``

export const PrivacyColumn = styled.div`
  & > div {
    margin-bottom: 25px;
  }
`

export const PrivacyItem = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemTitle = styled(Paragraph)`
  font-size: 17px;
`

export const ItemDescription = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 28px;

  /* Dark gray */
  color: #242426;
`

export const ItemContent = styled.div`
  ${media.max.tablet`
        > div {
                width: auto;
            }
        }
    `}
`

export const Toggler = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;

  > span {
    color: ${(props) => (props.isActive ? '#58c367 ' : '#BCBCBC')};
    margin-right: 10px;
    font-weight: 700;
    font-size: 15px;
    margin-left: 0 !important;
  }

  ${media.max.phone`
    justify-content: flex-start !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: unset !important;
    gap: 10px !important;

    > span {
      margin-top: 0 !important;
    }
    `}
`

export const DomainAttemps = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #666a73;
  margin-top: 12px;
`

export const ActiveWarning = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #ec9435;
  margin-top: 12px;
`
