import React from 'react'
import PropTypes from 'prop-types'

export const ExternalLinkIcon = ({ className, onClick }) => (
  <svg
    className={className}
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle className='iconBg' cx='20' cy='20' r='20' fill='white' onClick={onClick} />
    <path
      className='main'
      onClick={onClick}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30 29H10C8.897 29 8 28.103 8 27V13C8 11.897 8.897 11 10 11H30C31.103 11 32 11.897 32 13V27C32 28.103 31.103 29 30 29ZM10 14V27H30.001L30 14H10ZM10.5 13C10.7761 13 11 12.7761 11 12.5C11 12.2239 10.7761 12 10.5 12C10.2239 12 10 12.2239 10 12.5C10 12.7761 10.2239 13 10.5 13ZM12.5 13C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12C12.2239 12 12 12.2239 12 12.5C12 12.7761 12.2239 13 12.5 13ZM15 12.5C15 12.7761 14.7761 13 14.5 13C14.2239 13 14 12.7761 14 12.5C14 12.2239 14.2239 12 14.5 12C14.7761 12 15 12.2239 15 12.5Z'
    />
  </svg>
)

ExternalLinkIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

ExternalLinkIcon.defaultProps = {
  className: '',
  onClick: () => {},
}
