const messages = {
  'modalYesNo.yes': 'Yes',
  'modalYesNo.cancel': 'Cancel',
  'modalYesNo.header': 'Header',
  'modalYesNo.subHeader': 'SubHeader',
  'error.header': 'Oops.',
  'error.desc': 'Something went wrong.',
  'layout.error.head': 'Oops.',
  'layout.error.desc': 'Something went wrong.',
  'layout.error.home': 'go to home',
  'layout.error.report': 'Submit feedback report',
  'versionInProgress.ok': 'Got it, thanks.',
  'versionInProgress.text':
    'We recommend <b>creating and editing</b> your website from a desktop a computer or tablet for the best experience. <br/><br/> However, we are currently working on a mobile phone version that will be ready soon.',
}

export default messages
