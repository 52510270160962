import styled from 'styled-components'
import { FormDropdown } from 'packages/components/inputs/formElements'

export const Select = styled(FormDropdown)`
  .ui.selection.dropdown .menu > .item {
    padding: 0 !important;
    > div {
      padding: 0.78571429rem 1.14285714rem !important;
    }
    &:first-child {
      display: none !important;
    }
    &:last-child {
      pointer-events: all;
      border-top: none;
      font-weight: bold;
      color: grey;
      height: max-content !important;
      &:hover {
        background: none !important;
        cursor: initial !important;
      }
    }
  }
`
export const Pagination = styled.div`
  display: ${(props) => (!props.show ? 'none' : 'flex')};
  margin-top: 4px;
  margin-bottom: 4px;
  border-top: 1px solid #e1e2e5;
`
export const PaginationItem = styled.div`
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  cursor: pointer;

  &:last-child {
    margin-left: auto;

    i {
      margin: 0 0 0 0.25rem !important;
    }
  }
`

export const Container = styled.div`
  width: 100%;
`

export const Label = styled.label`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #343840;

  margin-bottom: 5px;
  display: block;
`

export const SelectContainer = styled.div`
  position: fixed;
  top: 80px;
  border-radius: 2px 2px 0px 0px;
  bottom: 10px;
  height: calc (100% - 90px);
  overflow-y: scroll;
  width: 340px;
  left: 400px;
  background: white;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
`

export const FontItem = styled.div`
  cursor: pointer;
  padding: 8px 14px;
  font-size: 17px;

  &:hover {
    background: #f6f6f6;
  }
`

export const SearchInput = styled.div`
  padding-bottom: 6px;
  margin-bottom: 2px;
  border-bottom: 1px solid #e1e2e5;

  > div {
    width: 100%;
  }

  input {
    padding: 8px 14px !important;
    border: none !important;
    font-size: 13px;
  }
`

export const FontValue = styled.div`
  border-radius: 2px;
  border: 1px solid #ededed;
  background: #ffffff;
  padding: 12px 14px;
  cursor: pointer;
`
