import styled from 'styled-components'

export const RootSVG = styled.svg`
  flex-shrink: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  user-select: none;
  box-sizing: content-box;
`

export function DragIndicatorIcon(props) {
  return (
    <RootSVG
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10ZM9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6ZM9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14ZM13 10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10ZM13 6C13 5.44772 13.4477 5 14 5C14.5523 5 15 5.44772 15 6C15 6.55228 14.5523 7 14 7C13.4477 7 13 6.55228 13 6ZM13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14Z'
        fill='currentColor'
      />
      <path
        d='M9 18C9 17.4477 9.44772 17 10 17C10.5523 17 11 17.4477 11 18C11 18.5523 10.5523 19 10 19C9.44772 19 9 18.5523 9 18ZM13 18C13 17.4477 13.4477 17 14 17C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18Z'
        fill='currentColor'
      />
    </RootSVG>
  )
}

export function DeleteIcon(props) {
  return (
    <RootSVG
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M14.6667 7.8V7.24C14.6667 6.45593 14.6667 6.06389 14.5213 5.76441C14.3935 5.50099 14.1895 5.28681 13.9387 5.15259C13.6534 5 13.2801 5 12.5333 5H11.4667C10.7199 5 10.3466 5 10.0613 5.15259C9.81046 5.28681 9.60649 5.50099 9.47866 5.76441C9.33333 6.06389 9.33333 6.45593 9.33333 7.24V7.8M10.6667 11.65V15.15M13.3333 11.65V15.15M6 7.8H18M16.6667 7.8V15.64C16.6667 16.8161 16.6667 17.4042 16.4487 17.8534C16.2569 18.2485 15.951 18.5698 15.5746 18.7711C15.1468 19 14.5868 19 13.4667 19H10.5333C9.41323 19 8.85318 19 8.42535 18.7711C8.04903 18.5698 7.74307 18.2485 7.55132 17.8534C7.33333 17.4042 7.33333 16.8161 7.33333 15.64V7.8'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}
