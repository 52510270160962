import { StylesToggle } from '@configurator/components/pages/pageStyles/components'
import {
  Description,
  ImageFitHeader,
  WrapperFooter,
  WrapperItem,
} from '@configurator/components/slideshow/nodes'
import isEqual from 'lodash/isEqual'
import { FEATURE_PROPERTY_KEYS, PORTFOLIO_TEMPLATES, STYLE_FEATURE_PROPERTIES } from 'packages/enum'
import { usePrevious } from 'packages/helpers/usePrevious'
import { useEffect, useMemo } from 'react'
import { injectIntl } from 'react-intl'

const FooterFields = ({
  footerOverSlideshow,
  isFooterOverSlideshowEnabled,
  handleToggleFooterOverSlideshow,
  isFooterHiddenEnabled,
  handleToggleFooterHidden,
  updateFeatureState,
  values,
  initialValues,
  selectedStyle,
  setFieldValue,
  openErrorModal,
  slideshowEnabled,
  templateName,
  intl: { formatMessage },
}) => {
  const prevValues = usePrevious(values)

  useEffect(() => {
    if (prevValues && !isEqual(prevValues, values)) {
      updateFeatureState({ key: FEATURE_PROPERTY_KEYS.style, values })
    }
  }, [initialValues, prevValues, updateFeatureState, values])

  const showFooterOverSlideshowToggle =
    !!footerOverSlideshow && slideshowEnabled && templateName !== PORTFOLIO_TEMPLATES.CATHERINE

  const formBlocksProps = useMemo(
    () => ({
      selectedStyle,
      values,
      initialValues,
      setFieldValue,
      openErrorModal,
      slideshowEnabled,
      footerOverSlideshow,
      isFooterOverSlideshowEnabled,
      handleToggleFooterOverSlideshow,
      isFooterHiddenEnabled,
      handleToggleFooterHidden,
      templateName,
    }),
    [
      initialValues,
      openErrorModal,
      selectedStyle,
      setFieldValue,
      values,
      slideshowEnabled,
      footerOverSlideshow,
      isFooterOverSlideshowEnabled,
      handleToggleFooterOverSlideshow,
      isFooterHiddenEnabled,
      handleToggleFooterHidden,
      templateName,
    ]
  )

  return (
    <WrapperFooter>
      <ImageFitHeader>{formatMessage({ id: 'slideshow.footer.title' })}</ImageFitHeader>
      <Description>{formatMessage({ id: 'slideshow.footer.description' })}</Description>

      {showFooterOverSlideshowToggle && (
        <WrapperItem>
          <StylesToggle
            label='slideshow.footer.toggle.over.slideshow'
            {...formBlocksProps}
            field={{
              value: isFooterOverSlideshowEnabled,
              name: STYLE_FEATURE_PROPERTIES.FOOTER_OVER_SLIDESHOW,
            }}
            onClick={handleToggleFooterOverSlideshow}
          />
        </WrapperItem>
      )}

      <WrapperItem>
        <StylesToggle
          label='slideshow.footer.toggle.hide'
          {...formBlocksProps}
          field={{
            value: isFooterHiddenEnabled,
            name: STYLE_FEATURE_PROPERTIES.FOOTER_HIDDEN,
          }}
          onClick={handleToggleFooterHidden}
        />
      </WrapperItem>
    </WrapperFooter>
  )
}

export default injectIntl(FooterFields)
