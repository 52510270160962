import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './rootReducer'
import initialState from './initialState'
import initialMiddlewares from './initialMiddlewares'

const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...initialMiddlewares),
    /*process.env.NODE_ENV !== 'production' && */ window && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newReducers = require('./rootReducer').default
    store.replaceReducer(newReducers)
  })
}

export default store
