import { media } from 'packages/components/media'
import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background: red;
  z-index: 50;

  > div {
    padding-left: 12px;
    padding-right: 12px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    > button {
      height: 34px;
      margin-left: 12px !important;
      margin-right: 12px !important;
    }

    > span {
      color: white;
      font-weight: 600;
    }
  }
`

export const CloseIcon = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > span {
    color: white;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 6px;
  }
  > svg {
    fill: white;
  }
`

export const Content = styled.div`
  padding: 0 20px 0 30px !important;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between !important;

  ${media.max.tablet`
        padding: 0 12px !important;
  `}
`

export const Title = styled.h4`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 18px;
  letter-spacing: 0%;
  color: #fff;
`
export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperButtonVerify = styled.div`
  & > button {
    height: 34px;
    margin-left: 12px !important;
    margin-right: 12px !important;
    padding-top: 15px !important;
    background-color: #0e1015 !important;
    border: 1px solid #0e1015 !important;
    opacity: 0.8;

    &:hover {
      opacity: 1;
      background-color: #0e1015 !important;
      border: 1px solid #0e1015 !important;
    }
  }
`
