import { PopupContent, PopupText, StyledPopup } from 'packages/components/header/nodes'
import { WideDropdownItem, WideDropdownText } from '@configurator/components/page/album/item/nodes'
import { FileStarIcon } from 'packages/components/icons'
import React from 'react'
import PropTypes from 'prop-types'

export function AlbumCoverImage(
  { isActive, index, onFocus, onBlur, ...props },
  { intl: { formatMessage } }
) {
  return isActive ? (
    <StyledPopup
      key={index}
      trigger={
        <WideDropdownItem isActive={true} {...props}>
          <WideDropdownText>{formatMessage({ id: props.text })}</WideDropdownText>
          <FileStarIcon />
        </WideDropdownItem>
      }
      content={
        <PopupContent>
          <PopupText>{formatMessage({ id: 'album.item.label.coverImageTip' })}</PopupText>
        </PopupContent>
      }
      position='bottom center'
    />
  ) : (
    <WideDropdownItem isActive={false} {...props}>
      <WideDropdownText>{formatMessage({ id: props.text })}</WideDropdownText>
      <FileStarIcon />
    </WideDropdownItem>
  )
}

AlbumCoverImage.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isLastButton: PropTypes.bool.isRequired,
  isBorderBottomButton: PropTypes.bool.isRequired,
  isAfterBorderBottomButton: PropTypes.bool.isRequired,
}

AlbumCoverImage.contextTypes = {
  intl: PropTypes.object.isRequired,
}
