import PropTypes from 'prop-types'
import { useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { useQuery, useMutation } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql'
import websiteSeoImageUpdateMutation from '@graphql/gql/website/websiteSeoImageUpdate.gql'
import featureGetQuery from '@graphql/gql/website/featureGet.gql'
import featureUpdateWithPhotoMutation from '@graphql/gql/website/featureUpdateWithPhoto.gql'
import featureUpdateMutation from '@graphql/gql/website/featureUpdate.gql'

import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import Image from 'packages/components/gatsby-image'
import { Button } from 'packages/components/buttons'
import { ImageIcon } from 'packages/components/icons'
import { FEATURE_PROPERTY_KEYS } from 'packages/enum'
import { getDomain } from 'packages/helpers/Helper'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'

import Loader from '../../loader/Loader'
import DropModal from '../dropModal'

import {
  Field,
  Paragraph,
  SearchPreview,
  SearchPreviewDomain,
  SearchPreviewDescription,
  SearchPreviewTitle,
  ImagePreview,
  UploadPicture,
  RightPanelBlock,
  Description,
  Container,
  AcceptedFiles,
  TitleRightPanelBlock,
} from '../nodes'

const getFeatureProperty = (source, obj, property) => {
  return (((source || []).properties || []).find((el) => el.key === property) || {}).value || null
}

const OpenGraph = ({
  intl: { formatMessage },
  isSubmitting,
  openErrorModal,
  lockModalProgress,
  unLockModalProgress,
  loaderStart,
  loaderStop,
  setSubmitting,
  updatePage,
  values,
  selectedPageData,
  premiumDomain,
  domainData,
}) => {
  const { permissions } = useSubscription()

  const [isFileReady, setIsFileReady] = useState(false)
  const [showModalDeleteImage, setShowModalDeleteImage] = useState(false)
  const [dropModalOpen, setDropModalOpen] = useState(false)

  const [previewGraph, setPreviewGraph] = useState()

  const metaGetOptions = {
    variables: {},
    fetchPolicy: 'network-only',
  }

  const { loading, data: { websiteMetaGet } = {} } = useQuery(websiteMetaGetQuery, metaGetOptions)

  const featureGetOptions = {
    variables: {
      websiteId: websiteMetaGet?.data?.id,
      name: FEATURE_PROPERTY_KEYS.openGraph,
    },
    skip: isEmpty(websiteMetaGet),
    fetchPolicy: 'network-only',
  }

  const { loading: featureLoading, data: { featureGet } = {} } = useQuery(
    featureGetQuery,
    featureGetOptions
  )

  const [websiteSeoImageUpdate] = useMutation(websiteSeoImageUpdateMutation)
  const [featureUpdateWithPhoto] = useMutation(featureUpdateWithPhotoMutation)
  const [featureUpdate] = useMutation(featureUpdateMutation)

  if (loading || featureLoading) {
    return <Loader />
  }

  const defaultError = 'error.desc'

  const domain = `https://${premiumDomain || `${domainData}.vsble.me`}`

  const openGraphEnabled = !!selectedPageData || get(featureGet, 'data.enabled', false)
  const openGraphImage = selectedPageData
    ? selectedPageData.seoPhotoUrl
    : getFeatureProperty(
        featureGet.data,
        FEATURE_PROPERTY_KEYS.openGraph,
        FEATURE_PROPERTY_KEYS.openGraphImageMediaUrl
      )

  const updateFeature = (variables) =>
    featureUpdate({
      variables: {
        featureId: featureGet.data.id,
        ...variables,
      },
      refetchQueries: [
        {
          query: featureGetQuery,
          ...featureGetOptions,
        },
      ],
    })

  const updateFeatureWithPhoto = (variables) => {
    return featureUpdateWithPhoto({
      variables: {
        featureId: featureGet.data.id,
        ...variables,
      },
    })
  }

  const updateSeoImage = (variables) =>
    websiteSeoImageUpdate({
      variables: {
        pageId: selectedPageData.id,
        ...variables,
      },
    })

  const handleSaveGraph = async (setSubmitting) => {
    loaderStart()

    if (previewGraph && previewGraph.file) {
      try {
        if (selectedPageData) {
          lockModalProgress()

          const photoUpdateRes = await updateSeoImage({
            file: previewGraph.file,
          })

          unLockModalProgress()

          if (!get(photoUpdateRes, 'data.websiteSeoImageUpdate.success')) {
            loaderStop()
            setSubmitting(false)

            return openErrorModal({
              headerMessageId: 'error.header',
              subMessageId:
                !get(photoUpdateRes, 'data.websiteSeoImageUpdate.errors._error') || defaultError,
              yesMessageId: 'OK',
            })
          }
        } else {
          lockModalProgress()

          const photoUpdateRes = await updateFeatureWithPhoto({
            file: previewGraph.file,
            propertyName: FEATURE_PROPERTY_KEYS.openGraphImage,
          })

          unLockModalProgress()

          if (!get(photoUpdateRes, 'data.featureUpdateWithPhoto.success')) {
            loaderStop()
            setSubmitting(false)

            return openErrorModal({
              headerMessageId: 'error.header',
              subMessageId:
                !get(photoUpdateRes, 'data.featureUpdateWithPhoto.errors._error') || defaultError,
              yesMessageId: 'OK',
            })
          }

          const featureUpdateRes = await updateFeature({
            enabled: true,
          })

          if (!get(featureUpdateRes, 'data.featureUpdate.success')) {
            loaderStop()
            setSubmitting(false)

            return openErrorModal({
              headerMessageId: 'error.header',
              subMessageId:
                !get(featureUpdateRes, 'data.featureUpdate.errors._error') || defaultError,
              yesMessageId: 'OK',
            })
          }
        }

        setPreviewGraph(undefined)
        setIsFileReady(false)

        loaderStop()
        setSubmitting(false)
      } catch (err) {
        loaderStop()
        setSubmitting(false)

        return openErrorModal({
          headerMessageId: 'error.header',
          subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
          yesMessageId: 'OK',
        })
      }
    }
  }

  const handleImageDelete = async () => {
    loaderStart()

    try {
      if (selectedPageData) {
        const websitePageUpdateRes = await updatePage({
          seoPhotoUrl: '',
        })

        if (!get(websitePageUpdateRes, 'data.websitePageUpdate.success')) {
          loaderStop()
          setShowModalDeleteImage(false)
          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId:
              !get(websitePageUpdateRes, 'data.websitePageUpdate.errors._error') || defaultError,
            yesMessageId: 'OK',
          })
        }
      } else {
        const featureUpdateRes = await updateFeature({
          enabled: false,
        })

        if (!get(featureUpdateRes, 'data.featureUpdate.success')) {
          loaderStop()
          setShowModalDeleteImage(false)
          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId:
              !get(featureUpdateRes, 'data.featureUpdate.errors._error') || defaultError,
            yesMessageId: 'OK',
          })
        }
      }

      setIsFileReady(false)

      loaderStop()
      setShowModalDeleteImage(false)
    } catch (err) {
      loaderStop()
      setShowModalDeleteImage(false)
      return openErrorModal({
        headerMessageId: 'error.header',
        subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
        yesMessageId: 'OK',
      })
    }
  }

  const handleDropFile = (preview) => {
    setIsFileReady(true)

    setPreviewGraph(preview)

    setDropModalOpen(false)
  }

  const hasOpenGraphImage = openGraphImage && openGraphEnabled
  const isFileExist = isFileReady || hasOpenGraphImage

  const view = isFileExist ? 'primaryRed' : 'secondaryBlack'
  const messageGraph = isFileExist ? 'seo.button.deleteImage' : 'seo.button.uploadImage'

  const isDisabledSubmitGraph = !previewGraph || isSubmitting

  return (
    <>
      <Container>
        <Field>
          <PermissionsOverlay isAllowed={permissions.OPEN_GRAPH}>
            <Paragraph mb='8' type='h1' fz='18px' data-intercom-target={'UploadOgImage'}>
              {formatMessage({ id: 'seo.uploadImage' })}
            </Paragraph>

            <Description mb='14'>
              {formatMessage({ id: 'seo.uploadImage.description1' })}
            </Description>

            <Description>{formatMessage({ id: 'seo.uploadImage.description2' })}</Description>

            <UploadPicture>
              <Button
                type='button'
                disabled={isSubmitting}
                view={view}
                icon={!isFileExist && <ImageIcon />}
                onClick={() => {
                  if (hasOpenGraphImage) {
                    setShowModalDeleteImage(true)
                  } else if (previewGraph) {
                    setPreviewGraph(undefined)
                  } else {
                    setDropModalOpen(true)
                  }
                  setIsFileReady(false)
                }}
                content={formatMessage({ id: messageGraph })}
              />

              <Button
                view='primary'
                disabled={isDisabledSubmitGraph}
                content={formatMessage({ id: 'seo.save' })}
                onClick={() => handleSaveGraph(setSubmitting)}
              />
            </UploadPicture>

            <AcceptedFiles>{formatMessage({ id: 'seo.accepted.files2' })}</AcceptedFiles>
          </PermissionsOverlay>
        </Field>

        <RightPanelBlock>
          <TitleRightPanelBlock>{formatMessage({ id: 'seo.social' })}</TitleRightPanelBlock>

          <ImagePreview>
            {previewGraph || hasOpenGraphImage ? (
              <Image
                fluid={{
                  src: previewGraph
                    ? previewGraph.preview
                    : openGraphEnabled && getDomain(`/${openGraphImage}`),
                  aspectRatio: 450 / 243,
                }}
                alt=''
                fadeIn
                loading='lazy'
                backgroundColor='#EDEDED'
              />
            ) : null}
          </ImagePreview>

          <SearchPreview>
            <div>
              <SearchPreviewDomain>{domain}</SearchPreviewDomain>

              <SearchPreviewTitle>{values.seoTitle}</SearchPreviewTitle>

              <SearchPreviewDescription>{values.seoDescription}</SearchPreviewDescription>
            </div>
          </SearchPreview>
        </RightPanelBlock>
      </Container>

      <DropModal
        isOpen={dropModalOpen}
        onClose={() => {
          setDropModalOpen(false)
        }}
        onDrop={handleDropFile}
        maxSize={MAX_IMAGE_SIZE}
        accept='image/jpeg, image/gif, image/png'
        openErrorModal={openErrorModal}
        textInfoId={'upload.video.text.info'}
      />

      <ModalConfirmDelete
        open={showModalDeleteImage}
        onClickYes={handleImageDelete}
        onClickCancel={() => setShowModalDeleteImage(false)}
        onClose={() => setShowModalDeleteImage(false)}
      />
    </>
  )
}

OpenGraph.propTypes = {
  intl: intlShape.isRequired,
  isSubmitting: PropTypes.bool,
}

OpenGraph.defaultProps = {}

export default injectIntl(OpenGraph)
