import CropperModal from '@configurator/components/modals/cropperModal'
import FocusPointModal from '@configurator/components/modals/focusPointModal'
import NavigationPopup from '@configurator/components/modals/navigationPopup'
import UpdateVideoModal from '@configurator/components/upload/uploadVideo/UpdateVideo'
import UploadPhotoModal from '@configurator/containers/UploadPhotoContainer'
import { useSubscription } from '@configurator/providers/subscription'
import { push } from 'connected-react-router'
import { debounce } from 'lodash'
import AddInfoAndAltModal from 'packages/components/AddInfoAndAltModal'
import DropdownMenu from 'packages/components/dropdownMenu'
import {
  AlignLeftIcon,
  CropIcon,
  DoubleRefreshIcon,
  EyeIcon,
  HideIcon,
  InfoCircleIcon,
  MenuIcon,
  MoveIcon,
  RectangleRightIcon,
  TabletVibratesIcon,
  TargetIcon,
  TrashIcon,
} from 'packages/components/icons'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import { MESSAGE_TYPE, URLS, WEBSITE_ALBUM_ITEM_TYPE } from 'packages/enum'
import { useIsMobile } from 'packages/helpers/useWindowSize'
import {
  close as closeSubscriptionModal,
  open as openSubscriptionModal,
} from 'packages/redux/modules/modalConfirmDelete/actions'
import isBrowser from 'packages/utils/isBrowser'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { intlShape } from 'react-intl'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { AlbumCoverImage } from './AlbumCoverImage'
import { AlbumItemUpdateModal } from './AlbumItemUpdateModal'
import { AlbumToggle } from './AlbumToggle'
import {
  AlbumCoverText,
  AlbumItemInner,
  Container,
  DropdownContainer,
  DropdownSeparator,
  ImageComponent,
  ImageLimitBanner,
  ImageLimitBannerWrapper,
  ItemInfo,
  MarkerIcon,
  SettingsButton,
  VideoPlay,
} from './nodes'

function getSrcPath(srcSetWebpMap) {
  return srcSetWebpMap[0].srcSetWebp?.split(',')[0]?.split(' ')[0]
}

const AlbumItem = (
  {
    src,
    cover,
    offline,
    srcSetWebpMap,
    aspectRatio,
    photoId,
    id,
    itemType,
    onOfflineAlbumItem,
    onItemMoveToAlbum,
    onImageReplace,
    onImageDelete,
    onAlbumUpdate,
    onDropImage,
    loaderStart,
    loaderStop,
    hideCover,
    hidden,
    onAlbumItemUpdate,
    altImageRecognition,
    description,
    hideText,
    index,
    expandedView,
    xFocalPoint,
    yFocalPoint,
    setPhotos,
    onImageResize,
    columnCount,
    disabledByLimit,
    isOfflineLimit,
    onMoveItem,
    handleCantDrop,
    setDraggingItemId,
    draggingItemId,
    imgWidth,
    imgHeight,
    altTextDescriptive,
    altText,
    albumId,
    hosting,
    videoId,
    originalFileSize,
    originalFileName,
    customPreview,
    videoRatioX,
    videoRatioY,
  },
  { intl: { formatMessage } }
) => {
  const ref = useRef()
  const menuRef = useRef()
  const dropDownRef = useRef()
  const [addInfoModalOpen, setAddInfoModalOpen] = useState(false)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [itemUpdateModalOpen, setItemUpdateModalOpen] = useState(false)
  const [focusPointModalOpen, setFocusPointModalOpen] = useState(false)
  const [cropperModalOpen, setCropperModalOpen] = useState(false)
  const [itemMoveModalOpen, setItemMoveModalOpen] = useState(false)
  const [replacePhotoModalOpen, setReplacePhotoModalOpen] = useState(false)
  const [replaceVideoModalOpen, setReplaceVideoModalOpen] = useState(false)
  const [overPosition] = useState(1)
  const dispatch = useDispatch()
  const { permissions } = useSubscription()
  const isMobile = useIsMobile()

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: itemType, photoId, id, src, srcSetWebpMap, aspectRatio },
    begin: () => {
      setDraggingItemId(id)
      return {
        type: itemType,
        photoId,
        id,
        src,
        srcSetWebpMap,
        aspectRatio,
      }
    },
    canDrag: () => isBrowser,
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging(),
      }
    },
    end: (res, mon) => {
      setDraggingItemId(null)
      if (!mon.didDrop()) {
        handleCantDrop()
      }
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  const [{ isOver }, drop] = useDrop({
    accept: [WEBSITE_ALBUM_ITEM_TYPE.VIDEO, WEBSITE_ALBUM_ITEM_TYPE.PHOTO],
    canDrop: () => isBrowser,
    collect: (monitor) => {
      if (!ref) {
        return {
          isOver: false,
        }
      }
      return {
        isOver: !!monitor.isOver() /* && (monitor.getItem() || {}).id !== id,*/,
      }
    },
    drop: (item) =>
      onDropImage({
        itemId: item.id,
        droppedId: id,
        position: overPosition,
      }),
    hover: debounce(
      (item) => {
        if (item.id !== id) {
          onMoveItem(item.id, id)
        }
      },
      100,
      { trailing: true }
    ),
  })

  const handleReplace = async (file) => {
    await onImageReplace(file)
    setReplacePhotoModalOpen(false)
  }

  const handleResize = async (file) => {
    await onImageResize(file)
    setCropperModalOpen(false)
  }

  const handleCloseDropdown = () => {
    if (dropDownRef.current) {
      dropDownRef.current.close()
    }
  }

  const handleClickCallback = (e, callback) => {
    e.stopPropagation()
    callback()
  }

  const handleDelete = async () => {
    setDeleteDialogVisible(false)
    loaderStart()
    await onImageDelete()
    setPhotos((prev) => prev.filter((el) => el.id !== id))
    loaderStop()
    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.UPDATE_PAGE,
      }),
      '*'
    )
  }

  const handleMoveToAlbum = async (albumId, albumName) => {
    const { success } = await onItemMoveToAlbum(albumId)
    if (success) {
      setItemMoveModalOpen(false)
      toast.success(
        `${formatMessage({
          id: 'album.notification.movedToAlbum',
        })} ${albumName}`
      )
    }
  }

  const handleClickOnReplace = useCallback(() => {
    if (itemType === 'VIDEO') {
      setReplaceVideoModalOpen(true)
    } else {
      setReplacePhotoModalOpen(true)
    }
  }, [itemType])

  const menuItems = useMemo(() => {
    let items = []
    if (!disabledByLimit) {
      if (!isOfflineLimit || (isOfflineLimit && offline)) {
        const handleClick = (e) => {
          handleClickCallback(e, onOfflineAlbumItem)
        }

        console.log({ dropDownRef: dropDownRef.current })
        items.push({
          text: 'album.item.label.setOffline',
          icon: <EyeIcon />,
          component: <AlbumToggle isChecked={offline} />,
          onClick: handleClick,
        })
      }

      if (cover && !offline) {
        const handleClick = (e) => {
          handleClickCallback(e, () => onAlbumItemUpdate({ hidden: !hidden }))
        }

        items.push({
          text: 'album.label.hideImage',
          icon: <TabletVibratesIcon />,
          component: <AlbumToggle isChecked={hidden} />,
          onClick: handleClick,
        })
      }

      if (!hideCover && !offline) {
        const handleClick = (e) => {
          if (!isActive) {
            handleClickCallback(e, () => onAlbumUpdate({ mainPhotoId: id }))
          }
        }

        const isActive = Boolean(cover)
        const text = isActive ? 'album.label.coverImage' : 'album.label.makeCover'

        items.push({
          text,
          component: (props) => <AlbumCoverImage {...props} isActive={cover} />,
          overwrite: true,
          onClick: handleClick,
        })
      }

      items.push({
        component: (props) => <DropdownSeparator {...props} />,
        overwrite: true,
        text: 'album-separator-1', // uses as key
      })

      const handleOpenSubscriptionModal = () => {
        dispatch(
          openSubscriptionModal({
            headerMessageId: 'permission.modal.pro.header',
            subMessageId: 'permission.modal.pro.subMessage',
            yesMessageId: 'permission.modal.buttonMessage',
            onClickYes: () => {
              dispatch(push(URLS.website_billing))
              dispatch(closeSubscriptionModal())
            },
            hideCancelButton: true,
            styles: { width: '450px' },
          })
        )
      }

      if (!isMobile) {
        const handleFocusPointClick = () => {
          if (permissions.FOCAL_POINTS) {
            setFocusPointModalOpen(true)
          } else {
            handleOpenSubscriptionModal()
          }
          handleCloseDropdown()
        }

        items.push({
          text: 'album.label.focusPoint',
          icon: <TargetIcon />,
          onClick: handleFocusPointClick,
        })

        const handleCropper = () => {
          if (permissions.EDIT_PHOTO) {
            setCropperModalOpen(true)
          } else {
            handleOpenSubscriptionModal()
          }
          handleCloseDropdown()
        }

        items.push({
          text: 'album.label.cropper',
          icon: <CropIcon />,
          onClick: handleCropper,
        })
      }

      const handleReplace = () => {
        if (permissions.REPLACE_PHOTO) {
          handleClickOnReplace()
        } else {
          handleOpenSubscriptionModal()
        }
        handleCloseDropdown()
      }

      items.push({
        text: 'album.label.replace',
        icon: <DoubleRefreshIcon />,
        onClick: handleReplace,
      })

      if (!hideText) {
        const handleClick = () => {
          if (permissions.IMAGE_DESCRIPTION) {
            setItemUpdateModalOpen(true)
          } else {
            handleOpenSubscriptionModal()
          }
          handleCloseDropdown()
        }

        items.push({
          text: 'album.item.label.addText',
          icon: <AlignLeftIcon size={14} />,
          onClick: handleClick,
        })
      }

      if (!isMobile) {
        const handleClick = (e) => {
          handleClickCallback(e, () => setItemMoveModalOpen(true))
        }

        items.push({
          text: 'album.label.move',
          icon: <MoveIcon />,
          onClick: handleClick,
        })
      }

      if (itemType === 'PHOTO') {
        const handleClick = () => {
          if (permissions.SEO_ALT_DESCRIPTION) {
            setAddInfoModalOpen(true)
          } else {
            handleOpenSubscriptionModal()
          }
          handleCloseDropdown()
        }

        items.push({
          dataIntercomTarget: 'InfoAltText',
          text: 'album.label.info',
          icon: <InfoCircleIcon />,
          onClick: handleClick,
        })
      }
    }

    const handleDelete = () => {
      setDeleteDialogVisible(true)
      handleCloseDropdown()
    }

    items.push({
      text: 'album.label.delete',
      icon: <TrashIcon color='red' />,
      onClick: handleDelete,
      color: 'red',
    })

    return items
  }, [
    disabledByLimit,
    isOfflineLimit,
    offline,
    onOfflineAlbumItem,
    cover,
    hideCover,
    isMobile,
    hideText,
    itemType,
    hidden,
    onAlbumItemUpdate,
    onAlbumUpdate,
    id,
    dispatch,
    permissions.FOCAL_POINTS,
    permissions.EDIT_PHOTO,
    permissions.REPLACE_PHOTO,
    permissions.IMAGE_DESCRIPTION,
    permissions.SEO_ALT_DESCRIPTION,
    handleClickOnReplace,
  ])

  drag(drop(ref))

  // Если убрали со своей позиции, то больше не раскрываем ее
  const [justDrag, setJustDrag] = useState(true)
  useEffect(() => {
    if (isDragging && !isOver) {
      setJustDrag(false)
    } else if (!isDragging) {
      setJustDrag(true)
    }
  }, [isDragging, isOver])

  const isLastItemInRow = (index + 1) % columnCount === 0

  return (
    <>
      <Container
        key={photoId}
        isOver={isOver}
        justDrag={justDrag}
        isDragging={isDragging || draggingItemId === id}
        ref={ref}
        cover={cover}
        hidden={hidden}
        expandedView={expandedView}
        columnCount={columnCount}
      >
        <AlbumItemInner isOver={isOver} isDragging={isDragging || draggingItemId === id}>
          {disabledByLimit && (
            <ImageLimitBannerWrapper>
              <ImageLimitBanner>Locked</ImageLimitBanner>
            </ImageLimitBannerWrapper>
          )}
          <ImageComponent
            aspectRatio={aspectRatio}
            src={src}
            srcSetWebpMap={srcSetWebpMap}
            backgroundColor='#ececec'
            imgStyle={{
              pointerEvents: 'none',
              opacity: disabledByLimit || hidden ? 0.5 : 1,
            }}
            draggable
            alt={altText || ''}
          />
          <DropdownContainer>
            <DropdownMenu
              open={itemMoveModalOpen}
              menuRef={menuRef}
              dropdownRef={dropDownRef}
              openOnFocus={true}
              closeOnBlur={true}
              trigger={
                <SettingsButton>
                  <MenuIcon color='darkGray' />
                </SettingsButton>
              }
              items={menuItems}
              isLastItemInRow={isLastItemInRow}
              size='l'
            />
          </DropdownContainer>
          {itemType === 'VIDEO' && (
            <VideoPlay>
              <RectangleRightIcon />
            </VideoPlay>
          )}
          {cover ? (
            <AlbumCoverText>{formatMessage({ id: 'album.label.cover' })}</AlbumCoverText>
          ) : null}
          <ItemInfo>
            {description && (
              <MarkerIcon
                title={formatMessage({
                  id: 'album.markerIconTitle.description',
                })}
                size={13}
              >
                <AlignLeftIcon color='white' size={13} />
              </MarkerIcon>
            )}
            {hidden && (
              <MarkerIcon
                title={formatMessage({
                  id: 'album.label.hidden',
                })}
              >
                <HideIcon size='small' color='white' />
              </MarkerIcon>
            )}
          </ItemInfo>
        </AlbumItemInner>
      </Container>
      {deleteDialogVisible && (
        <ModalConfirmDelete
          open
          onClickYes={handleDelete}
          onClickCancel={() => setDeleteDialogVisible(false)}
          onClose={() => setDeleteDialogVisible(false)}
        />
      )}
      {addInfoModalOpen && (
        <AddInfoAndAltModal
          altTextDescriptive={altTextDescriptive}
          altText={altText}
          open
          onAlbumItemUpdate={onAlbumItemUpdate}
          onClickCancel={() => setAddInfoModalOpen(false)}
          onClose={() => setAddInfoModalOpen(false)}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          src={src}
          originalFileSize={originalFileSize}
          originalFileName={originalFileName}
          altImageRecognition={altImageRecognition}
        />
      )}
      {itemUpdateModalOpen && (
        <AlbumItemUpdateModal
          open
          onAlbumItemUpdate={onAlbumItemUpdate}
          onClose={() => setItemUpdateModalOpen(false)}
          initialValues={{ description: description ? description : '' }}
        />
      )}
      {focusPointModalOpen && (
        <FocusPointModal
          open
          onClickYes={() => setFocusPointModalOpen(false)}
          onClose={() => setFocusPointModalOpen(false)}
          src={src}
          initialFocusPoint={{
            x: xFocalPoint || 50,
            y: yFocalPoint || 50,
          }}
          onFocusPointUpdate={(xFocalPoint, yFocalPoint) =>
            onAlbumItemUpdate({
              xFocalPoint,
              yFocalPoint,
            })
          }
        />
      )}
      {cropperModalOpen && (
        <CropperModal
          open
          src={src}
          onClose={() => setCropperModalOpen(false)}
          onSave={handleResize}
        />
      )}
      {replacePhotoModalOpen && (
        <UploadPhotoModal
          open
          onClose={() => setReplacePhotoModalOpen(false)}
          onUpload={handleReplace}
        />
      )}
      {itemMoveModalOpen && (
        <NavigationPopup
          open
          context={menuRef.current}
          onClose={() => setItemMoveModalOpen(false)}
          clickAlbum={({ id, name }) => handleMoveToAlbum(id, name)}
        />
      )}
      {replaceVideoModalOpen && (
        <UpdateVideoModal
          albumId={albumId}
          open
          onClose={() => setReplaceVideoModalOpen(false)}
          hosting={hosting}
          videoId={videoId}
          id={id}
          customPreview={customPreview}
          srcSetWebpMap={getSrcPath(srcSetWebpMap)}
          videoRatioX={videoRatioX}
          videoRatioY={videoRatioY}
        />
      )}
    </>
  )
}

AlbumItem.propTypes = {
  src: PropTypes.string.isRequired,
  cover: PropTypes.bool.isRequired,
  offline: PropTypes.bool.isRequired,
  srcSetWebpMap: PropTypes.array.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  photoId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  description: PropTypes.string,
  columnCount: PropTypes.number.isRequired,

  // methods
  onOfflineAlbumItem: PropTypes.func.isRequired,
  onImageReplace: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onAlbumUpdate: PropTypes.func.isRequired,
  onDropImage: PropTypes.func.isRequired,
  onAlbumItemUpdate: PropTypes.func.isRequired,
  onItemMoveToAlbum: PropTypes.func.isRequired,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  onImageResize: PropTypes.func.isRequired,

  lastItem: PropTypes.bool,
  hideCover: PropTypes.bool,
  hideText: PropTypes.bool,
  hidden: PropTypes.bool,
  expandedView: PropTypes.bool,
  disabledByLimit: PropTypes.bool,
  isOfflineLimit: PropTypes.bool,
  xFocalPoint: PropTypes.string,
  yFocalPoint: PropTypes.string,
  setPhotos: PropTypes.func,
  customPreview: PropTypes.bool,
}

AlbumItem.defaultProps = {
  hideCover: false,
  hideText: false,
  hidden: false,
  lastItem: false,
  expandedView: false,
  disabledByLimit: false,
  xFocalPoint: '',
  yFocalPoint: '',
  setPhotos: () => null,
}

AlbumItem.contextTypes = {
  intl: intlShape,
}

export default AlbumItem
