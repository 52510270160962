import React from 'react'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { StyledToggle } from './nodes'

export const Toggle = (props) => {
  return <StyledToggle toggle {...props} />
}

Toggle.propTypes = {
  ...SemanticCheckbox.propTypes,
}

Toggle.defaultProps = {}
