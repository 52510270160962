/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const MoveIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.6667 15.3333L18 12M18 12L14.6667 8.66667M18 12H10M12 15.3333C12 15.9533 12 16.2633 11.9319 16.5176C11.7469 17.2078 11.2078 17.7469 10.5176 17.9319C10.2633 18 9.95332 18 9.33333 18H9C8.06812 18 7.60218 18 7.23463 17.8478C6.74458 17.6448 6.35523 17.2554 6.15224 16.7654C6 16.3978 6 15.9319 6 15V9C6 8.06812 6 7.60218 6.15224 7.23463C6.35523 6.74458 6.74458 6.35523 7.23463 6.15224C7.60218 6 8.06812 6 9 6H9.33333C9.95332 6 10.2633 6 10.5176 6.06815C11.2078 6.25308 11.7469 6.79218 11.9319 7.48236C12 7.7367 12 8.04669 12 8.66667'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

MoveIcon.propTypes = {
  ...iconColorPropTypes,
}

MoveIcon.defaultProps = {
  color: 'dark',
}
