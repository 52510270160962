import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import { Cookies } from 'react-cookie'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import billingAccountUpdate from '@graphql/gql/website/billingAccountUpdate.gql'
import billingAccountGet from '@graphql/gql/website/billingAccountGet.gql'
import { COOKIE, PERIOD } from 'packages/enum'
import LoginInfo from '@configurator/components/billing/loginInfo'

export default compose(
  graphql(billingAccountGet, {
    props({ data: { loading, billingAccountGet, refetch } }) {
      if (loading) {
        return { loading }
      }

      if (isEmpty(billingAccountGet)) {
        return { loading }
      }

      const { data } = billingAccountGet

      return {
        data,
        refetch,
      }
    },
  }),
  graphql(billingAccountUpdate, {
    props({ mutate }) {
      return {
        async billingAccountUpdateMutation({ email, name }) {
          try {
            const data = await mutate({
              variables: {
                email,
                name,
              },
              refetchQueries: [
                {
                  query: billingAccountGet,
                },
              ],
            })
            if (get(data, 'data.billingAccountUpdate.data.token')) {
              new Cookies().set(
                COOKIE.token,
                `bearer ${get(data, 'data.billingAccountUpdate.data.token')}`,
                {
                  expires: PERIOD.neverExpires,
                  path: '/',
                }
              )
            }
            return data
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: ({ data }) => {
      return {
        email: '',
        name: '',
        ...data,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (
      { email, name },
      { setSubmitting, setStatus, props: { billingAccountUpdateMutation, refetch } }
    ) => {
      let defaultError = 'error.desc'
      try {
        const res = await billingAccountUpdateMutation({ email, name })

        let {
          data: { billingAccountUpdate: { errors, success } = {} },
        } = res
        if (success) {
          await refetch()
          setSubmitting(false)
          return
        } else {
          setSubmitting(false)
        }
        setStatus({ error: errors._error || defaultError })
      } catch (err) {
        setSubmitting(false)
        setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      }
    },
  })
)(LoginInfo)
