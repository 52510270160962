import styled from 'styled-components'
import { Semantic } from 'packages/components'

export const Dropdown = styled(Semantic.Dropdown)`
  height: max-content !important;
  width: max-content !important;

  .dropdown.icon {
    display: none !important;
  }

  .menu.transition.visible {
    bottom: unset;
    border: none;
    border-radius: 2px;
    ${(props) => (props.$isLastItemInRow ? '' : 'right: -138px !important;')}
  }

  .ui.disabled.dropdown,
  .ui.dropdown .menu > .disabled.item {
    opacity: 1 !important;
  }
`

export const SettingsContent = styled.div`
  width: ${(props) => getSize(props.size)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
`

function getSize(sign?: 's' | 'm' | 'l') {
  switch (sign) {
    case 's':
      return 170
    case 'm':
      return 215
    case 'l':
      return 240
    default:
      return 170
  }
}

export const SettingsItemButton = styled.button`
  color: ${(props) => (props.color ? props.color : '#343840')};
  font-size: 13px;
  line-height: 140%;
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.header || props.disabled ? 'initial' : 'pointer')};
  font-weight: ${(props) => (props.header ? 'bold' : 'normal')};
  padding-left: ${(props) => (!props.isIconExist ? 22 : 4)}px;
  border-radius: 4px;
  margin-bottom: ${(props) => (props.isLastButton ? 0 : 4)}px;

  border: none;
  background: none;

  position: relative;

  &:hover {
    ${(props) => (props.header ? '' : 'background-color: #F6F6F6;')}
  }

  > span {
    width: 30px;
    text-align: center;
  }

  input {
    max-width: 1rem;
  }
`

export const IconWrapper = styled.span`
  margin-top: 4px;
  margin-right: 4px;
`
