/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const CropIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5 7.8H13.96C14.7441 7.8 15.1361 7.8 15.4356 7.95259C15.699 8.08681 15.9132 8.30099 16.0474 8.56441C16.2 8.86389 16.2 9.25593 16.2 10.04V19M19 16.2L10.04 16.2C9.25593 16.2 8.86389 16.2 8.56441 16.0474C8.30099 15.9132 8.08681 15.699 7.95259 15.4356C7.8 15.1361 7.8 14.7441 7.8 13.96V5'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

CropIcon.propTypes = {
  ...iconColorPropTypes,
}

CropIcon.defaultProps = {
  color: 'dark',
}
