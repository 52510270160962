import { graphql } from 'react-apollo'
import { compose } from 'redux'
import isEmpty from 'lodash/isEmpty'

import billingStripeTokenGet from '@graphql/gql/website/billingStripeTokenGet.gql'

import Billing from '@configurator/components/billing/Billing'

export const billingWrapper = (Component) =>
  compose(
    graphql(billingStripeTokenGet, {
      props({ data: { loading, billingStripeTokenGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(billingStripeTokenGet)) {
          return { loading }
        }
        const { data } = billingStripeTokenGet

        return {
          config: data,
        }
      },
    })
  )(Component)

export default billingWrapper(Billing)
