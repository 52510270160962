import { injectIntl, intlShape } from 'react-intl'

import { Button } from 'packages/components/buttons'

import { Expire, Status, TextBold, TextInterval } from '../../nodes'
import Card from '../Card'

const PastDueCard = ({ onManageSubscription, planName, interval, intl: { formatMessage } }) => {
  return (
    <Card
      planName={planName}
      Status={
        <Status status='error'>
          {formatMessage({ id: 'accountBilling.billing.status.pastDue' })}
        </Status>
      }
      alertBlock={formatMessage({ id: 'accountBilling.billing.alert.pastDue' })}
      RightBlock={
        <Button view='secondaryBlack' onClick={onManageSubscription}>
          {formatMessage({ id: 'accountBilling.billing.manage' })}
        </Button>
      }
      Expire={
        <Expire>
          <TextInterval>
            {formatMessage({ id: 'accountBilling.billing.paymentInterval' })}:
          </TextInterval>

          <TextBold>{interval}.</TextBold>
        </Expire>
      }
    />
  )
}

PastDueCard.propTypes = {
  intl: intlShape.isRequired,
}

PastDueCard.defaultProps = {}

export default injectIntl(PastDueCard)
