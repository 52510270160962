import React from 'react'
import { Tab, Tabs } from './nodes'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { SmallTitle } from '../../nodes'

const ModalTabs = React.memo(function ({
  tabs = [],
  activeIdx,
  onTabClick,
  intl: { formatMessage },
}) {
  return (
    <Tabs hidden={!tabs.length}>
      {tabs.map((t, idx) => (
        <Tab
          key={idx}
          active={activeIdx === idx}
          onClick={() => onTabClick(idx)}
          {...{ 'data-intercom-target': t.intercomAttribute }}
        >
          <SmallTitle gray={activeIdx !== idx}>{formatMessage({ id: t.text })}</SmallTitle>
        </Tab>
      ))}
    </Tabs>
  )
})

export default injectIntl(ModalTabs)

ModalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      intercomAttribute: PropTypes.string,
    })
  ),
  activeIdx: PropTypes.number,
  onTabClick: PropTypes.func,
}
ModalTabs.defaultProps = {
  onTabClick: () => {},
}
