import PropTypes from 'prop-types'
import React from 'react'

import { Modal } from 'packages/components/modal'

import { Content, Header, Code, Note, InstructionImage } from './nodes'

import CopyToClipboardButton from './copyToClipboardButton/CopyToClipboardButton'
import { Button } from 'packages/components/buttons'
import instruction from './instruction.jpg'

const CodeModal = ({ code, open, onClose, onProceed }, { intl: { formatMessage } }) => {
  return (
    <Modal open={open} closeOnDocumentClick onClose={onClose} size='large' closeIcon dimmer={false}>
      <Content>
        <Header>{formatMessage({ id: 'feature.share.code.ready' })}</Header>
        <Note>{formatMessage({ id: 'feature.share.code.saved' })}</Note>
        <CopyToClipboardButton
          textToCopy={code}
          successMessage={formatMessage({ id: 'feature.share.copied' })}
        >
          <Code>{code}</Code>
        </CopyToClipboardButton>
        <InstructionImage>
          <img src={instruction} alt=''></img>
        </InstructionImage>
        {onProceed ? (
          <Button
            type='submit'
            onClick={onProceed}
            content={formatMessage({ id: 'feature.share.code.proceed' })}
          />
        ) : null}
      </Content>
    </Modal>
  )
}

CodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

CodeModal.defaultProps = {
  open: false,
}

CodeModal.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default CodeModal
