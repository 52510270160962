import React from 'react'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { StyledCheckbox } from './nodes'

export const Checkbox = ({ ...props }) => <StyledCheckbox {...props} />

Checkbox.propTypes = {
  ...SemanticCheckbox.propTypes,
}

Checkbox.defaultProps = {}
