import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Root = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 40px;
    width: 40px;
    border: none;
    cursor: pointer;
    border-radius: 8px;

    ${(props) =>
      props.$variant === 'default' &&
      css`
        color: #242426;
        background-color: transparent;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
          background-color: #fff;
        }
      `}};

    ${(props) =>
      props.$variant === 'selectable' &&
      css`
        color: ${props.$isSelected ? '#242426' : '#9FA1A6'};
        background-color: ${(props) => (props.$isSelected ? '#fff' : 'transparent')};
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
          color: #242426;
        }
      `}};

    ${(props) =>
      props.$edge === 'start' &&
      css`
        margin-left: -8px;
      `}};

    ${(props) =>
      props.$edge === 'end' &&
      css`
        margin-right: -8px;
      `}};
`

export const IconButton = React.forwardRef(function IconButton(props, ref) {
  const { edge, variant = 'default', isSelected = false, ...rest } = props

  return <Root ref={ref} $edge={edge} $variant={variant} $isSelected={isSelected} {...rest} />
})

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  edge: PropTypes.oneOf(['start', 'end']),
  variant: PropTypes.oneOf(['default', 'selectable']),
  isSelected: PropTypes.bool,
}
