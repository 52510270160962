import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ width, height, color }) => css`
    &,
    & div,
    & div:after,
    & div:before {
      box-sizing: border-box;
    }
    & {
      position: relative;
      width: 80px;
      height: 80px;
      transform: rotate(45deg) scaleX(${width / 80}) scaleY(${height / 80});
      transform-origin: left top;
      color: ${color};
    }
    & div {
      top: -12px;
      left: 46px;
      position: absolute;
      width: 32px;
      height: 32px;
      background: currentColor;
      animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    & div:after,
    & div:before {
      content: ' ';
      position: absolute;
      display: block;
      width: 32px;
      height: 32px;
      background: currentColor;
    }
    & div:before {
      left: -24px;
      border-radius: 50% 0 0 50%;
    }
    & div:after {
      top: -24px;
      border-radius: 50% 50% 0 0;
    }
    @keyframes lds-heart {
      0% {
        transform: scale(0.95);
      }
      5% {
        transform: scale(1.1);
      }
      39% {
        transform: scale(0.85);
      }
      45% {
        transform: scale(1);
      }
      60% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(0.9);
      }
    }
  `}
`
