import { injectIntl, intlShape } from 'react-intl'
import moment from 'moment'
// import { Button } from 'packages/components/buttons'

import { UpgradeButton, TextBold, TextInterval, Status, Expire } from '../../nodes'
import Card from '../Card'

const CancelledCard = ({
  onSelectPlan,
  planName,
  // onDowngradeToFree,
  renewsOn,
  interval,
  intl: { formatMessage },
}) => {
  const expireDate = moment(renewsOn)

  const expireText = expireDate.format('DD.MM.YYYY')

  return (
    <Card
      planName={planName}
      Status={
        <Status status='error'>
          {formatMessage({ id: 'accountBilling.billing.status.expired' })}
        </Status>
      }
      alertBlock={formatMessage({ id: 'accountBilling.billing.alert.expired' })}
      RightBlock={
        <>
          <UpgradeButton onClick={() => onSelectPlan()}>
            {formatMessage({ id: 'accountBilling.billing.expiredButton' })}
          </UpgradeButton>
          {/* <Button view='primaryText' onClick={onDowngradeToFree}>
            {formatMessage({ id: 'accountBilling.billing.downgrade' })}
          </Button> */}
        </>
      }
      Expire={
        <Expire>
          {interval ? (
            <>
              <TextInterval>
                {formatMessage({ id: 'accountBilling.billing.paymentInterval' })}:
              </TextInterval>
              <TextBold>{interval}.</TextBold>
            </>
          ) : null}

          <TextInterval>{formatMessage({ id: 'accountBilling.billing.expiredOn' })}:</TextInterval>
          <TextBold>{expireText}</TextBold>
        </Expire>
      }
    />
  )
}

CancelledCard.propTypes = {
  intl: intlShape.isRequired,
}

CancelledCard.defaultProps = {}

export default injectIntl(CancelledCard)
