import PropTypes from 'prop-types'
import React from 'react'
import { FormDropdown } from 'packages/components/inputs'

const OccupationDropdown = (props) => {
  return (
    <FormDropdown
      {...props}
      upward={false}
      options={props.options.map((itm) => ({
        text: itm.occupation,
        value: itm.occupation,
      }))}
    />
  )
}

OccupationDropdown.propTypes = {
  options: PropTypes.array.isRequired,
}

export default OccupationDropdown
