import React, { memo, useEffect, useMemo } from 'react'
import { useDragLayer } from 'react-dnd'
import { useDispatch } from 'react-redux'
import throttle from 'lodash/throttle'
import { ALBUM_TYPES, PAGE_TYPES } from 'packages/enum'
import AlbumCustomDragLayer from './albumNode/item/CustomDragLayer'
import PageCustomDragLayer from './pageLinkNode/CustomDragLayer'
import { setDragCurrentOffset } from './action'

const CustomDragLayer = memo(() => {
  const { item, isDragging, currentOffset, initialOffset, itemType } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const dispatch = useDispatch()

  // TODO достаточно 1 на всю навигацию + можно поправить еще в альомах
  const dispatchChangeOffset = useMemo(
    () =>
      throttle(
        (args) => dispatch(setDragCurrentOffset(args)),
        250,
        { trailing: true, leading: false }
        /* 4fps достаточно, т.к. мы только хотим убрать драгнутый айтем в сторону */
      ),
    [dispatch]
  )

  // Видимо либа блокирует подписку на событие движения курсора, придется так
  useEffect(() => {
    dispatchChangeOffset(currentOffset)
  }, [currentOffset, dispatchChangeOffset])

  if (!isDragging) {
    return null
  }

  if (itemType === ALBUM_TYPES.FOOTER || itemType === ALBUM_TYPES.MAIN) {
    return (
      <AlbumCustomDragLayer
        currentOffset={currentOffset}
        initialOffset={initialOffset}
        item={item}
      />
    )
  }

  if (itemType === PAGE_TYPES.FOOTER || itemType === PAGE_TYPES.MAIN) {
    return (
      <PageCustomDragLayer
        currentOffset={currentOffset}
        initialOffset={initialOffset}
        startPage={item.startPage}
        type={item.itemType}
        title={item.title}
      />
    )
  }

  return null
})

export default CustomDragLayer
