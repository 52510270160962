import { compose } from 'redux'
import { withFormik } from 'formik'

import VideoComponent from '@configurator/components/slideshow/VideoTab'

export default compose(
  withFormik({
    mapPropsToValues: ({ initialValues }) => {
      return {
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
  })
)(VideoComponent)
