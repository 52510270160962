import React from 'react'
import PropTypes from 'prop-types'
import {
  OpenGraphPreviewWrapper,
  OpenGraphPreviewImg,
  OpenGraphPreviewDomain,
  OpenGraphPreviewInfo,
  OpenGraphPreviewTitle,
} from './nodes'
import { Description } from '../../nodes'

function OpenGraphPreview({ fileSrc, domain = '', title = '', seoDescription = '' }) {
  return (
    <OpenGraphPreviewWrapper>
      <OpenGraphPreviewImg>
        <img src={fileSrc} alt='' />
      </OpenGraphPreviewImg>
      <OpenGraphPreviewInfo>
        <OpenGraphPreviewTitle>{title}</OpenGraphPreviewTitle>
        <Description>{seoDescription}</Description>
        <OpenGraphPreviewDomain>{domain}</OpenGraphPreviewDomain>
      </OpenGraphPreviewInfo>
    </OpenGraphPreviewWrapper>
  )
}

export default OpenGraphPreview

OpenGraphPreview.propTypes = {
  fileSrc: PropTypes.string,
  domain: PropTypes.string,
  seoDescription: PropTypes.string,
  title: PropTypes.string,
}
