import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'
import { injectIntl, intlShape } from 'react-intl'
import { Formik, Form as FormikForm } from 'formik'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { DOMAIN_TYPES } from 'packages/constants'
import { PREMIUM_FAILURE_CODE, PREMIUM_STATUSES } from 'packages/enum'
import { FormInput } from 'packages/components/inputs'
import { Button } from 'packages/components/buttons/button'
import { RetryIcon } from 'packages/components/icons/basic/retry'
import moment from 'moment'
import {
  DomainWrapper,
  DomainWarning,
  DomainStatus,
  DomainDeleteButton,
  DomainRetryButton,
  DomainFormContainer,
  DomainRightContainer,
  DomainAttemps,
  ActiveWarning,
  WrapperInput,
  ConnectedStatus,
  InputText,
  DomainPreviewDesktop,
  BorderDesktop,
  BorderMobile,
  DomainPreviewMobile,
  WrapperButton,
  WrapperButtonMobile,
  WrapperRetryButton,
  DomainStatusTablet,
} from '../nodes'
import { CloudCheck, Remove } from 'packages/components/icons'

const DomainForm = ({
  domain,
  type = DOMAIN_TYPES.CUSTOM_DOMAIN,
  attemptCount,
  lastAttemptDate,
  premiumStatus,
  failureCode,
  isRequestExist,
  deleteDomain,
  changeDomain,
  domainRetry,
  isMobile,
  intl: { formatMessage },
}) => {
  const errorState =
    premiumStatus === PREMIUM_STATUSES.CERT_REQUEST_FAILED ||
    premiumStatus === PREMIUM_STATUSES.DNS_CHECKING_FAILED ||
    premiumStatus === PREMIUM_STATUSES.FAILURE

  const warningState = premiumStatus === PREMIUM_STATUSES.ACTIVE_WARNING

  const getErrorCodeMessage = (fc) => {
    switch (fc) {
      case PREMIUM_FAILURE_CODE.DNS_CHECKING_WRONG_IPV4: {
        return formatMessage({ id: 'website.domain.status.dnsCheckingWrongIPV4' })
      }
      case PREMIUM_FAILURE_CODE.DNS_CHECKING_CONSTRAINT_IPV6: {
        return formatMessage({ id: 'website.domain.status.dnsCheckingConstantIPV6' })
      }
      case PREMIUM_FAILURE_CODE.DNS_CHECKING_WRONG_IPV4_AND_CONSTRAINT_IPV6: {
        return formatMessage({ id: 'website.domain.status.wrongIPV4AndConstantIPV6' })
      }
      case PREMIUM_FAILURE_CODE.DNS_CHECKING_UNREGISTERED_DOMAIN: {
        return formatMessage({ id: 'website.domain.status.unregistredDomain' })
      }
      case PREMIUM_FAILURE_CODE.GENERIC_ERROR: {
        return formatMessage({ id: 'website.domain.status.genericError' })
      }
      default:
        return null
    }
  }
  const getPremiumStatusContent = () => {
    if (errorState) {
      return getErrorCodeMessage(failureCode)
    } else if (warningState) {
      return (
        <div>
          <ConnectedStatus>
            {formatMessage({ id: 'website.domain.status.connected' })}
          </ConnectedStatus>
          &nbsp;
          <CloudCheck />
        </div>
      )
    } else {
      switch (premiumStatus) {
        case PREMIUM_STATUSES.DNS_CHECKING: {
          return formatMessage({ id: 'website.domain.status.dnsChecking' })
        }
        case PREMIUM_STATUSES.DNS_VALID: {
          return formatMessage({ id: 'website.domain.status.dnsValid' })
        }
        case PREMIUM_STATUSES.ACTIVE: {
          return (
            <div>
              <span>{formatMessage({ id: 'website.domain.status.connected' })}</span>
              &nbsp;
              <CloudCheck />
            </div>
          )
        }
        case PREMIUM_STATUSES.READY_FOR_CONF: {
          return formatMessage({ id: 'website.domain.status.ssl' })
        }
        case PREMIUM_STATUSES.READY_FOR_CERT: {
          return formatMessage({ id: 'website.domain.status.ssl' })
        }
        case PREMIUM_STATUSES.DELETE: {
          return formatMessage({ id: 'website.domain.status.configuration' })
        }
        // case PREMIUM_STATUSES.FAILURE: {
        //     return formatMessage({ id: "website.domain.status.failed" });
        // }
        // case PREMIUM_STATUSES.CERT_REQUEST_FAILED: {
        //     return formatMessage({ id: "website.domain.status.sertFailed" });
        // }
        // case PREMIUM_STATUSES.DNS_CHECKING_FAILED: {
        //     return formatMessage({ id: "website.domain.status.dnsCheckingFailed" });
        // }
        default:
          return null
      }
    }
  }
  const renderPremiumStatus = () => (
    <DomainStatus
      warning={
        premiumStatus === PREMIUM_STATUSES.DNS_CHECKING ||
        premiumStatus === PREMIUM_STATUSES.DNS_VALID ||
        premiumStatus === PREMIUM_STATUSES.READY_FOR_CONF ||
        premiumStatus === PREMIUM_STATUSES.READY_FOR_CERT ||
        premiumStatus === PREMIUM_STATUSES.DELETE ||
        premiumStatus === PREMIUM_STATUSES.ACTIVE_WARNING
      }
      success={premiumStatus === PREMIUM_STATUSES.ACTIVE}
      error={errorState}
    >
      {getPremiumStatusContent()}
    </DomainStatus>
  )

  const freeDomainValidationSchema = Yup.object().shape({
    domain: Yup.string()
      .test('len', formatMessage({ id: 'website.settings.less' }), (val = '') => {
        return val.length < 100
      })
      .test('latin', formatMessage({ id: 'website.settings.validation.free' }), (val = '') => {
        return /^[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z0-9][\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z0-9-]{1,61}[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z0-9]$/.test(
          `${val}`
        )
      })
      .nullable(false)
      .required(formatMessage({ id: 'website.settings.validation.common' })),
  })

  const premiumDomainValidationSchema = Yup.object().shape({
    domain: Yup.string()
      .test('len', formatMessage({ id: 'website.settings.less' }), (val = '') => {
        return val.length < 100
      })
      .test('latin', formatMessage({ id: 'website.settings.validation.premium' }), (val = '') => {
        // eslint-disable-next-line max-len
        // prettier-ignore
        return /^[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z0-9][\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z0-9-]{1,61}[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z0-9]\.[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z]{2,}(\.[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-zA-Z]{2,})?$/.test(`${val}`)
          && !/^www\./.test(`${val}`);
      })
      .test(
        'has-vsble',
        formatMessage({ id: 'website.settings.validation.premium.vsbleForbidden' }),
        (val = '') => {
          return !/[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00dfa-z0-9.]*vsble.me$/i.test(`${val}`)
        }
      )
      .nullable(false)
      .required(formatMessage({ id: 'website.settings.validation.common' })),
  })

  const premiumDomainFilter = (event, setFieldValue) => {
    const value = event.target.value.replace(/^w{3}\./i, '')
    setFieldValue('domain', value)
  }

  const isCustomDomain = type === DOMAIN_TYPES.CUSTOM_DOMAIN
  const isPremiumDomain = type === DOMAIN_TYPES.PREMIUM_DOMAIN

  return (
    <DomainFormContainer>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={
          isPremiumDomain ? premiumDomainValidationSchema : freeDomainValidationSchema
        }
        initialValues={{
          domain,
        }}
        onSubmit={async ({ domain }, { setFieldError, setSubmitting }) => {
          try {
            await changeDomain({ domain })
          } catch ({ message }) {
            setFieldError('domain', message)
            setSubmitting(false)
          }
        }}
        render={(props) => {
          return (
            <FormikForm>
              <DomainWarning>
                {isCustomDomain ? formatMessage({ id: 'website.settings.dots' }) : null}
                {isPremiumDomain && premiumStatus !== PREMIUM_STATUSES.ACTIVE && isRequestExist
                  ? formatMessage({ id: 'website.settings.without.dots' })
                  : null}
              </DomainWarning>

              <DomainRightContainer>
                <DomainWrapper isPremiumDomain={isPremiumDomain} isCustomDomain={isCustomDomain}>
                  <WrapperInput isCustomDomain={isCustomDomain} isPremiumDomain={isPremiumDomain}>
                    <FormInput
                      name='domain'
                      onChange={(event) => premiumDomainFilter(event, props.setFieldValue)}
                      disabled={isRequestExist}
                      placeholder={formatMessage({ id: 'bootstrap.domain' })}
                      error={props.errors.domain || errorState}
                      errorText={props.errors.domain}
                      inlineNode={isPremiumDomain ? renderPremiumStatus() : null}
                    />
                  </WrapperInput>

                  {isRequestExist && (
                    <WrapperRetryButton isRetryButton>
                      {errorState && (
                        <DomainRetryButton onClick={domainRetry}>
                          <RetryIcon />
                          <span>{formatMessage({ id: 'website.settings.retry' })}</span>
                        </DomainRetryButton>
                      )}
                      <DomainDeleteButton onClick={deleteDomain}>
                        <Remove />
                        <span>{formatMessage({ id: 'website.settings.remove' })}</span>
                      </DomainDeleteButton>
                    </WrapperRetryButton>
                  )}
                  {isCustomDomain ? <InputText>.vsble.me</InputText> : null}
                </DomainWrapper>

                <DomainStatusTablet>
                  {isPremiumDomain ? renderPremiumStatus() : null}
                </DomainStatusTablet>

                {!isRequestExist && (
                  <WrapperButton isPremiumDomain={isPremiumDomain} isSaveChanges>
                    <Button
                      type='submit'
                      view='primary'
                      disabled={props.isSubmitting || domain === props.values.domain}
                      content={formatMessage({ id: 'bootstrap.confirm.change' })}
                    />
                  </WrapperButton>
                )}

                {isCustomDomain && isMobile === false && <BorderDesktop />}

                {isCustomDomain && isMobile === false && (
                  <DomainPreviewDesktop>
                    https://<span>{props.values.domain}</span>
                    {isCustomDomain ? '.vsble.me' : null}
                  </DomainPreviewDesktop>
                )}
              </DomainRightContainer>

              <DomainStatusTablet>
                {isPremiumDomain ? renderPremiumStatus() : null}
              </DomainStatusTablet>

              {!isRequestExist && isCustomDomain && isMobile && (
                <WrapperButtonMobile>
                  <Button
                    type='submit'
                    view='primary'
                    disabled={props.isSubmitting || domain === props.values.domain}
                    content={formatMessage({ id: 'bootstrap.confirm.change' })}
                  />
                </WrapperButtonMobile>
              )}

              {isPremiumDomain && isMobile === false && (
                <DomainPreviewDesktop>
                  https://<span>{props.values.domain}</span>
                </DomainPreviewDesktop>
              )}

              {isPremiumDomain && errorState && attemptCount && lastAttemptDate ? (
                <DomainAttemps>
                  Total attempts: {attemptCount}, Last attempt: {moment(lastAttemptDate).calendar()}
                </DomainAttemps>
              ) : null}
              {isPremiumDomain && warningState ? (
                <ActiveWarning>
                  {formatMessage({ id: 'domains.ssl.error' })} {getErrorCodeMessage(failureCode)}{' '}
                  {formatMessage({ id: 'domains.ssl.support' })}
                </ActiveWarning>
              ) : null}

              {(isCustomDomain || isPremiumDomain) && isMobile && (
                <BorderMobile isPremiumDomain={isPremiumDomain} />
              )}

              {(isCustomDomain || isPremiumDomain) && isMobile && (
                <DomainPreviewMobile>
                  https://<span>{props.values.domain}</span>
                  {isCustomDomain ? '.vsble.me' : null}
                </DomainPreviewMobile>
              )}
            </FormikForm>
          )
        }}
      />
    </DomainFormContainer>
  )
}
DomainForm.propTypes = {
  domain: PropTypes.string,
  type: PropTypes.string.isRequired,
  premiumStatus: PropTypes.string,
  failureCode: PropTypes.string,
  isRequestExist: PropTypes.bool,
  deleteDomain: PropTypes.func,
  changeDomain: PropTypes.func.isRequired,
  domainRetry: PropTypes.func,
  intl: intlShape.isRequired,
  ...formProptypes,
}

DomainForm.defaultProps = {
  domain: '',
  premiumStatus: '',
  isRequestExist: false,
  deleteDomain: () => null,
}

export default injectIntl(DomainForm)
