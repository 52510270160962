import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import MostViewedPage from '@configurator/containers/statistics/MostViewedPageContainer'
import MostViewedAlbum from '@configurator/containers/statistics/MostViewedAlbumContainer'
import MostViewedImage from '@configurator/containers/statistics/MostViewedImageContainer'
import { WrapperContainer } from './nodes'

const Wrapper = ({ subIds }) => (
  <WrapperContainer>
    <MostViewedPage idSubtable={get(subIds, 'category')} />
    <MostViewedAlbum idSubtable={get(subIds, 'album')} />
    <MostViewedImage idSubtable={get(subIds, 'image')} />
  </WrapperContainer>
)

Wrapper.propTypes = { subIds: PropTypes.object }

export default Wrapper
