import styled, { css } from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  background-color: white;
  padding: 1.5em;
  display: flex;
  flex-direction: column;

  ${media.max.phone`
        padding: 20px 16px;
    `}
`
export const Content = styled.div`
  display: flex;
`

export const ChartComponent = styled.div`
  width: 100%;

  ${({ hasData }) =>
    hasData &&
    css`
      ${media.max.tablet`
            .echarts-for-react {
                height: 600px !important;
            }
        `}
      ${media.max.phone`
            .echarts-for-react {
                height: 520px !important;
            }
        `}
    `}
`
