import _ from 'lodash/fp'
import { List, Map } from 'immutable'
import get from 'lodash/get'
import {
  LOCALE,
  LOCATION_TYPE,
  FEATURE_PROPERTY_KEYS,
  WEBSITE_MODE,
  MESSAGES_SPLIT_KEY,
} from '../enum'
import { STYLE_KEYS_FOR_THEME } from '../constants'

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const checkWeglotLocale = (siteLocale) => {
  const locales = Object.keys(LOCALE)
  return locales[siteLocale] ? siteLocale : null
}

export const getLocaleFromDomain = (domain = '') => {
  let locale = 'en'
  Object.keys(LOCALE).forEach((itm) => (domain.includes(`${itm}.`) ? (locale = itm) : ''))
  return locale
}

export const mergeWith = _.mergeWith((prev, more) => {
  if (_.isArray(prev)) {
    return prev.concat(more)
  }
})

export const getPageType = (location) => {
  const locationName = location
    .replace(`${__PORTFOLIO_URL__}/`, '')
    .replace('website/config/page', '')
    .replace('website/config/style', '')
    .replace('website/config', '')

  if (locationName === '/') {
    return LOCATION_TYPE.MAIN_PAGE
  }

  const blocks = locationName.split('/').filter((item) => !!item)

  // if location contains "/" - it is details view.
  const isDetailView = blocks.length > 1

  return isDetailView ? LOCATION_TYPE.DETAILS_PAGE : LOCATION_TYPE.UNKNOWN_PAGE
}

export function serialize(obj, prefix) {
  let str = []
  let p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? `${prefix}[${p}]` : p
      let v = obj[p]
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      )
    }
  }
  return str.join('&')
}

export const updateQueryStringParameter = (uri = '', key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`)
  } else {
    return `${uri + separator + key}=${value}`
  }
}

const domain = `https://${__DOMAIN__}/img`

export const getDomain = (path) => {
  // TODO remove after s3 migration
  return path.startsWith('https://') ? path : domain + path
}

export const getWebpValues = ({ type = 'THUMB', properties = {} }) => {
  if (!properties) {
    return {
      srcImage: '',
      srcSetWebpMap: [],
    }
  }
  const webPUrl_640 = properties['640webPUrl'] ? getDomain(properties['640webPUrl']) : undefined
  const webPUrl_800 = properties['800webPUrl'] ? getDomain(properties['800webPUrl']) : undefined
  const webPUrl_960 = properties['960webPUrl'] ? getDomain(properties['960webPUrl']) : undefined
  const webPUrl_1120 = properties['1120webPUrl'] ? getDomain(properties['1120webPUrl']) : undefined
  const webPUrl_1280 = properties['1280webPUrl'] ? getDomain(properties['1280webPUrl']) : undefined
  const webPUrl_1440 = properties['1440webPUrl'] ? getDomain(properties['1440webPUrl']) : undefined
  const webPUrl_1440_jpg = properties['1440jpgUrl']
    ? getDomain(properties['1440jpgUrl'])
    : undefined
  const webPUrl_1440_png = properties['1440pngUrl']
    ? getDomain(properties['1440pngUrl'])
    : undefined
  const webPUrl_1600 = properties['1600webPUrl'] ? getDomain(properties['1600webPUrl']) : undefined
  const webPUrl_1760 = properties['1760webPUrl'] ? getDomain(properties['1760webPUrl']) : undefined
  const webPUrl_1920 = properties['1920webPUrl'] ? getDomain(properties['1920webPUrl']) : undefined
  const webPUrl_2080 = properties['2080webPUrl'] ? getDomain(properties['2080webPUrl']) : undefined
  const webPUrl_2240 = properties['2240webPUrl'] ? getDomain(properties['2240webPUrl']) : undefined
  const webPUrl_2240_jpg = properties['2240jpgUrl']
    ? getDomain(properties['2240jpgUrl'])
    : undefined
  const webPUrl_2240_png = properties['2240pngUrl']
    ? getDomain(properties['2240pngUrl'])
    : undefined
  const url_480_gif = properties['480gifUrl'] ? getDomain(properties['480gifUrl']) : undefined
  const url_960_gif = properties['960gifUrl'] ? getDomain(properties['960gifUrl']) : undefined

  // fallback for the old 480 gifs
  if (url_960_gif || url_480_gif) {
    return {
      srcImage: `${url_960_gif || url_480_gif}`,
      srcSetWebpMap: [],
    }
  }
  if (type === 'THUMB') {
    return {
      srcImage: `${webPUrl_1440_png || webPUrl_1440_jpg}`,
      srcSetWebpMap: [
        {
          srcSetWebp: `${webPUrl_640} 1x, ${webPUrl_1280} 2x, ${webPUrl_1920} 3x, ${webPUrl_2240} 4x`,
          media: '(min-width: 600px)',
        },
        {
          srcSetWebp: `${webPUrl_960} 1x, ${webPUrl_1920} 2x, ${webPUrl_2240} 3x`,
          media: '(min-width: 600px)',
        },
      ],
    }
  } else {
    return {
      srcImage: `${webPUrl_2240_png || webPUrl_2240_jpg}`,
      srcSetWebpMap: [
        {
          srcSetWebp: `${webPUrl_640} 1x, ${webPUrl_960} 2x, ${webPUrl_1440} 3x, ${webPUrl_1920} 4x`,
          media: '(max-width: 480px)',
        },
        {
          srcSetWebp: `${webPUrl_640} 1x, ${webPUrl_1280} 2x, ${webPUrl_1920} 3x, ${webPUrl_2240} 4x`,
          media: '(max-width: 640px)',
        },
        {
          srcSetWebp: `${webPUrl_800} 1x, ${webPUrl_1600} 2x, ${webPUrl_2240} 3x`,
          media: '(max-width: 800px)',
        },
        {
          srcSetWebp: `${webPUrl_960} 1x, ${webPUrl_1920} 2x, ${webPUrl_2240} 3x`,
          media: '(max-width: 960px)',
        },
        {
          srcSetWebp: `${webPUrl_1120} 1x, ${webPUrl_2240} 2x`,
          media: '(max-width: 1120px)',
        },
        {
          srcSetWebp: `${webPUrl_1440} 1x, ${webPUrl_2240} 2x`,
          media: '(max-width: 1440px)',
        },
        {
          srcSetWebp: `${webPUrl_1600} 1x, ${webPUrl_2240} 2x`,
          media: '(max-width: 1600px)',
        },
        {
          srcSetWebp: `${webPUrl_1760} 1x, ${webPUrl_2240} 2x`,
          media: '(max-width: 1760px)',
        },
        {
          srcSetWebp: `${webPUrl_1920} 1x, ${webPUrl_2240} 2x`,
          media: '(max-width: 1920px)',
        },
        {
          srcSetWebp: `${webPUrl_2080} 1x, ${webPUrl_2240} 2x`,
          media: '(max-width: 2080px)',
        },
        {
          srcSetWebp: `${webPUrl_2240} 1x`,
          media: '(min-width: 2080px)',
        },
      ],
    }
  }
}

export const getDominantColor = ({ properties = [], backgroundColor }) => {
  if (!properties) {
    return {
      primary: backgroundColor,
      secondary: backgroundColor,
      rest: backgroundColor,
    }
  }
  const colors = properties.dominantColor || ''
  const [primary, secondary, rest] = colors.split(' ')
  return {
    primary: primary || backgroundColor,
    secondary: secondary || backgroundColor,
    rest: rest || backgroundColor,
  }
}

export const getActiveSubscription = (itm = {}) => {
  const subscription = get(itm, 'subscriptions', [])[0] || {}
  return {
    aboStatus: subscription.aboStatus,
    planType: subscription.planType,
    interval: subscription.interval,
    currentPeriodEnd: subscription.currentPeriodEnd,
    template: itm ? itm.template : '',
    subscriptions: get(itm, 'subscriptions', []),
    capabilities: subscription.capabilities,
    capabilitiesMap: subscription.capabilitiesMap,
    templates: subscription.templates,
    cancelAtPeriodEnd: subscription.cancelAtPeriodEnd,
    downgradedFromFreeManually: subscription.downgradedFromFreeManually,
  }
}

export const getStateFeatureProperty = (source, obj, property) => {
  return (
    (
      (
        ((source || List()).find((el) => el.get('featureName') === obj) || new Map()).get(
          'properties'
        ) || new List()
      ).find((el) => el.get('key') === property) || new Map()
    ).get('value') || null
  )
}

export const getStateFeatureId = (source, obj, property) => {
  return (
    (
      (
        ((source || List()).find((el) => el.get('featureName') === obj) || new Map()).get(
          'properties'
        ) || new List()
      ).find((el) => el.get('key') === property) || new Map()
    ).get('featureId') || null
  )
}

export const getFeatureProperty = (source, obj, property) => {
  return (
    (
      (((source || []).find((el) => el.featureName === obj) || {}).properties || []).find(
        (el) => el.key === property
      ) || {}
    ).value || null
  )
}

export const getStateThemeStyles = (features) => {
  const styles = {}
  for (let styleKey of STYLE_KEYS_FOR_THEME) {
    styles[styleKey] = getStateFeatureProperty(features, FEATURE_PROPERTY_KEYS.style, styleKey)
  }
  return styles
}

export const getThemeStyles = (features) => {
  const styles = {}
  for (let styleKey of STYLE_KEYS_FOR_THEME) {
    styles[styleKey] = getFeatureProperty(features, FEATURE_PROPERTY_KEYS.style, styleKey)
  }
  return styles
}

export const getWebfontValue = (features) => {
  return [
    features.HEADER_LINKS_FONT_ID,
    features.HEADER_LOGO_FONT_ID,
    features.HEADER_SUBLOGO_FONT_ID,
    features.FOOTER_FONT_ID,
    features.CONTACTS_FONT_ID,
    features.ALBUM_TITLE_FONT_ID,
    features.ALBUM_DESCRIPTION_FONT_ID,
    features.ALBUM_PHOTO_NOTE_FONT_ID,
    features.TEXT_BLOCK_FONT_ID,
    features.PAGE_NAVIGATION_FONT_ID,
  ]
}
export const getUserDomain = (host) => {
  const domain = __DEVELOPMENT__
    ? __TEST_USER__
    : host
        .replace('.artbrief-websites.com', '')
        .replace('.artbrief-websites-dev.com', '')
        .replace('.vsble-dev.com', '')
        .replace('.vsble.me', '')
        .replace('.vsble.online', '')
        .replace('www.', '')

  if (domain.includes('vsble-edit-')) {
    return {
      mode: WEBSITE_MODE.PRIVATE,
      domain: domain.replace('vsble-edit-', ''),
    }
  } else {
    return {
      mode: WEBSITE_MODE.PUBLIC,
      domain,
    }
  }
}

export const formatMessagesList = (messages = []) => {
  return messages.join(MESSAGES_SPLIT_KEY)
}

export const getMessagesList = (message = '') => {
  return message.split(MESSAGES_SPLIT_KEY)
}

export const getPropertyMediaUrl = (propertyName) => {
  return `${propertyName}MediaUrl`
}

export const getLoaderShortProperty = (propertyName) => {
  return propertyName.match(/[A-Z]*$/)[0].toLowerCase()
}

export const SLUG_VALIDATION_PATTERN = /^[a-zA-Z0-9-_]+$/
export const SPACES_REGEXP = /\s+/g
