import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { HeaderStyled } from 'packages/components/layout/nodes'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { Container, Header, Stack, Item, Badge } from './nodes'
import Image from 'packages/components/gatsby-image'
import ecwid from '../assets/list/ecwid.png'
import tawkto from '../assets/list/tawkto.png'
import livechat from '../assets/list/livechat.png'
import chatra from '../assets/list/chatra.png'
import helpspace from '../assets/list/helpspace.png'
import calendly from '../assets/list/calendly.png'
import tidio from '../assets/list/tidio.png'
import blogger from '../assets/list/blogger.png'
import optinly from '../assets/list/optinly.png'
import weglot from '../assets/list/weglot.png'
import typeform from '../assets/list/typeform.png'
import jotform from '../assets/list/jotform.png'
import dropinblog from '../assets/list/dropinblog.png'
import customIntegration from '../assets/list/customintegrations.png'
import { Button } from 'packages/components/buttons'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { URLS } from 'packages/enum'
import { useSubscription } from '@configurator/providers/subscription'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'

const integrations = [
  {
    img: customIntegration,
    aspectRatio: 2.25,
    redirectLink: 'custom',
    disabled: false,
  },
  {
    img: ecwid,
    aspectRatio: 2.25,
    redirectLink: 'ecwid',
    disabled: false,
  },
  {
    img: tawkto,
    aspectRatio: 2.25,
    redirectLink: 'tawk',
    disabled: false,
  },
  {
    img: livechat,
    aspectRatio: 2.25,
    redirectLink: 'livechat',
    disabled: false,
  },
  {
    img: chatra,
    aspectRatio: 2.25,
    redirectLink: 'chatra',
    disabled: false,
  },
  {
    img: helpspace,
    aspectRatio: 2.25,
    redirectLink: 'helpspace',
    disabled: false,
  },
  {
    img: tidio,
    aspectRatio: 2.25,
    redirectLink: 'tidio',
    disabled: false,
  },
  {
    img: blogger,
    aspectRatio: 2.25,
    redirectLink: 'blogger',
    disabled: false,
  },
  {
    img: optinly,
    aspectRatio: 2.25,
    redirectLink: 'optinly',
    disabled: false,
  },
  {
    img: weglot,
    aspectRatio: 2.25,
    redirectLink: 'weglot',
    disabled: false,
  },
  // {
  //     img: mailchimp,
  //     aspectRatio: 2.25,
  //     redirectLink: "mailchimp",
  //     disabled: true,
  // },
  // {
  //     img: eventbrite,
  //     aspectRatio: 2.25,
  //     redirectLink: "eventbrite",
  //     disabled: true,
  // },
  {
    img: calendly,
    aspectRatio: 2.25,
    redirectLink: 'calendly',
    disabled: false,
  },
  {
    img: typeform,
    aspectRatio: 2.25,
    redirectLink: 'typeform',
    disabled: false,
  },
  {
    img: jotform,
    aspectRatio: 2.25,
    redirectLink: 'jotform',
    disabled: false,
  },
  {
    img: dropinblog,
    aspectRatio: 2.25,
    redirectLink: 'dropinblog',
    disabled: false,
  },
]
const IntegrationList = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const { permissions } = useSubscription()

  const redirect = (path) => dispatch(push(`${URLS.website_integrations}/${path}`))

  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header data-intercom-target={'IntegrationsPage'}>
          {formatMessage({
            id: 'integration.name',
          })}
        </Header>
        <Stack>
          {integrations.map((itm) => (
            <Item key={itm.redirectLink}>
              {itm.disabled && (
                <Badge>
                  <div>
                    {formatMessage({
                      id: 'integration.soon',
                    })}
                  </div>
                </Badge>
              )}
              <Image
                fluid={{
                  src: itm.img,
                  aspectRatio: itm.aspectRatio,
                }}
                alt=''
                fadeIn
                imgStyle={{ objectFit: 'contain' }}
                loading='lazy'
                backgroundColor='white'
              />
              <PermissionsOverlay isAllowed={permissions.INTEGRATIONS} proMax={true}>
                <Button
                  data-intercom-target={
                    itm.redirectLink === 'custom' ? 'CustomIntegration' : undefined
                  }
                  disabled={itm.disabled}
                  content={formatMessage({
                    id: 'integration.edit.settings',
                  })}
                  onClick={() => {
                    ANALYTICS.trackIntegration(itm.redirectLink)
                    redirect(itm.redirectLink)
                  }}
                />
              </PermissionsOverlay>
            </Item>
          ))}
        </Stack>
      </Container>
    </>
  )
}

IntegrationList.propTypes = {
  intl: intlShape.isRequired,
  config: PropTypes.object,
}

IntegrationList.defaultProps = {}

export default injectIntl(IntegrationList)
