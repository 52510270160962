import { FormRadio } from 'packages/components/inputs'
import React from 'react'
import { Description } from '@configurator/components/modals/nodes'
import {
  DisplayMode,
  DisplayModeOptions,
  DisplayModeOptionTab,
  EntireZoomOption,
  ExpandedPreview,
  StackedPreview,
  StackedImage,
  ExpandedPreviewRow,
  ExpandedPreviewRowItem,
  StyledRadio,
} from '../pageCreate/nodes'
import PropTypes from 'prop-types'
import albumExpanded1 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_1.svg'
import albumExpanded2 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_2.svg'
import albumExpanded3 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_3.svg'
import albumExpanded4 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_4.svg'
import albumStacked from 'src/packages/theme-ab/resources/assets/configurator/album_stacked.png'
import { injectIntl } from 'react-intl'

function DisplayModeTab({ intl: { formatMessage }, values, setFieldValue }) {
  return (
    <DisplayMode>
      <DisplayModeOptions>
        <DisplayModeOptionTab
          active={values.stacked}
          onClick={() => {
            return setFieldValue('stacked', true)
          }}
        >
          {formatMessage({
            id: 'newDesign.page.update.field.displayMode.stacked',
          })}
        </DisplayModeOptionTab>
        <DisplayModeOptionTab
          active={values.expanded}
          onClick={() => {
            return setFieldValue('expanded', true)
          }}
        >
          {formatMessage({
            id: 'newDesign.page.update.field.displayMode.expanded',
          })}
        </DisplayModeOptionTab>
      </DisplayModeOptions>
      <StackedPreview hide={!values.stacked}>
        <Description>
          {formatMessage({
            id: 'newDesign.page.update.field.displayMode.desc',
          })}
        </Description>
        <StackedImage>
          <img src={albumStacked} alt='' />
          <span>
            {formatMessage({
              id: 'newDesign.page.update.field.displayMode.albumTitle',
            })}
          </span>
        </StackedImage>
      </StackedPreview>
      {values.expanded && (
        <>
          <EntireZoomOption>
            <StyledRadio>
              <FormRadio name='album' value />
              <span>
                {formatMessage({
                  id: 'newDesign.page.update.field.displayMode.album',
                })}
              </span>
            </StyledRadio>

            <StyledRadio>
              <FormRadio name='zoom' value />
              <span>
                {formatMessage({
                  id: 'newDesign.page.update.field.displayMode.zoom',
                })}
              </span>
            </StyledRadio>
          </EntireZoomOption>
          <ExpandedPreview>
            <ExpandedPreviewRow>
              <ExpandedPreviewRowItem>
                <img src={albumExpanded1} alt='' />
                <span>
                  {formatMessage({
                    id: 'newDesign.page.update.field.displayMode.albumTitle',
                  })}
                </span>
              </ExpandedPreviewRowItem>
              <ExpandedPreviewRowItem>
                <img src={albumExpanded2} alt='' />
              </ExpandedPreviewRowItem>
              <ExpandedPreviewRowItem>
                <img src={albumExpanded3} alt='' />
              </ExpandedPreviewRowItem>
              <ExpandedPreviewRowItem>
                <img src={albumExpanded4} alt='' />
              </ExpandedPreviewRowItem>
            </ExpandedPreviewRow>
          </ExpandedPreview>
        </>
      )}
    </DisplayMode>
  )
}

export default injectIntl(DisplayModeTab)

DisplayModeTab.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
}
