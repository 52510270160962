import styled from 'styled-components'

export const TabsWrapper = styled.ul`
  width: 100%;
`

export const TabItem = styled.li`
  list-style: none;
  margin-bottom: 30px;

  & > a {
    font-size: 18px;
    cursor: pointer;
    color: ${(props) => (props.active ? '#2EBA76' : '#242426')};
    font-weight: ${(props) => (props.active ? '700' : '400')};
  }
`
