import styled from 'styled-components'
import Slider from 'rc-slider'

export const Container = styled.div`
  width: 100%;
`

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
`

export const BottomMessagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;

  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
`

export const StyledSlider = styled(Slider)`
  & .rc-slider-rail {
    background-color: #e1e2e6 !important;
    height: 2px;
    border-radius: 0 !important;
  }
  & .rc-slider-track {
    display: none !important;
  }
  & .rc-slider-handle {
    width: 15px;
    height: 15px;
    background: #f6f6f6;
    border: 3px solid #343840;
    box-sizing: border-box;
    margin-top: -7px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:active {
      box-shadow: none !important;
    }
  }
  & .rc-slider-tip {
    background-color: #fff;
    padding: 4px 6px;
    position: absolute;
    border-radius: 2px;
    top: -32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1;
    border: 1px solid rgba(34, 36, 38, 0.15);
    &::before {
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 4px 0 4px;
      border-color: #fff transparent transparent transparent;
      content: '';
      z-index: 2;
    }
    &::after {
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 4px 0 4px;
      border-color: rgba(34, 36, 38, 0.15) transparent transparent transparent;
      content: '';
      z-index: 0;
    }
  }
`
