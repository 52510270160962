import { compose } from 'redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import get from 'lodash/get'

import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'
import { FEATURE_PROPERTY_KEYS, MESSAGE_TYPE } from 'packages/enum'

import featureUpdateWithPhoto from '@graphql/gql/website/featureUpdateWithPhoto.gql'
import featureUpdate from '@graphql/gql/website/featureUpdate.gql'
import featurePropertyDelete from '@graphql/gql/website/featurePropertyDelete.gql'
import featureGet from '@graphql/gql/website/featureGet.gql'

import PdfTool from '@configurator/components/pdfTool/PdfTool'

export const pdfToolWrapper = (Component) =>
  compose(
    connect(
      // mapStateToProps
      (state) => ({
        uploads: state.getIn(['uploadPhotos', 'uploads']),
        websiteId: state.getIn(['preview', 'meta', 'id']),
      }),
      // mapDispatchToProps
      (dispatch) => ({
        lockModalProgress: () => dispatch(lockModal(true)),
        unLockModalProgress: () => dispatch(lockModal(false)),
        openErrorModal: ({ headerMessageId, yesMessageId, subMessageId, subMessageValues }) =>
          dispatch(
            openErrorModal({
              headerMessageId,
              yesMessageId,
              subMessageId,
              subMessageValues,
              hideCancelButton: true,
            })
          ),
      })
    ),
    graphql(featureUpdateWithPhoto, {
      props({ mutate }) {
        return {
          async pdfLogoUpdate({ featureId, file }) {
            try {
              return await mutate({
                variables: {
                  featureId,
                  file,
                  propertyName: 'logo',
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(featureUpdate, {
      props({ mutate }) {
        return {
          async featureUpdate({ featureId, enabled, properties }) {
            try {
              return await mutate({
                variables: {
                  featureId,
                  enabled,
                  properties,
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(featureGet, {
      options({ websiteId }) {
        return {
          variables: {
            websiteId,
            name: 'pdf',
          },
          ssr: false,
          fetchPolicy: 'network-only',
        }
      },
      props({ data: { loading, featureGet, refetch } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(featureGet)) {
          return
        }
        const { data } = featureGet

        const getLogoSizePercentValue = () => {
          const range = data.properties
            ? get(
                data.properties.find((prop) => prop.key === FEATURE_PROPERTY_KEYS.pdfLogoSize),
                'value'
              ) || 0.5
            : 0.5
          if (typeof range === 'string') {
            return parseFloat(range)
          }
          return range
        }

        return {
          initialValues: { logoSizePercent: getLogoSizePercentValue(), ...data },
          refetchData: refetch,
        }
      },
    }),
    graphql(featurePropertyDelete, {
      props({ mutate }) {
        return {
          async featurePropertyDelete({ propertyId }) {
            try {
              return await mutate({
                variables: {
                  propertyId,
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        return {
          ...initialValues,
        }
      },
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: false,
      handleSubmit: async (
        { file, id, enabled, footer, logoSizePercent },
        {
          setSubmitting,
          setStatus,
          props: { onClose, lockModalProgress, unLockModalProgress, pdfLogoUpdate, featureUpdate },
        }
      ) => {
        let defaultError = 'page.update.error.header'

        if (file) {
          lockModalProgress()
          try {
            const res = await pdfLogoUpdate({
              featureId: id,
              file,
            })
            unLockModalProgress()
            let {
              data: { featureUpdateWithPhoto: { errors, success } = {} },
            } = res
            if (!success) {
              unLockModalProgress()
              setSubmitting(false)
              return setStatus({ error: errors._error || defaultError })
            }
          } catch (err) {
            unLockModalProgress()
            setSubmitting(false)
            return setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
          }
        }

        try {
          const res = await featureUpdate({
            featureId: id,
            properties: {
              [FEATURE_PROPERTY_KEYS.footer]: footer,
              [FEATURE_PROPERTY_KEYS.pdfLogoSize]: logoSizePercent,
            },
            enabled,
          })
          let {
            data: { featureUpdate: { errors, success } = {} },
          } = res
          if (success) {
            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_META,
              }),
              '*'
            )
            return onClose()
          }
          setStatus({ error: errors._error || defaultError })
        } catch (err) {
          setSubmitting(false)
          return setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
        }
      },
    })
  )(Component)

export default pdfToolWrapper(PdfTool)
