import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Semantic } from 'packages/components'
import { Link } from 'react-router-dom'
import { Cookies } from 'react-cookie'
import { getInitials } from 'packages/utils/getInitial'
import { COOKIE, URLS } from 'packages/enum'
import { FormattedMessage } from 'react-intl'
import { nameSelector, emailSelector } from 'packages/selectors/User'
import LocalSwitcher from './LocalSwitcher'
import { EllipseWrapper, UserDropdownHeader } from '../layout/nodes'
import { UserDropdownContainer, Initials } from './nodes'
import { getShortName } from 'packages/utils/getShortName'
import { ChevronDown } from './icons'
// import AffiliateInfoBanner from 'packages/components/header/affiliateInfoBanner'

const cookies = new Cookies()

export const UserDropdown = () => {
  const name = useSelector(nameSelector)
  const email = useSelector(emailSelector)
  const initials = useMemo(() => getInitials(name), [name])

  const handleClickLogout = () => {
    cookies.remove(COOKIE.token, { path: '/' })
    window.location = URLS.website_login
  }

  return (
    <UserDropdownContainer
      data-testid='main-page-profile-dropdown'
      pointing='top right'
      icon={<ChevronDown />}
      trigger={<Initials>{getShortName(name)}</Initials>}
    >
      <Semantic.Dropdown.Menu>
        <Semantic.Dropdown.Header>
          <UserDropdownHeader>
            <EllipseWrapper inverted>
              <div>{initials}</div>
            </EllipseWrapper>
            <div className='dropdown-header-text'>
              <div>{name}</div>
              <div>{email}</div>
            </div>
          </UserDropdownHeader>
        </Semantic.Dropdown.Header>
        <Semantic.Dropdown.Item
          text={<FormattedMessage id='user.menu.accountAndBilling' />}
          as={Link}
          to={URLS.website_billing}
        />
        <Semantic.Dropdown.Item
          text={<FormattedMessage id='user.menu.domainAndWebsite' />}
          as={Link}
          to={URLS.website_settings}
        />
        <Semantic.Dropdown.Item
          text={<FormattedMessage id='user.menu.googleSeo' />}
          as={Link}
          to={URLS.website_seo}
        />
        <Semantic.Dropdown.Item
          text={<FormattedMessage id='user.menu.services' />}
          as={Link}
          to={URLS.website_services}
        />
        <Semantic.Dropdown.Item
          text={<FormattedMessage id='user.menu.integrations' />}
          as={Link}
          to={URLS.website_integrations}
        />
        <Semantic.Dropdown.Item as={LocalSwitcher} />
        <Semantic.Dropdown.Divider />
        {/* <Semantic.Dropdown.Item as={AffiliateInfoBanner} /> */}
        <Semantic.Dropdown.Item
          text={<FormattedMessage id='user.menu.logout' />}
          onClick={handleClickLogout}
        />
      </Semantic.Dropdown.Menu>
    </UserDropdownContainer>
  )
}
