import { injectIntl } from 'react-intl'

import { AccordionTitle } from './nodes'
import { ArrowUp, ArrowDown } from 'packages/components/icons'

const AccordionItem = ({ title, isOpen, onToggle, children, intl: { formatMessage } }) => {
  return (
    <>
      <div onClick={onToggle}>
        <AccordionTitle active={isOpen}>
          {formatMessage({ id: `${title}` })}

          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </AccordionTitle>
      </div>

      {isOpen && <div>{children}</div>}
    </>
  )
}

export default injectIntl(AccordionItem)
