import { Map } from 'immutable'
import { handleActions } from 'redux-actions'
import transform from 'lodash/transform'
import { updateStatus, closeBar } from 'packages/redux/modules/modalProgress/actions.js'

const defaultState = Map({
  uploads: Map({}),
})

export default handleActions(
  {
    [updateStatus]: {
      next(state, { payload }) {
        let files = transform(
          payload.fileNames,
          (acc, fileName) => (acc[fileName] = payload.percent),
          {}
        )
        return state.mergeDeep({ uploads: Map(files) })
      },
    },
    [closeBar]: {
      next(state, { payload: fileNames }) {
        return state.withMutations((map) =>
          fileNames.map((fileName) => map.removeIn(['uploads', fileName]))
        )
      },
    },
  },
  defaultState
)
