import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { renderRoutes } from 'react-router-config'
import ModalYesNo from 'packages/containers/ModalYesNo'
import ModalProgress from 'packages/containers/ModalProgress'
import style from './home.less'

export default class Home extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static contextTypes = {
    userAgent: PropTypes.shape({
      isMobile: PropTypes.bool,
    }),
  }

  render() {
    const { route } = this.props

    return (
      <>
        <Helmet>
          <link rel='stylesheet' href='https://unpkg.com/react-table@6.8.2/react-table.css' />
        </Helmet>
        <section className={style.reactRoot}>
          <ModalYesNo />
          <ModalProgress />
          {renderRoutes(route.routes)}
        </section>
      </>
    )
  }
}
