import _ from 'lodash'

export const getTitle = (title, formatMessage) =>
  typeof title === 'string' && /^\w+(\.\w+)+$/.test(title) ? formatMessage({ id: title }) : title

export const fieldFormatter = (msg, field) => `${_.capitalize(field)}: ${msg}`
export const getErrorMessage = (errors, formatMessage) =>
  _.chain(errors)
    .mapValues((error) => getTitle(error, formatMessage))
    .map(fieldFormatter)
    .join('\n')
    .value()

export const intlDuck = {
  formatMessage: (text) => text,
}
