import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const RectangleRightIcon = ({ color }) => (
  <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6 4L1.50571e-07 7.4641L4.53412e-07 0.535898L6 4Z' fill={iconColors[color]} />
  </svg>
)

RectangleRightIcon.propTypes = {
  ...iconColorPropTypes,
}

RectangleRightIcon.defaultProps = {
  color: 'dark',
}
