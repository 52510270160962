import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Root = styled.div`
  width: 100%;
  padding: 30px 30px 60px;

  ${media.max.tablet`
    padding-left: 20px;
    padding-right: 20px;
  `}

  ${media.max.phone`
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 80px;
  `}
`

export const Header = styled.div`
  max-width: 1220px;
  margin: 0 auto 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  /* Dark */
  color: #343840;

  ${media.max.phone`
    font-size: 24px;
    line-height: 150%;
  `}
`

export const Body = styled.div`
  max-width: 1220px;
  margin: 0 auto;
`

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px 90px;

  ${media.max.laptop`
    gap: 40px 50px;
  `}

  ${media.max.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.max.phone`
    grid-template-columns: 1fr;
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardTitle = styled.div`
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -1px;
  color: #343840;
`

export const CardDescription = styled.div`
  flex-grow: 1;
  margin-bottom: 16px;
  color: #343840;
  letter-spacing: -0.6px;
  font-weight: 500;
`
