import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'react-apollo'
import { useState } from 'react'

import websiteFaviconUpdate from '@graphql/gql/website/websiteFaviconUpdate.gql'
import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql'
import { Button } from 'packages/components/buttons'
import Image from 'packages/components/image/Image'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
import { BrowserTabPreview } from 'packages/icons/basic/browserTabPreview'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import websiteFaviconDelete from '@graphql/gql/website/websiteFaviconDelete.gql'
import { MAX_FAVICON_SIZE } from '@configurator/constants/upload'
import { getDomain } from 'packages/helpers/Helper'
import { MESSAGE_TYPE } from 'packages/enum'

import Loader from '../../loader/Loader'
import DropModal from '../dropModal'

import {
  Field,
  Paragraph,
  UploadPicture,
  RightPanelBlock,
  Description,
  Container,
  AcceptedFiles,
  TitleRightPanelBlock,
  TabPreview,
  FaviconBox,
  DomainBox,
  TitleBox,
} from '../nodes'

const Favicon = ({
  isSubmitting,
  premiumDomain,
  domainData,
  title,
  setSubmitting,
  lockModalProgress,
  unLockModalProgress,
  loaderStart,
  loaderStop,
  openErrorModal,
  intl: { formatMessage },
}) => {
  const { permissions } = useSubscription()

  const [isFaviconFileReady, setIsFaviconFileReady] = useState(false)
  const [showModalDeleteImage, setShowModalDeleteImage] = useState(false)

  const [newFavicon, setNewFavicon] = useState()
  const [previewFavicon, setPreviewFavicon] = useState()

  const [dropModalOpen, setDropModalOpen] = useState(false)

  const metaGetOptions = {
    variables: {},
    fetchPolicy: 'network-only',
  }

  const {
    loading,
    data: { websiteMetaGet } = {},
    refetch: refetchMeta,
  } = useQuery(websiteMetaGetQuery, metaGetOptions)
  const [deleteFavicon] = useMutation(websiteFaviconDelete)
  const [updateFavicon] = useMutation(websiteFaviconUpdate)

  if (loading) {
    return <Loader />
  }

  const favicon = websiteMetaGet?.data?.favicon

  const hasFaviconFromServer = favicon && !newFavicon

  const handleDropFile = (preview, file) => {
    setIsFaviconFileReady(true)

    setNewFavicon(file)
    setPreviewFavicon(preview)

    setDropModalOpen(false)
  }

  const handleImageDelete = async () => {
    if (hasFaviconFromServer) {
      deleteFavicon()
        .then(refetchMeta)
        .then(() =>
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )
        )
    }

    setPreviewFavicon(undefined)
    setIsFaviconFileReady(false)
    setNewFavicon(undefined)
    setShowModalDeleteImage(false)
  }

  const onSave = async () => {
    if (newFavicon) {
      const defaultError = 'error.desc'

      loaderStart()

      try {
        lockModalProgress()

        const res = await updateFavicon({
          variables: {
            file: newFavicon,
          },
        })

        unLockModalProgress()

        let {
          data: { websiteFaviconUpdate: { success } = {} },
        } = res

        if (!success) {
          loaderStop()
          unLockModalProgress()
          setSubmitting(false)

          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId: defaultError,
            yesMessageId: 'OK',
          })
        } else {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )
          refetchMeta()

          setNewFavicon(undefined)
          setPreviewFavicon(undefined)
          loaderStop()
        }
      } catch (err) {
        unLockModalProgress()
        loaderStop()
        setSubmitting(false)

        return openErrorModal({
          headerMessageId: 'error.header',
          subMessageId: defaultError,
          yesMessageId: 'OK',
        })
      }
    }
  }

  const existFavicon = websiteMetaGet?.data?.favicon || newFavicon

  const domain = `https://${premiumDomain || `${domainData}.vsble.me`}`

  const fileExist = existFavicon ? 'primaryRed' : 'secondaryBlack'
  const buttonPreview = existFavicon
    ? 'seo.browserIcon.DeleteFavicon'
    : 'seo.browserIcon.uploadFavicon'

  const isDisabledSubmit = !previewFavicon || isSubmitting

  return (
    <>
      <Container>
        <Field>
          <PermissionsOverlay isAllowed={permissions.UPLOADABLE_LOGO}>
            <Paragraph mb='8' type='h1' fz='18px'>
              {formatMessage({ id: 'seo.browserIcon' })}
            </Paragraph>

            <Description mb='14'>
              {formatMessage({ id: 'seo.browserIcon.description' })}
            </Description>

            <UploadPicture>
              <Button
                type='button'
                disabled={isSubmitting}
                view={fileExist}
                onClick={() => {
                  if (existFavicon) {
                    setShowModalDeleteImage(true)
                  } else {
                    setDropModalOpen(true)
                  }
                }}
                content={formatMessage({ id: buttonPreview })}
              />

              <Button
                onClick={onSave}
                view='primary'
                disabled={isDisabledSubmit}
                content={formatMessage({ id: 'seo.save' })}
              />
            </UploadPicture>

            <AcceptedFiles>{formatMessage({ id: 'seo.browserIcon.files' })}</AcceptedFiles>
          </PermissionsOverlay>
        </Field>

        <RightPanelBlock>
          <TitleRightPanelBlock>
            {formatMessage({ id: 'seo.browserIcon.preview' })}
          </TitleRightPanelBlock>

          {hasFaviconFromServer ? (
            <TabPreview>
              <BrowserTabPreview />

              <FaviconBox>
                <Image
                  imgStyle={{ width: '20px', height: '20px', objectFit: 'contain' }}
                  src={getDomain(favicon?.hashPath)}
                  alt='Favicon'
                />
              </FaviconBox>

              <TitleBox>{title}</TitleBox>

              <DomainBox>{domain}</DomainBox>
            </TabPreview>
          ) : isFaviconFileReady ? (
            <TabPreview>
              <BrowserTabPreview />

              <FaviconBox>
                {previewFavicon?.preview && (
                  <Image
                    src={previewFavicon.preview}
                    imgStyle={{ objectFit: 'contain' }}
                    alt='Favicon'
                  />
                )}
              </FaviconBox>

              <TitleBox>{title}</TitleBox>

              <DomainBox>{domain}</DomainBox>
            </TabPreview>
          ) : (
            <TabPreview>
              <BrowserTabPreview isDefault />

              <TitleBox>{title}</TitleBox>

              <DomainBox>{domain}</DomainBox>
            </TabPreview>
          )}
        </RightPanelBlock>
      </Container>

      <DropModal
        isOpen={dropModalOpen}
        onClose={() => {
          setDropModalOpen(false)
        }}
        onDrop={handleDropFile}
        maxSize={MAX_FAVICON_SIZE}
        accept='image/jpeg, image/png, image/svg'
        openErrorModal={openErrorModal}
        textInfoId={'upload.video.text.info2'}
      />

      <ModalConfirmDelete
        open={showModalDeleteImage}
        onClickYes={handleImageDelete}
        onClickCancel={() => setShowModalDeleteImage(false)}
        onClose={() => setShowModalDeleteImage(false)}
      />
    </>
  )
}

Favicon.propTypes = {
  intl: intlShape.isRequired,
  isSubmitting: PropTypes.bool,
}

Favicon.defaultProps = {}

export default injectIntl(Favicon)
