import styled from 'styled-components'

export const ResendCodeButton = styled.button`
  color: #55bd64;
  font-size: 13px;
  line-height: 16px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  border: none;

  transition: 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
  }
`

export const SentCodeText = styled.p`
  color: #55bd64;
  font-size: 13px;
  line-height: 16px;
  margin-top: 2px;
  margin-left: auto;
`
