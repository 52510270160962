import { Link, useHistory } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import { useSearchParam } from '@configurator/hooks/useSearchParam'

import { TabItem, TabsWrapper } from './nodes'

const Tabs = ({ tabs, active, setActive, intl: { formatMessage } }) => {
  const { push } = useHistory()

  useSearchParam({
    key: 'tab',
    onSuccess: (slug) => {
      if (active !== slug) setActive(slug)
    },
    onFailure: () => {
      setActive(tabs[0].slug)

      push({ search: `?tab=${tabs[0].slug}` })
    },
  })

  const handleClick = (slug) => {
    setActive(slug)
  }

  return (
    <TabsWrapper>
      {tabs?.map((tab) => (
        <TabItem
          key={tab.id}
          active={active === tab.slug}
          data-intercom-target={tab.dataIntercomTarget || ''}
        >
          <Link onClick={() => handleClick(tab.slug)} to={`?tab=${tab.slug}`}>
            {formatMessage({ id: `${tab.title}` })}
          </Link>
        </TabItem>
      ))}
    </TabsWrapper>
  )
}

export default injectIntl(Tabs)
