import styled from 'styled-components'
import { Semantic } from 'packages/components'
import { Link } from 'react-router-dom'
import { media } from 'packages/components/media'

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  color: #343840;
`

export const Arrow = styled(Semantic.Icon)`
  font-size: 36px !important;
  height: auto !important;
`

export const StyledLink = styled(Link)`
  color: black !important;

  &:hover {
    color: black !important;
  }
`

export const Header = styled.span`
  margin: 0 27px 0 0 !important;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;

  ${media.max.tablet`
        padding-bottom: 30px;
    `}

  ${media.max.phone`
        padding: 25px 16px;
        font-size: 24px;
        line-height: 150%;
    `}
`

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 86px;

  > span {
    margin-top: 1px;
    font-size: 13px;
    color: #343840;
    text-transform: capitalize;
  }
`
