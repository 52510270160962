import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'

export const StyledGrid = styled(Grid)`
  ${(props) =>
    props.loading
      ? `
        border: 1px dashed #b3b3b3;
    `
      : ''}
`

export const CellContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 140px;

  ::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const PreviewContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ededed;
  opacity: ${(props) => (props.loading ? 0.8 : 1)};
  pointer-events: ${(props) => (props.loading ? 'none' : 'initial')};

  ${(props) =>
    props.error
      ? `
        background: indianred;
        border: 1px solid  #FF002E;

        ::after {
            content: '${props.error}';
            display: block;
            margin-top: 4px;
            color: #FF002E;
            font-size: 11px;
            text-align: center;
        }
    `
      : ''}

  .delete-button {
    display: none;
  }

  &:hover {
    .delete-button {
      display: initial;
    }
  }

  ${(props) =>
    props.isOver
      ? `
        width: calc(100% - 20px);
        margin-left: 20px;
    `
      : ''}
`

export const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const CheckboxContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  > div > div {
    padding: 0 0 15px 10px;

    label:before {
      width: 15px !important;
      height: 15px !important;
    }
    label:after {
      width: 11px !important;
      height: 11px !important;
      left: 2px;
      top: 5px;
      transition: none;
    }
  }
`

export const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 3;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProgressContainer = styled.div`
  width: ${(props) => props.progress}%;
  background: #58c367;
  height: 3px;
  position: absolute;
  bottom: 0;
  z-index: 3;
`

export const Placeholder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const PlaceholderOverlay = styled.div`
  background-color: rgb(187, 189, 191);
  height: 100%;
`

export const AddButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px dashed #e1e2e6;
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    width: 22px;
    height: 22px;
  }
`

export const ArrowContainer = styled.div`
  border-left: 2px solid #e61f9d;
  position: absolute;
  height: 100% !important;
  left: -7px;
`

export const ArrowImg = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: -15px;
`
