import styled, { css } from 'styled-components'
import { FormCheckbox } from 'packages/components/inputs'
import { FormInput, FormInputPassword } from 'packages/components/inputs'
import Message from 'packages/components/message/Message'
import OccupationDropdown from 'packages/components/occupationDropdown'

import checkboxActiveIcon from '@landing/components/common/img/checkbox-active.svg'
import checkboxDisabledIcon from '@landing/components/common/img/checkbox-disabled.svg'
import dropdownIcon from '@landing/components/common/img/dropdown.svg'

export const Content = styled.div`
  margin-top: 24px;

  > form {
    position: static !important;
    align-items: center;
    display: flex !important;
    flex-direction: column;
    width: 100%;
    color: #343840;

    :before {
      background: rgba(0, 0, 0, 50%) !important;
    }

    :after {
      border-color: #fff rgb(0 0 0 / 20%) rgb(0 0 0 / 20%) rgb(0 0 0 / 20%) !important;
    }

    > div {
      width: 100%;
    }

    .ui.input {
      width: 100% !important;
    }

    .ui.dropdown {
      width: 100% !important;

      .menu {
        max-height: 210px;
      }
    }

    button {
      margin-top: 24px !important;
      height: 48px !important;
      background-color: #436df6 !important;
      border-radius: 4px !important;
      padding: 14px 24px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      border: 0 !important;

      &:hover {
        background-color: #3251b8 !important;
        border: 0 !important;
      }
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  background-color: #242426;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 85px 16px 16px;
  height: 100%;

  @media (max-width: 768px) {
    background-color: #141414;
  }

  @media (max-width: 480px) {
    padding-top: 85px;
    flex-direction: column;
    height: initial;
  }
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 432px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  background-color: #141414;
  height: max-content;
  border-radius: 8px;
  align-self: ${(props) => (props.verticalAlign ? props.verticalAlign : 'center')};

  @media (max-width: 768px) {
    padding: 0;
  }
`

export const TypeformContainer = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
  > div {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    z-index: 1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0%;

    /* Avoid Chrome to see Safari hack */
    @supports (-webkit-touch-callout: none) {
      /* The hack for Safari */
      height: -webkit-fill-available;
    }
  }
`

export const Logo = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;
  height: 54px;
  z-index: 2;

  > img {
    width: 103px;
    height: 33px;
  }
`

export const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  line-height: 150%;
  color: #fff;
`

export const Footer = styled.div`
  margin-top: 24px;
  color: #f6f6f6;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  a {
    color: #436df6;
    cursor: pointer;

    &:hover {
      color: #436df6;
    }
  }
`

export const Text = styled.div`
  color: #ededed;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`

export const SingInRow = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 15px;
  line-height: 21px;

  > a {
    margin-left: 36px;

    > button {
      min-width: 136px !important;
      height: 48px !important;
      padding: 14px 24px !important;
      border-radius: 4px !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #141414 !important;
      background-color: #fff !important;

      :hover {
        color: #141414 !important;
        background-color: #ededed !important;
      }
    }
  }

  @media (max-width: 768px) {
    right: 20px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: initial;
    top: initial;
    right: initial;
    margin-top: 80px;

    > a {
      margin-left: initial;
      margin-top: 24px;
    }

    > span {
      text-align: center;
    }
  }
`

export const LogoRow = styled.a`
  position: absolute;
  top: 35px;
  left: 30px;

  @media (max-width: 768px) {
    left: 20px;
  }

  @media (max-width: 480px) {
    left: calc(50% - 31.5px);
  }
`

export const Stack = styled.div`
  & > :not(style) + :not(style) {
    margin-top: ${({ $spacing }) => $spacing || 16}px;
  }
`

export const StyledFormCheckbox = styled(FormCheckbox)`
  width: 24px !important;
  height: 24px !important;

  > label {
    :before,
    :after {
      width: 24px !important;
      height: 24px !important;
    }

    :before {
      background-color: #242426 !important;
      border-radius: 4px !important;
      border: none !important;
    }

    :after {
      top: 0 !important;
      left: 0 !important;
      content: '' !important;
      background-image: url('${checkboxActiveIcon}');
      background-repeat: no-repeat;
      background-color: #436df6;
      background-position: center;
      border-radius: 4px !important;
      transition: opacity 0.1s ease, background-color 0.1s ease !important;
    }
  }

  :hover {
    > label {
      :before {
        border: 1px solid #666a73 !important;
      }

      :after {
        background-color: #3251b8;
      }
    }
  }

  &.disabled {
    > label {
      opacity: 1 !important;

      :before {
        border: 0 !important;
      }

      :after {
        background-color: transparent;
        background-image: url('${checkboxDisabledIcon}');
      }
    }

    + span {
      color: #666a73;
    }
  }
`

const commonStyleFormInput = css`
  label {
    color: #f6f6f6;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  input {
    background-color: #242426 !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    color: #f6f6f6 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    height: auto !important;

    :hover {
      border-color: #666a73 !important;
    }

    :focus {
      border-color: #666a73 !important;
    }

    ::selection {
      color: #f6f6f6 !important;
    }
  }

  div.ui.error.input {
    > input {
      background-color: #242426 !important;
      border-color: #e7002a !important;
    }
  }

  div.ui.error.input & > div {
    display: none;
  }
`

export const StyledFormInput = styled(FormInput)`
  ${commonStyleFormInput};

  input {
    padding: 16px !important;
  }
`

export const StyledFormInputPassword = styled(FormInputPassword)`
  ${commonStyleFormInput};

  &&& {
    input {
      padding: 16px 48px 16px 16px !important;

      + div {
        top: 10px;
        right: 10px;

        svg path {
          fill: #666a73;
        }

        :hover svg path {
          fill: #9fa1a6;
        }
      }

      ::placeholder {
        color: #666a73;
      }
    }
  }
`

export const StyledFormSelect = styled(OccupationDropdown)`
  > div.ui.dropdown {
    &.selection {
      background-color: #242426 !important;
      border-radius: 4px !important;
      padding: 14px 16px !important;
      height: 52px !important;
      border: 1px solid transparent !important;
      transition: border 0.1s ease !important;

      :hover {
        border-color: #666a73 !important;
      }

      &.active {
        border-radius: 4px !important;
      }

      > .text {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;

        &.default {
          color: #666a73 !important;
        }
      }

      i {
        width: 14px !important;
        height: 8px !important;
        top: 50% !important;
        opacity: 1 !important;

        :before {
          content: '' !important;
          position: absolute !important;
          left: 0 !important;
          background-image: url('${dropdownIcon}') !important;
          background-repeat: no-repeat !important;
          width: 14px !important;
          height: 8px !important;
        }
      }

      &.active {
        > i {
          :before {
            transform: rotate(180deg) !important;
          }
        }

        > .text {
          color: #fff !important;
        }
      }

      > .menu {
        background-color: #242426;

        > .item {
          :hover {
            background-color: #34363b !important;
          }

          &.active {
            background-color: transparent !important;

            > .text {
              color: #436df6 !important;
            }
          }

          > .text {
            color: #ffffff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
`

export const StyledMessage = styled(Message)`
  &&& {
    margin-bottom: 24px;
    padding: 16px;
    border: 1px solid ${({ error }) => (error ? '#e7002a' : '#58c367')};
    background: #242426;
    border-radius: 8px;
    color: ${({ error }) => (error ? '#e7002a' : '#58c367')};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    & > div {
      position: relative;
      margin-left: 0;
      padding-left: 36px;
    }

    & svg {
      top: 0;
      left: 0;
      transform: none;

      path {
        fill: ${({ error }) => (error ? '#e7002a' : '#58c367')};
      }
    }
  }
`
