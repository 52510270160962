import { compose } from 'redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import {
  MESSAGE_TYPE,
  WEBSITE_PAGE_TYPES,
  CONTACT_FORM_LOCALE,
  CONTACT_FORM_ALIGN,
} from 'packages/enum'
import websitePageCreate from '@graphql/gql/website/websitePageCreate.gql'
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql'
import PageCreate from '@configurator/components/modals/pageCreate'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('page.field.error.title'),
  pageType: Yup.string().required('page.field.error.pageType'),
})

export const pageCreateWrapper = (Component) =>
  compose(
    graphql(websiteMetaGet, {
      options() {
        return {
          variables: {},
          ssr: false,
          fetchPolicy: 'network-only',
        }
      },
      props({ data: { loading, websiteMetaGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(websiteMetaGet)) {
          return
        }
        const { data } = websiteMetaGet

        return { meta: { ...data } }
      },
    }),
    graphql(websitePageCreate, {
      props({ mutate }) {
        return {
          async createPage({
            title,
            mainNavigation,
            pageType,
            visible,
            websiteId,
            startPage,
            password,
            url,
            expanded,
            zoom,
            showHeader,
            contactFormLang,
            contactFormAlign,

            blockType,
            content,
          }) {
            try {
              return await mutate({
                variables: {
                  title,
                  mainNavigation,
                  pageType,
                  visible,
                  websiteId,
                  startPage,
                  password,
                  url,
                  expanded,
                  zoom,
                  showHeader,
                  contactFormLang,
                  contactFormAlign,
                  blockType,
                  content,
                },
                refetchQueries: [
                  {
                    query: websitePagesGet,
                  },
                  {
                    query: previewPageGet,
                  },
                ],
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    withFormik({
      mapPropsToValues: ({ initialValues }) => ({
        title: '',
        pagePosition: 'main',
        visible: true,
        expanded: false,
        stacked: true,
        displayMode: 'album',
        hideHeader: false,
        url: undefined,
        ...initialValues,
        password: initialValues?.password ? initialValues.password : '',
      }),
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
      handleSubmit: async (
        {
          title,
          pagePosition,
          pageType,
          blockType,
          visible,
          startPage,
          password,
          url,
          expanded,
          displayMode,
          hideHeader,
          content,
        },
        {
          setSubmitting,
          setStatus,
          props: {
            createPage,
            onClose,
            meta: { id },
          },
        }
      ) => {
        let defaultError = 'page.create.error.header'
        const isMainNavigation = pagePosition === 'main'
        const isDisplayModeZoom = displayMode === 'zoom'

        try {
          let payload = {
            title,
            mainNavigation: isMainNavigation,
            pageType,
            visible,
            startPage,
            password,
            websiteId: id,
            url,
            expanded,
            zoom: isDisplayModeZoom,
            showHeader: !hideHeader,
          }

          if (pageType === WEBSITE_PAGE_TYPES.CONTACTS) {
            payload = {
              ...payload,
              contactFormLang: CONTACT_FORM_LOCALE.en,
              contactFormAlign: CONTACT_FORM_ALIGN.LEFT,
            }
          }

          if (pageType === WEBSITE_PAGE_TYPES.HTML) {
            payload = {
              ...payload,
              blockType,
              content,
            }
          }

          const res = await createPage(payload)
          setSubmitting(false)

          let {
            data: { websitePageCreate: { errors, success } = {} },
          } = res
          if (success) {
            ANALYTICS.pageCreate(pageType)

            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_PAGE,
              }),
              '*'
            )
            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_META,
              }),
              '*'
            )
            return onClose()
          }
          setStatus({ error: errors._error || defaultError })
        } catch (err) {
          setSubmitting(false)
          setStatus({
            error: __PRODUCTION__ ? defaultError : err.toString(),
          })
        }
      },
    })
  )(Component)

export default pageCreateWrapper(PageCreate)
