import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import TotalPageViews from '@configurator/containers/statistics/TotalPageViewsContainer'
import TotalVisitors from '@configurator/containers/statistics/TotalVisitorsContainer'
import TotalVisitorsNow from '@configurator/containers/statistics/TotalVisitorsNowContainer'
import AverageTimeOnSite from '@configurator/containers/statistics/AverageTimeOnSiteContainer'
import LineChart from '@configurator/containers/statistics/LineChartContainer'
import StatisticMap from '@configurator/containers/statistics/StatisticMapContainer'
import Referrals from '@configurator/containers/statistics/ReferralsContainer'
import PeriodPicker from '@configurator/containers/statistics/PeriodPickerContainer'
import StatisticsHeader from '@configurator/components/statistics/statisticsHeader/StatisticsHeader'
import MostViewedWrapper from '@configurator/containers/statistics/MostViewedWrapperContainer'
import { HeaderStyled } from 'packages/components/layout/nodes'
import { Container, Row, Column, HeaderContainer, GroupWrapper } from './nodes'
import { useIsMobile } from 'packages/helpers/useWindowSize'

const Statistics = ({ applyFilter, idSite }) => {
  const isMobile = useIsMobile()
  const [width, setWidth] = useState()

  const resize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', resize, false)
    return () => {
      window.removeEventListener('resize', resize, false)
    }
  }, [])
  useEffect(() => {
    applyFilter({ idSite })
  }, [applyFilter, idSite])

  const referralsColumn = useMemo(
    () => (
      <Column width={4.26}>
        <Referrals />
      </Column>
    ),
    []
  )

  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Row>
          <Column width={16}>
            <HeaderContainer>
              <StatisticsHeader />
              <PeriodPicker />
            </HeaderContainer>
          </Column>
        </Row>
        <Row>
          <Column width={4.26}>
            <GroupWrapper>
              <TotalPageViews />
              <TotalVisitors />
              <AverageTimeOnSite />
              <TotalVisitorsNow />
            </GroupWrapper>
          </Column>
          <Column width={7.47}>
            <LineChart />
          </Column>
          {!isMobile && referralsColumn}
        </Row>
        <Row>
          <Column width={4.26}>
            <MostViewedWrapper />
          </Column>
          {isMobile && referralsColumn}
          <Column width={width <= 1440 ? 11.74 : 7.47}>
            <StatisticMap />
          </Column>
        </Row>
      </Container>
    </>
  )
}

Statistics.propTypes = {
  idSite: PropTypes.number,
  applyFilter: PropTypes.func.isRequired,
}

Statistics.defaultProps = { idSite: null }

export default Statistics
