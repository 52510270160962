import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Semantic } from 'packages/components'
import { Modal } from 'packages/components/modal'
import {
  FormInput,
  FormRadio,
  FormToggle,
  FormInputPassword,
  FormTextArea,
} from 'packages/components/inputs'
import { Button } from 'packages/components/buttons'
// import { ProFeatureBudge } from "packages/components/newFeatureBudge/nodes";
import { WEBSITE_PAGE_BLOCK_TYPES, WEBSITE_PAGE_TYPES } from 'packages/enum'
import { formProptypes } from 'packages/utils/formikPropTypes'
import Message from 'packages/components/message/Message'
import get from 'lodash/get'
import { URLS } from 'packages/enum'
import { MobileSubmitContainer } from '@configurator/components/modals/nodes'
import {
  Header,
  Content,
  StyledInput,
  StyledInputCode,
  Title,
  Description,
  StyledRadio,
  EnablePage,
  Divider,
  DisplayMode,
  DisplayModeOptions,
  DisplayModeOptionTab,
  EntireZoomOption,
  ExpandedPreview,
  StackedPreview,
  LandingPage,
  HidePage,
  Password,
  LinkComponent,
  StFieldsContainer,
  StPagePosition,
} from './nodes'
import albumExpanded1 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_1.svg'
import albumExpanded2 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_2.svg'
import albumExpanded3 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_3.svg'
import albumExpanded4 from 'src/packages/theme-ab/resources/assets/configurator/album_expanded_4.svg'
import albumStacked from 'src/packages/theme-ab/resources/assets/configurator/album_stacked.svg'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PageCreate = (
  {
    open,
    onClose,
    handleSubmit,
    errors,
    status,
    isSubmitting,
    values,
    setFieldValue,
    meta,
    slideshowEnabled,
    loading,
  },
  { intl: { formatMessage } }
) => {
  const [isLinkPage, setIsLinkPage] = useState(false)
  const [isHtmlPage, setIsHtmlPage] = useState(false)
  const prevValues = usePrevious(values)
  const { permissions } = useSubscription()

  useEffect(() => {
    if (prevValues?.pagePosition !== values.pagePosition) {
      setFieldValue('pagePosition', values.pagePosition)
    }

    if (values.pageType === WEBSITE_PAGE_TYPES.EXTERNAL_LINK) {
      setIsLinkPage(true)
    } else if (prevValues && prevValues.pageType === WEBSITE_PAGE_TYPES.EXTERNAL_LINK) {
      setIsLinkPage(false)
    }

    if (values.pageType === WEBSITE_PAGE_TYPES.HTML) {
      setIsHtmlPage(true)
    } else if (prevValues?.pageType === WEBSITE_PAGE_TYPES.HTML) {
      setIsHtmlPage(false)
    }
    if (prevValues?.stacked !== values.stacked) {
      setFieldValue('expanded', !values.stacked)

      if (values.stacked) {
        setFieldValue('displayMode', 'album')
      }
    }
    if (prevValues?.expanded !== values.expanded) {
      setFieldValue('stacked', !values.expanded)

      if (!values.expanded) {
        setFieldValue('displayMode', 'album')
      }
    }

    if (prevValues?.displayMode !== values.displayMode) {
      setFieldValue('displayMode', values.displayMode)
    }
  }, [prevValues, setFieldValue, values])

  const error = Object.keys(errors).length || (status && !!status.error)

  const getHeaderTitle = useMemo(() => {
    if (values.blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID) {
      return 'newDesign.page.create.header.ecwid'
    }
    switch (values.pageType) {
      case WEBSITE_PAGE_TYPES.EXTERNAL_LINK:
        return 'newDesign.page.create.header.url'
      case WEBSITE_PAGE_TYPES.CONTACTS:
        return 'newDesign.page.create.header.contact'
      case WEBSITE_PAGE_TYPES.TEXT:
        return 'newDesign.page.create.header.text'
      case WEBSITE_PAGE_TYPES.HTML:
        return 'newDesign.page.create.header.html'
      case WEBSITE_PAGE_TYPES.PHOTO:
        return 'newDesign.page.create.header.simple'
      default:
        return 'newDesign.page.create.header.photo'
    }
  }, [values.pageType, values.blockType])

  const showExpandedOptions = useMemo(() => {
    return values.pageType === WEBSITE_PAGE_TYPES.ALBUM_LIST && get(meta, 'canExpand')
  }, [values.pageType, meta])

  const modalStyle = {
    width: '580px',
    height: 'initial',
    paddingTop: '24px',
  }

  return (
    <Modal open={open} onClose={onClose} styles={modalStyle} mobileWide>
      <Content>
        <Semantic.Form noValidate error={error} loading={isSubmitting} onSubmit={handleSubmit}>
          <Header>
            <span>{formatMessage({ id: getHeaderTitle })}</span>
            <Button
              type='submit'
              content={formatMessage({
                id: 'newDesign.page.create.submit',
              })}
              disabled={isSubmitting || loading}
            />
          </Header>
          <Message error={error} messages={errors} content={status && status.error} />

          <StyledInput>
            <FormInput name='title' type='text' label='newDesign.page.create.field.title' />
          </StyledInput>
          <StyledInput hide={!isLinkPage}>
            <FormInput
              validate={(value) => isLinkPage && !value && 'page.field.error.url'}
              name='url'
              type='text'
              label='newDesign.page.create.field.linkUrl'
            />
          </StyledInput>
          <StFieldsContainer>
            <StPagePosition>
              <Title>
                {formatMessage({
                  id: 'newDesign.page.create.field.pagePosition',
                })}
              </Title>
              <StyledRadio>
                <FormRadio name='pagePosition' value='main' />
                <span>
                  {formatMessage({
                    id: 'newDesign.page.create.field.mainNavigation',
                  })}
                </span>
              </StyledRadio>
              <StyledRadio>
                <FormRadio name='pagePosition' value='footer' />
                <span>
                  {formatMessage({
                    id: 'newDesign.page.create.field.footerNavigation',
                  })}
                </span>
              </StyledRadio>
            </StPagePosition>
            {values.blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID && (
              <LinkComponent target='_blank' href={`${URLS.website_integrations}/ecwid`}>
                {formatMessage({
                  id: 'newDesign.page.create.ecwid.link',
                })}
              </LinkComponent>
            )}
            <StyledInputCode hide={!isHtmlPage}>
              <FormTextArea
                name='content'
                type='text'
                maxLength={50000}
                label={
                  values.blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID
                    ? formatMessage({
                        id: 'newDesign.page.create.ecwid.page.code',
                      })
                    : formatMessage({
                        id: 'newDesign.page.create.page.code',
                      })
                }
              />
            </StyledInputCode>
            <EnablePage hide={isLinkPage}>
              <div>
                <Title>
                  {formatMessage({
                    id: 'newDesign.page.create.field.enablePage.title',
                  })}
                </Title>
                <Description>
                  {formatMessage({
                    id: 'newDesign.page.create.field.enablePage.desc',
                  })}
                </Description>
              </div>
              <FormToggle name='visible' />
            </EnablePage>
            <DisplayMode hide={!showExpandedOptions}>
              <Divider />
              <Title bold>
                {formatMessage({
                  id: 'newDesign.page.create.field.displayMode.title',
                })}
              </Title>
              <DisplayModeOptions>
                <DisplayModeOptionTab
                  active={values.stacked}
                  onClick={() => setFieldValue('stacked', true)}
                >
                  {formatMessage({
                    id: 'newDesign.page.create.field.displayMode.stacked',
                  })}
                </DisplayModeOptionTab>
                <DisplayModeOptionTab
                  active={values.expanded}
                  onClick={() => setFieldValue('expanded', true)}
                >
                  {formatMessage({
                    id: 'newDesign.page.create.field.displayMode.expanded',
                  })}
                </DisplayModeOptionTab>
              </DisplayModeOptions>
              <StackedPreview hide={!values.stacked}>
                <Description>
                  {formatMessage({
                    id: 'newDesign.page.create.field.displayMode.desc',
                  })}
                </Description>
                <div>
                  <img src={albumStacked} alt='' />
                  <span>
                    {formatMessage({
                      id: 'newDesign.page.create.field.displayMode.albumTitle',
                    })}
                  </span>
                </div>
              </StackedPreview>
              {values.expanded && (
                <>
                  <EntireZoomOption>
                    <StyledRadio>
                      <FormRadio name='displayMode' value='album' />
                      <span>
                        {formatMessage({
                          id: 'newDesign.page.create.field.displayMode.album',
                        })}
                      </span>
                    </StyledRadio>
                    <StyledRadio>
                      <FormRadio name='displayMode' value='zoom' />
                      <span>
                        {formatMessage({
                          id: 'newDesign.page.create.field.displayMode.zoom',
                        })}
                      </span>
                    </StyledRadio>
                  </EntireZoomOption>
                  <ExpandedPreview>
                    <img src={albumExpanded1} alt='' />
                    <img src={albumExpanded2} alt='' />
                    <img src={albumExpanded3} alt='' />
                    <img src={albumExpanded4} alt='' />
                    <span>
                      {formatMessage({
                        id: 'newDesign.page.create.field.displayMode.albumTitle',
                      })}
                    </span>
                  </ExpandedPreview>
                </>
              )}
              <Divider />
            </DisplayMode>
            <LandingPage hide={slideshowEnabled}>
              <Title>
                {formatMessage({
                  id: 'newDesign.page.create.field.landingPage',
                })}
              </Title>
              <FormToggle name='startPage' />
            </LandingPage>
            <HidePage>
              <div>
                <Title>
                  {formatMessage({
                    id: 'newDesign.page.create.field.hidePage.title',
                  })}
                  {/*<ProFeatureBudge src={newFeatureIcon} alt='' />*/}
                </Title>
                <Description>
                  {formatMessage({
                    id: 'newDesign.page.create.field.hidePage.desc',
                  })}
                </Description>
              </div>
              <div>
                <PermissionsOverlay isAllowed={permissions.HIDE_PAGE_TITLE}>
                  <FormToggle name='hideHeader' />
                </PermissionsOverlay>
              </div>
            </HidePage>
            <Password hide={isLinkPage}>
              <div>
                <Title>
                  {formatMessage({
                    id: 'newDesign.page.create.field.password.title',
                  })}
                  {/*<ProFeatureBudge src={newFeatureIcon} alt='' />*/}
                </Title>
                <Description>
                  {formatMessage({
                    id: 'newDesign.page.create.field.password.desc',
                  })}
                </Description>
              </div>
              <PermissionsOverlay isAllowed={permissions.PASSWORD_PROTECTION}>
                <FormInputPassword name='password' type='text' />
              </PermissionsOverlay>
            </Password>
          </StFieldsContainer>
          <MobileSubmitContainer>
            <Button
              type='submit'
              fluid
              content={formatMessage({
                id: 'newDesign.page.create.submit',
              })}
              disabled={isSubmitting || loading}
            />
          </MobileSubmitContainer>
        </Semantic.Form>
      </Content>
    </Modal>
  )
}

PageCreate.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  meta: PropTypes.shape({
    id: PropTypes.number,
    canExpand: PropTypes.bool,
  }),
  slideshowEnabled: PropTypes.bool,
  ...formProptypes,
}

PageCreate.defaultProps = {
  open: false,
  meta: {
    id: null,
    canExpand: undefined,
  },
  slideshowEnabled: false,
}

PageCreate.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default PageCreate
