import styled, { css } from 'styled-components'

export const CustomLoaderContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  .ui.loader {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    vertical-align: initial;
    text-align: initial;
    transform: none;

    ${({ width, height }) => css`
      transform: scaleX(${width / 64}) scaleY(${height / 64});
      transform-origin: left top;
    `}

    &::before, &::after {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
    }

    &::after {
      border-color: ${({ color }) => color} transparent transparent !important;
      opacity: 0.6;
    }

    &::before {
      border-color: ${({ color }) => color} !important;
      opacity: 0.1;
    }
  }
`

export const UserCustomLoader = styled.img`
  max-width: 100%;
  height: auto;
`
