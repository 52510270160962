import styled from 'styled-components'

export const Content = styled.div`
  color: #343840;
  background: #fff;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;

  padding: 30px 40px;

  .ui.button {
    width: 100%;
    max-width: 120px;
    height: 45px;
    margin: 25px 0 0 10px !important;
  }

  @media (max-width: 375px) {
    padding: 20px;
  }
`

export const Header = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`

export const SubHeading = styled.div`
  margin-top: 16px;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
`

export const Field = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 30px;

  @media (max-width: 550px) {
    flex-direction: column;

    .ui.button {
      max-width: initial;
      margin: 10px 0 0 0 !important;
    }
  }
`

export const StyledInput = styled.div`
  width: 300px;

  .ui.input {
    width: 300px !important;
  }

  @media (max-width: 550px) {
    width: 100%;

    .ui.input {
      width: 100% !important;
    }
  }
`
