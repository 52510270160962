import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Ref, Input as SemanticInput } from 'semantic-ui-react'
import { Field } from 'formik'
import { intlShape } from 'react-intl'
import { fieldProptypes } from './formikPropTypes'
import { Input } from '../input'

const SemanticFormInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched = {}, errors = {}, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid,
  ...props
}) => {
  const [fieldRef, setFieldRef] = useState(null)
  // fix Firefox autofill form input
  useEffect(() => {
    if (fieldRef && fieldRef.value && !field.value) {
      setFieldValue(field.name, fieldRef.value)
    }
  }, [field.name, field.value, fieldRef, setFieldValue])

  return (
    <Ref innerRef={(node) => setFieldRef(node.querySelector('input'))}>
      <Input
        error={errors[field.name] && touched[field.name]}
        maxLength={255}
        {...field}
        {...props}
      />
    </Ref>
  )
}

SemanticFormInput.propTypes = {
  ...fieldProptypes,
  ...SemanticInput.propTypes,
}

SemanticFormInput.defaultProps = {
  ...SemanticInput.defaultProps,
}

SemanticFormInput.contextTypes = {
  intl: intlShape,
}

export const FormInput = ({ name, ...props }) => (
  <Field name={name} {...props} component={SemanticFormInput} />
)

FormInput.propTypes = {
  name: PropTypes.string,
  ...SemanticInput.propTypes,
}
