import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import { connect } from 'react-redux'
import { MESSAGE_TYPE, WEBSITE_ALBUM_ITEM_TYPE } from 'packages/enum'
import { lockModal } from 'packages/redux/modules/modalProgress/actions.js'
import websiteVideoUpload from '@graphql/gql/website/websiteVideoUpload.gql'
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import UploadVideo from '@configurator/components/upload/uploadVideo/UploadVideo'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      lockModalProgress: () => dispatch(lockModal(true)),
      unLockModalProgress: () => dispatch(lockModal(false)),
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId, subMessageValues }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
            subMessageValues,
            hideCancelButton: true,
          })
        ),
    })
  ),
  graphql(websiteVideoUpload, {
    props({ mutate }) {
      return {
        async uploadVideo({
          hosting,
          albumId,
          itemType,
          videoId,
          file,
          customPreview,
          videoRatioY,
          videoRatioX,
        }) {
          try {
            return await mutate({
              variables: {
                hosting,
                albumId,
                itemType,
                videoId,
                file,
                customPreview,
                videoRatioY,
                videoRatioX,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                },
                {
                  query: previewPageGet,
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => ({
      hosting: '',
      itemType: WEBSITE_ALBUM_ITEM_TYPE.VIDEO,
      videoId: '',
      ...initialValues,
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (
      { hosting, itemType, videoId, file, customPreview, videoRatioY, videoRatioX },
      {
        setSubmitting,
        setStatus,
        props: {
          uploadVideo,
          albumId,
          onClose,
          refetchPagePreview,
          lockModalProgress,
          unLockModalProgress,
        },
      }
    ) => {
      let defaultError = 'upload.modal.error.header'
      lockModalProgress()
      try {
        const res = await uploadVideo({
          hosting,
          itemType,
          videoId,
          albumId,
          file: customPreview ? file : undefined,
          customPreview,
          videoRatioY,
          videoRatioX,
        })
        setSubmitting(false)
        let {
          data: { websiteVideoUpload: { errors, success } = {} },
        } = res
        unLockModalProgress()
        if (success) {
          ANALYTICS.videoUpload()
          refetchPagePreview()
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_PAGE,
            }),
            '*'
          )
          return onClose()
        }
        setStatus({ error: errors._error || defaultError })
      } catch (err) {
        unLockModalProgress()
        setSubmitting(false)
        setStatus({
          error: __PRODUCTION__ ? defaultError : err.toString(),
        })
      }
    },
  })
)(UploadVideo)
