import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const ChevronDownIcon = ({ color }) => (
  <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.989258 1.7023L5.99966 6.71271L11.0101 1.7023L9.59586 0.288087L5.99967 3.88428L2.40347 0.288086L0.989258 1.7023Z'
      fill={iconColors[color]}
    />
  </svg>
)

ChevronDownIcon.propTypes = {
  ...iconColorPropTypes,
}

ChevronDownIcon.defaultProps = {
  color: 'dark',
}
