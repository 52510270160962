import styled from 'styled-components'

export const AccordionTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 0;
  border-bottom: 1px solid #e1e2e6;
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => (props.active ? '#2EBA76' : '#242426')};
  font-weight: ${(props) => (props.active ? '700' : '400')};
`
