import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import websitePhotosCount from '@graphql/gql/website/websitePhotosCount.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import websitePrefilledPhotosDelete from '@graphql/gql/website/websitePrefilledPhotosDelete.gql'

import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import WebsiteBanner from '@configurator/components/configPanel/WebsiteBanner'
import { MESSAGE_TYPE } from 'packages/enum'

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
          })
        ),
      loaderStart: () => dispatch(loaderStart()),
      loaderStop: () => dispatch(loaderStop()),
    })
  ),
  graphql(websitePhotosCount, {
    options() {
      return {
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websitePhotosCount } }) {
      if (loading) {
        return { loading, data: {} }
      }
      if (isEmpty(websitePhotosCount)) {
        return { loading, data: {} }
      }
      const { data } = websitePhotosCount

      return {
        data,
      }
    },
  }),
  graphql(websitePrefilledPhotosDelete, {
    props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
      return {
        async deletePrefilledPhotos() {
          try {
            loaderStart()
            const res = await mutate({
              refetchQueries: [
                {
                  query: previewPageGet,
                },
                {
                  query: websitePhotosCount,
                },
              ],
            })
            loaderStop()
            if (get(res, 'data.websitePrefilledPhotosDelete.errors._error')) {
              openErrorModal({
                headerMessageId: 'error.header',
                yesMessageId: 'OK',
                subMessageId: get(res, 'data.websitePrefilledPhotosDelete.errors._error'),
              })
            } else {
              window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                  name: MESSAGE_TYPE.UPDATE_PAGE,
                }),
                '*'
              )
            }
            return res
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  })
)(WebsiteBanner)
