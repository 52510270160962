import React from 'react'
import PropTypes from 'prop-types'
import { Button as SemanticButton } from 'semantic-ui-react'

import { StyledButton, viewPropType } from './nodes'

export const Button = ({ view, icon, content, children, ...props }) => (
  <StyledButton view={view} {...props}>
    {content || children}
    {icon || null}
  </StyledButton>
)

Button.propTypes = {
  ...SemanticButton.propTypes,
  icon: PropTypes.node,
  view: viewPropType,
}

Button.defaultProps = {
  view: 'primary',
  icon: undefined,
}
