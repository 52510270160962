import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #666a73;

  ${media.max.phone`
        padding: 20px 16px;
    `}
`

export const Content = styled.div`
  display: flex;
  max-height: 250px;
  overflow-y: auto;
  flex-direction: column;
  font-weight: normal;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width * 6.25 : 6.25)}%;
`

export const Title = styled.span`
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
`

export const RowHeader = styled.div`
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin-bottom: 5px;
`
