import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const SelectGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  margin-bottom: 40px;
`

export const Heading = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
`

export const IconList = styled.div`
  display: grid;
  grid-template-columns: repeat(9, auto);
  justify-content: space-between;
  gap: 5px;
`

export const IconItem = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    opacity: 0.5;
  }

  ${(props) =>
    props.$isSelected &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.$isShadow &&
    css`
      & > svg {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
      }
    `}
`

export const LinkList = styled.div`
  margin-top: 24px;
`
