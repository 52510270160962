import PropTypes from 'prop-types'
import React from 'react'
import UserTable from '../userTable'
import Search from '../searchByEmail'
import style from './style.less'

function UsersList(props) {
  return (
    <>
      <div className={style['search-bar__box']}>
        <Search filter={props.filter} setFilter={props.setFilterByEmail} />
      </div>
      <UserTable {...props} />
    </>
  )
}

export default UsersList

UsersList.propTypes = {
  users: PropTypes.array.isRequired,
  page: PropTypes.object.isRequired,
  fetchPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setFilterByEmail: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
}
