import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { intlShape } from 'react-intl'

import { Button } from 'packages/components/buttons'
import { useSubscription } from '@configurator/providers/subscription'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { Container, CloseIcon, WrapperButtonVerify, WrapperButtons, Content, Title } from './nodes'
import { ABO_STATUS, URLS } from 'packages/enum'

const FreeWarningBanner = ({ intl: { formatMessage } }) => {
  const { info, loading } = useSubscription()
  console.log(info.downgradedFromFreeManually)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!loading && info.aboStatus === ABO_STATUS.TRIAL_ENDED && info.downgradedFromFreeManually) {
      setOpen(true)
    }
  }, [setOpen, loading, info.aboStatus, info.downgradedFromFreeManually])

  if (!open) {
    return null
  }
  return (
    <Container style={{ background: '#385EDC', zIndex: 60 }}>
      <Content>
        <Title style={{ fontSize: '14px' }}>
          Since 2020, we’ve been proud to offer a free plan. However, due to rising maintenance
          costs and our commitment to providing the best possible service to paying customers, we
          have discontinued the free plan.
          <div style={{ marginTop: '6px' }}></div>
          Your content is safe, and you can either delete your account or upgrade to a paid plan
          with a 50% discount using code: <b>vsble50</b>
          <div style={{ marginTop: '6px' }}></div>
          This offer expires in next 14 days Thank you for your understanding and support.
          <div style={{ marginTop: '6px' }}></div>
          Best regards, The Vsble Team
        </Title>

        <WrapperButtons>
          <WrapperButtonVerify>
            <Button
              type='button'
              content={formatMessage({
                id: 'Upgrade',
              })}
              onClick={() => dispatch(push(URLS.website_billing_plan))}
            />
          </WrapperButtonVerify>

          <CloseIcon onClick={() => setOpen(false)}>
            <span
              style={{
                color: 'white',
              }}
            >
              {formatMessage({
                id: 'unpaid.banner.close',
              })}
            </span>
            <svg
              style={{
                fill: 'white',
              }}
              width='14'
              height='14'
              viewBox='0 0 14 14'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'></path>
            </svg>
          </CloseIcon>
        </WrapperButtons>
      </Content>
    </Container>
  )
}

FreeWarningBanner.contextTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(FreeWarningBanner)
