import React from 'react'
import { injectIntl } from 'react-intl'
import { FormInput, FormTextArea } from 'packages/components/inputs'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { StyledInput } from '@configurator/components/modals/nodes'
import { SeoSettingsWrapper, SeoSettingsFields, SeoSettingsUpload, StTextarea } from './nodes'

import { StyledLabel } from 'packages/components/inputs/input/nodes'
import PropTypes from 'prop-types'
import { getDomain } from 'packages/helpers/Helper'
import OpenGraphPreview from '../common/openGraphPreview/openGraphPreview'
import UploadImageBlock from '../common/uploadImageBlock/uploadImageBlock'
import { useSubscription } from '@configurator/providers/subscription'

function SeoSettingsTab({
  intl: { formatMessage },
  isAlbum,
  isSubmitting,
  onDropSeoFile,
  onRejectSeoFile,
  isFileReady,
  onDeleteImagePreview,
  fileName,
  fileSrc,
  domain,
  seoPhotoUrl,
  values,
}) {
  const seoImg = seoPhotoUrl ? getDomain(seoPhotoUrl) : ''
  const { permissions } = useSubscription()
  return (
    <SeoSettingsWrapper>
      <PermissionsOverlay proMax={true} isAllowed={permissions.SEO}>
        <SeoSettingsFields>
          <StyledInput>
            <FormInput
              name='seoTitle'
              type='text'
              label={
                isAlbum
                  ? 'newDesign.album.update.field.seo.metaTitle'
                  : 'newDesign.page.update.field.seo.metaTitle'
              }
              data-intercom-target={isAlbum ? 'AlbumMetaTitle' : 'PageMetaTitle'}
            />
          </StyledInput>
          <div>
            <StyledLabel
              data-intercom-target={isAlbum ? 'AlbumMetaDescription' : 'PageMetaDescription'}
            >
              {formatMessage({
                id: isAlbum
                  ? 'newDesign.album.update.field.seo.description'
                  : 'newDesign.page.update.field.seo.description',
              })}
            </StyledLabel>
            <StTextarea>
              <FormTextArea name='seoDescription' />
            </StTextarea>
          </div>
        </SeoSettingsFields>
      </PermissionsOverlay>
      <PermissionsOverlay isAllowed={permissions.OPEN_GRAPH}>
        <SeoSettingsUpload>
          <UploadImageBlock
            onDropFile={onDropSeoFile}
            onDropReject={onRejectSeoFile}
            // description={"newDesign.page.update.openGraph.upload.desc"}
            title='newDesign.page.update.openGraph.upload.title'
            isSubmitting={isSubmitting}
            isFileReady={isFileReady}
            fileName={fileName}
            onDeleteImageClick={onDeleteImagePreview}
            fileSrc={fileSrc ? fileSrc : seoImg}
          />
          <OpenGraphPreview
            fileSrc={fileSrc ? fileSrc : seoImg}
            title={values.seoTitle}
            domain={domain}
            seoDescription={values.seoDescription}
          />
        </SeoSettingsUpload>
      </PermissionsOverlay>
    </SeoSettingsWrapper>
  )
}

export default injectIntl(SeoSettingsTab)

SeoSettingsTab.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isFileReady: PropTypes.bool,
  onDropSeoFile: PropTypes.func.isRequired,
  onRejectSeoFile: PropTypes.func.isRequired,
  fileSrc: PropTypes.string,
  fileName: PropTypes.string,
  onDeleteImagePreview: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  domain: PropTypes.string,
  seoPhotoUrl: PropTypes.string,
  values: PropTypes.object.isRequired,
}

SeoSettingsTab.defaultProps = {
  values: {},
  onDropSeoFile: () => {},
  onRejectSeoFile: () => {},
}
