import { useHistory } from 'react-router'

import { useSearchParam } from '@configurator/hooks/useSearchParam'

import { WrapperAccordion } from './nodes'
import AccordionItem from './AccordionItem'

export const Accordion = ({ active, setActive, items }) => {
  const { push } = useHistory()

  useSearchParam({
    key: 'tab',
    onSuccess: (slug) => {
      if (active !== slug) setActive(slug)
    },
    onFailure: () => {
      setActive(items[0].slug)

      push({ search: `?tab=${items[0].slug}` })
    },
  })

  const onSelect = (slug) => {
    setActive(slug)

    push({ search: `?tab=${slug}` })
  }

  return (
    <WrapperAccordion>
      {items.map((item) => (
        <AccordionItem
          key={item.slug}
          title={item.title}
          isOpen={active === item.slug}
          onToggle={() => onSelect(item.slug)}
        >
          {item.content}
        </AccordionItem>
      ))}
    </WrapperAccordion>
  )
}
