import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { DATE_FORMAT } from '@configurator/constants/statistics'
import { Button } from 'packages/elements/button'
import DatePicker from '../TrialEndDatePicker'
import { ButtonContainer, StyledModal, TariffName } from './nodes'

const TrialEndDateModal = ({ currentTrialEnd, startTrial, freePlan, tariffName, ...props }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <div>
      <ButtonContainer>
        {!freePlan ? (
          <>
            <Button
              color='grey'
              content={
                currentTrialEnd ? moment(currentTrialEnd).format(DATE_FORMAT) : 'Trial expired'
              }
              onClick={() => setOpen(!open)}
            />
            <TariffName>{tariffName}</TariffName>
          </>
        ) : (
          <>
            <Button color='grey' content='Make PRO Trial' onClick={startTrial} />
            <TariffName>{tariffName}</TariffName>
          </>
        )}
      </ButtonContainer>
      <StyledModal open={open} onClose={() => setOpen(false)}>
        <DatePicker {...props} closeModal={() => setOpen(false)} />
      </StyledModal>
    </div>
  )
}

TrialEndDateModal.propTypes = {
  currentTrialEnd: PropTypes.string,
  freePlan: PropTypes.bool,
  startTrial: PropTypes.func.isRequired,
}

TrialEndDateModal.defaultProps = {
  currentTrialEnd: undefined,
  freePlan: false,
}

export default TrialEndDateModal
