import React from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'

import { HappyIcon } from 'packages/components/icons'

import TotalInfo from './TotalInfo'
import { VisitorsContainer } from './nodes'

const TotalVisitors = ({ count, loading }, { intl: { formatMessage, formatNumber } }) => (
  <VisitorsContainer>
    <TotalInfo
      icon={<HappyIcon color='white' />}
      count={formatNumber(count)}
      text={formatMessage({ id: 'statistics.total.visitors' })}
      loading={loading}
    />
  </VisitorsContainer>
)

TotalVisitors.propTypes = {
  count: PropTypes.number,
  loading: PropTypes.bool,
}

TotalVisitors.defaultProps = {
  count: 0,
  loading: false,
}

TotalVisitors.contextTypes = {
  intl: intlShape,
}

export default TotalVisitors
