import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'

import { ResendCodeButton, SentCodeText } from './nodes'

const ResendCode = ({ onResend, intl: { formatMessage } }) => {
  const [isResendCodeSent, setIsResendCodeSent] = useState(false)
  const [showResendButton, setShowResendButton] = useState(false)

  useEffect(() => {
    let timer

    if (!showResendButton) {
      timer = setTimeout(() => {
        setShowResendButton(true)
      }, 5000)
    }

    if (isResendCodeSent) {
      timer = setTimeout(() => {
        setIsResendCodeSent(false)
        setShowResendButton(true)
      }, 60000)
    }

    return () => clearTimeout(timer)
  }, [showResendButton, isResendCodeSent])

  const onClickResend = async () => {
    setIsResendCodeSent(true)
    setShowResendButton(false)
    onResend()
  }

  if (isResendCodeSent) {
    return (
      <SentCodeText>
        {formatMessage({
          id: 'preview.banner.confirmEmail.modal.codeWasSend',
        })}
      </SentCodeText>
    )
  }

  if (showResendButton) {
    return (
      <ResendCodeButton onClick={onClickResend}>
        {formatMessage({
          id: 'preview.banner.confirmEmail.modal.resendCode',
        })}
      </ResendCodeButton>
    )
  }

  return null
}

export default injectIntl(ResendCode)
