import { iconColorPropTypes } from '../const'

export const CloudCheck = ({ width, height }) => {
  return (
    <svg
      width={width ?? 18}
      height={height ?? 16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id='clip60_4261'>
          <rect
            id='icon / bootstrap / filled / cloud-check'
            width='16.000000'
            height='16.000000'
            fill='white'
            fill-opacity='0'
          />
        </clipPath>
      </defs>
      <rect
        id='icon / bootstrap / filled / cloud-check'
        width='16.000000'
        height='16.000000'
        fill='#FFFFFF'
        fill-opacity='0'
      />
      <g clip-path='url(#clip60_4261)'>
        <path
          id='Vector'
          d='M8 2C6.68018 2.00415 5.40527 2.48013 4.40576 3.34201C3.64014 4.00201 3.08496 4.862 2.94189 5.72501C1.26611 6.095 0 7.55499 0 9.31799C0 11.366 1.70801 13 3.78076 13L12.687 13C14.502 13 16 11.57 16 9.77301C16 8.13699 14.7578 6.80399 13.166 6.57901C12.9229 3.99899 10.6899 2 8 2ZM10.354 6.854L7.354 9.854C7.30762 9.90057 7.25244 9.9375 7.19141 9.96271C7.13086 9.98792 7.06592 10.0009 7 10.0009C6.93408 10.0009 6.86914 9.98792 6.80859 9.96271C6.74756 9.9375 6.69238 9.90057 6.646 9.854L5.146 8.354C5.09961 8.30753 5.0625 8.25232 5.0376 8.19159C5.01221 8.13086 4.99951 8.06573 4.99951 8C4.99951 7.93427 5.01221 7.86914 5.0376 7.80841C5.0625 7.74768 5.09961 7.69247 5.146 7.646C5.19238 7.59952 5.24756 7.56262 5.30859 7.53748C5.36914 7.51233 5.43408 7.49936 5.5 7.49936C5.56592 7.49936 5.63086 7.51233 5.69141 7.53748C5.75244 7.56262 5.80762 7.59952 5.854 7.646L7 8.793L9.646 6.146C9.73975 6.05212 9.86719 5.99936 10 5.99936C10.1328 5.99936 10.2603 6.05212 10.354 6.146C10.4478 6.2399 10.5005 6.36722 10.5005 6.5C10.5005 6.63278 10.4478 6.7601 10.354 6.854Z'
          fill='#58C367'
          fill-opacity='1.000000'
          fill-rule='nonzero'
        />
      </g>
    </svg>
  )
}

CloudCheck.propTypes = {
  ...iconColorPropTypes,
}

CloudCheck.defaultProps = {}
