import React from 'react'
import { loaderDefaultProps, loaderPropTypes } from '../../const'

import { Container } from './nodes'

export const Spinner = ({ color, width, height }) => (
  <Container color={color} width={width} height={height}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </Container>
)

Spinner.propTypes = loaderPropTypes

Spinner.defaultProps = loaderDefaultProps
