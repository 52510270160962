import { createActions } from 'redux-actions'

export const {
  albumView: { changeView },
  typeView: { setView },
} = createActions({
  ALBUM_VIEW: {
    CHANGE_VIEW: (payload) => payload,
  },
  TYPE_VIEW: {
    SET_VIEW: (payload) => payload,
  },
})
