import PageStyles from '@configurator/components/pages/pageStyles'
import {
  UPDATE_FEATURE_PROPERTIES,
  UPDATE_PREVIEW_META_DATA,
  UPDATE_PREVIEW_STRUCTURE_DATA,
} from '@configurator/constants/Preview'
import { changeTemplatesColor } from '@configurator/redux/modules/templatesColor/actions'
import featureResetFeaturesProp from '@graphql/gql/website/featureResetFeaturesProp.gql'
import featureResetTemplate from '@graphql/gql/website/featureResetTemplate.gql'
import featureUpdate from '@graphql/gql/website/featureUpdate.gql'
import featureUpdateWithPhoto from '@graphql/gql/website/featureUpdateWithPhoto.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import updateTemplate from '@graphql/gql/website/updateTemplate.gql'
import updateSlideshow from '@graphql/gql/website/updateSlideshow.gql'
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql'
import websiteMetaUpdate from '@graphql/gql/website/websiteMetaUpdate.gql'
import websiteShareFeaturesApply from '@graphql/gql/website/websiteShareFeaturesApply.gql'
import websiteShareFeaturesCreate from '@graphql/gql/website/websiteShareFeaturesCreate.gql'
import websiteShareFeaturesDelete from '@graphql/gql/website/websiteShareFeaturesDelete.gql'
import websiteShareFeaturesGet from '@graphql/gql/website/websiteShareFeaturesGet.gql'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'
import { FEATURE_PROPERTY_KEYS, MESSAGE_TYPE } from 'packages/enum'
import { close, open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createAction } from 'redux-actions'

export default compose(
  connect(
    (state) => ({
      templatesColor: state.getIn(['templatesColor']),
    }),
    (dispatch) => ({
      updateMeta: throttle(
        (payload) => dispatch(createAction(UPDATE_PREVIEW_META_DATA)(payload)),
        32
      ),
      updateStructure: throttle(
        (payload) => dispatch(createAction(UPDATE_PREVIEW_STRUCTURE_DATA)(payload)),
        32
      ),
      updateFeatureProperties: (payload) =>
        dispatch(createAction(UPDATE_FEATURE_PROPERTIES)(payload)),
      loaderStart: ({ content } = {}) => dispatch(loaderStart({ content })),
      loaderStop: () => dispatch(loaderStop()),
      changeTemplatesColor: (payload) => dispatch(changeTemplatesColor(payload)),
      openErrorModal: ({
        headerMessageId,
        yesMessageId,
        subMessageId,
        subMessageValues,
        onClickYes,
        hideSubHeader,
        hideHeader,
        hideCancelButton,
        size,
        styles,
      }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
            subMessageValues,
            onClickYes,
            hideSubHeader,
            hideHeader,
            hideCancelButton,
            size,
            styles,
            onClickCancel: () => dispatch(close()),
          })
        ),
      closeErrorModal: () => dispatch(close()),
    })
  ),
  graphql(featureUpdateWithPhoto, {
    props({ mutate }) {
      return {
        async featureUpdateWithPhoto({ featureId, file, propertyName }) {
          try {
            return await mutate({
              variables: {
                featureId,
                file,
                propertyName,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(featureUpdate, {
    props({ mutate }) {
      return {
        async featureUpdate({ featureId, properties, enabled }) {
          try {
            return await mutate({
              variables: {
                featureId,
                properties,
                enabled,
              },
            })
          } catch (err) {
            return {
              data: { featureUpdate: { errors: err.message, success: false } },
            }
          }
        },
      }
    },
  }),
  graphql(updateTemplate, {
    props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
      return {
        async updateTemplate({ template, align, inverted, slideshowEnabled }) {
          try {
            loaderStart()
            const res = await mutate({
              variables: {
                template,
                align,
                inverted,
                slideshowEnabled,
              },
              refetchQueries: [
                {
                  query: previewPageGet,
                },
              ],
            })
            loaderStop()

            if (get(res, 'data.updateTemplate.success')) {
              window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                  name: MESSAGE_TYPE.UPDATE_META,
                }),
                '*'
              )
            } else {
              openErrorModal({
                headerMessageId: 'error.header',
                yesMessageId: 'OK',
                subMessageId: get(res, 'data.updateTemplate.errors._error'),
              })
            }

            return get(res, 'data.updateTemplate')
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(updateSlideshow, {
    props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
      return {
        async updateSlideshow({ slideshowEnabled }) {
          try {
            loaderStart()
            const res = await mutate({
              variables: {
                slideshowEnabled,
              },
              refetchQueries: [
                {
                  query: previewPageGet,
                },
              ],
            })
            loaderStop()

            if (get(res, 'data.updateSlideshow.success')) {
              window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                  name: MESSAGE_TYPE.UPDATE_META,
                }),
                '*'
              )
            } else {
              openErrorModal({
                headerMessageId: 'error.header',
                yesMessageId: 'OK',
                subMessageId: get(res, 'data.updateSlideshow.errors._error'),
              })
            }

            return get(res, 'data.updateSlideshow')
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(featureResetTemplate, {
    props({ mutate }) {
      return {
        async featureResetTemplate({ websiteId }) {
          try {
            return await mutate({
              variables: {
                websiteId,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteShareFeaturesCreate, {
    props({ mutate }) {
      return {
        async websiteShareFeaturesCreate({ websiteId }) {
          try {
            return await mutate({
              variables: {
                websiteId,
              },
              refetchQueries: [
                {
                  query: websiteShareFeaturesGet,
                  variables: {},
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteShareFeaturesDelete, {
    props({ mutate }) {
      return {
        async websiteShareFeaturesDelete({ code }) {
          try {
            return await mutate({
              variables: {
                code,
              },
              refetchQueries: [
                {
                  query: websiteShareFeaturesGet,
                  variables: {},
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteShareFeaturesApply, {
    props({ mutate }) {
      return {
        async websiteShareFeaturesApply({ code, websiteId }) {
          try {
            return await mutate({
              variables: {
                code,
                websiteId,
              },
              refetchQueries: [
                {
                  query: websiteShareFeaturesGet,
                  variables: {},
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(featureResetFeaturesProp, {
    props({ mutate }) {
      return {
        async featureResetFeaturesProp({ featurePropertyNames, featureId, domain }) {
          try {
            return await mutate({
              variables: {
                featureId,
                featurePropertyNames,
                domain,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteMetaUpdate, {
    props({ mutate }) {
      return {
        async websiteMetaUpdate({ socialLinks, websiteId }) {
          try {
            return await mutate({
              variables: {
                socialLinks,
                websiteId,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteMetaGet, {
    options() {
      return {
        variables: {},
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websiteMetaGet } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(websiteMetaGet)) {
        return
      }
      const { data } = websiteMetaGet

      return { logoValues: { ...data } }
    },
  }),
  graphql(previewPageGet, {
    options() {
      // withRouter использовать нельзя,т.к. компонент прикручен выше в роутинге, чем параметры
      let pageId = ''
      const roots = `${window.location.href}`.split('/')
      const pageIndex = roots.findIndex((root) => root === 'page' || root === 'blocks')
      if (pageIndex >= 0) {
        pageId = roots[pageIndex + 1]
      }
      return {
        variables: {
          id: pageId ? decodeURIComponent(pageId) : undefined,
        },
        fetchPolicy: 'network-only',
      }
    },
    props({
      data: { loading, previewPageGet, refetch },
      ownProps: { updateStructure, updateMeta },
    }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(previewPageGet)) {
        return { loading }
      }
      const { data } = previewPageGet
      // update preview
      setTimeout(() => {
        updateMeta(data.meta)
        updateStructure(data.structure)
      })

      const features = get(data, 'meta.features', [])
      const socialLinks = get(data, 'meta.socialLinks', [])

      console.log('features', features)

      return {
        footerOverSlideshow: features.find(
          (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.FOOTER_OVER_SLIDESHOW
        ),
        scrollableHeader: features.find(
          (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER
        ),
        blocks: data.structure,
        featureData:
          features.find((feature) => feature.featureName === FEATURE_PROPERTY_KEYS.style) || {},
        loaderData:
          [
            features.find(
              (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_CUSTOM
            ),
            features.find(
              (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_VARIANT
            ),
            features.find(
              (feature) => feature.featureName === FEATURE_PROPERTY_KEYS.LOADER_ANIMATION_COLOR
            ),
          ] || [],
        refetchPagePreview: refetch,
        websiteId: get(data, 'meta.id'),
        domain: get(data, 'meta.domain'),
        meta: get(data, 'meta'),
        templateParams: {
          template: get(data, 'meta.templateName'),
          align: get(data, 'meta.align')?.toUpperCase(),
          inverted: get(data, 'meta.inverted'),
          slideshowEnabled: get(data, 'meta.slideshowEnabled'),
        },
        socialLinks,
      }
    },
  })
)(PageStyles)
