import styled, { css } from 'styled-components'
import { media } from 'packages/components/media'
import { Modal } from 'semantic-ui-react'

export const Content = styled.div`
  padding: 40px;
  color: #242426;
  letter-spacing: 0%;
  font-weight: 400;
  background-color: #f6f6f6;

  ${({ isVerified }) =>
    isVerified &&
    css`
      padding: 80px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

  ${media.max.tablet`
        padding: 25px;
  `}
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-bottom: 16px;
`

export const Text = styled.p`
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 16px;
`

export const WrapperFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const InputCode = styled.input.attrs({ type: 'number' })`
  /* Стили для Webkit-браузеров */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Стили для Firefox */
  -moz-appearance: textfield;

  width: 48px;
  height: 64px;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0%;
  border: 1px solid rgba(14, 16, 21, 0.12);
  border-radius: 4px;
  padding: 0 13px;
`

export const ErrorText = styled.p`
  color: red;
  font-size: 15px;
  font-weight: 500;
  padding-top: 13px;
`

export const StyledModal = styled(Modal)`
  width: 456px !important;
  max-width: 100% !important;
  border-radius: 8px !important;

  &.ui.modal > :first-child:not(.icon),
  &.ui.modal > .icon:first-child + * {
    border-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-radius: 8px !important;
  }
`
