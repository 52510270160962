import { useCallback } from 'react'
import { useState } from 'react'

export const useUploadImage = (setFieldValue, fieldName) => {
  const [preview, setPreview] = useState(null)
  const [isFileReady, setIsFileReady] = useState(false)

  const handleDeletePreviewFile = useCallback(() => {
    setFieldValue(fieldName, undefined)
    setPreview(null)
    setIsFileReady(false)
  }, [setFieldValue, fieldName])

  const handleDropFile = useCallback(
    (files) => {
      if (files && files.length) {
        setIsFileReady(true)
        const file = files[0]
        setPreview({
          index: 0,
          file,
          name: file.name,
          preview: window.URL.createObjectURL(file),
        })
        if (setFieldValue && fieldName) {
          setFieldValue(fieldName, file)
        }
      }
    },
    [setFieldValue, fieldName]
  )

  return { handleDropFile, handleDeletePreviewFile, preview, isFileReady }
}
