import React from 'react'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { StyledRadio } from './nodes'

export const Radio = (props) => {
  return <StyledRadio radio {...props} />
}

Radio.propTypes = {
  ...SemanticCheckbox.propTypes,
}

Radio.defaultProps = {}
