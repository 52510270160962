import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Container, BlackToggleItem, WhiteToggleItem } from './nodes'

const TemplatesColorToggle = ({ intl: { formatMessage }, value, onChange }) => (
  <Container>
    <BlackToggleItem isSelected={value === 'black'} onClick={() => onChange('black')}>
      {formatMessage({ id: 'templates.colorToggle.black' })}
    </BlackToggleItem>
    <WhiteToggleItem isSelected={value === 'white'} onClick={() => onChange('white')}>
      {formatMessage({ id: 'templates.colorToggle.white' })}
    </WhiteToggleItem>
  </Container>
)

TemplatesColorToggle.propTypes = {
  intl: intlShape.isRequired,
  value: PropTypes.oneOf(['black', 'white']).isRequired,
  onChange: PropTypes.func.isRequired,
}

TemplatesColorToggle.defaultProps = {
  value: 'black',
  onChange: () => null,
}

export const StylesTemplatesColorToggle = injectIntl(TemplatesColorToggle)
