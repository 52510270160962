import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { COOKIE, PERIOD, URLS } from 'packages/enum'
import get from 'lodash/get'

import login from '@graphql/gql/common/login.gql'
import LoginForm from '@landing/components/login/LoginForm'
import { Cookies } from 'react-cookie'

const validationSchema = Yup.object().shape({
  login: Yup.string().required('landing.login.error'),
  password: Yup.string().required('landing.password.error'),
})

export default compose(
  graphql(login, {
    props({ mutate }) {
      return {
        async loginMutation({ login, password }) {
          try {
            return await mutate({
              variables: {
                login: login.trim(),
                password,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: ({
      initialValues = {
        login: '',
        password: '',
      },
    }) => {
      return {
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      { login, password },
      { setSubmitting, setStatus, props: { loginMutation } }
    ) => {
      let defaultError = 'landing.login.defaultError'
      try {
        const res = await loginMutation({
          login,
          password,
        })
        setSubmitting(false)
        let {
          data: { login: { /* errors, */ success, data } = {} },
        } = res
        if (success && get(data, 'token')) {
          new Cookies().set(COOKIE.token, get(data, 'token'), {
            expires: PERIOD.neverExpires,
            path: '/',
          })

          if (login === 'admin@vsble.me') {
            return (window.location = URLS.admin_users)
          }

          return (window.location = URLS.website_config)
        }
        setSubmitting(false)
        // setStatus({error: get(errors, 'error_description', defaultError)});
        setStatus({ error: defaultError })
      } catch (err) {
        setSubmitting(false)
        setStatus({
          error: __PRODUCTION__ ? defaultError : err.toString(),
        })
      }
    },
  })
)(LoginForm)
