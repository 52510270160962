import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Ref, TextArea as SemanticTextArea } from 'semantic-ui-react'
import { Field } from 'formik'
import { intlShape } from 'react-intl'
import { fieldProptypes } from './formikPropTypes'
import { Textarea } from '../textarea'

const EOLRegexp = /\r\n|\r|\n/

const SemanticFormTextArea = ({
  field: { onChange: onFieldChange, ...field }, // { name, value, onChange, onBlur }
  form: { touched = {}, errors = {}, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid,
  rowsLimit,
  ...props
}) => {
  const [fieldRef, setFieldRef] = useState(null)
  // fix Firefox autofill form input
  useEffect(() => {
    if (fieldRef && fieldRef.value && !field.value) {
      setFieldValue(field.name, fieldRef.value)
    }
  }, [field.name, field.value, fieldRef, setFieldValue])

  const _onChange = (event) => {
    if (
      rowsLimit &&
      Number.isInteger(rowsLimit) &&
      rowsLimit > 0 &&
      event.target.value.split(EOLRegexp).length > rowsLimit
    ) {
      return
    }

    onFieldChange(event)
  }

  return (
    <Ref innerRef={(node) => setFieldRef(node.querySelector('input'))}>
      <Textarea
        error={errors[field.name] && touched[field.name]}
        maxLength={5000}
        onChange={_onChange}
        {...field}
        {...props}
      />
    </Ref>
  )
}

SemanticFormTextArea.propTypes = {
  rowsLimit: PropTypes.number,
  ...fieldProptypes,
  ...SemanticTextArea.propTypes,
}

SemanticFormTextArea.defaultProps = {
  ...SemanticTextArea.defaultProps,
}

SemanticFormTextArea.contextTypes = {
  intl: intlShape,
}

export const FormTextArea = ({ name, ...props }) => (
  <Field name={name} {...props} component={SemanticFormTextArea} />
)

FormTextArea.propTypes = {
  /**
   * Ограничение количества строк (перевод длинных слов не считается, только когда перевод явный).
   */
  rowsLimit: PropTypes.number,
  name: PropTypes.string.isRequired,
  ...SemanticTextArea.propTypes,
}
