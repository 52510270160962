import styled from 'styled-components'
import { Toggle } from 'packages/components/inputs/toggle'

export const Container = styled.div`
  border: 1px solid rgb(237, 237, 237);
  background: #fff;
  padding: 30px 40px;
  border-radius: 5px;

  @media (max-width: 375px) {
    padding: 20px;
  }
`

export const Header = styled.div`
  color: #343840;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    margin-top: 10px;
    color: #666a73;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
  }
`

export const StyledToggle = styled(Toggle)`
  margin-top: -5px;
`
