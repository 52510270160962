import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { FormToggle } from 'packages/components/inputs'
import { Container, Label, LabelText, LabelDescription } from './nodes'

const Component = ({ intl: { formatMessage }, label, description, ...props }) => {
  return (
    <Container>
      <Label>
        <LabelText>{formatMessage({ id: label })}</LabelText>
        {description && <LabelDescription>{formatMessage({ id: description })}</LabelDescription>}
      </Label>
      <FormToggle {...props} />
    </Container>
  )
}

Component.propTypes = {
  intl: intlShape.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export const StylesToggle = injectIntl(Component)
