import React from 'react'
import { loaderDefaultProps, loaderPropTypes } from '../../const'

import { Container } from './nodes'

export const Ripple = ({ color, width, height }) => (
  <Container color={color} width={width} height={height}>
    <div />
    <div />
  </Container>
)

Ripple.propTypes = loaderPropTypes

Ripple.defaultProps = loaderDefaultProps
