import React from 'react'
import { useDragLayer } from 'react-dnd'
import { WEBSITE_ALBUM_ITEM_TYPE } from 'packages/enum'
import ImageDragPreview from './ImageDragPreview'
import { LayerContainer } from './nodes'
import { getDomain } from 'packages/helpers/Helper'
// import { shallowEqual } from '@react-dnd/shallowequal';
import { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual } from 'packages/helpers/shallowEqual'

// подлагивания при DND вызывает использование DragLayer'a https://github.com/react-dnd/react-dnd/issues/2621
export const useEfficientDragLayer = (collect) => {
  const requestID = useRef()
  const collectCallback = useCallback(
    (monitor) => (requestID.current === undefined ? { data: collect(monitor) } : undefined),
    [collect]
  )
  const collected = useDragLayer(collectCallback)
  const result = useRef(collected?.data)
  if (collected && !shallowEqual(result.current, collected.data)) {
    result.current = collected.data
    requestID.current = requestAnimationFrame(() => (requestID.current = undefined))
  }

  return result.current
}

const getItemStyles = (initialOffset, currentOffset) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }
  let { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

const handleCollect = (monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
})

const CustomDragLayer = React.memo(({ draggingItemId }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useEfficientDragLayer(handleCollect)

  // const dispatch = useDispatch();
  //
  // const dispatchChangeOffset = useCallback(
  //     throttle((currentOffset) => dispatch(setDragCurrentOffset({
  //         ...currentOffset,
  //         item,
  //     })), 250/* 4fps достаточно, т.к. мы только хотим убрать драгнутый айтем в сторону */),
  //     [],
  // );
  //
  // // Видимо либа блокирует подписку на событие движения курсора, придется так
  // useEffect(() => {
  //     dispatchChangeOffset(currentOffset);
  // }, [currentOffset]);

  const renderItem = () => {
    if ([WEBSITE_ALBUM_ITEM_TYPE.PHOTO, WEBSITE_ALBUM_ITEM_TYPE.VIDEO].includes(itemType)) {
      return (
        <ImageDragPreview
          aspectRatio={item.aspectRatio}
          src={getDomain(item.src)}
          srcSetWebpMap={item.srcSetWebpMap}
        />
      )
    } else {
      return null
    }
  }

  if (!isDragging || !draggingItemId) {
    return null
  }
  return (
    <LayerContainer>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </LayerContainer>
  )
})

CustomDragLayer.propTypes = {
  draggingItemId: PropTypes.number,
}

export default CustomDragLayer
