import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Input } from 'packages/components/semantic'

const SearchByEmail = ({ filter, setFilter }) => {
  const [search, setSearch] = useState('')

  const onSearch = () => setFilter(search)

  useEffect(() => {
    setFilter('')
  }, [setFilter])

  return (
    <div>
      <Input
        value={search}
        action={{
          icon: 'search',
          color: 'grey',
          onClick: () => onSearch(),
        }}
        iconPosition='left'
        loading={false}
        onChange={(event) => {
          filter && filter !== '' ? setFilter('') : setSearch(event.target.value)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onSearch()
          }
        }}
        placeholder='Search by email'
      />
    </div>
  )
}

SearchByEmail.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
}

SearchByEmail.defaultProps = { filter: undefined }

export default SearchByEmail
