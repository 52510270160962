import PropTypes from 'prop-types'
import React from 'react'
import { intlShape, injectIntl } from 'react-intl'

import { Toggle } from 'packages/components/inputs'

import { Field, VisibilityDescription, Box, Paragraph, WrapperBox, Toggler } from '../nodes'

const VisibilitySection = ({
  values: { visible },
  onChange: { toggleVisibility },
  intl: { formatMessage },
  isMobile,
}) => (
  <WrapperBox isMobile={isMobile}>
    <Box isMobile={isMobile}>
      <Field isActive={visible}>
        <div>
          <Paragraph type='h2' mb={17} fw={700} color='#242426'>
            {formatMessage({ id: 'website.settings.visibility.text1' })}
          </Paragraph>

          <VisibilityDescription>
            {formatMessage({ id: 'website.settings.visibility.text2' })}
          </VisibilityDescription>
        </div>

        <Toggler isActive={visible}>
          <span>
            {formatMessage({ id: `website.settings.visibility.${visible ? 'live' : 'offline'}` })}
          </span>
          <Toggle checked={visible} onChange={toggleVisibility} />
        </Toggler>
      </Field>
    </Box>
  </WrapperBox>
)

VisibilitySection.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.shape({
    [PropTypes.string]: PropTypes.func,
  }).isRequired,
  intl: intlShape.isRequired,
  isMobile: PropTypes.bool,
}

VisibilitySection.defaultProps = {
  value: true,
}

export default injectIntl(VisibilitySection)
