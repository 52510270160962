import React, { useEffect } from 'react'
import { injectIntl, intlShape } from 'react-intl'

import { HeaderStyled } from 'packages/components/layout/nodes'
import {
  Container,
  Header,
  FormWrapper,
  Grid,
  Notes,
  StyledTextareaContainerLabel,
  StyledTextareaContainer,
  NoteHeader,
  Step,
  StepContent,
  StepNumber,
  StepWrapper,
  Paragraph,
} from '../nodes'

import { Button } from 'packages/components/buttons'
import { Semantic } from 'packages/components'
import { FormTextArea } from 'packages/components/inputs'

const Custom = ({
  handleSubmit,
  errors,
  status,
  isSubmitting,
  loading,
  setFieldValue,
  intl: { formatMessage },
  header,
  footer,
}) => {
  const error = Object.keys(errors).length || (status && !!status.error)

  useEffect(() => {
    if (header) {
      setFieldValue('header', header)
    }
    if (footer) {
      setFieldValue('footer', footer)
    }
  }, [header, footer, setFieldValue])
  return (
    <>
      <HeaderStyled backArrow />
      <Container>
        <Header>
          {formatMessage({
            id: 'integrations.custom.header',
          })}
        </Header>
        <Grid>
          <div>
            <FormWrapper>
              <Semantic.Form
                noValidate
                error={error}
                loading={isSubmitting || loading}
                onSubmit={handleSubmit}
              >
                <StyledTextareaContainerLabel>
                  {formatMessage({
                    id: 'integration.custom.header.code',
                  })}
                </StyledTextareaContainerLabel>

                <StyledTextareaContainer>
                  <FormTextArea
                    maxLength={10000}
                    name='header'
                    placeholder={formatMessage({
                      id: 'integration.custom.header.placeholder',
                    })}
                  />
                </StyledTextareaContainer>

                <StyledTextareaContainerLabel>
                  {formatMessage({
                    id: 'integration.custom.footer.code',
                  })}
                </StyledTextareaContainerLabel>
                <StyledTextareaContainer>
                  <FormTextArea
                    maxLength={10000}
                    name='footer'
                    placeholder={formatMessage({
                      id: 'integration.custom.header.placeholder',
                    })}
                  />
                </StyledTextareaContainer>
                <Button
                  type='submit'
                  content={formatMessage({
                    id: 'integration.save',
                  })}
                  disabled={isSubmitting}
                />
              </Semantic.Form>
            </FormWrapper>
          </div>
          <div>
            <Notes>
              <NoteHeader>
                {formatMessage({
                  id: 'integrations.custom.note.header',
                })}
              </NoteHeader>
              <StepWrapper>
                <Step>
                  <StepNumber>
                    <div>
                      <span>1</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatMessage({
                            id: 'integrations.custom.note.1',
                          }),
                        }}
                      />
                    </Paragraph>
                  </StepContent>
                </Step>
                <Step>
                  <StepNumber>
                    <div>
                      <span>2</span>
                    </div>
                  </StepNumber>
                  <StepContent>
                    <Paragraph type='h2' mt={3} mb={10}>
                      {formatMessage({
                        id: 'integrations.custom.note.2',
                      })}
                    </Paragraph>
                  </StepContent>
                </Step>
              </StepWrapper>
            </Notes>
          </div>
        </Grid>
      </Container>
    </>
  )
}

Custom.propTypes = {
  intl: intlShape.isRequired,
}

Custom.defaultProps = {}

export default injectIntl(Custom)
