import styled, { css } from 'styled-components'

const views = {
  error: css`
    color: white;
    background: #ff002e;
  `,
  success: css`
    color: white;
    background: #58c367;
  `,
  default: css`
    background: white;
  `,
}

export const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  ${({ view }) => views[view]};
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: ${({ lightClose }) => (lightClose ? '50%' : '6px')};
  right: ${({ lightClose }) => (lightClose ? '20px' : '6px')};
  ${({ lightClose }) => (lightClose ? 'transform: translateY(-45%)' : '')};
  border: none;
  background: transparent;
  cursor: pointer;

  & svg {
    width: ${({ lightClose }) => (lightClose ? '12px' : '10px')};
    height: ${({ lightClose }) => (lightClose ? '12px' : '10px')};
  }
`
