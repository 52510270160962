import { connect } from 'react-redux'
import { Map } from 'immutable'
import ModalProgress from '../components/modalProgress/ModalProgress'

const mapStateToProps = (state) => {
  let {
    open = false,
    percent = 0,
    label = '',
  } = (state.get('modalProgress') || new Map()).toJS() || {}
  return {
    open,
    percent,
    label,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProgress)
