import { createActions } from 'redux-actions'

export const {
  downgrade: { openModal, closeModal },
} = createActions({
  DOWNGRADE: {
    OPEN_MODAL: () => {},
    CLOSE_MODAL: () => {},
  },
})
