import { FOOTER_ICON_TYPE, FOOTER_SOCIAL_ICON_COLOR } from 'packages/enum'
import * as Icons from './icons'

const icon = FOOTER_ICON_TYPE.ICON
const circle = FOOTER_ICON_TYPE.CIRCLE
const square = FOOTER_ICON_TYPE.SQUARE
const squareRounded = FOOTER_ICON_TYPE.SQUARE_ROUNDED
const black = FOOTER_SOCIAL_ICON_COLOR.BLACK
const white = FOOTER_SOCIAL_ICON_COLOR.WHITE
const colored = FOOTER_SOCIAL_ICON_COLOR.COLORED

export const socialIcons = {
  linkedin: {
    name: 'LinkedIn',
    icon: {
      [icon]: {
        [black]: Icons.LinkedInBlackIcon,
        [white]: Icons.LinkedInWhiteIcon,
      },
      [circle]: {
        [black]: Icons.LinkedInBlackCircle,
        [white]: Icons.LinkedInWhiteCircle,
        [colored]: Icons.LinkedInColoredCircle,
      },
      [square]: {
        [black]: Icons.LinkedInBlackSquare,
        [white]: Icons.LinkedInWhiteSquare,
        [colored]: Icons.LinkedInColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.LinkedInBlackSquareRounded,
        [white]: Icons.LinkedInWhiteSquareRounded,
        [colored]: Icons.LinkedInColoredSquareRounded,
      },
    },
    placeholder: 'www.linkedin.com/',
  },
  pinterest: {
    name: 'Pinterest',
    icon: {
      [icon]: {
        [black]: Icons.PinterestBlackIcon,
        [white]: Icons.PinterestWhiteIcon,
      },
      [circle]: {
        [black]: Icons.PinterestBlackCircle,
        [white]: Icons.PinterestWhiteCircle,
        [colored]: Icons.PinterestColoredCircle,
      },
      [square]: {
        [black]: Icons.PinterestBlackSquare,
        [white]: Icons.PinterestWhiteSquare,
        [colored]: Icons.PinterestColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.PinterestBlackSquareRounded,
        [white]: Icons.PinterestWhiteSquareRounded,
        [colored]: Icons.PinterestColoredSquareRounded,
      },
    },
    placeholder: 'pinterest.com/',
  },
  vimeo: {
    name: 'Vimeo',
    icon: {
      [icon]: {
        [black]: Icons.VimeoBlackIcon,
        [white]: Icons.VimeoWhiteIcon,
      },
      [circle]: {
        [black]: Icons.VimeoBlackCircle,
        [white]: Icons.VimeoWhiteCircle,
        [colored]: Icons.VimeoColoredCircle,
      },
      [square]: {
        [black]: Icons.VimeoBlackSquare,
        [white]: Icons.VimeoWhiteSquare,
        [colored]: Icons.VimeoColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.VimeoBlackSquareRounded,
        [white]: Icons.VimeoWhiteSquareRounded,
        [colored]: Icons.VimeoColoredSquareRounded,
      },
    },
    placeholder: 'vimeo.com/',
  },
  youtube: {
    name: 'YouTube',
    icon: {
      [icon]: {
        [black]: Icons.YouTubeBlackIcon,
        [white]: Icons.YouTubeWhiteIcon,
      },
      [circle]: {
        [black]: Icons.YouTubeBlackCircle,
        [white]: Icons.YouTubeWhiteCircle,
        [colored]: Icons.YouTubeColoredCircle,
      },
      [square]: {
        [black]: Icons.YouTubeBlackSquare,
        [white]: Icons.YouTubeWhiteSquare,
        [colored]: Icons.YouTubeColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.YouTubeBlackSquareRounded,
        [white]: Icons.YouTubeWhiteSquareRounded,
        [colored]: Icons.YouTubeColoredSquareRounded,
      },
    },
    placeholder: 'www.youtube.com/',
  },
  snapchat: {
    name: 'Snapchat',
    icon: {
      [icon]: {
        [black]: Icons.SnapchatBlackIcon,
        [white]: Icons.SnapchatWhiteIcon,
      },
      [circle]: {
        [black]: Icons.SnapchatBlackCircle,
        [white]: Icons.SnapchatWhiteCircle,
        [colored]: Icons.SnapchatColoredCircle,
      },
      [square]: {
        [black]: Icons.SnapchatBlackSquare,
        [white]: Icons.SnapchatWhiteSquare,
        [colored]: Icons.SnapchatColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.SnapchatBlackSquareRounded,
        [white]: Icons.SnapchatWhiteSquareRounded,
        [colored]: Icons.SnapchatColoredSquareRounded,
      },
    },
    placeholder: 'www.snapchat.com/',
  },
  whatsapp: {
    name: 'WhatsApp',
    icon: {
      [icon]: {
        [black]: Icons.WhatsAppBlackIcon,
        [white]: Icons.WhatsAppWhiteIcon,
      },
      [circle]: {
        [black]: Icons.WhatsAppBlackCircle,
        [white]: Icons.WhatsAppWhiteCircle,
        [colored]: Icons.WhatsAppColoredCircle,
      },
      [square]: {
        [black]: Icons.WhatsAppBlackSquare,
        [white]: Icons.WhatsAppWhiteSquare,
        [colored]: Icons.WhatsAppColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.WhatsAppBlackSquareRounded,
        [white]: Icons.WhatsAppWhiteSquareRounded,
        [colored]: Icons.WhatsAppColoredSquareRounded,
      },
    },
    placeholder: 'www.whatsapp.com/',
  },
  reddit: {
    name: 'Reddit',
    icon: {
      [icon]: {
        [black]: Icons.RedditBlackIcon,
        [white]: Icons.RedditWhiteIcon,
      },
      [circle]: {
        [black]: Icons.RedditBlackCircle,
        [white]: Icons.RedditWhiteCircle,
        [colored]: Icons.RedditColoredCircle,
      },
      [square]: {
        [black]: Icons.RedditBlackSquare,
        [white]: Icons.RedditWhiteSquare,
        [colored]: Icons.RedditColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.RedditBlackSquareRounded,
        [white]: Icons.RedditWhiteSquareRounded,
        [colored]: Icons.RedditColoredSquareRounded,
      },
    },
    placeholder: 'www.reddit.com/',
  },
  facebookmessenger: {
    name: 'Facebook Messenger',
    icon: {
      [icon]: {
        [black]: Icons.FacebookMessengerBlackIcon,
        [white]: Icons.FacebookMessengerWhiteIcon,
      },
      [circle]: {
        [black]: Icons.FacebookMessengerBlackCircle,
        [white]: Icons.FacebookMessengerWhiteCircle,
        [colored]: Icons.FacebookMessengerColoredCircle,
      },
      [square]: {
        [black]: Icons.FacebookMessengerBlackSquare,
        [white]: Icons.FacebookMessengerWhiteSquare,
        [colored]: Icons.FacebookMessengerColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.FacebookMessengerBlackSquareRounded,
        [white]: Icons.FacebookMessengerWhiteSquareRounded,
        [colored]: Icons.FacebookMessengerColoredSquareRounded,
      },
    },
    placeholder: 'www.facebook.com/about/messenger',
  },
  tripadvisor: {
    name: 'TripAdvisor',
    icon: {
      [icon]: {
        [black]: Icons.TripAdvisorBlackIcon,
        [white]: Icons.TripAdvisorWhiteIcon,
      },
      [circle]: {
        [black]: Icons.TripAdvisorBlackCircle,
        [white]: Icons.TripAdvisorWhiteCircle,
        [colored]: Icons.TripAdvisorColoredCircle,
      },
      [square]: {
        [black]: Icons.TripAdvisorBlackSquare,
        [white]: Icons.TripAdvisorWhiteSquare,
        [colored]: Icons.TripAdvisorColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.TripAdvisorBlackSquareRounded,
        [white]: Icons.TripAdvisorWhiteSquareRounded,
        [colored]: Icons.TripAdvisorColoredSquareRounded,
      },
    },
    placeholder: 'www.tripadvisor.com/',
  },
  rss: {
    name: 'RSS',
    icon: {
      [icon]: {
        [black]: Icons.RSSBlackIcon,
        [white]: Icons.RSSWhiteIcon,
      },
      [circle]: {
        [black]: Icons.RSSBlackCircle,
        [white]: Icons.RSSWhiteCircle,
        [colored]: Icons.RSSColoredCircle,
      },
      [square]: {
        [black]: Icons.RSSBlackSquare,
        [white]: Icons.RSSWhiteSquare,
        [colored]: Icons.RSSColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.RSSBlackSquareRounded,
        [white]: Icons.RSSWhiteSquareRounded,
        [colored]: Icons.RSSColoredSquareRounded,
      },
    },
    placeholder: 'rss.app/',
  },
  tinder: {
    name: 'Tinder',
    icon: {
      [icon]: {
        [black]: Icons.TinderBlackIcon,
        [white]: Icons.TinderWhiteIcon,
      },
      [circle]: {
        [black]: Icons.TinderBlackCircle,
        [white]: Icons.TinderWhiteCircle,
        [colored]: Icons.TinderColoredCircle,
      },
      [square]: {
        [black]: Icons.TinderBlackSquare,
        [white]: Icons.TinderWhiteSquare,
        [colored]: Icons.TinderColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.TinderBlackSquareRounded,
        [white]: Icons.TinderWhiteSquareRounded,
        [colored]: Icons.TinderColoredSquareRounded,
      },
    },
    placeholder: 'tinder.com/',
  },
  tumblr: {
    name: 'Tumblr',
    icon: {
      [icon]: {
        [black]: Icons.TumblrBlackIcon,
        [white]: Icons.TumblrWhiteIcon,
      },
      [circle]: {
        [black]: Icons.TumblrBlackCircle,
        [white]: Icons.TumblrWhiteCircle,
        [colored]: Icons.TumblrColoredCircle,
      },
      [square]: {
        [black]: Icons.TumblrBlackSquare,
        [white]: Icons.TumblrWhiteSquare,
        [colored]: Icons.TumblrColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.TumblrBlackSquareRounded,
        [white]: Icons.TumblrWhiteSquareRounded,
        [colored]: Icons.TumblrColoredSquareRounded,
      },
    },
    placeholder: 'www.tumblr.com/',
  },
  facebook: {
    name: 'Facebook',
    icon: {
      [icon]: {
        [black]: Icons.FacebookBlackIcon,
        [white]: Icons.FacebookWhiteIcon,
      },
      [circle]: {
        [black]: Icons.FacebookBlackCircle,
        [white]: Icons.FacebookWhiteCircle,
        [colored]: Icons.FacebookColoredCircle,
      },
      [square]: {
        [black]: Icons.FacebookBlackSquare,
        [white]: Icons.FacebookWhiteSquare,
        [colored]: Icons.FacebookColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.FacebookBlackSquareRounded,
        [white]: Icons.FacebookWhiteSquareRounded,
        [colored]: Icons.FacebookColoredSquareRounded,
      },
    },
    placeholder: 'facebook.com/',
  },
  twitter: {
    name: 'Twitter',
    icon: {
      [icon]: {
        [black]: Icons.TwitterBlackIcon,
        [white]: Icons.TwitterWhiteIcon,
      },
      [circle]: {
        [black]: Icons.TwitterBlackCircle,
        [white]: Icons.TwitterWhiteCircle,
        [colored]: Icons.TwitterColoredCircle,
      },
      [square]: {
        [black]: Icons.TwitterBlackSquare,
        [white]: Icons.TwitterWhiteSquare,
        [colored]: Icons.TwitterColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.TwitterBlackSquareRounded,
        [white]: Icons.TwitterWhiteSquareRounded,
        [colored]: Icons.TwitterColoredSquareRounded,
      },
    },
    placeholder: 'twitter.com/',
  },
  dribbble: {
    name: 'Dribbble',
    icon: {
      [icon]: {
        [black]: Icons.DribbbleBlackIcon,
        [white]: Icons.DribbbleWhiteIcon,
      },
      [circle]: {
        [black]: Icons.DribbbleBlackCircle,
        [white]: Icons.DribbbleWhiteCircle,
        [colored]: Icons.DribbbleColoredCircle,
      },
      [square]: {
        [black]: Icons.DribbbleBlackSquare,
        [white]: Icons.DribbbleWhiteSquare,
        [colored]: Icons.DribbbleColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.DribbbleBlackSquareRounded,
        [white]: Icons.DribbbleWhiteSquareRounded,
        [colored]: Icons.DribbbleColoredSquareRounded,
      },
    },
    placeholder: 'dribbble.com/',
  },
  telegram: {
    name: 'Telegram',
    icon: {
      [icon]: {
        [black]: Icons.TelegramBlackIcon,
        [white]: Icons.TelegramWhiteIcon,
      },
      [circle]: {
        [black]: Icons.TelegramBlackCircle,
        [white]: Icons.TelegramWhiteCircle,
        [colored]: Icons.TelegramColoredCircle,
      },
      [square]: {
        [black]: Icons.TelegramBlackSquare,
        [white]: Icons.TelegramWhiteSquare,
        [colored]: Icons.TelegramColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.TelegramBlackSquareRounded,
        [white]: Icons.TelegramWhiteSquareRounded,
        [colored]: Icons.TelegramColoredSquareRounded,
      },
    },
    placeholder: 'telegram.org/',
  },
  behance: {
    name: 'Behance',
    icon: {
      [icon]: {
        [black]: Icons.BehanceBlackIcon,
        [white]: Icons.BehanceWhiteIcon,
      },
      [circle]: {
        [black]: Icons.BehanceBlackCircle,
        [white]: Icons.BehanceWhiteCircle,
        [colored]: Icons.BehanceColoredCircle,
      },
      [square]: {
        [black]: Icons.BehanceBlackSquare,
        [white]: Icons.BehanceWhiteSquare,
        [colored]: Icons.BehanceColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.BehanceBlackSquareRounded,
        [white]: Icons.BehanceWhiteSquareRounded,
        [colored]: Icons.BehanceColoredSquareRounded,
      },
    },
    placeholder: 'www.behance.net/',
  },
  instagram: {
    name: 'Instagram',
    icon: {
      [icon]: {
        [black]: Icons.InstagramBlackIcon,
        [white]: Icons.InstagramWhiteIcon,
      },
      [circle]: {
        [black]: Icons.InstagramBlackCircle,
        [white]: Icons.InstagramWhiteCircle,
        [colored]: Icons.InstagramColoredCircle,
      },
      [square]: {
        [black]: Icons.InstagramBlackSquare,
        [white]: Icons.InstagramWhiteSquare,
        [colored]: Icons.InstagramColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.InstagramBlackSquareRounded,
        [white]: Icons.InstagramWhiteSquareRounded,
        [colored]: Icons.InstagramColoredSquareRounded,
      },
    },
    placeholder: 'instagram.com/',
  },
  flickr: {
    name: 'Flickr',
    icon: {
      [icon]: {
        [black]: Icons.FlickrBlackIcon,
        [white]: Icons.FlickrWhiteIcon,
      },
      [circle]: {
        [black]: Icons.FlickrBlackCircle,
        [white]: Icons.FlickrWhiteCircle,
        [colored]: Icons.FlickrColoredCircle,
      },
      [square]: {
        [black]: Icons.FlickrBlackSquare,
        [white]: Icons.FlickrWhiteSquare,
        [colored]: Icons.FlickrColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.FlickrBlackSquareRounded,
        [white]: Icons.FlickrWhiteSquareRounded,
        [colored]: Icons.FlickrColoredSquareRounded,
      },
    },
    placeholder: 'www.flickr.com/',
  },
  skype: {
    name: 'Skype',
    icon: {
      [icon]: {
        [black]: Icons.SkypeBlackIcon,
        [white]: Icons.SkypeWhiteIcon,
      },
      [circle]: {
        [black]: Icons.SkypeBlackCircle,
        [white]: Icons.SkypeWhiteCircle,
        [colored]: Icons.SkypeColoredCircle,
      },
      [square]: {
        [black]: Icons.SkypeBlackSquare,
        [white]: Icons.SkypeWhiteSquare,
        [colored]: Icons.SkypeColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.SkypeBlackSquareRounded,
        [white]: Icons.SkypeWhiteSquareRounded,
        [colored]: Icons.SkypeColoredSquareRounded,
      },
    },
    placeholder: 'www.skype.com/',
  },
  shutterstock: {
    name: 'Shutterstock',
    icon: {
      [icon]: {
        [black]: Icons.ShutterstockBlackIcon,
        [white]: Icons.ShutterstockWhiteIcon,
      },
      [circle]: {
        [black]: Icons.ShutterstockBlackCircle,
        [white]: Icons.ShutterstockWhiteCircle,
        [colored]: Icons.ShutterstockColoredCircle,
      },
      [square]: {
        [black]: Icons.ShutterstockBlackSquare,
        [white]: Icons.ShutterstockWhiteSquare,
        [colored]: Icons.ShutterstockColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.ShutterstockBlackSquareRounded,
        [white]: Icons.ShutterstockWhiteSquareRounded,
        [colored]: Icons.ShutterstockColoredSquareRounded,
      },
    },
    placeholder: 'www.shutterstock.com/',
  },
  medium: {
    name: 'Medium',
    icon: {
      [icon]: {
        [black]: Icons.MediumBlackIcon,
        [white]: Icons.MediumWhiteIcon,
      },
      [circle]: {
        [black]: Icons.MediumBlackCircle,
        [white]: Icons.MediumWhiteCircle,
        [colored]: Icons.MediumColoredCircle,
      },
      [square]: {
        [black]: Icons.MediumBlackSquare,
        [white]: Icons.MediumWhiteSquare,
        [colored]: Icons.MediumColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.MediumBlackSquareRounded,
        [white]: Icons.MediumWhiteSquareRounded,
        [colored]: Icons.MediumColoredSquareRounded,
      },
    },
    placeholder: 'medium.com/',
  },
  tiktok: {
    name: 'TikTok',
    icon: {
      [icon]: {
        [black]: Icons.TikTokBlackIcon,
        [white]: Icons.TikTokWhiteIcon,
      },
      [circle]: {
        [black]: Icons.TikTokBlackCircle,
        [white]: Icons.TikTokWhiteCircle,
        [colored]: Icons.TikTokColoredCircle,
      },
      [square]: {
        [black]: Icons.TikTokBlackSquare,
        [white]: Icons.TikTokWhiteSquare,
        [colored]: Icons.TikTokColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.TikTokBlackSquareRounded,
        [white]: Icons.TikTokWhiteSquareRounded,
        [colored]: Icons.TikTokColoredSquareRounded,
      },
    },
    placeholder: 'www.tiktok.com/',
  },
  discord: {
    name: 'Discord',
    icon: {
      [icon]: {
        [black]: Icons.DiscordBlackIcon,
        [white]: Icons.DiscordWhiteIcon,
      },
      [circle]: {
        [black]: Icons.DiscordBlackCircle,
        [white]: Icons.DiscordWhiteCircle,
        [colored]: Icons.DiscordColoredCircle,
      },
      [square]: {
        [black]: Icons.DiscordBlackSquare,
        [white]: Icons.DiscordWhiteSquare,
        [colored]: Icons.DiscordColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.DiscordBlackSquareRounded,
        [white]: Icons.DiscordWhiteSquareRounded,
        [colored]: Icons.DiscordColoredSquareRounded,
      },
    },
    placeholder: 'discord.com/',
  },
  googleplus: {
    name: 'Google+',
    icon: {
      [icon]: {
        [black]: Icons.GooglePlusBlackIcon,
        [white]: Icons.GooglePlusWhiteIcon,
      },
      [circle]: {
        [black]: Icons.GooglePlusBlackCircle,
        [white]: Icons.GooglePlusWhiteCircle,
        [colored]: Icons.GooglePlusColoredCircle,
      },
      [square]: {
        [black]: Icons.GooglePlusBlackSquare,
        [white]: Icons.GooglePlusWhiteSquare,
        [colored]: Icons.GooglePlusColoredSquare,
      },
      [squareRounded]: {
        [black]: Icons.GooglePlusBlackSquareRounded,
        [white]: Icons.GooglePlusWhiteSquareRounded,
        [colored]: Icons.GooglePlusColoredSquareRounded,
      },
    },
    placeholder: 'plus.google.com/',
  },
}
