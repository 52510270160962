import { injectIntl, intlShape } from 'react-intl'

import { Accordion } from 'packages/components/Accordion'
import { HeaderStyled } from 'packages/components/layout/nodes'

import { Container as ContainerSettings, Footer, Header, Settings } from '../../settings/nodes'

const SeoMobile = ({
  intl: { formatMessage },
  VerificationForm,
  Content,
  setActiveSlug,
  activeSlug,
}) => {
  const currentYear = new Date().getFullYear()

  const accordionItems = [
    {
      slug: 'settings',
      title: formatMessage({
        id: 'seo.google.website.settings',
      }),
      content: Content,
    },
    {
      slug: 'verification',
      title: formatMessage({
        id: 'seo.google.site.verification',
      }),
      content: VerificationForm,
    },
  ]

  const onSelectAccordionItem = (slug) => {
    setActiveSlug(slug === activeSlug ? null : slug)
  }

  return (
    <>
      <HeaderStyled backArrow />
      <Header>{formatMessage({ id: 'seo.google' })}</Header>

      <ContainerSettings>
        <Settings>
          <Accordion active={activeSlug} setActive={onSelectAccordionItem} items={accordionItems} />
        </Settings>
      </ContainerSettings>

      <Footer>
        {formatMessage({ id: 'website.footer' })} @ {currentYear}
      </Footer>
    </>
  )
}

SeoMobile.propTypes = {
  intl: intlShape.isRequired,
}

SeoMobile.defaultProps = {}

export default injectIntl(SeoMobile)
