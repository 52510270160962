import React from 'react'
import propTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { BlankIcon, GridIcon } from 'packages/components/icons'
import { getItemStyles } from 'packages/utils/dndDropHelper'

import { IconWrapper, Item, LayerContainer, StructureBlock } from './nodes'

const CustomDragLayer = ({ initialOffset, currentOffset, item }) => {
  return (
    <LayerContainer>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <Item isVisible isShowHeader>
          <IconWrapper paddingL='18' paddingR='23'>
            <GridIcon color='gray' />
          </IconWrapper>
          <StructureBlock />
          <IconWrapper paddingR='11'>
            <BlankIcon />
          </IconWrapper>
          <span>
            {item.name && item.name.length ? (
              item.name
            ) : (
              <FormattedMessage id='album.name.noTitle' />
            )}
          </span>
        </Item>
      </div>
    </LayerContainer>
  )
}

CustomDragLayer.propTypes = {
  initialOffset: propTypes.object,
  currentOffset: propTypes.object,
  item: propTypes.object.isRequired,
}

export default CustomDragLayer
