import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import { injectIntl, intlShape } from 'react-intl'

import { StylesLayoutAlignDropdown } from '../stylesLayoutAlignDropdown'
import { FONT_WEIGHT_OPTIONS } from 'packages/enum'

const StylesFontWeightComponent = ({ intl: { formatMessage }, name, ...rest }) => {
  const fontWeightOptions = [
    {
      text: formatMessage({ id: 'styles.fontWeight.300' }),
      value: FONT_WEIGHT_OPTIONS[300],
    },
    {
      text: formatMessage({ id: 'styles.fontWeight.400' }),
      value: FONT_WEIGHT_OPTIONS[400],
    },
    {
      text: formatMessage({ id: 'styles.fontWeight.500' }),
      value: FONT_WEIGHT_OPTIONS[500],
    },
    {
      text: formatMessage({ id: 'styles.fontWeight.600' }),
      value: FONT_WEIGHT_OPTIONS[600],
    },
    {
      text: formatMessage({ id: 'styles.fontWeight.700' }),
      value: FONT_WEIGHT_OPTIONS[700],
    },
    {
      text: formatMessage({ id: 'styles.fontWeight.800' }),
      value: FONT_WEIGHT_OPTIONS[800],
    },
    {
      text: formatMessage({ id: 'styles.fontWeight.900' }),
      value: FONT_WEIGHT_OPTIONS[900],
    },
  ]

  return (
    <StylesLayoutAlignDropdown
      options={fontWeightOptions}
      label='styles.section.label.fontWeight'
      name={name}
      rest={rest}
    />
  )
}

StylesFontWeightComponent.propTypes = {
  ...SemanticDropdown.propTypes,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export const StylesFontWeight = injectIntl(StylesFontWeightComponent)
