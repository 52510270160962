import styled from 'styled-components'
import { Semantic } from 'packages/components'

export const StyledPopup = styled(Semantic.Popup)`
  min-width: 400px !important;
  height: 500px !important;
  overflow: auto !important;
  padding: 0 0 15px 0 !important;
  border-radius: 2px !important;
  z-index: 999 !important;
  @media (max-width: 480px) {
    height: 300px !important;
    min-width: 340px !important;
  }
  @media (max-width: 400px) {
    min-width: 300px !important;
  }
  @media (max-width: 320px) {
    min-width: 280px !important;
  }
`
