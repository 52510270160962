import PropTypes from 'prop-types'
import React from 'react'
import merge from 'lodash/merge'
import { Formik, Form as FormikForm } from 'formik'
import { Button } from 'packages/components/buttons'
import {
  ModalContainer,
  StAltBlock,
  StFooter,
  StHint,
  StImg,
  StImgInfoBlock,
  StImgInfoParams,
  StImgInfoParamsRow,
  StImgParam,
  StModalBody,
  StTabBtn,
  StTabsContainer,
  StTextarea,
  StGenerate,
  StTitle,
} from './nodes'
import { useState } from 'react'
import { SizeSmallIcon } from '../icons/basic/sizeSmall'
import { WeightIcon } from '../icons/basic/weight'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
// import { ColorsIcon } from "../icons/basic/colors";

const AddInfoAndAltModal = (
  {
    open,
    onClose,
    hideHeader,
    imgWidth,
    imgHeight,
    originalFileSize,
    originalFileName,
    src,
    onAlbumItemUpdate,
    altText,
    altTextDescriptive,
    altImageRecognition,
  },
  { intl: { formatMessage } }
) => {
  const [isDecorative, setIsDecorative] = useState(!altTextDescriptive)

  const initialValues = {
    altText: altText || '',
  }

  const { permissions } = useSubscription()
  return (
    <ModalContainer
      open={open}
      onClose={onClose || close}
      styles={merge({
        height: 'initial',
        width: 'initial',
      })}
    >
      {!hideHeader && (
        <StTitle className='title'>{formatMessage({ id: `addInfoAndAlt.modal.title` })}</StTitle>
      )}
      <StModalBody>
        <StImgInfoBlock>
          <StImgInfoParams>
            {originalFileName && <div className=''>{originalFileName}</div>}

            <StImgInfoParamsRow>
              <StImgParam>
                <SizeSmallIcon />
                {`${imgWidth} x ${imgHeight}`}
              </StImgParam>
              {originalFileSize && (
                <StImgParam>
                  <WeightIcon />
                  {(+originalFileSize / 1000).toFixed(0)}kb
                </StImgParam>
              )}
              {/* <StImgParam>
                                <ColorsIcon />
                                Adobe-RGB
                            </StImgParam> */}
            </StImgInfoParamsRow>
          </StImgInfoParams>
          <StImg>
            <img src={src} alt={altText || ''} />
          </StImg>
        </StImgInfoBlock>
        <StAltBlock>
          <StTitle small data-intercom-target='ImageAltText'>
            {formatMessage({
              id: 'addInfoAndAlt.modal.alt.subtitle',
            })}
          </StTitle>
          <StTabsContainer>
            <StTabBtn
              view={isDecorative ? `secondaryWhite` : `secondaryBlack`}
              onClick={() => setIsDecorative(false)}
            >
              {formatMessage({
                id: 'addInfoAndAlt.modal.button.descriptive',
              })}
            </StTabBtn>
            <StTabBtn
              view={isDecorative ? `secondaryBlack` : `secondaryWhite`}
              onClick={() => setIsDecorative(true)}
            >
              {formatMessage({
                id: 'addInfoAndAlt.modal.button.decorative',
              })}
            </StTabBtn>
          </StTabsContainer>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={{}}
            initialValues={initialValues}
            onSubmit={async (values) => {
              onAlbumItemUpdate({ ...values, altTextDescriptive: !isDecorative })
              onClose()
            }}
            render={({ setValues }) => {
              const onGenerateWithAiClicked = async () => {
                const res = await altImageRecognition({
                  url: src,
                })
                if (res) {
                  setValues({ altText: res })
                }
              }

              return (
                <FormikForm>
                  {!isDecorative ? (
                    <>
                      <StTextarea
                        name='altText'
                        placeholder={formatMessage({
                          id: 'addInfoAndAlt.modal.textarea.placeholder',
                        })}
                      />
                      <PermissionsOverlay
                        isAllowed={permissions.ALT_DESCRIPTION_RECOGNITION}
                        proMax={true}
                      >
                        <StGenerate onClick={onGenerateWithAiClicked}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='256'
                            height='256'
                            viewBox='0 0 256 256'
                          >
                            <path
                              fill='currentColor'
                              d='M252 152a12 12 0 0 1-12 12h-12v12a12 12 0 0 1-24 0v-12h-12a12 12 0 0 1 0-24h12v-12a12 12 0 0 1 24 0v12h12a12 12 0 0 1 12 12ZM56 76h12v12a12 12 0 0 0 24 0V76h12a12 12 0 1 0 0-24H92V40a12 12 0 0 0-24 0v12H56a12 12 0 0 0 0 24Zm128 112h-4v-4a12 12 0 0 0-24 0v4h-4a12 12 0 0 0 0 24h4v4a12 12 0 0 0 24 0v-4h4a12 12 0 0 0 0-24Zm38.14-105.17L82.82 222.14a20 20 0 0 1-28.28 0l-20.69-20.68a20 20 0 0 1 0-28.29L173.17 33.86a20 20 0 0 1 28.28 0l20.69 20.68a20 20 0 0 1 0 28.29ZM159 112l-15-15l-90.35 90.31l15 15Zm43.31-43.31l-15-15L161 80l15 15Z'
                            />
                          </svg>
                          <span>
                            {formatMessage({
                              id: 'addInfoAndAlt.modal.descriptive.generate',
                            })}
                          </span>
                        </StGenerate>
                      </PermissionsOverlay>
                      <StHint>
                        <p>
                          {formatMessage({
                            id: 'addInfoAndAlt.modal.descriptive.text',
                          })}
                        </p>
                      </StHint>
                    </>
                  ) : (
                    <p>
                      {formatMessage({
                        id: 'addInfoAndAlt.modal.decorative.text',
                      })}
                    </p>
                  )}
                  <StFooter>
                    <Button
                      content={formatMessage({
                        id: 'addInfoAndAlt.modal.button.save',
                      })}
                      type='submit'
                    />
                  </StFooter>
                </FormikForm>
              )
            }}
          />
        </StAltBlock>
      </StModalBody>
    </ModalContainer>
  )
}

AddInfoAndAltModal.propTypes = {
  src: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  hideCancelButton: PropTypes.bool,
  hideHeader: PropTypes.bool,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  onAlbumItemUpdate: PropTypes.func,
  altText: PropTypes.string,
}

AddInfoAndAltModal.contextTypes = {
  intl: PropTypes.object,
}

export default AddInfoAndAltModal
