import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'

import statisticVisitsSummaryGet from '@graphql/gql/website/statisticVisitsSummaryGet.gql'

import TotalPageViews from '@configurator/components/statistics/totalInfo/TotalPageViews'

export const totalPageViewsWrapper = (Component) =>
  compose(
    connect((state) => ({ matomoOptions: state.get('periodPicker').toJS() })),
    graphql(statisticVisitsSummaryGet, {
      skip: ({ matomoOptions }) => !matomoOptions.idSite,
      options: ({ matomoOptions }) => ({
        variables: matomoOptions,
        ssr: false,
      }),
      props({ data: { loading, statisticVisitsSummaryGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(statisticVisitsSummaryGet)) {
          return { loading }
        }
        const {
          data: { nb_visits: count },
        } = statisticVisitsSummaryGet

        return {
          count,
        }
      },
    })
  )(Component)

export default totalPageViewsWrapper(TotalPageViews)
