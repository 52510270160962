import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'
import { FormToggle } from 'packages/components/inputs'
import { usePrevious } from 'packages/helpers/usePrevious'
import { formProptypes } from 'packages/utils/formikPropTypes'

import { ToggleContainer } from './nodes'

const OnlineToggle = (
  {
    values,
    updateMeta,
    loading,
    isSubmitting,
    refetchMeta,
    setSubmitting,
    setStatus,
    openErrorModal,
  },
  { intl: { formatMessage } }
) => {
  const prevValues = usePrevious(values)

  useEffect(() => {
    if (prevValues?.matomoOn !== values.matomoOn && prevValues?.id === values.id) {
      updateMeta({
        id: values.id,
        matomoOn: values.matomoOn,
      })
        .then((res) => {
          setSubmitting(false)
          let {
            data: { websiteMetaUpdate: { errors, success } = {} },
          } = res
          if (success) {
            return refetchMeta()
          }
          openErrorModal({ subMessageId: errors._error || 'error' })
          setStatus({ error: errors._error || 'error' })
        })
        .catch((err) => {
          setSubmitting(false)
          openErrorModal({ subMessageId: __PRODUCTION__ ? 'error' : err.toString() })
          setStatus()
        })
    }
  }, [openErrorModal, prevValues, refetchMeta, setStatus, setSubmitting, updateMeta, values])
  return (
    <ToggleContainer>
      <span>{formatMessage({ id: 'statistics.online' })}</span>
      <FormToggle name='matomoOn' disabled={isSubmitting || loading} />
    </ToggleContainer>
  )
}

OnlineToggle.propTypes = {
  updateMeta: PropTypes.func.isRequired,
  openErrorModal: PropTypes.func.isRequired,
  ...formProptypes,
}

OnlineToggle.contextTypes = {
  intl: intlShape,
}

export default OnlineToggle
