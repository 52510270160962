import { Map, List, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import keys from 'lodash/keys'

import {
  UPDATE_PREVIEW_STRUCTURE_DATA,
  UPDATE_PREVIEW_META_DATA,
  UPDATE_FEATURE_PROPERTIES,
  SET_LOGO_MARGINS,
  SET_FEATURE_ENABLED,
} from '../../constants/Preview'

const updateStructureData = createAction(UPDATE_PREVIEW_STRUCTURE_DATA)
const updateMetaData = createAction(UPDATE_PREVIEW_META_DATA)
const updateFeatureProperties = createAction(UPDATE_FEATURE_PROPERTIES)
const setTemplateLogoMargins = createAction(SET_LOGO_MARGINS)
const setFeatureEnabled = createAction(SET_FEATURE_ENABLED)

export default handleActions(
  {
    [updateStructureData]: (state, { payload }) => {
      return state.merge({
        structure: payload,
      })
    },
    [updateMetaData]: (state, { payload }) => {
      return state.merge({
        meta: { ...state.getIn(['meta']).toJS(), ...payload },
      })
    },
    [setFeatureEnabled]: (state, { payload }) => {
      const { key, value } = payload
      const features = (state.getIn(['meta', 'features']) || List()).toJS()
      const feature = features.find((feature) => feature.featureName === key)
      if (feature) {
        const featureIndex = features.findIndex((feature) => feature.featureName === key)
        return state.setIn(['meta', 'features', featureIndex, 'enabled'], value)
      } else {
        return state
      }
    },
    [updateFeatureProperties]: (state, { payload }) => {
      const { key, values } = payload

      const features = (state.getIn(['meta', 'features']) || List()).toJS()
      const featureIndex = features.findIndex((feature) => feature.featureName === key)
      if (featureIndex !== -1) {
        const properties = (features[featureIndex] || {}).properties
        keys(values).forEach((key) => {
          const propIndex = properties.findIndex((el) => el.key === key)
          if (propIndex !== -1) {
            properties[propIndex].value = values[key]
          }
        })

        return state.setIn(['meta', 'features', featureIndex, 'properties'], fromJS(properties))
      } else {
        return state
      }
    },

    [setTemplateLogoMargins]: (state, { payload }) => {
      return state.setIn(['meta', 'templateLogoMargins'], fromJS(payload))
    },
  },
  new Map()
)
