import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { withRouter } from 'react-router'
import isEmpty from 'lodash/isEmpty'

import billingCreateCheckoutSession from '@graphql/gql/website/billingCreateCheckoutSession.gql'
import billingCreateCustomerPortal from '@graphql/gql/website/billingCreateCustomerPortal.gql'
import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import { openModal as openDowngradeModal } from '@configurator/redux/modules/downgrade/actions'
import Subscription from '@configurator/components/billing/subscription'
import billingPlansGet from '@graphql/gql/website/billingPlansGet.gql'
import billingTrialStart from '@graphql/gql/website/billingTrialStart.gql'
import billingSubscriptionsGet from '@graphql/gql/website/billingSubscriptionsGet.gql'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'

export default compose(
  withRouter,
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
          })
        ),
      loaderStart: () => dispatch(loaderStart()),
      loaderStop: () => dispatch(loaderStop()),
      openDowngradeModal: () => dispatch(openDowngradeModal()),
    })
  ),
  graphql(billingCreateCheckoutSession, {
    props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
      return {
        async billingCreateCheckoutSession({ priceId, clientReferenceId }) {
          try {
            loaderStart()
            const res = await mutate({
              variables: {
                priceId,
                clientReferenceId,
              },
            })
            loaderStop()
            if (get(res, 'data.billingCreateCheckoutSession.errors._error')) {
              openErrorModal({
                headerMessageId: 'error.header',
                yesMessageId: 'OK',
                subMessageId: get(res, 'data.billingCreateCheckoutSession.errors._error'),
              })
            }
            return res
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(billingPlansGet, {
    options({ discountCode }) {
      return {
        variables: {
          discountCode,
        },
      }
    },
    props({ data: { loading, billingPlansGet } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(billingPlansGet)) {
        return { loading }
      }
      const { data } = billingPlansGet

      return {
        plans: data.plans,
      }
    },
  }),
  graphql(billingCreateCustomerPortal, {
    props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
      return {
        async billingCreateCustomerPortal() {
          try {
            loaderStart()
            const res = await mutate()
            loaderStop()
            if (get(res, 'data.billingCreateCustomerPortal.errors._error')) {
              openErrorModal({
                headerMessageId: 'error.header',
                yesMessageId: 'OK',
                subMessageId: get(res, 'data.billingCreateCustomerPortal.errors._error'),
              })
            }
            return res
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(billingTrialStart, {
    props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
      return {
        async billingTrialStart() {
          try {
            loaderStart()
            const res = await mutate({
              variables: {},
              refetchQueries: [
                {
                  query: billingSubscriptionsGet,
                },
              ],
            })
            loaderStop()
            if (get(res, 'data.billingTrialStart.errors._error')) {
              openErrorModal({
                headerMessageId: 'error.header',
                yesMessageId: 'OK',
                subMessageId: get(res, 'data.billingTrialStart.errors._error'),
              })
            }
            ANALYTICS.startTrial()
            return get(res, 'data.billingTrialStart')
          } catch (err) {
            loaderStop()
            return {
              success: false,
            }
          }
        },
      }
    },
  })
)(Subscription)
