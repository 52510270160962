import styled from 'styled-components'
import { media } from 'packages/components/media'

const TEXT_COLOR = '#343840'
const DESC_TEXT_COLOR = '#666A73'

export const StFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > span {
    font-weight: bold;
    font-size: 20px;
    color: ${TEXT_COLOR};
  }

  .ui.button {
    font-weight: bold;
    width: 166px;
    height: 45px;
  }

  ${media.max.phone`
        margin-bottom: 32px;

        .ui.button {
            display: none !important;
        }
    `}
`

export const Content = styled.div`
  justify-content: center;
  display: flex !important;
  color: ${TEXT_COLOR};

  > form {
    width: 100%;
  }
`

export const StyledInput = styled.div`
  ${(props) => (props.hide ? 'display: none;' : '')}
  margin-top: 20px;

  .ui.input {
    width: 100% !important;
  }
`
export const StyledInputCode = styled.div`
  ${(props) => (props.hide ? 'display: none;' : '')}
  margin-top: 20px;

  textarea {
    width: 100% !important;
    min-height: 130px !important;
    background: #343840 !important;
    color: white !important;
    border-color: #343840 !important;

    &:focus {
      border-color: #343840 !important;
    }

    &::placeholder {
      color: white !important;
    }
  }
`

export const Title = styled.div`
  position: relative;
  font-size: 15px;
  line-height: 21px;
  ${(props) => (props.bold ? 'font-weight: bold;' : '')}
`

export const Description = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${DESC_TEXT_COLOR};
  margin-top: 5px;
`

export const StyledRadio = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 13px;
    margin-left: 10px;
  }
`

export const StPagePosition = styled.div`
  display: flex;
  margin-top: 30px;

  ${Title} {
    margin-right: 10px;

    ${media.max.phone`
            margin-right: auto;
        `}
  }

  ${StyledRadio} {
    margin-left: 40px;

    ${media.max.phone`
            margin-left: 30px;
        `}
  }
`

export const EnablePage = styled.div`
  ${(props) => (props.hide ? 'display: none;' : 'display: flex;')}
  align-items: center;
  justify-content: space-between;
`

export const Divider = styled.div`
  border-top: 1px solid #e1e2e6;
  margin: 30px 0;
`

export const DisplayMode = styled.div`
  ${(props) => (props.hide ? 'display: none;' : '')}
`

export const DisplayModeOptions = styled.div`
  height: 50px;
  width: 100%;
  margin: 28px 0;
  border-radius: 2px;
  border: 1px solid #e1e2e6;
  font-size: 13px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DisplayModeOptionTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  background: ${(props) => (props.active ? TEXT_COLOR : 'transparent')};
  color: ${(props) => (props.active ? 'white' : DESC_TEXT_COLOR)};
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  border-radius: 2px;
`

export const EntireZoomOption = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 56px;

  ${media.max.phone`
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const ExpandedPreview = styled.div`
  margin-top: 30px;
  white-space: nowrap;

  > img {
    margin-right: 5px;
  }

  > span {
    display: block;
    margin-top: 10px;
    font-size: 13px;
  }
`

export const ExpandedPreviewRow = styled.div`
  display: flex;
  gap: 4px;
`

export const ExpandedPreviewRowItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  color: #666a73;
  img {
    height: 100px;
    object-fit: cover;
  }
`

export const StackedImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  text-align: center;
  img {
    width: 150px;
    margin-bottom: 10px;
  }
  span {
    margin-right: 10px;
    color: #666a73;
  }
`

export const StackedPreview = styled.div`
  display: flex;

  > div {
    &:first-child {
      margin: ${(props) => (props.hide ? '0' : '0 40px 0 0')};
    }
    &:last-child {
      ${(props) => (props.hide ? 'display: none;' : '')}

      > span {
        font-size: 13px;
      }
    }
  }

  ${media.max.phone`
        flex-direction: column;

        > div {
            &:first-child {
                margin-right: 0;
            }
            &:last-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                ${(props) => (props.hide ? 'display: none;' : '')}

                > span {
                    margin-right: 35px;
                }
            }
        }
    `}
`

export const LandingPage = styled.div`
  ${(props) => (props.hide ? 'display: none;' : 'display: flex;')}
  align-items: center;
  justify-content: space-between;
`

export const HidePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Password = styled.div`
  ${(props) => (props.hide ? 'display: none;' : '')}
  padding-top: 16px;
  > div {
    :first-child {
      margin-bottom: 18px;
    }
  }

  .ui.input {
    width: 100% !important;
  }
`

export const LinkComponent = styled.a`
  display: block;
  font-family: Sailec;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #58c367;

  &:hover {
    color: #58c367;
  }
`

export const InputIconContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  height: 32px;
  cursor: pointer;

  &:hover svg path {
    fill: #666a73;
  }
`
