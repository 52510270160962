import styled, { css } from 'styled-components'
import { Button as ButtonComp } from 'packages/components/buttons/button'

export const Container = styled.div``

export const Settings = styled.div`
  display: flex;
  width: max-content;
  height: 50px;
  margin: 0 auto 20px;
  background: #343840;
`

export const SettingsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 17px;
  margin-left: -20px;

  & > * {
    margin-left: 20px;
  }
`

export const SettingsItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: #c1c3c7;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    path {
      fill: #58c367;
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      &:hover {
        path {
          fill: #c1c3c7;
        }
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: #58c367;
    `}
`

export const SettingsDivider = styled.div`
  width: 1px;
  height: 24px;
  background: #666a73;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-right: 5px;

  & > * {
    margin-left: 5px !important;
    padding: 0 10px !important;
  }
`

export const Button = styled(ButtonComp)`
  width: 120px;
  height: 36px;
`

export const Img = styled.div`
  height: 80vh;

  img {
    display: block;
    ///* This rule is very important, please don't ignore this */
    max-width: 100%;
  }
`
