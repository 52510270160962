import {
  UPDATE_PREVIEW_META_DATA,
  UPDATE_PREVIEW_STRUCTURE_DATA,
} from '@configurator/constants/Preview'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'
import { Tabs } from 'packages/components/tabs'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createAction } from 'redux-actions'

export default compose(
  connect(
    (state) => ({
      templatesColor: state.getIn(['templatesColor']),
      activeTab: state.getIn(['mainTab', 'activeTab']),
    }),
    (dispatch) => ({
      updateMeta: throttle(
        (payload) => dispatch(createAction(UPDATE_PREVIEW_META_DATA)(payload)),
        32
      ),
      updateStructure: throttle(
        (payload) => dispatch(createAction(UPDATE_PREVIEW_STRUCTURE_DATA)(payload)),
        32
      ),
    })
  ),

  graphql(previewPageGet, {
    options() {
      // withRouter использовать нельзя,т.к. компонент прикручен выше в роутинге, чем параметры
      let pageId = ''
      const roots = `${window.location.href}`.split('/')
      const pageIndex = roots.findIndex((root) => root === 'page' || root === 'blocks')
      if (pageIndex >= 0) {
        pageId = roots[pageIndex + 1]
      }
      return {
        variables: {
          id: pageId ? decodeURIComponent(pageId) : undefined,
        },
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, previewPageGet }, ownProps: { updateStructure, updateMeta } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(previewPageGet)) {
        return { loading }
      }
      const { data } = previewPageGet
      // update preview
      setTimeout(() => {
        updateMeta(data.meta)
        updateStructure(data.structure)
      })

      return {
        templateParams: {
          template: get(data, 'meta.templateName'),
          align: get(data, 'meta.align')?.toUpperCase(),
          inverted: get(data, 'meta.inverted'),
          slideshowEnabled: get(data, 'meta.slideshowEnabled'),
        },
      }
    },
  })
)(Tabs)
