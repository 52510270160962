import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import moment from 'moment'
import { DATE_FORMAT, PERIOD_OPTIONS } from '@configurator/constants/statistics'

import statisticLineDataGet from '@graphql/gql/website/statisticLineDataGet.gql'

import LineChart from '@configurator/components/statistics/charts/statisticLine/LineChart'

export const lineChartWrapper = (Component) =>
  compose(
    connect((state) => ({ matomoOptions: state.get('periodPicker').toJS() })),
    graphql(statisticLineDataGet, {
      skip: ({ matomoOptions }) => !matomoOptions.idSite,
      options: ({ matomoOptions }) => {
        if (matomoOptions.period === PERIOD_OPTIONS.day) {
          const dateFrom = moment(matomoOptions.date, DATE_FORMAT)
            .subtract(15, 'days')
            .format(DATE_FORMAT)
          return {
            variables: {
              ...matomoOptions,
              date: `${dateFrom},${matomoOptions.date}`,
              ssr: false,
            },
          }
        }
        if (matomoOptions.period === PERIOD_OPTIONS.week) {
          const dateFrom = moment(matomoOptions.date, DATE_FORMAT)
            .subtract(6, 'weeks')
            .format(DATE_FORMAT)
          return {
            variables: {
              ...matomoOptions,
              date: `${dateFrom},${matomoOptions.date}`,
              ssr: false,
            },
          }
        }
        if (matomoOptions.period === PERIOD_OPTIONS.month) {
          const dateFrom = moment(matomoOptions.date, DATE_FORMAT)
            .subtract(6, 'months')
            .format(DATE_FORMAT)
          return {
            variables: {
              ...matomoOptions,
              date: `${dateFrom},${matomoOptions.date}`,
              ssr: false,
            },
          }
        }
        if (matomoOptions.period === PERIOD_OPTIONS.year) {
          const dateFrom = moment(matomoOptions.date, DATE_FORMAT)
            .subtract(3, 'years')
            .format(DATE_FORMAT)
          return {
            variables: {
              ...matomoOptions,
              date: `${dateFrom},${matomoOptions.date}`,
              ssr: false,
            },
          }
        }
        if (matomoOptions.period === PERIOD_OPTIONS.range) {
          return {
            variables: {
              ...matomoOptions,
              period: 'day',
              ssr: false,
            },
          }
        }
      },
      props({ data: { loading, statisticLineDataGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(statisticLineDataGet)) {
          return { loading }
        }
        const { data } = statisticLineDataGet

        return {
          data: isObject(data) ? data : undefined,
          loading,
        }
      },
    })
  )(Component)

export default lineChartWrapper(LineChart)
