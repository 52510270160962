import React from 'react'
import propTypes from 'prop-types'
import { GridIcon, HomeIcon } from 'packages/components/icons'
import { getItemStyles } from 'packages/utils/dndDropHelper'
import { IconWrapper, Title, LayerContainer } from './nodes'
import { renderTypeIcon } from './index'

const CustomDragLayer = ({ initialOffset, currentOffset, startPage, type, title }) => {
  return (
    <LayerContainer style={getItemStyles(initialOffset, currentOffset)}>
      <Title isShowHeader>
        {startPage ? (
          <IconWrapper paddingL='17' paddingR='17'>
            <HomeIcon />
          </IconWrapper>
        ) : (
          <IconWrapper paddingL='18' paddingR='18'>
            <GridIcon color='gray' />
          </IconWrapper>
        )}
        {renderTypeIcon(type)}
        <span className='navTitle'>{title}</span>
      </Title>
    </LayerContainer>
  )
}

CustomDragLayer.propTypes = {
  title: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  startPage: propTypes.bool.isRequired,
  initialOffset: propTypes.object,
  currentOffset: propTypes.object,
}

export default CustomDragLayer
