import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Notes = styled.div`
  color: #ededed;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  > a {
    color: #436df6;
    cursor: pointer;
  }
`

export const NotesRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`

export const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  color: ${(props) => (props.isActive ? '#fff' : '#666a73')};
  background: ${(props) => (props.isActive ? '#343840' : '#fff')};
  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
`

export const Tabs = styled.div`
  height: 50px;
  border-radius: 2px;
  border: 1px solid #e1e2e6;
  display: flex;
  align-items: center;
  padding: 0 4px;

  > a {
    width: 50%;

    ${Tab} {
      width: 100%;
    }
  }
`
export const Description = styled.div`
  color: #ededed;

  > span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
  }
`
export const WaitingScreenHeader = styled.div`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
`

export const WaitingScreenDescription = styled.div`
  margin-bottom: 56px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ededed;
`

export const WaitingScreenContent = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`

export const WaitingScreenProgress = styled.div`
  max-width: 330px;
  margin: auto;

  .ui.progress {
    border-radius: 6px;
    margin-bottom: 0;
    background-color: #34363b;

    .bar {
      height: 8px;
      border-radius: inherit;
      background-color: #436df6;
    }
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    margin-left: 5px;
  }
`

export const AboutFormHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #fff;
`

export const AboutFormSelectHeader = styled(AboutFormHeader)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
`

export const AboutFormCheckboxHeader = styled(AboutFormHeader)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 16px;
`

export const AboutFormLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ededed;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  > span {
    margin-left: 16px;
  }
`

export const AboutFormInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div:nth-child(2) > div > div {
    color: #c1c3c7 !important;
  }
`

export const NativationTabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px;
`

export const NavigationTabItem = styled(Link)`
  background: ${(props) => (props.isActive ? '#242426' : '#141414')};
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  align-items: center;
`

export const Container = styled.div`
  max-width: 432px;
  width: 100%;
  margin: 0 auto;
  background-color: #141414;
  height: max-content;
  border-radius: 8px;
  align-self: ${(props) => (props.verticalAlign ? props.verticalAlign : 'center')};
`

export const ContainerInner = styled.div`
  padding: 10px 40px 40px 40px;

  @media (max-width: 768px) {
    padding: 0;
  }
`

export const WaitingScreenContainer = styled(Container)`
  text-align: center;
`

export const NavigationTabItemHeader = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  color: #fff;
  font-size: 28px;
  max-width: 280px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`

export const NavigationTabItemTrialNote = styled.div`
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
`

export const NavigationTabItemNote = styled.div`
  color: #58c367;
  text-align: center;
  /* Text 12-16 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`
