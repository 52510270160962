import styled from 'styled-components'
import { Semantic } from 'packages/components'

export const Container = styled.div`
  height: 300px;
  width: 400px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 4px;
`

export const Input = styled.input`
  height: 30px;
  outline: none;
  text-align: center;
  margin-bottom: 20px;
`

export const SubmitButton = styled(Semantic.Button)`
  position: absolute;
  bottom: 17px;
  right: 30px;
`

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
