import { injectIntl } from 'react-intl'

import {
  AlertBlock,
  CompareButton,
  ComparePlans,
  CurrentPlan,
  HiddenStatus,
  LeftBlock,
  Name,
  RightBlock as RightBlockStyled,
  SubscriptionCard,
  TitleBlock,
  Wrapper,
} from '../../nodes'

export const Card = ({
  planName,
  Status,
  Expire,
  RightBlock,
  alertBlock,
  intl: { formatMessage },
}) => {
  return (
    <SubscriptionCard>
      <Wrapper>
        <LeftBlock>
          <TitleBlock>
            {Status}

            <CurrentPlan>
              <Name>{planName}</Name>

              {Expire}
            </CurrentPlan>
          </TitleBlock>

          <ComparePlans status='active'>
            {/* Only needs for correct alignment of CompareButton */}
            <HiddenStatus>{Status}</HiddenStatus>

            <CompareButton href='https://www.vsble.me/pricing' target='_blank' rel='noreferrer'>
              {formatMessage({ id: 'accountBilling.billing.comparePlans' })}
            </CompareButton>
          </ComparePlans>
        </LeftBlock>

        {RightBlock && <RightBlockStyled>{RightBlock}</RightBlockStyled>}
      </Wrapper>

      {alertBlock && <AlertBlock>{alertBlock}</AlertBlock>}
    </SubscriptionCard>
  )
}
export default injectIntl(Card)
