import styled from 'styled-components'
import { media } from 'packages/components/media'

const TEXT_COLOR = '#343840'
const VISIBILITY_OFF_TEXT_COLOR = '#C1C3C7'
const TRASH_HOVER_COLOR = '#FF002E'
const SETTINGS_HOVER_COLOR = '#666A73'
const BACKGROUND_HOVER_COLOR = '#EDEDED'

export const Container = styled.div`
  position: relative;
  opacity: ${(props) => (props.isDragging ? 0 : 1)};
`

export const ContainerDropAlbum = styled.div`
  z-index: 2;
`

export const IconWrapper = styled.div`
  line-height: 0;

  ${(props) => (props.paddingL ? `padding-left: ${props.paddingL}px;` : '')}
  ${(props) => (props.paddingR ? `padding-right: ${props.paddingR}px;` : '')}

    ${media.laptop`

    `}
`

export const RightIconsBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  span:first-child {
    svg path {
      fill: ${SETTINGS_HOVER_COLOR};
    }
  }

  span:nth-child(2) {
    padding: 6px;

    svg path {
      fill: ${SETTINGS_HOVER_COLOR};
    }
  }

  > span {
    line-height: 0;
    margin-left: 4px;
  }

  > svg {
    margin-left: 35px;
  }

  ${media.tablet`
        margin-right: 15px;
    `}
  ${media.laptop`
         > span {
            svg {
                visibility: hidden;
            }
        }
        span:first-child {
            &:hover svg path {
                fill: ${TRASH_HOVER_COLOR};
            }
        }

        span:nth-child(2) {
            &:hover svg path {
                fill: ${SETTINGS_HOVER_COLOR};
            }
        }
    `}
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${(props) => (props.isDragging && (!props.isOver || !props.justDrag) ? '0' : '50px')};
  opacity: ${(props) => (props.isDragging ? '0' : '1')};
  transition: height 0.2s cubic-bezier(0.2, 0, 0, 1) 0.1s;

  ${(props) => (props.isOverAlbum ? `border: 1px solid ${TEXT_COLOR};` : '')}
  > span.navTitle {
    ${(props) => (props.isOverAlbum ? 'font-size: 14px;' : '')}
    ${(props) => (props.isOverAlbum ? 'font-weight: bold;' : '')}
        color: ${(props) => (props.isVisible ? TEXT_COLOR : VISIBILITY_OFF_TEXT_COLOR)};
    text-decoration: ${(props) => (props.isShowHeader ? 'none' : 'line-through')};
    padding: 0 10px 0 18px;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${RightIconsBlock} > svg {
    visibility: ${(props) => (props.isLocked ? 'visible' : 'hidden')};
  }

  ${media.laptop`
       height: ${(props) =>
         props.isDragging && (!props.isOver || !props.justDrag) ? '0' : '40px'};
       &:hover {
           background: ${BACKGROUND_HOVER_COLOR};
       }
       &:hover ${RightIconsBlock} > span svg {
           visibility: visible;
       }
   `}
`
export const LayerContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  opacity: 1;
  width: max-content !important;
`

export const DropZone = styled.div`
  height: ${(props) => (props.show ? '40px' : '0px')};
  transition: height 0.2s cubic-bezier(0.2, 0, 0, 1) 0.1s;
  background: ${(props) => (props.show ? 'green' : '')};
  opacity: ${(props) => (props.show ? '0.1' : '')};
`
