import React from 'react'
import PropTypes from 'prop-types'
import maxBy from 'lodash/maxBy'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import Chart from 'packages/components/echart'
import { intlShape } from 'react-intl'

import { ChartComponent } from './nodes'
import { useMediaQuery } from 'packages/helpers/useMediaQuery'
import { sizes } from 'packages/components/media'

const MapChart = ({ loading, rawData }, { intl: { formatMessage } }) => {
  const isMobile = useMediaQuery(`(max-width: ${sizes.phone}px)`)
  const isTablet = useMediaQuery(`(min-width: ${sizes.phone}px) and (max-width: ${sizes.tablet}px)`)
  const data =
    rawData && !isEmpty(rawData)
      ? rawData.map((el) => ({
          name: el.code.toUpperCase(),
          value: el.nb_visits || 0,
          label: el.label,
        }))
      : undefined
  const getMax = () => (data ? maxBy(data, 'value') : {})
  const topCountries = data
    ? reverse(sortBy(data, 'value')).splice(0, isTablet ? 30 : isMobile ? 11 : 7)
    : []

  const option = {
    title: {
      text: formatMessage({ id: 'mapChart.title' }),
      left: 0,
      textStyle: {
        color: '#343840',
        fontSize: 18,
        fontWeight: 'bolder',
      },
    },
    legend: {
      show: !!data,
      orient: 'vertical',
      left: 0,
      top: 35,
      data: topCountries.map(({ label }) => label),
      formatter: (label) => {
        const value = topCountries.find((el) => el.label === label).value
        return `${label} ${value}`
      },
      type: 'scroll',
      textStyle: {
        color: '#666A73',
        fontSize: 13,
      },
      ...((isMobile || isTablet) && {
        top: undefined,
        bottom: 0,
        type: 'plain',
        height: 230,
      }),
      ...(isMobile && {
        height: 260,
      }),
    },
    tooltip: {
      show: !!data,
      trigger: 'item',
      formatter: (props) => {
        if (props.value) {
          const label = get(
            data.find((el) => el.name === props.name),
            'label'
          )
          return `${label}: ${props.value}`
        }
        return null
      },
    },
    visualMap: {
      show: !!data,
      left: 'right',
      top: 0,
      min: 0,
      max: get(getMax(), 'value') || 0,
      text: ['High', 'Low'],
      realtime: false,
      calculable: true,
      inRange: {
        color: ['#8BD0F5', '#B6E09E', '#C6E67F', '#FFF837', '#FDA529', '#FD4A1F'],
      },
      itemHeight: 215,
      itemWidth: 30,
      textStyle: {
        color: '#666A73',
        fontSize: 13,
      },
      ...(isMobile && {
        top: undefined,
        bottom: 0,
      }),
      ...(isTablet && {
        top: 45,
      }),
    },
    series: data
      ? data.map((el) => ({
          itemStyle: {
            normal: { label: { show: false } },
            emphasis: { label: { show: false } },
          },
          showLegendSymbol: false,
          nameProperty: 'ISO_A2',
          name: el.label,
          type: 'map',
          map: 'world2',
          data: [el],
          ...((isMobile || isTablet) && {
            top: 35,
            left: 0,
          }),
          ...(isMobile && {
            width: '100%',
          }),
          ...(isTablet && {
            width: '85%',
          }),
        }))
      : {
          itemStyle: {
            normal: { label: { show: false } },
            emphasis: { label: { show: false } },
          },
          showLegendSymbol: false,
          nameProperty: 'ISO_A2',
          name: 'Empty map',
          type: 'map',
          map: 'world2',
          data: [],
          ...(isMobile && {
            width: '100%',
            top: 'middle',
            left: 0,
          }),
        },
    // series: {
    //     itemStyle: {
    //         normal: { label: { show: false } },
    //         emphasis: { label: { show: false } },
    //     },
    //     nameProperty: 'ISO_A2',
    //     name: 'Visits',
    //     type: 'map',
    //     map: 'world2',
    //     data,
    // },
  }

  return (
    <ChartComponent hasData={data}>
      <Chart showLoading={loading} option={option} notMerge />
    </ChartComponent>
  )
}

MapChart.propTypes = {
  rawData: PropTypes.array,
  loading: PropTypes.bool,
}

MapChart.defaultProps = {
  loading: false,
}

MapChart.contextTypes = { intl: intlShape }

export default MapChart
