import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { Field } from 'formik'
import { intlShape } from 'react-intl'
import { fieldProptypes } from './formikPropTypes'
import { Checkbox } from '../checkbox'

const SemanticCheckboxInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched = {}, errors = {}, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid,
  label,
  ...props
}) => {
  const handleClick = () => {
    if (!props.disabled) {
      setFieldValue(field.name, !field.value)
    }
  }
  return (
    <Checkbox
      {...field}
      {...props}
      onChange={handleClick}
      checked={field.value}
      error={errors[field.name] && touched[field.name]}
    />
  )
}

SemanticCheckboxInput.propTypes = {
  ...fieldProptypes,
  ...SemanticCheckbox.propTypes,
}

SemanticCheckboxInput.defaultProps = {
  ...SemanticCheckbox.defaultProps,
}

SemanticCheckboxInput.contextTypes = {
  intl: intlShape,
}

export const FormCheckbox = ({ name, ...props }) => (
  <Field name={name} {...props} component={SemanticCheckboxInput} />
)

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  ...SemanticCheckbox.propTypes,
}
