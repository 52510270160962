import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.laptop`
       margin-top: 0;
   `}

  ${media.max.tablet`
        padding-bottom: 100px;
    `}
`

export const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NavigationDataContainer = styled.div`
  & > *:not(:first-child) {
    margin-top: 23px;
  }

  @media (pointer: coarse) {
    user-select: none;
  }
`

const PLUS_HOVER_COLOR = '#666A73'

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 3px 0 3px 0;
  margin: -1px 9px 16px 15px;

  > span {
    font-weight: bold;
    font-size: 13px;
  }
  ${media.tablet`
        margin: -1px 23px 16px 18px;
    `}
  ${media.laptop`
        margin: 0 27px 10px 30px;
    `}
`

export const HeaderSvgContainer = styled.div`
  height: 100%;
  width: 30px;
  cursor: pointer;

  &:hover svg path {
    fill: ${PLUS_HOVER_COLOR};
  }

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;

  .menu.transition.visible {
    right: -10px !important;
    top: 10px !important;
  }
`

export const SearchContainer = styled.div`
  display: flex;
  position: relative;
  height: 50px;
  margin-bottom: 17px;
  border-bottom: 1px solid #e1e2e6;
`

export const SearchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  left: 15px;
  pointer-events: none;
`

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 50px;
  border: none;
  background: transparent;
  outline: none;
`

export const NotFoundTitle = styled.span`
  display: block;
  text-align: center;
  margin-bottom: 10px;
`
