import PropTypes from 'prop-types'
import React from 'react'
import { PlusIcon } from 'packages/components/icons'
import AlbumNodeItem from './item'
import AlbumPlaceholder from './placeholder'
import { Container, Button, StructureBlock } from './nodes'

const AlbumNode = ({
  pageId,
  albums,
  albumType,
  onClickAlbum,
  onDeleteAlbum,
  onCreateAlbum,
  onDropAlbum,
  redirectToPage,
  draggable,
  deletable,
  customizable,
  creatable,
  pageUrl,
}) => (
  <Container>
    {creatable && (
      <Button onClick={onCreateAlbum}>
        <StructureBlock empty={albums.length === 0} />
        <PlusIcon />
        <span>New album</span>
      </Button>
    )}
    {albums.length > 0 &&
      albums.map((album, index) => {
        return (
          <AlbumNodeItem
            key={album.id}
            pageId={pageId}
            id={album.id}
            link={album.link}
            name={album.name}
            itemType={albumType}
            onClick={onClickAlbum}
            onDelete={onDeleteAlbum}
            onDrop={onDropAlbum}
            redirectToPage={redirectToPage}
            password={album.password}
            showHeader={album.showHeader}
            visible={album.visible}
            last={albums.length - 1 === index}
            draggable={draggable}
            deletable={deletable}
            customizable={customizable}
            albumUrl={album.albumUrl}
            pageUrl={pageUrl}
          />
        )
      })}
    {!albums.length && draggable && (
      <AlbumPlaceholder pageId={pageId} onDrop={onDropAlbum} itemType={albumType} />
    )}
  </Container>
)

AlbumNode.propTypes = {
  pageId: PropTypes.number.isRequired,
  albums: PropTypes.array.isRequired,
  albumType: PropTypes.string.isRequired,
  draggable: PropTypes.bool,
  deletable: PropTypes.bool,
  customizable: PropTypes.bool,
  creatable: PropTypes.bool,
  albumUrl: PropTypes.string,
  pageUrl: PropTypes.string,

  onClickAlbum: PropTypes.func,
  onDeleteAlbum: PropTypes.func,
  onCreateAlbum: PropTypes.func,
  onDropAlbum: PropTypes.func,
  redirectToPage: PropTypes.func,
}

AlbumNode.defaultProps = {
  draggable: true,
  deletable: true,
  customizable: true,
  creatable: true,

  onDeleteAlbum: () => null,
  onCreateAlbum: () => null,
  onDropAlbum: () => null,
  redirectToPage: () => null,
}

export default AlbumNode
