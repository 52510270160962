import adminReducer from '@admin/redux/modules/admin/reducers'
import { connectRouter } from 'connected-react-router/immutable'
import intlReducer from 'packages/intl/redux'
import { routingReducer, userAgentReducer } from 'packages/redux'
import modalConfirmDeleteReducer from 'packages/redux/modules/modalConfirmDelete/reducers'
import modalProgressReducer from 'packages/redux/modules/modalProgress/reducers'
import errorModalReducer from 'packages/redux/modules/modalYesNo/reducers'
import pageLoaderReducer from 'packages/redux/modules/pageLoader/reducers'
import userReducer from 'packages/redux/modules/user/reducers'
import { combineReducers } from 'redux-immutable'
import { history } from './initialMiddlewares'
import accordionReducer from './modules/accordion/reducers'
import albumViewReducer from './modules/albumView/reducers'
import downgradeReducer from './modules/downgrade/reducers'
import mainTabReducer from './modules/mainTab/reducers'
import navigationReducer from './modules/navigation'
import periodPickerReducer from './modules/periodPicker/reducers'
import previewReducer from './modules/previewReducer'
import selectedImageReducer from './modules/selectedImages/reducers'
import subscriptionReducer from './modules/subscription/reducers'
import templatesColorReducer from './modules/templatesColor/reducers'
import uploadPhotoReducer from './modules/uploadPhotoReducer'

export default combineReducers({
  intl: intlReducer,
  router: connectRouter(history),
  // для логирования
  routerHook: routingReducer,
  userAgent: userAgentReducer,
  errorModal: errorModalReducer,
  modalConfirmDelete: modalConfirmDeleteReducer,
  pageLoader: pageLoaderReducer,
  user: userReducer,
  modalProgress: modalProgressReducer,
  preview: previewReducer,
  uploadPhotos: uploadPhotoReducer,
  selectedImages: selectedImageReducer,
  subscription: subscriptionReducer,
  periodPicker: periodPickerReducer,
  accordion: accordionReducer,
  downgrade: downgradeReducer,
  admin: adminReducer,
  navigation: navigationReducer,
  albumView: albumViewReducer,
  templatesColor: templatesColorReducer,
  mainTab: mainTabReducer,
})
