import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import get from 'lodash/get'

import resetPassword from '@graphql/gql/common/resetPassword.gql'
import ResetPasswordForm from '@landing/components/resetPassword/ResetPasswordForm'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('landing.password.error'),
})

export default compose(
  graphql(resetPassword, {
    props({ mutate }) {
      return {
        async resetPasswordMutation({ token, password }) {
          try {
            return await mutate({
              variables: {
                token,
                password,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => {
      return {
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      { password },
      {
        setSubmitting,
        setStatus,
        props: {
          resetPasswordMutation,
          match: { params },
        },
      }
    ) => {
      let defaultError = 'error.desc'
      try {
        const mailToken = params.token

        const res = await resetPasswordMutation({
          password,
          token: mailToken,
        })
        setSubmitting(false)
        let {
          data: { resetPassword: { errors, success } = {} },
        } = res
        if (success) {
          return setStatus({ success: true })
        }
        setSubmitting(false)
        setStatus({ error: get(errors, '_error', defaultError) })
      } catch (err) {
        setSubmitting(false)
        setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      }
    },
  })
)(ResetPasswordForm)
