import { createActions } from 'redux-actions'

export const {
  accordion: { pushIdx, setIdx, remIdx },
} = createActions({
  ACCORDION: {
    PUSH_IDX: (payload) => payload,
    SET_IDX: (payload) => payload,
    REM_IDX: (payload) => payload,
  },
})
