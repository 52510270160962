import PropTypes from 'prop-types'
import { iconColorPropTypes, SettingsIcon } from '../../icons'
import { StyledSVG } from '../svgStyles'

export const SettingsButton = StyledSVG(SettingsIcon)

SettingsButton.propTypes = {
  ...iconColorPropTypes,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

SettingsButton.defaultProps = {
  color: 'darkGray',
  active: true,
  className: '',
  onClick: () => {},
}
