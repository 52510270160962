import PropTypes from 'prop-types'
import { useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { useQuery, useMutation } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'

import keyBy from 'lodash/keyBy'
import get from 'lodash/get'

import websiteMetaGetQuery from '@graphql/gql/website/websiteMetaGet.gql'
import websiteMetaUpdateMutation from '@graphql/gql/website/websiteMetaUpdate.gql'
import websitePagesGetByTypeQuery from '@graphql/gql/website/websitePagesGetByType.gql'
import websitePageUpdateMutation from '@graphql/gql/website/websitePageUpdate.gql'
import { useIsMobile } from 'packages/helpers/useWindowSize'

import VerificationForm from './verificationForm'
import Loader from '../loader/Loader'
import SeoMobile from './seoMobile'
import SeoDesktop from './seoDesktop'
import SettingsContent from './settingsContent'

const tabs = [
  { id: 1, title: 'seo.google.website.settings', slug: 'settings' },
  { id: 2, title: 'seo.google.site.verification', slug: 'verification' },
]

const WebsiteSEO = ({
  lockModalProgress,
  unLockModalProgress,
  loaderStart,
  loaderStop,
  openErrorModal,
}) => {
  const isMobile = useIsMobile()

  const [activeSlug, setActiveSlug] = useState(tabs[0].slug)

  const metaGetOptions = {
    variables: {},
    fetchPolicy: 'network-only',
  }

  const { loading, data: { websiteMetaGet } = {} } = useQuery(websiteMetaGetQuery, metaGetOptions)

  const pageGetByTypeOptions = {
    variables: {
      pageType: '',
    },
    fetchPolicy: 'network-only',
  }

  const { loading: pagesLoading, data: { websitePagesGetByType } = {} } = useQuery(
    websitePagesGetByTypeQuery,
    pageGetByTypeOptions
  )

  const [websiteMetaUpdate] = useMutation(websiteMetaUpdateMutation)
  const [websitePageUpdate] = useMutation(websitePageUpdateMutation)

  if (loading || pagesLoading) {
    return <Loader />
  }

  const isMetaGetEmpty = isEmpty(websiteMetaGet) || !isEmpty(websiteMetaGet.errors)
  const isPagesGetEmpty = isEmpty(websitePagesGetByType) || !isEmpty(websitePagesGetByType.errors)

  if (isMetaGetEmpty || isPagesGetEmpty) {
    return <div>Error</div>
  }

  const { data: metaData } = websiteMetaGet
  const { data: pagesData } = websitePagesGetByType
  const pagesDataById = keyBy(pagesData, 'id')
  const selectedPageData = pagesDataById['all']
  const data = {
    ...metaData,
    seoTitle: selectedPageData?.seoTitle ?? metaData.seoTitle,
    seoDescription: selectedPageData?.seoDescription ?? metaData.seoDescription,
  }

  const defaultValues = {
    seoTitle: data.seoTitle,
    seoDescription: data.seoDescription,
  }

  const defaultError = 'error.desc'

  const domain = `https://${data.premiumDomain || `${data.domain}.vsble.me`}`

  const updateMeta = (variables) =>
    websiteMetaUpdate({
      variables: {
        websiteId: data.id,
        ...variables,
      },
      refetchQueries: [
        {
          query: websiteMetaGetQuery,
          ...metaGetOptions,
        },
      ],
    })

  const updatePage = (variables) =>
    websitePageUpdate({
      variables: {
        pageId: selectedPageData.id,
        ...variables,
      },
      refetchQueries: [
        {
          query: websitePagesGetByTypeQuery,
          ...pageGetByTypeOptions,
        },
      ],
    })

  const handleSubmitForm = async (values, { setSubmitting }) => {
    loaderStart()

    try {
      if (selectedPageData) {
        const websitePageUpdateRes = await updatePage({
          seoTitle: values.seoTitle,
          seoDescription: values.seoDescription,
        })

        if (!get(websitePageUpdateRes, 'data.websitePageUpdate.success')) {
          loaderStop()
          setSubmitting(false)
          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId:
              !get(websitePageUpdateRes, 'data.websitePageUpdate.errors._error') || defaultError,
            yesMessageId: 'OK',
          })
        }
      } else {
        const websiteMetaUpdateRes = await updateMeta({
          seoTitle: values.seoTitle,
          seoDescription: values.seoDescription,
        })

        if (!get(websiteMetaUpdateRes, 'data.websiteMetaUpdate.success')) {
          loaderStop()
          setSubmitting(false)

          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId:
              !get(websiteMetaUpdateRes, 'data.websiteMetaUpdate.errors._error') || defaultError,
            yesMessageId: 'OK',
          })
        }
      }

      loaderStop()
      setSubmitting(false)
    } catch (err) {
      loaderStop()
      setSubmitting(false)

      return openErrorModal({
        headerMessageId: 'error.header',
        subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
        yesMessageId: 'OK',
      })
    }
  }

  if (isMobile) {
    return (
      <SeoMobile
        VerificationForm={
          <VerificationForm
            data={data}
            loaderStart={loaderStart}
            loaderStop={loaderStop}
            openErrorModal={openErrorModal}
          />
        }
        Content={
          <SettingsContent
            lockModalProgress={lockModalProgress}
            unLockModalProgress={unLockModalProgress}
            loaderStart={loaderStart}
            loaderStop={loaderStop}
            openErrorModal={openErrorModal}
            defaultValues={defaultValues}
            domain={domain}
            data={data}
            updatePage={updatePage}
            selectedPageData={selectedPageData}
            handleSubmitForm={handleSubmitForm}
          />
        }
        setActiveSlug={setActiveSlug}
        activeSlug={activeSlug}
      />
    )
  }

  if (isMobile === false) {
    return (
      <SeoDesktop
        VerificationForm={
          <VerificationForm
            data={data}
            loaderStart={loaderStart}
            loaderStop={loaderStop}
            openErrorModal={openErrorModal}
          />
        }
        Content={
          <SettingsContent
            lockModalProgress={lockModalProgress}
            unLockModalProgress={unLockModalProgress}
            loaderStart={loaderStart}
            loaderStop={loaderStop}
            openErrorModal={openErrorModal}
            defaultValues={defaultValues}
            domain={domain}
            data={data}
            updatePage={updatePage}
            selectedPageData={selectedPageData}
            handleSubmitForm={handleSubmitForm}
          />
        }
        activeSlug={activeSlug}
        setActiveSlug={setActiveSlug}
      />
    )
  }

  return null
}

WebsiteSEO.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  lockModalProgress: PropTypes.func.isRequired,
  unLockModalProgress: PropTypes.func.isRequired,
  openErrorModal: PropTypes.func.isRequired,
}

WebsiteSEO.defaultProps = {}

export default injectIntl(WebsiteSEO)
