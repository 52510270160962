import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  max-width: 1270px;
  margin: 0 auto 0 auto;
  color: #343840;
  padding: 30px 20px 10px 20px;

  ${media.max.phone`
        padding-left: 16px;
        padding-right: 16px;
    `}
`

export const WrapperTabs = styled.div`
  padding-top: 15px;
  width: 172px;
`

export const Content = styled.div`
  flex: 1;
  border-left: 1px solid #e1e2e6;
  border-radius: 0px !important;
  padding: 15px 30px 0 50px;
  overflow-y: auto;
  overflow-x: hidden;

  ${media.max.laptop`
    padding: 15px 40px 0 40px;
  `}
`

export const BillingDesktop = styled.div`
  display: flex;
  gap: 110px;

  ${media.max.laptop`
        gap: 70px;
    `}

  ${media.max.tablet`
      display: none;
  `}

  @media (max-width: 1000px) {
    gap: 20px;
  }
`

export const BillingMobile = styled.div`
  display: none;

  ${media.max.tablet`
      display: flex;
    `}
`

export const Header = styled.div`
  font-size: 36px;
  line-height: 52px;
  font-weight: 700;
  margin-bottom: 30px;

  @media (max-width: 375px) {
    font-size: 26px;
    line-height: 38px;
    margin-bottom: 10px;
  }
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 867px;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
`

export const Footer = styled.div`
  margin-top: 100px;
  padding: 18px 0 16px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  font-size: 12px;
  color: #000;
  font-weight: 300;
`
