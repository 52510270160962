import { connect } from 'react-redux'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import SEOComponent from '@configurator/components/seo'

export default connect(
  // mapStateToProps
  () => ({}),
  // mapDispatchToProps
  (dispatch) => ({
    lockModalProgress: () => dispatch(lockModal(true)),
    unLockModalProgress: () => dispatch(lockModal(false)),
    loaderStart: ({ content } = {}) => dispatch(loaderStart({ content })),
    loaderStop: () => dispatch(loaderStop()),
    openErrorModal: ({ headerMessageId, yesMessageId, subMessageId, subMessageValues }) =>
      dispatch(
        openErrorModal({
          headerMessageId,
          yesMessageId,
          subMessageId,
          subMessageValues,
          hideCancelButton: true,
        })
      ),
  })
)(SEOComponent)
