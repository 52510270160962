import React from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'

import { TrendingUpIcon } from 'packages/components/icons'

import TotalInfo from './TotalInfo'
import { PagesContainer } from './nodes'

const TotalPageViews = ({ count, loading }, { intl: { formatMessage, formatNumber } }) => (
  <PagesContainer>
    <TotalInfo
      icon={<TrendingUpIcon color='white' />}
      count={formatNumber(count)}
      text={formatMessage({ id: 'statistics.total.pageViews' })}
      loading={loading}
    />
  </PagesContainer>
)

TotalPageViews.propTypes = {
  count: PropTypes.number,
  loading: PropTypes.bool,
}

TotalPageViews.defaultProps = {
  count: 0,
  loading: false,
}

TotalPageViews.contextTypes = {
  intl: intlShape,
}

export default TotalPageViews
