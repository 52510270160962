import { createSelector } from 'reselect'

export const nameSelector = createSelector(
  (state) => state.getIn(['user', 'name']),
  (name) => name
)

export const emailSelector = createSelector(
  (state) => state.getIn(['user', 'email']),
  (email) => email
)
