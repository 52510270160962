import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { convert } from 'cashify'
import { CURRENCY } from 'packages/enum'

export const getPlansByInterval = (plans) => {
  const plansByType = groupBy(plans, 'planType')
  return mapValues(plansByType, (type) => keyBy(type, 'interval'))
}

export const currencyConversion = (count = 1, rates = { USD: 1, EUR: 1 }) => {
  return Number(
    convert(count, {
      from: CURRENCY.EUR,
      to: CURRENCY.USD,
      base: CURRENCY.EUR,
      rates,
    }).toFixed(2)
  )
}
