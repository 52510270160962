import styled, { css } from 'styled-components'
import { media } from 'packages/components/media'

export const RightPanelBlock = styled.div`
  margin-bottom: ${(props) => props.mb || 0}px;

  ${media.max.tablet`
        width: 50%;

        &:first-child {
            margin-right: 30px;
        }

        &:last-child {
            > div:first-child {
                margin-top: 0;
            }
        }
    `}

  ${media.max.phone`
        width: 100%;

        &:last-child {
            > div:first-child {
                margin-top: 30px;
            }
        }
    `}
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    &:first-child {
      flex-grow: 1;
      width: 100%;
      max-width: 100%;
      margin-bottom: 64px;
    }

    &:last-child {
      width: 100%;
      max-width: 350px;
      margin-left: 40px;
    }
  }

  ${media.max.laptop`
    flex-direction: column;

    > div {
          &:first-child {
              margin-bottom: 40px;
          }

          &:last-child {
              margin-left: 0;
              margin-bottom: 64px;
          }
      }
  `}

  ${media.max.phone`
    > div {
        &:last-child {
          max-width: none;

          &:last-child {
            margin-bottom: 48px;
          }
        }
      }
  `}
`

export const SettingsWrapper = styled.div`
  ${media.max.tablet`
    margin-top: 24px;
  `}
`

export const Header = styled.div`
  max-width: 1220px;
  margin: 30px auto 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  /* Dark */
  color: #343840;

  ${media.max.tablet`
        padding: 0 20px;
    `}

  ${media.max.phone`
        font-size: 24px;
        line-height: 150%;
        padding: 0 16px;
    `}
`

export const Paragraph = styled.div`
  ${(props) =>
    props.type === 'h1' &&
    css`
      font-style: normal;
      font-weight: bold;
      font-size: ${(props) => props.fz || '17px'};
      line-height: 150%;
    `}
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  padding-right: ${(props) => props.pr || 0}px;

  color: #343840;

  ${media.max.tablet`
    font-size: 20px;
  `}
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
  color: #343840;
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  margin-bottom: ${(props) => props.mb || 24}px;
  color: rgb(36, 36, 38);
`

export const TitleRightPanelBlock = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #343840;
  margin-bottom: 4px;
`

export const StyledInput = styled.div`
  & > div > div > div {
    width: 100%;
  }

  & > div > label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #343840;
  }
`

export const WrapperButton = styled.div`
  margin-bottom: 40px;
  & > button {
    padding: 15px 82px 14px 82px !important;
  }
`

export const StyledLink = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #343840;

  @media (max-width: 550px) {
    margin-top: 10px;
  }
`

export const Field = styled.div`
  & > div > div > div {
    width: 100%;
  }

  dropdown,
  input,
  textarea {
    width: 100% !important;
    font-size: 16px !important;
    resize: none;
  }

  margin-bottom: ${(props) => props.mb || 20}px;

  & > div > label {
    font-size: 12px;
  }
`
export const TabPreview = styled.div`
  position: relative;

  ${media.max.phone`
        & > svg {
          width: 290px
        }
    `}
`

export const ImagePreview = styled.div`
  height: 194px;
  background: #ededed;
  width: 100%;

  > div {
    height: 100%;
  }
`

export const FaviconBox = styled.div`
  position: absolute;
  top: 12px;
  left: 40px;
  width: 20px;
  height: 20px;
`

export const DomainBox = styled.div`
  position: absolute;
  bottom: 22px;
  left: 136px;
  color: rgb(36, 36, 38);
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;

  ${media.max.phone`
        bottom: 25px;
        left: 110px;
    `}
`

export const TitleBox = styled.div`
  position: absolute;
  top: 15px;
  left: 70px;
  color: rgb(36, 36, 38);
  font-size: 13px;
  line-height: 150%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
`

export const SearchPreview = styled.div`
  > div {
    padding: 19px 20px;
    background: white;
    border-radius: 3.81356px;
  }
`
export const SearchPreviewDomain = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 9.91525px;
  line-height: 140%;
  /* Dark */
  color: #343840;

  margin-bottom: 7px;
`
export const SearchPreviewTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15.2542px;
  line-height: 150%;
  /* Dark */
  color: #343840;

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;

  margin-bottom: 3px;
`
export const SearchPreviewDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11.4407px;
  line-height: 17px;

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;

  /* Dark */
  color: #343840;
`

export const UploadPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 8px;
  gap: 16px;

  .ui.button {
    font-size: 13px !important;
    height: 45px;
    width: 100%;
  }
`

export const Title = styled.div`
  position: relative;
  font-size: 15px;
  line-height: 21px;
  ${(props) => (props.bold ? 'font-weight: bold;' : '')}
`

export const AcceptedFiles = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #737373;
`

export const SaveWrapper = styled.div`
  margin-top: 30px;

  & > button {
    min-width: 230px;
  }
`
