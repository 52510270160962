import React from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'

import { Semantic } from 'packages/components'

import { Header, Loader } from '../nodes'
import { Title, Container, Column, Row, Content, RowHeader } from './nodes'

const Referrals = ({ items = [], loading }, { intl: { formatMessage } }) => {
  const rows = items.map(({ url, label, nb_visits: value }) => (
    <Row>
      <Column width={12}>{url || label}</Column>
      <Column width={4}>{value}</Column>
    </Row>
  ))

  return (
    <Container>
      <Header>{formatMessage({ id: 'referrals.title' })}</Header>
      <RowHeader>
        <Row>
          <Column width={12}>
            <Title>{formatMessage({ id: 'referrals.column.header.url' })}</Title>
          </Column>
          <Column width={4}>
            <Title>{formatMessage({ id: 'statistics.newDesign.referrals.visits' })}</Title>
          </Column>
        </Row>
      </RowHeader>
      <Content>{rows}</Content>
      {loading && (
        <Loader>
          <Semantic.Loader active size='small' />
        </Loader>
      )}
    </Container>
  )
}

Referrals.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
}

Referrals.defaultProps = {
  loading: false,
}

Referrals.contextTypes = {
  intl: intlShape,
}

export default Referrals
