import { Button } from 'packages/components/buttons/button'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Container } from './nodes'

const StyledButtonsComponent = ({ intl: { formatMessage }, onResetClick, saveDisabled }) => {
  return (
    <Container>
      <Button
        type='button'
        view='secondaryGray'
        onClick={onResetClick}
        content={formatMessage({ id: 'styles.form.button.resetToDefault' })}
      />
      <Button
        type='submit'
        view='primary'
        content={formatMessage({ id: 'styles.form.button.save' })}
        disabled={saveDisabled}
      />
    </Container>
  )
}

StyledButtonsComponent.propTypes = {
  intl: intlShape.isRequired,
  onResetClick: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
}

export const StylesButtons = injectIntl(StyledButtonsComponent)
