import React from 'react'
import styled from 'styled-components'

export const RootSVG = styled.svg`
  flex-shrink: 0;
  vertical-align: middle;
  pointer-events: none;
`

export function DesktopLargeIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 21H17' stroke='currentColor' strokeWidth='1.5' />
      <path d='M12 18L12 21' stroke='currentColor' strokeWidth='1.5' />
      <path d='M9 18L9 21' stroke='currentColor' strokeWidth='1.5' />
      <path d='M15 18L15 21' stroke='currentColor' strokeWidth='1.5' />
      <rect
        x='1'
        y='4'
        width='22'
        height='14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function DesktopIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 21H17' stroke='currentColor' strokeWidth='1.5' />
      <path d='M12 18L12 21' stroke='currentColor' strokeWidth='1.5' />
      <path d='M9 18L9 21' stroke='currentColor' strokeWidth='1.5' />
      <path d='M15 18L15 21' stroke='currentColor' strokeWidth='1.5' />
      <rect
        x='3'
        y='4'
        width='18'
        height='14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function LaptopIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.45634 6C4.20425 6 4 6.24014 4 6.53634V17.4637C4 17.7599 4.20425 18 4.45634 18H19.5437C19.7957 18 20 17.7599 20 17.4637V6.53634C20 6.24014 19.7957 6 19.5437 6H4.45634Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M1 18H23'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function TabletIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9 20H15' stroke='currentColor' strokeWidth='1.5' />
      <rect
        x='4'
        y='2'
        width='16'
        height='20'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function PhoneIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9 20H15' stroke='currentColor' strokeWidth='1.5' />
      <rect
        x='6'
        y='2'
        width='12'
        height='20'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function BrowserIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M1 7L23 7' stroke='currentColor' strokeWidth='1.5' />
      <rect
        x='1'
        y='4'
        width='22'
        height='16'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function PDFDownload(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.43495 2C6.91687 2 7.33538 2.09143 7.69047 2.27429C8.04557 2.45143 8.3195 2.70857 8.51227 3.04571C8.70504 3.37714 8.80142 3.76857 8.80142 4.22C8.80142 4.67143 8.70504 5.06286 8.51227 5.39429C8.3195 5.72571 8.04557 5.98286 7.69047 6.16571C7.33538 6.34286 6.91687 6.43143 6.43495 6.43143H5.50663V7.24669C5.50663 7.66273 5.16936 8 4.75331 8C4.33727 8 4 7.66273 4 7.24669V3C4 2.44772 4.44772 2 5 2H6.43495ZM6.34364 5.09429C6.65308 5.09429 6.88643 5.02 7.04369 4.87143C7.20095 4.71714 7.27958 4.5 7.27958 4.22C7.27958 3.94 7.20095 3.72286 7.04369 3.56857C6.88643 3.41429 6.65308 3.33714 6.34364 3.33714H5.50663V5.09429H6.34364Z'
        fill='currentColor'
      />
      <path
        d='M9.53601 3C9.53601 2.44772 9.98372 2 10.536 2H12.0547C12.638 2 13.1555 2.12286 13.6069 2.36857C14.0584 2.61429 14.4084 2.96286 14.657 3.41429C14.9056 3.86571 15.0299 4.39429 15.0299 5C15.0299 5.60571 14.9056 6.13429 14.657 6.58571C14.4084 7.03714 14.0584 7.38571 13.6069 7.63143C13.1555 7.87714 12.638 8 12.0547 8H10.536C9.98372 8 9.53601 7.55228 9.53601 7V3ZM11.9938 6.64571C12.4503 6.64571 12.8156 6.5 13.0895 6.20857C13.3685 5.91714 13.508 5.51429 13.508 5C13.508 4.48571 13.3685 4.08286 13.0895 3.79143C12.8156 3.5 12.4503 3.35429 11.9938 3.35429H11.0426V6.64571H11.9938Z'
        fill='currentColor'
      />
      <path
        d='M17.3292 3.31143V4.63143H19.0247C19.3868 4.63143 19.6804 4.925 19.6804 5.28714C19.6804 5.64928 19.3868 5.94286 19.0247 5.94286H17.3292V7.24669C17.3292 7.66273 16.9919 8 16.5758 8C16.1598 8 15.8225 7.66273 15.8225 7.24669V3C15.8225 2.44772 16.2703 2 16.8225 2H19.3443C19.7064 2 20 2.29357 20 2.65571C20 3.01786 19.7064 3.31143 19.3443 3.31143H17.3292Z'
        fill='currentColor'
      />
      <path
        d='M15.4446 15.2222L12.0001 18.6667M12.0001 18.6667L8.55566 15.2222M12.0001 18.6667V11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
      <path
        d='M4 17V22H20V17'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function BarChart(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.33333 13.5556H4V20C6.0828 20 7.25054 20 9.33333 20M9.33333 20H14.6667M9.33333 20C9.33333 16.101 9.43022 7.11249 9.43022 4.09767L14.6667 4.09689C14.6667 4.09689 14.6667 17.6974 14.6667 20M14.6667 8.11112C16.3873 8.11112 20 8.11112 20 8.11112V20C20 20 16.2192 20 14.6667 20'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function WhatsNewIcon(props) {
  return (
    <RootSVG
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 14L5.57464 20.2986C5.61893 20.4757 5.64107 20.5643 5.66726 20.6416C5.92316 21.3971 6.60351 21.9283 7.39852 21.9933C7.4799 22 7.57119 22 7.75379 22C7.98243 22 8.09676 22 8.19307 21.9907C9.14499 21.8983 9.89833 21.145 9.99066 20.1931C10 20.0968 10 19.9824 10 19.7538V5.5M18.5 13.5C20.433 13.5 22 11.933 22 10C22 8.067 20.433 6.5 18.5 6.5M10.2496 5.49999H6.49957C4.01429 5.49999 1.99957 7.51471 1.99957 9.99999C1.99957 12.4853 4.01429 14.5 6.49957 14.5H10.2496C12.016 14.5 14.1768 15.4469 15.8439 16.3556C16.8164 16.8858 17.9996 17.0001 18.4996 17.0001C18.4996 16.5001 18.4996 16.5001 18.4996 16.5001C18.4996 16.0001 18.4996 15.918 18.4996 14.8737V5.12627C18.4996 4.08198 18.4996 3.99959 18.4996 3.49959C18.4996 3.00009 18.4996 3.00009 18.4996 3.00009C17.9996 3.00009 16.8164 3.11419 15.8439 3.64434C14.1768 4.5531 12.016 5.49999 10.2496 5.49999Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}

export function ChevronDown(props) {
  return (
    <RootSVG
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M8 11L12 15L16 11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </RootSVG>
  )
}
