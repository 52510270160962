import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 12px;

  & + & {
    margin-top: 20px;
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const LabelText = styled.span`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  color: #343840;
  display: block;
`

export const LabelDescription = styled.span`
  color: #666a73;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
`
