import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { openErrorModal, closeErrorModal } from './actions'

const defaultState = fromJS({
  openErrorModal: false,
})

export default handleActions(
  {
    [openErrorModal]: {
      next(state, { payload }) {
        return fromJS(payload)
      },
    },

    [closeErrorModal]: {
      next() {
        return defaultState
      },
    },
  },
  defaultState
)
