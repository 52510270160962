import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { createAction } from 'redux-actions'
import { withRouter } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import websiteBlockDelete from '@graphql/gql/website/websiteBlockDelete.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql'
import websiteAlbumUpdate from '@graphql/gql/website/websiteAlbumUpdate.gql'
import websiteAlbumDelete from '@graphql/gql/website/websiteAlbumDelete.gql'
import {
  UPDATE_PREVIEW_META_DATA,
  UPDATE_PREVIEW_STRUCTURE_DATA,
} from '@configurator/constants/Preview'
import { changeView as changeAlbumView } from '@configurator/redux/modules/albumView/actions'
import Page from '@configurator/components/page/Page'
import { injectIntl } from 'react-intl'

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state) => ({
      expandedView: state.getIn(['albumView', 'expandedView']),
    }),
    (dispatch) => ({
      updateMeta: (payload) => dispatch(createAction(UPDATE_PREVIEW_META_DATA)(payload)),
      updateStructure: (payload) => dispatch(createAction(UPDATE_PREVIEW_STRUCTURE_DATA)(payload)),
      changeAlbumView: (payload) => dispatch(changeAlbumView(payload)),
    })
  ),
  graphql(websiteBlockDelete, {
    props({ mutate }) {
      return {
        async deleteBlock({ pageId, id }) {
          try {
            return await mutate({
              variables: {
                id,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                },
                {
                  query: previewPageGet,
                  variables: {
                    id: pageId ? decodeURIComponent(pageId) : undefined,
                  },
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(previewPageGet, {
    options({ match }) {
      const decodedPageId = match.params.pageId
        ? decodeURIComponent(match.params.pageId)
        : undefined

      return {
        variables: {
          id: decodedPageId,
        },
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({
      data: { loading, previewPageGet, refetch },
      ownProps: { updateStructure, updateMeta },
    }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(previewPageGet)) {
        return { loading }
      }
      const { data } = previewPageGet

      // update preview
      setTimeout(() => {
        updateMeta(data.meta)
        updateStructure(data.structure)
      })

      return {
        blocks: data.structure,
        refetchPagePreview: refetch,
      }
    },
  }),
  graphql(websiteAlbumDelete, {
    props({ mutate, ownProps }) {
      return {
        async deleteAlbum({ albumId }) {
          try {
            return await mutate({
              variables: {
                albumId,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                  variables: {
                    pageId: decodeURIComponent(ownProps.pageId || ''),
                  },
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteAlbumUpdate, {
    props({ mutate, ownProps }) {
      return {
        async updateAlbum({ visible, albumId }) {
          try {
            return await mutate({
              variables: {
                visible,
                albumId,
              },
              refetchQueries: [
                {
                  query: websitePagesGet,
                },
                {
                  variables: {
                    id: ownProps.match.params.pageId
                      ? decodeURIComponent(ownProps.match.params.pageId)
                      : undefined,
                  },
                  query: previewPageGet,
                },
              ],
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  })
)(Page)
