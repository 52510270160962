import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 30px;
  text-align: center;
`

export const Note = styled.div`
  margin-bottom: 20px;
`

export const Content = styled.div`
  justify-content: center;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > span {
    margin-bottom: 25px;
    text-align: center;
    line-height: 1.7em;
  }

  > button {
    min-width: 240px;
  }
`

export const Code = styled.div`
  font-weight: 600;
  font-size: 18px;
`

export const InstructionImage = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  display: flex;
  justify-content: center;

  > img {
    max-width: 414px;
  }
`
