import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Semantic } from 'packages/components'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { URLS } from 'packages/enum'
import { Button } from 'packages/components/buttons'

import {
  Content,
  Container,
  Header,
  Footer,
  Text,
  StyledFormInput,
  StyledMessage,
} from '@landing/components/common/nodes'
import { Helmet } from 'react-helmet'
import { seoTags } from 'packages/intl/messages/seo'

const SendEmailForm = (
  { handleSubmit, errors, status, isSubmitting, loading },
  { intl: { formatMessage } }
) => {
  const error = Object.keys(errors).length || (status && !!status.error)
  return (
    <Container>
      <Helmet>
        <title>{seoTags.resetPassword.title}</title>
        <meta name='title' content={seoTags.resetPassword.title} />
        <meta name='description' content={seoTags.resetPassword.description} />
        <meta property='og:title' content={seoTags.resetPassword.title} />
        <meta property='og:description' content={seoTags.resetPassword.description} />
        <meta property='twitter:title' content={seoTags.resetPassword.title} />
        <meta property='twitter:description' content={seoTags.resetPassword.description} />
      </Helmet>
      <Header>{formatMessage({ id: 'landing.sendEmail.title' })}</Header>
      <Text
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: 'landing.login.forgot.text' }),
        }}
      />
      <Content>
        <Semantic.Form
          noValidate
          error={error}
          loading={isSubmitting || loading}
          onSubmit={handleSubmit}
        >
          <StyledMessage error={error} messages={errors} content={status && status.error} />
          <StyledFormInput
            name='email'
            label={formatMessage({
              id: 'landing.sendEmail.label.email',
            })}
            width='100%'
          />
          <Button
            fluid
            type='submit'
            disabled={isSubmitting}
            content={formatMessage({
              id: 'landing.sendEmail.button.label',
            })}
          />
        </Semantic.Form>
        <Footer>
          <Link to={URLS.website_login}>{formatMessage({ id: 'landing.login.forgot.back' })}</Link>
        </Footer>
      </Content>
    </Container>
  )
}

SendEmailForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  ...formProptypes,
}

SendEmailForm.defaultProps = {}

SendEmailForm.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default SendEmailForm
