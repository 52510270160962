import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { setView } from '@configurator/redux/modules/albumView/actions'
import { setActiveTab } from '@configurator/redux/modules/mainTab/actions'
import { useDispatch } from 'react-redux'

import { ShortLeftIcon } from '../icons'
import { ArrowBackContainer, Container, Menu, MenuItem, Pane } from './nodes'

const Component = ({ panes, activeIndex, onTabClick, templateParams, activeTab, ...props }) => {
  const dispatch = useDispatch()
  const [backArrowState, setBackArrowState] = useState(null)

  useEffect(() => {
    dispatch(setActiveTab(activeIndex))
  }, [activeIndex, dispatch])

  const onClick = useCallback(
    (index) => {
      onTabClick ? onTabClick(index) : dispatch(setActiveTab(index))
      if (index === 2) {
        dispatch(setView('mobile'))
      }
    },
    [dispatch, onTabClick]
  )

  const tabSetBackArrowState = useCallback(
    (text, onArrowClick, settingsComponent) => {
      setBackArrowState({ text, onArrowClick, settingsComponent })
    },
    [setBackArrowState]
  )

  const tabClearBackArrowState = useCallback(() => {
    setBackArrowState(null)
  }, [setBackArrowState])

  const isV2Template = templateParams?.template?.includes('v2')

  const filteredPanes = panes?.filter((el) => {
    if (!isV2Template && el?.menuItem === 'tabs.tab.mobile') {
      return false
    }

    return true
  })

  console.log(activeTab)
  console.log(filteredPanes)
  return (
    <Container>
      {!backArrowState ? (
        <Menu>
          {filteredPanes?.map(({ menuItem, hide }, index) => (
            <MenuItem
              key={menuItem}
              active={index === activeTab}
              onClick={() => !hide && onClick(index)}
              hide={hide}
            >
              <FormattedMessage id={menuItem} />
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <Menu onClick={backArrowState.onArrowClick} enableHover showBackArrow>
          <ArrowBackContainer>
            <ShortLeftIcon />
            <FormattedMessage id={backArrowState.text} />
          </ArrowBackContainer>
          {backArrowState.settingsComponent}
        </Menu>
      )}
      {filteredPanes[activeTab] ? (
        <Pane isPortfolio={activeTab === 0}>
          {React.cloneElement(
            filteredPanes[activeTab].render({
              ...props,
              tab: {
                setBackArrowState: tabSetBackArrowState,
                clearBackArrowState: tabClearBackArrowState,
                onChangeTab: onClick,
              },
            }),
            {
              tab: {
                setBackArrowState: tabSetBackArrowState,
                clearBackArrowState: tabClearBackArrowState,
                onChangeTab: onClick,
              },
            }
          )}
        </Pane>
      ) : null}
    </Container>
  )
}

Component.propTypes = {
  panes: PropTypes.arrayOf(
    PropTypes.shape({
      menuItem: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    })
  ).isRequired,
  activeIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTabClick: PropTypes.func,
  activeTab: PropTypes.number,
}

Component.defaultProps = {
  activeIndex: 0,
  activeTab: 0,
  onTabClick: undefined,
}

export const Tabs = Component

export const TabPropTypes = {
  tab: PropTypes.shape({
    setBackArrowState: PropTypes.func.isRequired,
    clearBackArrowState: PropTypes.func.isRequired,
  }).isRequired,
}
