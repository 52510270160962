import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const RotateMinus45Icon = ({ color }) => (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.00527 7.62609e-06C12.164 -0.00568472 15.6336 3.17599 15.9873 7.3197C16.3409 11.4634 13.4607 15.187 9.36111 15.8862C5.26152 16.5853 1.30997 14.0267 0.270271 10H2.35127C3.36849 12.8771 6.38324 14.5324 9.3568 13.8465C12.3304 13.1605 14.3154 10.3519 13.9695 7.3199C13.6235 4.28792 11.0569 1.99856 8.00527 2.00001C6.41572 2.00234 4.89388 2.64379 3.78227 3.78002L7.00027 7.00001H0.000270844V7.62609e-06L2.35127 2.35002C3.84755 0.844637 5.88277 -0.0012743 8.00527 7.62609e-06Z'
      fill={iconColors[color]}
    />
  </svg>
)

RotateMinus45Icon.propTypes = {
  ...iconColorPropTypes,
}

RotateMinus45Icon.defaultProps = {
  color: 'default',
}
