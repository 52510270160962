import { Formik } from 'formik'

import throttle from 'lodash/throttle'
import { FEATURE_PROPERTY_KEYS, MESSAGE_TYPE, STYLE_TYPES } from 'packages/enum'
import { useMemo } from 'react'
import FooterFields from './FooterFields'

const Footer = ({
  footerOverSlideshow,
  updateFeatureProperties,
  templateParams,
  isFooterOverSlideshowEnabled,
  setIsFooterOverSlideshowEnabled,
  isFooterHiddenEnabled,
  setIsFooterHiddenEnabled,
  selectedStyle,
  styles,
  loaderStyles,
}) => {
  const handleUpdateFeatureProp = useMemo(
    () =>
      throttle(({ key, values }) => {
        const { FOOTER_OVER_SLIDESHOW, SCROLLABLE_HEADER, ...styleValues } = values
        if (FOOTER_OVER_SLIDESHOW !== null && FOOTER_OVER_SLIDESHOW !== undefined) {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
              key: FEATURE_PROPERTY_KEYS.FOOTER_OVER_SLIDESHOW,
              value: isFooterOverSlideshowEnabled,
            }),
            '*'
          )
        }

        window.frames['preview-frame']?.postMessage(
          JSON.stringify({
            name: 'update_feature',
            key,
            values: styleValues,
          }),
          '*'
        )
        updateFeatureProperties({
          key: FEATURE_PROPERTY_KEYS.style,
          values: styleValues,
        })
      }, 32),
    [updateFeatureProperties, isFooterOverSlideshowEnabled]
  )

  // const handleDiscard = useCallback(() => {
  //   if (!!footerOverSlideshow && selectedStyle.type === STYLE_TYPES.footer) {
  //     setIsFooterOverSlideshowEnabled(footerOverSlideshow.enabled)
  //     window.frames['preview-frame']?.postMessage(
  //       JSON.stringify({
  //         name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
  //         key: FEATURE_PROPERTY_KEYS.FOOTER_OVER_SLIDESHOW,
  //         value: footerOverSlideshow.enabled,
  //       }),
  //       '*'
  //     )
  //   }
  // }, [footerOverSlideshow, selectedStyle?.type])

  const handleToggleFooterOverSlideshow = () =>
    setIsFooterOverSlideshowEnabled(!isFooterOverSlideshowEnabled)

  const handleToggleFooterHidden = () => setIsFooterHiddenEnabled(!isFooterHiddenEnabled)

  return (
    <div>
      {selectedStyle && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          validate={() => ({})}
          initialValues={selectedStyle.type === STYLE_TYPES.loaderAnimation ? loaderStyles : styles}
          render={(props) => {
            return (
              <FooterFields
                footerOverSlideshow={footerOverSlideshow}
                isFooterOverSlideshowEnabled={isFooterOverSlideshowEnabled}
                handleToggleFooterOverSlideshow={handleToggleFooterOverSlideshow}
                isFooterHiddenEnabled={isFooterHiddenEnabled}
                handleToggleFooterHidden={handleToggleFooterHidden}
                updateFeatureState={handleUpdateFeatureProp}
                initialValues={
                  selectedStyle.type === STYLE_TYPES.loaderAnimation ? loaderStyles : styles
                }
                slideshowEnabled={templateParams?.slideshowEnabled}
                templateName={templateParams?.template}
                {...props}
              />
            )
          }}
        />
      )}
    </div>
  )
}

export default Footer
