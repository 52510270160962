import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { Semantic } from 'packages/components'
import { formProptypes } from 'packages/utils/formikPropTypes'
import Loader from 'packages/components/pageLoader/PageLoader'
import { Button } from 'packages/components/buttons'
import { Widget } from '@typeform/embed-react'
import {
  Content,
  TypeformContainer,
  Stack,
  StyledFormInput,
  StyledFormInputPassword,
  StyledMessage,
} from '@landing/components/common/nodes'
import WaitingScreen from '@landing/components/createAccount/WaitingScreen'
import {
  Notes,
  NotesRow,
  Description,
  NativationTabsContainer,
  NavigationTabItem,
  ContainerInner,
  Container,
  NavigationTabItemHeader,
  // NavigationTabItemTrialNote,
  NavigationTabItemNote,
} from './nodes'
import { Helmet } from 'react-helmet'
import { seoTags } from 'packages/intl/messages/seo'
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
// import { useLocation } from 'react-router-dom'
import { URLS } from 'packages/enum'

const CreateAccountFormWithReCaptcha = (props) => {
  if (__RECAPTCHA_KEY__) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={__RECAPTCHA_KEY__}>
        <CreateAccountForm {...props} />
      </GoogleReCaptchaProvider>
    )
  }

  return <CreateAccountForm {...props} />
}

const CreateAccountForm = (
  { handleSubmit, errors, status, isSubmitting, loading, setFieldValue },
  { intl: { formatMessage } }
) => {
  // let location = useLocation()

  let freePlan = false //location.pathname.replace(/\/+$/, '') === URLS.website_create_free_account

  const error = Object.keys(errors).length || (status && !!status.error)
  const showSurvey = status && status.showSurvey

  const { executeRecaptcha } = useGoogleReCaptcha()

  const getReCaptchaToken = useCallback(async () => {
    try {
      return await executeRecaptcha('CreateAccount')
    } catch (err) {
      throw new Error('Token error')
    }
  }, [executeRecaptcha])

  const formSubmitHandler = async (event) => {
    event.preventDefault()
    setFieldValue('freePlan', freePlan)
    if (__RECAPTCHA_KEY__) {
      const token = await getReCaptchaToken()
      setFieldValue('recaptcha', token)
      await handleSubmit()
    } else {
      await handleSubmit()
    }
  }

  if (showSurvey) {
    return (
      <TypeformContainer>
        <Widget
          id={'PqERYviP'}
          style={{}}
          hidden={{ email: status.email }}
          inlineOnMobile={true}
          onSubmit={() => {
            return (window.location = URLS.website_config)
          }}
        />
      </TypeformContainer>
    )
  }
  const renderCreateAccountForm = () => (
    <Container verticalAlign='initial'>
      <Helmet>
        <title>{freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}</title>
        <meta
          name='title'
          content={freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}
        />
        <meta
          name='description'
          content={
            freePlan ? seoTags.createAccountFree.description : seoTags.createAccountPro.description
          }
        />
        <meta
          property='og:title'
          content={freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}
        />
        <meta
          property='og:description'
          content={
            freePlan ? seoTags.createAccountFree.description : seoTags.createAccountPro.description
          }
        />
        <meta
          property='twitter:title'
          content={freePlan ? seoTags.createAccountFree.title : seoTags.createAccountPro.title}
        />
        <meta
          property='twitter:description'
          content={
            freePlan ? seoTags.createAccountFree.description : seoTags.createAccountPro.description
          }
        />
      </Helmet>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src='https://app.prospect.io/companies/efa4d352-4543-4d08-b954-d5e6ea29f14b/tracker'
        width='0'
        height='0'
        style={{ display: 'none' }}
      />

      <NativationTabsContainer>
        <NavigationTabItem isActive={freePlan} to={URLS.website_create_account}>
          <NavigationTabItemHeader>Start Your Free Vsble MAX Trial</NavigationTabItemHeader>
          {/* <NavigationTabItemTrialNote>14-day trial</NavigationTabItemTrialNote> */}
          <NavigationTabItemNote>No credit card required</NavigationTabItemNote>
        </NavigationTabItem>

        {/* <NavigationTabItem isActive={!freePlan} to={URLS.website_create_free_account}>
          <NavigationTabItemHeader>Lifetime Free</NavigationTabItemHeader>
          <NavigationTabItemNote>No credit card required</NavigationTabItemNote>
        </NavigationTabItem> */}
      </NativationTabsContainer>

      <ContainerInner>
        <Description>
          {/* <span
            dangerouslySetInnerHTML={{
              __html: formatMessage({
                id: freePlan
                  ? 'landing.account.free.description'
                  : 'landing.account.pro.description',
              }),
            }}
          /> */}
          <span>
            Experience the full power of our Vsble MAX plan risk-free for 14 days! Enjoy
            unrestricted access to all our features and tools during your trial. Once the trial
            period is over, you have the freedom to continue with the PRO MAX plan or switch to any
            other plan.
          </span>
        </Description>
        <Content>
          <Semantic.Form noValidate error={!!error} onSubmit={formSubmitHandler}>
            <StyledMessage error={!!error} messages={errors} content={status && status.error} />
            <Stack>
              <StyledFormInput
                name='name'
                label={formatMessage({
                  id: 'landing.account.create.label.name',
                })}
                width='100%'
                type='text'
              />
              <StyledFormInput
                name='email'
                label={formatMessage({
                  id: 'landing.account.create.label.email',
                })}
                width='100%'
                type='email'
              />
              <StyledFormInputPassword
                name='password'
                label={formatMessage({
                  id: 'landing.account.create.label.password',
                })}
                width='100%'
                type='password'
              />
              <StyledFormInputPassword
                name='passwordConfirm'
                label={formatMessage({
                  id: 'landing.account.create.label.passwordConfirm',
                })}
                width='100%'
                type='password'
              />
            </Stack>
            <NotesRow>
              <Notes>
                <span>{formatMessage({ id: 'landing.agree.text.by' })}</span>{' '}
                <a rel='noreferrer' href={'https://www.vsble.me/terms-conditions'} target='_blank'>
                  {formatMessage({ id: 'legal.nav.terms' })}
                </a>{' '}
                {formatMessage({ id: 'landing.agree.text.and' })}{' '}
                <a rel='noreferrer' href={'https://www.vsble.me/privacy'} target='_blank'>
                  {formatMessage({ id: 'legal.nav.privacy' })}
                </a>{' '}
                {formatMessage({ id: 'landing.agree.text.last' })}
              </Notes>
            </NotesRow>
            <Button
              fluid
              onClick={formSubmitHandler}
              disabled={isSubmitting}
              content={formatMessage({
                id: 'landing.account.create.button.label',
              })}
            />
          </Semantic.Form>
        </Content>
        {isSubmitting || loading ? (
          <Loader
            open
            inverted
            content={formatMessage({
              id: 'landing.account.create.loader',
            })}
          />
        ) : null}
      </ContainerInner>
    </Container>
  )

  return isSubmitting ? (
    <Container verticalAlign='initial'>
      <ContainerInner>
        <WaitingScreen formatMessage={formatMessage} />
      </ContainerInner>
    </Container>
  ) : (
    renderCreateAccountForm()
  )
}

CreateAccountForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  ...formProptypes,
}
CreateAccountForm.defaultProps = {
  loading: false,
}

CreateAccountForm.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default CreateAccountFormWithReCaptcha
