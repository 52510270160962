import { FormSlider } from 'packages/components/inputs'
import { injectIntl, intlShape } from 'react-intl'

const FontSizeSliderComponent = ({ formatMessageId, intl: { formatMessage }, ...props }) => {
  const step = 0.05
  const min = 0.2
  return (
    <FormSlider
      label={formatMessage({
        id: formatMessageId ? formatMessageId : 'styles.inputs.fontSize.label',
      })}
      valueMessages={[
        formatMessage({ id: 'styles.inputs.fontSize.value.small' }),
        formatMessage({ id: 'styles.inputs.fontSize.value.medium' }),
        formatMessage({ id: 'styles.inputs.fontSize.value.large' }),
      ]}
      min={0.2}
      max={3}
      step={step}
      showTooltip
      formatTooltipValue={(val) => ((val - min) / step).toFixed(0)}
      {...props}
    />
  )
}

FontSizeSliderComponent.propTypes = {
  intl: intlShape.isRequired,
}

export const StylesFontSizeSlider = injectIntl(FontSizeSliderComponent)
