import React from 'react'
import { loaderDefaultProps, loaderPropTypes } from '../../const'

import { Container } from './nodes'

export const Grid = ({ color, width, height }) => (
  <Container color={color} width={width} height={height}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </Container>
)

Grid.propTypes = loaderPropTypes

Grid.defaultProps = loaderDefaultProps
