import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'

import PageStylesContainer from '@configurator/containers/PageStylesContainer'
import WebsiteBanner from '@configurator/containers/WebsiteBannerContainer'
import { StyledConfigPanel, StyledTab } from './nodes'
import TabsContainer from './TabsContainer'

const panes = [
  {
    menuItem: 'tabs.tab.portfolio',
    render: ({ route, ...props }) => (
      <StyledTab>{renderRoutes(route.routes, { ...props })}</StyledTab>
    ),
  },
  {
    menuItem: 'tabs.tab.fontsAndColors',
    render: (props) => (
      <StyledTab>
        <PageStylesContainer {...props} />
      </StyledTab>
    ),
  },
  {
    menuItem: 'tabs.tab.mobile',
    render: (props) => (
      <StyledTab>
        <PageStylesContainer isMobile {...props} />
      </StyledTab>
    ),
  },
]

export const ConfigPanel = ({ route }) => {
  return (
    <StyledConfigPanel>
      <TabsContainer panes={panes} route={route} />
      <WebsiteBanner />
    </StyledConfigPanel>
  )
}

ConfigPanel.propTypes = {
  route: PropTypes.object.isRequired,
}

ConfigPanel.defaultProps = {}
