import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'
import ModalYesNo from 'packages/containers/ModalYesNo'
import ModalConfirmDelete from 'packages/containers/ModalConfirmDelete'
import ModalEmailVerification from '../modals/modalEmailConfirm'
import { useSubscription } from '@configurator/providers/subscription'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'
import UnpaidBanner from '../banners/UnpaidBanner'
import TrialEndedBanner from '../banners/TrialEndedBanner'
import PastDueBanner from '../banners/PastDueBanner'
import CancelledBanner from '../banners/CancelledBanner'
import intercomDataGetQuery from '@graphql/gql/common/intercomDataGet.gql'
import { useQuery } from 'react-apollo'
import FreeWarningBanner from '../banners/FreeWarningBanner'

function Home({ route, email, name, intercomHash }) {
  const {
    info: { aboStatus, planType },
    loading: subscriptionDataLoading,
  } = useSubscription()

  const { data: { loading: intercomDataLoading, intercomDataGet } = {} } =
    useQuery(intercomDataGetQuery)

  useEffect(() => {
    if (!subscriptionDataLoading && email && !intercomDataLoading) {
      const intercomData = intercomDataGet?.data || {}

      if (window.__ls) {
        window.__ls('identify', {
          name: name, // Full name
          email: email, // Email address
          params: {
            aboStatus,
            planType,
          },
        })
      }
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'l7s3ded4',
        name: name, // Full name
        email: email, // Email address
        aboStatus,
        planType,
        user_hash: intercomHash,
        ...intercomData,
      })

      ANALYTICS.identify({
        email,
        aboStatus,
        planType,
      })
    }
  }, [
    email,
    name,
    intercomHash,
    aboStatus,
    planType,
    subscriptionDataLoading,
    intercomDataLoading,
    intercomDataGet,
  ])

  return (
    <>
      {renderRoutes(route.routes)}
      <UnpaidBanner />
      <CancelledBanner />
      <FreeWarningBanner />
      <TrialEndedBanner />
      <ModalEmailVerification />
      <PastDueBanner />
      <ModalYesNo />
      <ModalConfirmDelete />
    </>
  )
}

Home.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Home
