import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const CooliconIcon = ({ color }) => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.0002 20.9999H2.00019C1.46427 21.0186 0.944666 20.8138 0.56548 20.4347C0.186295 20.0555 -0.0184319 19.5359 0.000191672 18.9999V8.99995C-0.0184319 8.46402 0.186295 7.94442 0.56548 7.56524C0.944666 7.18605 1.46427 6.98132 2.00019 6.99995H6.00019V2.99995C5.98157 2.46402 6.1863 1.94442 6.56548 1.56524C6.94467 1.18605 7.46427 0.981324 8.00019 0.999948H18.0002C18.5361 0.981324 19.0557 1.18605 19.4349 1.56524C19.8141 1.94442 20.0188 2.46402 20.0002 2.99995V12.9999C20.0185 13.5358 19.8137 14.0552 19.4346 14.4343C19.0555 14.8134 18.536 15.0183 18.0002 14.9999H14.0002V18.9999C14.0185 19.5358 13.8137 20.0552 13.4346 20.4343C13.0555 20.8134 12.536 21.0183 12.0002 20.9999ZM2.00019 8.99995V18.9999H12.0002V14.9999H8.00019C7.46435 15.0183 6.94493 14.8134 6.56581 14.4343C6.18669 14.0552 5.98187 13.5358 6.00019 12.9999V8.99995H2.00019ZM8.00019 2.99995V12.9999H18.0002V2.99995H8.00019Z'
      fill={iconColors[color]}
    />
  </svg>
)

CooliconIcon.propTypes = {
  ...iconColorPropTypes,
}

CooliconIcon.defaultProps = {
  color: 'dark',
}
