import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { AlphaPicker } from 'react-color'
import { fieldProptypes } from '../formElements/formikPropTypes'

const OpacityPickerInput = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (color) => {
    if (!props.disabled) {
      setFieldValue(field.name, color.rgb.a)
    }
  }

  return <AlphaPicker {...field} {...props} color={{ a: field.value }} onChange={handleChange} />
}

OpacityPickerInput.propTypes = {
  ...fieldProptypes,
}

export const OpacityPicker = ({ name, ...props }) => (
  <div style={{ marginBottom: '20px' }}>
    <Field name={name} {...props} component={OpacityPickerInput} />
  </div>
)

OpacityPicker.propTypes = {
  name: PropTypes.string.isRequired,
}

export default OpacityPicker
