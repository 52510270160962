import { HEADER_DESKTOP_TYPE } from 'packages/enum'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Container, Label, ToggleItem } from './nodes'

const ButtonsToggle = ({
  intl: { formatMessage },
  value,
  setFieldValue,
  onChange,
  buttons,
  label,
  fieldName,
  isLogoToggle,
  ...props
}) => {
  const handleChange = (e, elementType) => {
    e.preventDefault()

    if (!isLogoToggle && !props.disabled) {
      setFieldValue(fieldName, elementType)
    }

    onChange(elementType)
  }

  return (
    <>
      {label ? <Label>{formatMessage({ id: label })}</Label> : null}
      <Container>
        {buttons?.map((el) => (
          <ToggleItem isSelected={el.type === value} onClick={(e) => handleChange(e, el.type)}>
            {formatMessage({ id: el.text })}
          </ToggleItem>
        ))}
      </Container>
    </>
  )
}

ButtonsToggle.propTypes = {
  intl: intlShape.isRequired,
  value: PropTypes.oneOf([HEADER_DESKTOP_TYPE.CLASSIC, HEADER_DESKTOP_TYPE.BURGER]).isRequired,
  onChange: PropTypes.func.isRequired,
  buttons: PropTypes.object,
}

ButtonsToggle.defaultProps = {
  value: HEADER_DESKTOP_TYPE.CLASSIC,
  onChange: () => null,
  buttons: [],
}

export const StylesButtonsToggle = injectIntl(ButtonsToggle)
