import { iconColorPropTypes, iconColors } from '../const'

export const ArrowUp = ({ color }) => {
  return (
    <svg
      width='15.500000'
      height='8.500000'
      viewBox='0 0 15.5 8.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id='Stroke 1'
        d='M0.219727 8.28027C0.48584 8.54663 0.902832 8.5708 1.19629 8.35303L1.28027 8.28027L7.75 1.81104L14.2197 8.28027C14.4858 8.54663 14.9028 8.5708 15.1963 8.35303L15.2803 8.28027C15.5464 8.01416 15.5708 7.59741 15.353 7.30371L15.2803 7.21973L8.28027 0.219727C8.01416 -0.0466309 7.59717 -0.0708008 7.30371 0.146973L7.21973 0.219727L0.219727 7.21973C-0.0732422 7.51245 -0.0732422 7.98755 0.219727 8.28027Z'
        fill={iconColors[color]}
        fill-opacity='1.000000'
        fill-rule='nonzero'
      />
    </svg>
  )
}

ArrowUp.propTypes = {
  ...iconColorPropTypes,
}

ArrowUp.defaultProps = {
  color: 'black',
}
