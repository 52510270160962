import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const Label = styled.label`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #343840;

  margin-bottom: 5px;
  display: block;
`
