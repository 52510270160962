export const BrowserTabPreview = ({ isDefault }) => {
  return (
    <svg
      width='350.000000'
      height='91.000000'
      viewBox='0 0 350 91'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <clipPath id='clip108_1765'>
          <rect
            id='basic/close/Hover'
            width='18.305084'
            height='18.199997'
            transform='translate(233.000000 13.650024)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
        <clipPath id='clip108_1768'>
          <rect
            id='arrow'
            width='18.305084'
            height='18.199997'
            transform='translate(7.626953 56.875000)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
        <clipPath id='clip108_1769'>
          <rect
            id='arrow'
            width='18.305084'
            height='18.199997'
            transform='matrix(-1 0 0 1 56.4395 56.875)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
        <clipPath id='clip108_1770'>
          <rect
            id='basic / refresh'
            width='18.305084'
            height='18.199999'
            transform='translate(68.644531 56.875000)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
        <clipPath id='clip108_1774'>
          <rect
            id='basic'
            width='18.305084'
            height='18.199999'
            transform='translate(109.830078 56.875000)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
        <clipPath id='clip108_1759'>
          <rect id='Frame 415' width='351.000000' height='91.000000' fill='white' fillOpacity='0' />
        </clipPath>
      </defs>
      <g clip-path='url(#clip108_1759)'>
        <mask
          id='mask108_1761'
          mask-type='alpha'
          maskUnits='userSpaceOnUse'
          x='0.000000'
          y='0.000000'
          width='450.000000'
          height='91.000000'
        >
          <rect
            id='Rectangle 327'
            width='450.000000'
            height='91.000000'
            fill='#EDEDED'
            fillOpacity='1.000000'
          />
        </mask>
        <g mask='url(#mask108_1761)'>
          <rect
            id='Rectangle 328'
            width='455.338989'
            height='81.141663'
            fill='#E1E2E6'
            fillOpacity='1.000000'
          />
          <path
            id='Rectangle 325'
            d='M35.08 0L252.8 0C259.54 0 265 5.46 265 12.2L265 44.74L22.88 44.74L22.88 12.2C22.88 5.46 28.34 0 35.08 0Z'
            fill='#FFFFFF'
            fillOpacity='1.000000'
            fillRule='evenodd'
          />
          <g clip-path='url(#clip108_1765)'>
            <path
              id='menu / close big'
              d='M247.49 18.51L246.41 17.44L242.15 21.68L237.88 17.44L236.81 18.51L241.07 22.75L236.81 26.98L237.88 28.05L242.15 23.81L246.41 28.05L247.49 26.98L243.22 22.75L247.49 18.51Z'
              fill='#666A73'
              fillOpacity='1.000000'
              fillRule='evenodd'
            />
          </g>
          <rect
            id='Rectangle 326'
            y='44.741699'
            width='463.728821'
            height='46.258331'
            fill='#FFFFFF'
            fillOpacity='1.000000'
          />
          <g clip-path='url(#clip108_1768)'>
            <path
              id='arrow / short left'
              d='M22.88 65.21L13.59 65.21L16.33 62.49L15.25 61.42L10.67 65.97L15.25 70.52L16.33 69.45L13.59 66.73L22.88 66.73L22.88 65.21Z'
              fill='#242426'
              fillOpacity='1.000000'
              fillRule='nonzero'
            />
          </g>
          <g clip-path='url(#clip108_1769)'>
            <path
              id='arrow / short left'
              d='M41.18 65.21L50.46 65.21L47.73 62.49L48.81 61.42L53.38 65.97L48.81 70.52L47.73 69.45L50.46 66.73L41.18 66.73L41.18 65.21Z'
              fill='#666A73'
              fillOpacity='1.000000'
              fillRule='nonzero'
            />
          </g>
          <g clip-path='url(#clip108_1770)'>
            <path
              id='coolicon'
              d='M77.79 59.9C74.62 59.9 71.97 62.31 71.7 65.45C71.43 68.6 73.63 71.42 76.75 71.95C79.88 72.48 82.9 70.54 83.69 67.49L82.1 67.49C81.33 69.67 79.03 70.92 76.76 70.4C74.49 69.88 72.98 67.75 73.24 65.45C73.5 63.16 75.46 61.42 77.79 61.42C79 61.42 80.16 61.91 81.01 62.77L78.56 65.21L83.9 65.21L83.9 59.9L82.1 61.69C80.96 60.54 79.41 59.9 77.79 59.9Z'
              fill='#666A73'
              fillOpacity='1.000000'
              fillRule='nonzero'
            />
          </g>

          <rect
            id='Rectangle 44'
            x='102.203125'
            y='50.808350'
            rx='15.166666'
            width='264.814453'
            height='30.333332'
            fill='#F2F2F2'
            fillOpacity='1.000000'
          />
          <g clip-path='url(#clip108_1774)'>
            <path
              id='Line 6'
              d='M121.27 69.76L121.27 65.97L116.69 65.97L116.69 69.76L117.45 69.76L120.5 69.76L121.27 69.76Z'
              fill='#666A73'
              fillOpacity='1.000000'
              fillRule='nonzero'
            />
            <path
              id='Line 6'
              d='M121.27 69.76L120.5 69.76L117.45 69.76L116.69 69.76L116.69 65.97L121.27 65.97L121.27 69.76Z'
              stroke='#666A73'
              stroke-opacity='1.000000'
              stroke-width='1.525424'
            />
            <path id='Vector 1' d='' fill='#666A73' fillOpacity='1.000000' fillRule='nonzero' />
            <path
              id='Vector 1'
              d='M117.45 65.21L117.45 63.7C117.45 62.86 118.14 62.18 118.98 62.18C119.82 62.18 120.5 62.86 120.5 63.7L120.5 65.21'
              stroke='#666A73'
              stroke-opacity='1.000000'
              stroke-width='0.762712'
            />
          </g>

          {isDefault && (
            <ellipse
              id='Ellipse 98'
              cx='49.578125'
              cy='22.750000'
              rx='11.440679'
              ry='11.375000'
              fill='#E1E2E6'
              fill-opacity='1.000000'
            />
          )}

          <path
            id='Subtract'
            d='M10.6777 44.738L10.6777 44.7417L10.9453 44.7417C10.8555 44.7415 10.7676 44.7402 10.6777 44.738ZM11.0176 44.7417L22.8809 44.7417L22.8809 32.6084L22.877 32.6084C22.8789 32.7092 22.8809 32.8103 22.8809 32.9117C22.8809 39.4333 17.5723 44.7224 11.0176 44.7417Z'
            clip-rule='evenodd'
            fill='#FFFFFF'
            fillOpacity='1.000000'
            fillRule='evenodd'
          />
          <path
            id='Subtract'
            d='M277.238 44.738L277.238 44.7417L276.971 44.7417C277.061 44.7415 277.148 44.7402 277.238 44.738ZM276.898 44.7417L265.035 44.7417L265.035 32.6084L265.039 32.6084C265.037 32.7092 265.035 32.8103 265.035 32.9117C265.035 39.4333 270.344 44.7224 276.898 44.7417Z'
            clip-rule='evenodd'
            fill='#FFFFFF'
            fillOpacity='1.000000'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}
