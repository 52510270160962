import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Container, Label } from './nodes'
// import {FormDropdown} from 'packages/newDesign/components/inputs/formElements';
import { FormFontPicker } from './fontPicker'

// TODO добавить толщину к шрифту
// TODO intl message
const StylesFontDropdownsComponent = ({
  intl: { formatMessage, ...intl },
  fontFamilyProps,
  // fontWeightProps,
  // ...props
}) => {
  return (
    <Container>
      <Label>{formatMessage({ id: 'Font' })}</Label>
      <FormFontPicker {...fontFamilyProps} intl={{ formatMessage, ...intl }} />
      {/*<FormDropdown name={fontWeightName} {...props}/>*/}
    </Container>
  )
}

StylesFontDropdownsComponent.propTypes = {
  intl: intlShape.isRequired,
  fontFamilyProps: PropTypes.object.isRequired,
  fontWeightProps: PropTypes.object,
}

export const StylesFontDropdowns = injectIntl(StylesFontDropdownsComponent)
