import React from 'react'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import ModalYesNo from 'packages/containers/ModalYesNo'
import PageLoader from 'packages/containers/PageLoader'
import ModalProgress from 'packages/containers/ModalProgress'
import { StyledToastContainer } from './nodes'

const Section = styled.section`
  background: #f6f6f6;
  height: 100%;
  box-sizing: border-box;
`

const Root = ({ Header, route }) => {
  return (
    <>
      <Helmet></Helmet>
      <Section>
        <StyledToastContainer
          position='bottom-left'
          autoClose={2000}
          closeButton={false}
          hideProgressBar
          newestOnTop
          closeOnClick
          pauseOnVisibilityChange
          pauseOnHover
        />
        <ModalYesNo />
        <PageLoader />
        <ModalProgress />
        {renderRoutes(route.routes, {
          Header,
        })}
      </Section>
    </>
  )
}

Root.propTypes = {
  Header: PropTypes.object,
  route: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  changeTemplatesColor: PropTypes.func,
  isInverted: PropTypes.bool,
}

export default Root
