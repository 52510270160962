import React from 'react'
import PropTypes from 'prop-types'
import { Toggle } from 'packages/components/inputs'
import { ToggleWrapper } from './nodes'

export function AlbumToggle(props) {
  return (
    <ToggleWrapper>
      <Toggle disabled checked={props.isChecked} />
    </ToggleWrapper>
  )
}

AlbumToggle.propTypes = {
  isChecked: PropTypes.bool,
}
