import PropTypes from 'prop-types'
import React from 'react'
import { intlShape, injectIntl } from 'react-intl'

import { DOMAIN_TYPES } from 'packages/constants'

import DomainForm from '../domain/DomainForm'

import { Box, DomainContainer, Paragraph, WrapperBox } from '../nodes'

const FreeSection = ({ domain, changeDomain, isMobile, intl: { formatMessage } }) => (
  <WrapperBox isMobile={isMobile}>
    <Box isMobile={isMobile}>
      <Paragraph type='h2' mb={17} fw={700} lh='24px' color='#242426'>
        {formatMessage({ id: 'bootstrap.your.free' })}
      </Paragraph>

      <DomainContainer>
        <Paragraph type='h2' mb={20} fw={300} lh='24px' color='#242426'>
          {formatMessage({ id: 'bootstrap.domain.subheader.text2' })}
        </Paragraph>

        <DomainForm type={DOMAIN_TYPES.CUSTOM_DOMAIN} domain={domain} changeDomain={changeDomain} />
      </DomainContainer>
    </Box>
  </WrapperBox>
)

FreeSection.propTypes = {
  domain: PropTypes.string,
  changeDomain: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
}

FreeSection.defaultProps = {
  domain: '',
}

export default injectIntl(FreeSection)
