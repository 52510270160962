import styled, { css } from 'styled-components'

export const Header = styled.div`
  color: #343840;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ hasData }) => {
    if (hasData) {
      return css`
        margin-bottom: 0;
      `
    }
  }}
`

export const Content = styled.div`
  color: #343840;
  font-size: 13px;
  line-height: 140%;

  border: 1px solid rgb(237, 237, 237);
  background: #fff;
  padding: 30px 40px;
  border-radius: 5px;
`

export const ContentHeader = styled.div`
  ${(props) => (props.hide ? 'display: none;' : 'display: flex;')}
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin-top: ${(props) => (!props.hide ? 20 : 0)}px;

  > span:first-child {
    width: 28%;
  }
  > span:nth-child(2) {
    width: 35%;
  }
  > span:nth-child(3) {
    width: 20%;
  }
  > span:last-child {
    width: 17%;
  }
`

export const Item = styled.div`
  display: flex;
  padding: 20px 0;

  > a {
    color: ${({ isNotPaid }) => (isNotPaid ? '#ff002e' : '#58c367')};
  }

  > span:first-child {
    width: 28%;
  }
  > span:nth-child(2) {
    width: 35%;
  }
  > span:nth-child(3) {
    color: ${({ isNotPaid }) => (isNotPaid ? '#ff002e' : '#343840')};
    width: 20%;
  }
  > span:last-child {
    width: 17%;
  }
`

export const NoItemsMsg = styled.div`
  color: #343840;
  font-size: 15px;
  line-height: 21px;
`
