import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'
import checkSvg from './checkSmall.svg'

export const StyledCheckbox = styled(Checkbox)`
  width: 24px !important;
  height: 24px !important;

  // box
  & > label:before {
    width: 24px !important;
    height: 24px !important;
    box-sizing: border-box !important;
    background: #fff !important;
    border: 1px solid #ededed !important;
    border-radius: 4px !important;
  }

  &:hover {
    & > label:before {
      border-color: #e1e2e6 !important;
    }
  }

  &.disabled:hover {
    & > label:before {
      border-color: #ededed !important;
    }
  }

  &.checked {
    & > label:before {
      background: #58c367 !important;
    }
  }

  // check icon
  & > label:after {
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    content: url('${checkSvg}') !important;
    top: 2px !important;
    left: 6px !important;
  }
`
