import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-apollo'
import isEmpty from 'lodash/isEmpty'
import websiteVideoPreviewGetQuery from '@graphql/gql/website/websiteVideoPreviewGet.gql'
import Image from 'packages/components/gatsby-image'
import Loader from '../../loader/Loader'
import { VideoPreview } from './nodes'

const AutomaticVideoPreview = ({ hosting, videoId }) => {
  const { loading, data: { websiteVideoPreviewGet } = {} } = useQuery(websiteVideoPreviewGetQuery, {
    variables: {
      hosting,
      videoId,
    },
    skip: isEmpty(hosting),
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <Loader />
  }

  if (isEmpty(websiteVideoPreviewGet) || !websiteVideoPreviewGet.data) {
    return 'no preview available'
  }

  const { data } = websiteVideoPreviewGet

  return (
    <VideoPreview>
      <Image
        fluid={{
          src: data.link,
          aspectRatio: 500 / 300,
        }}
        alt=''
        fadeIn
        imgStyle={{ objectFit: 'contain' }}
        loading='lazy'
        backgroundColor='#F6F6F6'
      />
    </VideoPreview>
  )
}

AutomaticVideoPreview.propTypes = {
  hosting: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
}

AutomaticVideoPreview.defaultProps = {}

export default AutomaticVideoPreview
