import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  border: none;
  background: transparent;
  cursor: pointer;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  @media (max-width: 550px) {
    flex-wrap: wrap;

    > b {
      width: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }
`

export const Title = styled.span`
  margin: 0 16px 0 10px;
  user-select: none;
  color: #343840;
  font-size: 16px;

  ${media.max.phone`
        width: 50%;
        text-align: left;
    `}
`
