/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const TargetIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19 12H16.2M7.8 12H5M12 7.8V5M12 19V16.2M17.6 12C17.6 15.0928 15.0928 17.6 12 17.6C8.90721 17.6 6.4 15.0928 6.4 12C6.4 8.90721 8.90721 6.4 12 6.4C15.0928 6.4 17.6 8.90721 17.6 12Z'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

TargetIcon.propTypes = {
  ...iconColorPropTypes,
}

TargetIcon.defaultProps = {
  color: 'dark',
}
