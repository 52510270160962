import isEmpty from 'lodash/isEmpty'
import { compose } from 'redux'
import { graphql } from 'react-apollo'
import billingHistoryGet from '@graphql/gql/website/billingHistoryGet.gql'
import billingCreateCustomerPortal from '@graphql/gql/website/billingCreateCustomerPortal.gql'
import BillingHistory from '@configurator/components/billing/history'

export const billingHistoryWrapper = (Component) =>
  compose(
    graphql(billingCreateCustomerPortal, {
      props({ mutate }) {
        return {
          async billingCreateCustomerPortal() {
            try {
              const res = await mutate()
              return res
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(billingHistoryGet, {
      options() {
        return {
          fetchPolicy: 'network-only',
        }
      },
      props({ data: { loading, billingHistoryGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(billingHistoryGet)) {
          return { loading }
        }
        const { data } = billingHistoryGet

        return {
          data,
        }
      },
    })
  )(Component)

export default billingHistoryWrapper(BillingHistory)
