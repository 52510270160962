import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Button } from 'packages/components/buttons'
import { URLS } from 'packages/enum'

import { Container, Header, Description } from './nodes'

// TODO сделать intl как в дизайне, если надо
const Component = ({
  history,
  reference,
  headerMsg,
  descMsg,
  buttonMsg,
  intl: { formatMessage },
}) => {
  return (
    <Container ref={reference}>
      <Header>{formatMessage({ id: headerMsg })}</Header>
      <Description>{formatMessage({ id: descMsg })}</Description>
      <Button
        centered
        view='primary'
        content={formatMessage({ id: buttonMsg })}
        onClick={() => history.push(URLS.website_billing)}
      />
    </Container>
  )
}

Component.propTypes = {
  history: PropTypes.object.isRequired,
  headerMsg: PropTypes.string.isRequired,
  descMsg: PropTypes.string.isRequired,
  buttonMsg: PropTypes.string.isRequired,
  reference: PropTypes.object,
  intl: PropTypes.object.isRequired,
}

Component.defaultProps = { reference: null }

export const UpgradeBlock = withRouter(injectIntl(Component))
