import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'

export const StyledToggle = styled(Checkbox).withConfig({
  shouldForwardProp: (prop) => {
    return !['selectedStyle', 'initialValues', 'setFieldValue', 'openErrorModal'].includes(prop)
  },
})`
  width: 36px !important;
  height: 20px !important;

  // box
  & > label:before {
    width: 36px !important;
    height: 20px !important;
    background-color: #e1e2e5 !important;
    border-radius: 20px !important;
  }

  &.ui.toggle.checkbox input:checked ~ .box:before,
  &.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #2eba76 !important;
  }

  // check icon
  & > label:after {
    content: '' !important;
    width: 16px !important;
    height: 16px !important;
    background: white !important;
    border-radius: 50% !important;
    position: absolute !important;
    top: 2px !important;
    left: 2px !important;
    box-shadow: none !important;
  }

  input:checked ~ label:after {
    left: 18px !important;
  }
`
