import PropTypes from 'prop-types'
import React from 'react'
import ReactTable from 'react-table'

import { Semantic } from 'packages/components'
import { Button } from 'packages/elements'
import { countryOptions } from 'packages/helpers/CountryHelper'
import ModalYesNo from 'packages/components/modalYesNo/ModalYesNo'

import TrialEndDateModal from '../trialEndDatePicker/modal/TrialEndDateModal'
import { PAGE_SIZE } from '../../constants'
import { Container } from './nodes'

const UserTable = ({
  page,
  users,
  fetchPage,
  loading,
  loginAs,
  refetch,
  resetPassword,
  setLifetimePro,
  setByInvoiceAllowed,
  undeleteUser,
  startTrial,
}) => {
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = React.useState(false)
  const [lifetimeProModalOpen, setLifetimeProModalOpen] = React.useState(false)
  const [invoiceAllowedModelOpen, setInvoiceAllowedModelOpen] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState('')

  const renderDelete = ({ original: { id, deletedAt } }) => (
    <div>
      {deletedAt ? (
        <Button
          centered
          color='grey'
          content='Undelete'
          onClick={() => {
            undeleteUser(id).then(() => refetch())
          }}
        />
      ) : (
        <span>User is not deleted</span>
      )}
    </div>
  )

  const renderLoginAs = (cellInfo) => (
    <div>
      <Button centered color='grey' content='LOGIN AS' onClick={() => loginAs(cellInfo.value)} />
    </div>
  )

  const renderResetPassword = (cellInfo) => (
    <div>
      <Button
        centered
        color='grey'
        content='RESET PASSWORD'
        onClick={() => {
          setSelectedUser(cellInfo.original.id)
          setResetPasswordModalOpen(true)
        }}
      />
    </div>
  )

  const renderSetLifetimePro = (cellInfo) => (
    <div>
      {cellInfo.original.lifetimePro ? (
        <span>LIFETIME PRO</span>
      ) : (
        <Button
          centered
          color='grey'
          content='SET LIFETIME PRO'
          onClick={() => {
            setSelectedUser(cellInfo.original.id)
            setLifetimeProModalOpen(true)
          }}
        />
      )}
    </div>
  )

  const renderSetByInvoiceAllowed = (cellInfo) => (
    <div>
      {cellInfo.original.paymentByInvoiceAllowed ? (
        <span>ENABLED</span>
      ) : (
        <Button
          centered
          color='grey'
          content='ENABLE'
          onClick={() => {
            setSelectedUser(cellInfo.original.id)
            setInvoiceAllowedModelOpen(true)
          }}
        />
      )}
    </div>
  )

  const renderCountry = (cellInfo) => {
    const country = countryOptions.find((item) => +item.value === +cellInfo.value)

    return country ? (
      <div>
        <Semantic.Flag name={country.flag} />
        {country.text}
      </div>
    ) : null
  }

  const trialEnd = (cellInfo) => {
    return (
      <div>
        <TrialEndDateModal
          id={cellInfo.original.id}
          currentTrialEnd={cellInfo.original.trialEndDate || cellInfo.original.trialWillEnd}
          freePlan={cellInfo.original.freePlan}
          refetch={refetch}
          startTrial={() => startTrial(cellInfo.original.id)}
          tariffName={cellInfo.original.subscriptions[0]?.planName || ''}
        />
      </div>
    )
  }

  const handleResetPassword = () => {
    setResetPasswordModalOpen(false)
    resetPassword(selectedUser)
  }

  const handleCloseResetPassword = () => {
    setResetPasswordModalOpen(false)
    setSelectedUser()
  }

  const handleSetLifetimePro = () => {
    setLifetimeProModalOpen(false)
    setLifetimePro(selectedUser)
  }

  const handleCloseSetLifetimePro = () => {
    setLifetimeProModalOpen(false)
    setSelectedUser()
  }

  const handleSetByInvoiceAllowed = () => {
    setInvoiceAllowedModelOpen(false)
    setByInvoiceAllowed(selectedUser)
  }

  const handleCloseSetByInvoiceAllowed = () => {
    setInvoiceAllowedModelOpen(false)
    setSelectedUser()
  }

  const columns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Country',
      accessor: 'countryId',
      Cell: renderCountry,
    },
    {
      Header: 'Address 1',
      accessor: 'address1',
    },
    {
      Header: 'Address 2',
      accessor: 'address2',
    },
    {
      Header: 'Login As',
      accessor: 'email',
      Cell: renderLoginAs,
      minWidth: 66,
    },
    {
      Header: 'Trial End',
      accessor: 'trialEnd',
      Cell: trialEnd,
      minWidth: 108,
    },
    {
      Header: 'Set Lifetime PRO',
      accessor: 'lifetimePro',
      Cell: renderSetLifetimePro,
      minWidth: 85,
    },
    {
      Header: 'Set Payment By Invoice',
      accessor: 'paymentByInvoiceAllowed',
      Cell: renderSetByInvoiceAllowed,
      minWidth: 76,
    },
    {
      Header: 'Reset Password',
      accessor: 'id',
      Cell: renderResetPassword,
      minWidth: 106,
    },
    {
      Header: 'Undelete user',
      Cell: renderDelete,
      minWidth: 81,
    },
  ]

  return (
    <Container>
      <ReactTable
        columns={columns}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={users}
        onPageChange={(value) =>
          fetchPage({
            page: value + 1,
            pageSize: page.pageSize,
          })
        }
        onPageSizeChange={(pageSize, page) =>
          fetchPage({
            page: page + 1,
            pageSize,
          })
        }
        page={page.page - 1}
        pageSize={page.pageSize}
        pages={page.pagesCount}
        loading={loading} // Display the loading overlay when we need it
        defaultPageSize={PAGE_SIZE}
      />
      <ModalYesNo
        headerMessageId='Reset password'
        subMessageId=' '
        open={resetPasswordModalOpen}
        onClickCancel={handleCloseResetPassword}
        close={handleCloseResetPassword}
        onClickYes={handleResetPassword}
      />
      <ModalYesNo
        headerMessageId='Set lifetime PRO'
        subMessageId=' '
        open={lifetimeProModalOpen}
        onClickCancel={handleCloseSetLifetimePro}
        close={handleCloseSetLifetimePro}
        onClickYes={handleSetLifetimePro}
      />
      <ModalYesNo
        headerMessageId='Enable Payment By Invoice'
        subMessageId=' '
        open={invoiceAllowedModelOpen}
        onClickCancel={handleCloseSetByInvoiceAllowed}
        close={handleCloseSetByInvoiceAllowed}
        onClickYes={handleSetByInvoiceAllowed}
      />
    </Container>
  )
}

UserTable.propTypes = {
  page: PropTypes.object,
  users: PropTypes.array,
  fetchPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loginAs: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  setLifetimePro: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  undeleteUser: PropTypes.func.isRequired,
  startTrial: PropTypes.func.isRequired,
}

UserTable.defaultProps = {
  page: {
    page: 1,
    pagesCount: 1,
    pageSize: PAGE_SIZE,
  },
  users: [],
}

export default UserTable
