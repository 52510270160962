import React from 'react'
import { iconColorPropTypes, iconColors } from '../const'

export const ArrowDown = ({ color }) => {
  return (
    <svg
      width='15.500000'
      height='8.500000'
      viewBox='0 0 15.5 8.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id='Stroke 1'
        d='M0.219727 0.219727C0.48584 -0.0466309 0.902832 -0.0708008 1.19629 0.147095L1.28027 0.219727L7.75 6.68896L14.2197 0.219727C14.4858 -0.0466309 14.9028 -0.0708008 15.1963 0.147095L15.2803 0.219727C15.5464 0.485962 15.5708 0.902588 15.353 1.19617L15.2803 1.28027L8.28027 8.28027C8.01416 8.54663 7.59717 8.5708 7.30371 8.35291L7.21973 8.28027L0.219727 1.28027C-0.0732422 0.987427 -0.0732422 0.512573 0.219727 0.219727Z'
        fill={iconColors[color]}
        fill-opacity='1.000000'
        fill-rule='nonzero'
      />
    </svg>
  )
}

ArrowDown.propTypes = {
  ...iconColorPropTypes,
}

ArrowDown.defaultProps = {
  color: 'black',
}
