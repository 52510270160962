import EditLogo from '@configurator/components/editLogo/EditLogo'
import logoRemoveBackgroundUpdate from '@graphql/gql/website/logoRemoveBackgroundUpdate.gql'
import websiteLogoDelete from '@graphql/gql/website/websiteLogoDelete.gql'
import websiteLogoUpdate from '@graphql/gql/website/websiteLogoUpdate.gql'
import websiteMetaGet from '@graphql/gql/website/websiteMetaGet.gql'
import websiteMetaUpdate from '@graphql/gql/website/websiteMetaUpdate.gql'
import { withFormik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { MESSAGE_TYPE } from 'packages/enum'
import { open as openErrorModal } from 'packages/redux/modules/modalConfirmDelete/actions'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  siteName: Yup.string().nullable(),
})

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      loaderStart: ({ content } = {}) => dispatch(loaderStart({ content })),
      loaderStop: () => dispatch(loaderStop()),
      lockModalProgress: () => dispatch(lockModal(true)),
      unLockModalProgress: () => dispatch(lockModal(false)),
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId, subMessageValues }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
            subMessageValues,
            hideCancelButton: true,
          })
        ),
    })
  ),
  graphql(websiteMetaUpdate, {
    props({ mutate }) {
      return {
        async updateMeta({ siteName, siteDescription, id, logo }) {
          try {
            return await mutate({
              variables: {
                siteName,
                siteDescription,
                websiteId: id,
                logo,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(websiteLogoUpdate, {
    props({ mutate }) {
      return {
        async updateLogo({ file }) {
          try {
            return await mutate({
              variables: {
                file,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),

  graphql(websiteLogoDelete, {
    props({ mutate }) {
      return {
        async deleteLogo() {
          try {
            return await mutate({
              variables: {},
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),

  graphql(logoRemoveBackgroundUpdate, {
    props({ mutate }) {
      return {
        async logoRemoveBackground() {
          try {
            const res = await mutate({
              variables: {},
            })
            return res
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),

  graphql(websiteMetaGet, {
    options() {
      return {
        variables: {},
        ssr: false,
        fetchPolicy: 'network-only',
      }
    },
    props({ data: { loading, websiteMetaGet, refetch } }) {
      if (loading) {
        return { loading }
      }
      if (isEmpty(websiteMetaGet)) {
        return
      }
      const { data } = websiteMetaGet

      return { initialValues: { ...data }, refetchMeta: refetch }
    },
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => {
      return {
        siteName: '',
        siteDescription: '',
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      { siteName, siteDescription, id, newLogo, logoSizePercent, logoSizePercentMobile },
      {
        setSubmitting,
        setStatus,
        setFieldValue,
        props: { updateMeta, updateLogo, lockModalProgress, unLockModalProgress, refetchMeta },
      }
    ) => {
      let defaultError = 'siteName.update.error.header'

      if (newLogo) {
        lockModalProgress()
        try {
          const res = await updateLogo({
            file: newLogo,
          })
          unLockModalProgress()
          let {
            data: { websiteLogoUpdate: { errors, success } = {} },
          } = res
          if (!success) {
            unLockModalProgress()
            setSubmitting(false)
            setStatus({ error: errors._error || defaultError })
          } else {
            setFieldValue('newLogo', undefined)
          }
        } catch (err) {
          unLockModalProgress()
          setSubmitting(false)
          setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
        }
      }

      try {
        const res = await updateMeta({
          id,
          siteName,
          siteDescription,
          logo: {
            logoSizePercent,
            logoSizePercentMobile,
          },
        })
        setSubmitting(false)
        let {
          data: { websiteMetaUpdate: { errors, success } = {} },
        } = res
        if (success) {
          refetchMeta()
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )
        } else {
          setStatus({ error: errors._error || defaultError })
        }
      } catch (err) {
        setSubmitting(false)
        setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      }
    },
  })
)(EditLogo)
