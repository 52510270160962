import isEmpty from 'lodash/isEmpty'
import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import get from 'lodash/get'
import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'
import updateTemplate from '@graphql/gql/website/updateTemplate.gql'
import previewPageGet from '@graphql/gql/website/previewPageGet.gql'
import { URLS } from 'packages/enum'
import { Templates } from '@configurator/components/templates'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'
import websiteShareFeaturesCreate from '@graphql/gql/website/websiteShareFeaturesCreate.gql'
import websiteShareFeaturesGet from '@graphql/gql/website/websiteShareFeaturesGet.gql'

export const templatesWrapper = (Component) =>
  compose(
    withRouter,
    connect(
      // mapStateToProps
      (state) => ({
        templatesColor: state.getIn(['templatesColor']),
      }),
      // mapDispatchToProps
      (dispatch) => ({
        openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
          dispatch(
            openErrorModal({
              headerMessageId,
              yesMessageId,
              subMessageId,
            })
          ),
        loaderStart: () => dispatch(loaderStart()),
        loaderStop: () => dispatch(loaderStop()),
      })
    ),

    graphql(updateTemplate, {
      props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop, history } }) {
        return {
          async updateTemplate({ template, align, inverted, slideshowEnabled }) {
            try {
              loaderStart()
              const res = await mutate({
                variables: {
                  template,
                  align,
                  inverted,
                  slideshowEnabled,
                },
                refetchQueries: [
                  {
                    query: previewPageGet,
                  },
                ],
              })
              loaderStop()

              ANALYTICS.changeTemplate()

              if (get(res, 'data.updateTemplate.errors._error')) {
                openErrorModal({
                  headerMessageId: 'error.header',
                  yesMessageId: 'OK',
                  subMessageId: get(res, 'data.updateTemplate.errors._error'),
                })
                return res
              }
              history.push(URLS.website_config)
            } catch (err) {
              console.log(err)
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(websiteShareFeaturesCreate, {
      props({ mutate }) {
        return {
          async websiteShareFeaturesCreate({ websiteId }) {
            try {
              return await mutate({
                variables: {
                  websiteId,
                },
                refetchQueries: [
                  {
                    query: websiteShareFeaturesGet,
                    variables: {},
                  },
                ],
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(previewPageGet, {
      options() {
        return {
          ssr: false,
          fetchPolicy: 'network-only',
        }
      },
      props({ data: { loading, previewPageGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(previewPageGet)) {
          return
        }
        const { data } = previewPageGet

        return {
          meta: data.meta,
        }
      },
    })
  )(Component)

export default templatesWrapper(Templates)
