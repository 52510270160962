import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import billingSubscriptionsGetQuery from '@graphql/gql/website/billingSubscriptionsGet.gql'
import { getActiveSubscription } from 'packages/helpers/Helper'

const SubscriptionContext = React.createContext()

const PRO_PERMISSION = {
  PDF: 'PDF',
  PASSWORD_PROTECTION: 'PASSWORD_PROTECTION',
  CUSTOM_DOMAIN: 'CUSTOM_DOMAIN',
  UPLOADABLE_LOGO: 'UPLOADABLE_LOGO',
  CUSTOM_FAVICON: 'CUSTOM_FAVICON',
  FONTS_COLORS: 'FONTS_COLORS',
  SAVE_FONT_COLOR: 'SAVE_FONT_COLOR',
  IMAGE_DESCRIPTION: 'IMAGE_DESCRIPTION',
  OPEN_GRAPH: 'OPEN_GRAPH',
  MULTILANG_CONTACT: 'MULTILANG_CONTACT',
  CUSTOM_VIDEO_PLACEHOLDER: 'CUSTOM_VIDEO_PLACEHOLDER',
  HIDE_PAGE_TITLE: 'HIDE_PAGE_TITLE',
  HIDE_ALBUM_TITLE: 'HIDE_ALBUM_TITLE',
  STATISTICS: 'STATISTICS',
  FOCAL_POINTS: 'FOCAL_POINTS',
  EDIT_PHOTO: 'EDIT_PHOTO',
  REPLACE_PHOTO: 'REPLACE_PHOTO',
  TEXT_PAGE: 'TEXT_PAGE',
  TEXT_PAGE_LINK: 'TEXT_PAGE_LINK',
  HTML_PAGE: 'HTML_PAGE',
  WEBSITE_VISIBILITY: 'WEBSITE_VISIBILITY',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  BRANDING_REMOVAL: 'BRANDING_REMOVAL',
  SEO_ALT_DESCRIPTION: 'SEO_ALT_DESCRIPTION',
  SITEMAP_ROBOTS: 'SITEMAP_ROBOTS',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  SOCIAL_ICONS: 'SOCIAL_ICONS',
  CUSTOM_SPINNER: 'CUSTOM_SPINNER',
  OWN_LOADER: 'OWN_LOADER',
}

const PRO_MAX_PERMISSION = {
  INTEGRATIONS: 'INTEGRATIONS',
  ALBUM_EXTENDED_VIEW: 'ALBUM_EXTENDED_VIEW',
  SEO: 'SEO',
  SUPPORT_CHAT: 'SUPPORT_CHAT',
  ALT_DESCRIPTION_RECOGNITION: 'ALT_DESCRIPTION_RECOGNITION',
  GOOGLE_SITE_VERIFICATION: 'GOOGLE_SITE_VERIFICATION',
}

const defaultPermissions = {
  [PRO_PERMISSION.PDF]: false,
  [PRO_PERMISSION.PASSWORD_PROTECTION]: false,
  [PRO_PERMISSION.CUSTOM_DOMAIN]: false,
  [PRO_PERMISSION.UPLOADABLE_LOGO]: false,
  [PRO_PERMISSION.CUSTOM_FAVICON]: false,
  [PRO_PERMISSION.FONTS_COLORS]: false,
  [PRO_PERMISSION.SAVE_FONT_COLOR]: false,
  [PRO_PERMISSION.IMAGE_DESCRIPTION]: false,
  [PRO_PERMISSION.OPEN_GRAPH]: false,
  [PRO_PERMISSION.MULTILANG_CONTACT]: false,
  [PRO_PERMISSION.CUSTOM_VIDEO_PLACEHOLDER]: false,
  [PRO_PERMISSION.HIDE_PAGE_TITLE]: false,
  [PRO_PERMISSION.HIDE_ALBUM_TITLE]: false,
  [PRO_PERMISSION.STATISTICS]: false,
  [PRO_PERMISSION.FOCAL_POINTS]: false,
  [PRO_PERMISSION.EDIT_PHOTO]: false,
  [PRO_PERMISSION.REPLACE_PHOTO]: false,
  [PRO_PERMISSION.TEXT_PAGE]: false,
  [PRO_PERMISSION.TEXT_PAGE_LINK]: false,
  [PRO_PERMISSION.HTML_PAGE]: false,
  [PRO_PERMISSION.WEBSITE_VISIBILITY]: false,
  [PRO_PERMISSION.UPLOAD_PHOTO]: false,
  [PRO_PERMISSION.SEO_ALT_DESCRIPTION]: false,
  [PRO_PERMISSION.SITEMAP_ROBOTS]: false,
  [PRO_PERMISSION.CUSTOM_SPINNER]: false,
  [PRO_PERMISSION.OWN_LOADER]: false,
  [PRO_MAX_PERMISSION.INTEGRATIONS]: false,
  [PRO_MAX_PERMISSION.ALBUM_EXTENDED_VIEW]: false,
  [PRO_MAX_PERMISSION.GOOGLE_SITE_VERIFICATION]: false,
  [PRO_MAX_PERMISSION.SEO]: false,
  TEMPLATES: [],
  FEATURES_SHARES_COUNT: 0,
}

const defaultInfo = {
  template: null,
  aboStatus: null,
  currentPeriodEnd: null,
  planType: null,
}

function SubscriptionProvider(props) {
  const [info, setInfo] = useState(defaultInfo)
  const [permissions, setPermissions] = useState(defaultPermissions)
  const [loading, setLoading] = useState(true)

  const { loading: billingSubscriptionsLoading, data: { billingSubscriptionsGet = {} } = {} } =
    useQuery(billingSubscriptionsGetQuery)

  useEffect(() => {
    if (billingSubscriptionsLoading) {
      setLoading(true)
      setPermissions(defaultPermissions)
      setInfo(defaultInfo)
    } else {
      const { data, errors } = billingSubscriptionsGet
      if (errors) {
        console.error('subscription error', errors)
      } else {
        const { capabilities, capabilitiesMap, templates, ...subscription } =
          getActiveSubscription(data)

        // aboStatus
        // planType
        setInfo({
          ...defaultInfo,
          template: subscription.template,
          aboStatus: subscription.aboStatus,
          planType: subscription.planType,
          currentPeriodEnd: subscription.currentPeriodEnd,
          downgradedFromFreeManually: subscription.downgradedFromFreeManually,
        })
        setPermissions({
          ...defaultPermissions,
          TEMPLATES: templates,
          ...capabilities,
          FEATURES_SHARES_COUNT: capabilitiesMap?.FEATURES_SHARES_COUNT,
        })
      }
      setLoading(false)
    }
  }, [billingSubscriptionsLoading, billingSubscriptionsGet])

  return (
    <SubscriptionContext.Provider
      value={{
        permissions,
        info,
        loading,
      }}
      {...props}
    />
  )
}

const useSubscription = () => React.useContext(SubscriptionContext)

function subscriptionInject(Component) {
  return (props) => {
    const subscription = useSubscription()
    return <Component {...props} subscription={subscription} />
  }
}

export {
  SubscriptionProvider,
  useSubscription,
  subscriptionInject,
  PRO_PERMISSION,
  PRO_MAX_PERMISSION,
}
