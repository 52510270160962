import { Button } from 'packages/components/buttons/button'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  margin: 0 30px 0 20px;
`

export const StyleItem = styled.div`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #343840;

  cursor: pointer;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const ResetButton = styled(Button)`
  width: 100% !important;
  margin-top: 40px !important;
  display: block !important;
`

export const FeatureCodeButton = styled(Button)`
  width: 100% !important;
  margin-top: 10px !important;
  display: block !important;
`

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & > h1 {
    margin: 0;
  }
`

export const Header = styled.h1`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  color: #343840;

  margin-bottom: 20px;
`

export const SubHeader = styled.h2`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #343840;

  margin-bottom: 20px;
`

export const SubDescription = styled.h2`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #343840;

  margin-top: -10px;
  margin-bottom: 20px;
`

export const Description = styled.p`
  color: #666a73;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 24px;
`

export const WrapperButton = styled.p`
  & > button {
    width: 100%;
  }
  margin-bottom: 24px;
`

export const WrapperImg = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e1e2e5;
`

export const FormBlock = styled.div`
  margin-bottom: 30px;
  & > * {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`
