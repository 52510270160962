import { createActions } from 'redux-actions'

export const {
  userProfile: { set },
} = createActions({
  USER_PROFILE: {
    SET: (payload) => ({
      id: payload.id,
      name: payload.name,
      email: payload.email,
      token: payload.token,
      intercomHash: payload.intercomHash,
    }),
  },
})
