import React, { useEffect } from 'react'
import { StyledFormTextArea, Title } from './nodes'
import { intlShape } from 'react-intl'
import PropTypes from 'prop-types'

export const MessageStep = ({ values, setFieldValue }, { intl: { formatMessage } }) => {
  useEffect(() => {
    setFieldValue('messageQuestion', formatMessage({ id: 'downgradeToFree.modal.message.header' }))
  }, [formatMessage, setFieldValue])

  return (
    <>
      <Title>{values['messageQuestion']}</Title>
      <StyledFormTextArea name='messageAnswer' />
    </>
  )
}

MessageStep.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

MessageStep.contextTypes = {
  intl: intlShape.isRequired,
}
