import { FOOTER_SOCIAL_ICON_COLOR, FOOTER_ICON_TYPE } from 'packages/enum'

export function getTypeOptions({ formatMessage, isIconTypeDisabled }) {
  return [
    {
      text: formatMessage({ id: 'styles.socialLinks.iconType.circle' }),
      value: FOOTER_ICON_TYPE.CIRCLE,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconType.square' }),
      value: FOOTER_ICON_TYPE.SQUARE,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconType.icon' }),
      value: FOOTER_ICON_TYPE.ICON,
      disabled: isIconTypeDisabled,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconType.squareRounded' }),
      value: FOOTER_ICON_TYPE.SQUARE_ROUNDED,
    },
  ]
}

export function getColorOptions({ formatMessage, isColoredDisabled }) {
  return [
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.black' }),
      value: FOOTER_SOCIAL_ICON_COLOR.BLACK,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.white' }),
      value: FOOTER_SOCIAL_ICON_COLOR.WHITE,
    },
    {
      text: formatMessage({ id: 'styles.socialLinks.iconColor.colored' }),
      value: FOOTER_SOCIAL_ICON_COLOR.COLORED,
      disabled: isColoredDisabled,
    },
  ]
}

export function getOptions({ formatMessage, isIconTypeDisabled, isColoredDisabled }) {
  return {
    typeOptions: getTypeOptions({ formatMessage, isIconTypeDisabled }),
    colorOptions: getColorOptions({ formatMessage, isColoredDisabled }),
  }
}
