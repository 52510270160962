import { Button } from 'packages/components/buttons/button'
import { FormSlider } from 'packages/components/inputs/formElements'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 20px !important;
`

export const Container = styled.div`
  width: 100%;
  padding: 0 30px 0 20px;
`

export const Tabs = styled.div`
  height: 50px;
  border-radius: 2px;
  border: 1px solid #e1e2e6;
  display: flex;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 20px;
`

export const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  color: ${(props) => (props.active ? '#FFFFFF' : '#666A73')};
  background: ${(props) => (props.active ? '#343840' : 'transparent')};
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
`

export const Description = styled.div`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #666a73;
  margin-bottom: 20px;
`

export const StyledSlider = styled(FormSlider)`
  margin-bottom: 20px;
  // label container margin
  & > div:first-child {
    margin-bottom: 20px !important;
    // label style
    & > div:first-child {
      font-family: Sailec, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 150%;
      color: #343840;
    }
  }
  // capitalize bottom messages
  & > div:last-child > div {
    text-transform: capitalize;
  }
`

export const ImageFit = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

export const ImageFitHeader = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #343840;
  margin-bottom: 10px;
`

export const ImageFitBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  padding: 15px 20px 15px 10px;
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 17px;
  }

  & + & {
    margin-top: 5px;
  }
`

export const ImageFitText = styled.div`
  width: 50%;
`

export const ImageFitName = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  margin-bottom: 5px;
`

export const ImageFitDesc = styled.div`
  font-size: 13px;
  line-height: 140%;
`

export const FullHeightIconContainer = styled.div`
  width: 86px;
  height: 48px;
  background: #ededed;
  margin-left: auto;
  display: flex;
  justify-content: center;
  & > div {
    height: 100%;
    width: 39px;
    background: #c1c3c7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ScaleToFixIconContainer = styled.div`
  width: 86px;
  height: 48px;
  margin-left: auto;
  background: #c1c3c7;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VideoInput = styled.div`
  .ui.input {
    width: 100% !important;
    margin-bottom: 20px;
  }
`

export const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WrapperFooter = styled.div`
  margin-bottom: 20px;
`

export const WrapperItem = styled.div`
  border-top: 1px solid #e1e2e6;
  padding: 15px 0;

  &:last-child {
    border-bottom: 1px solid #e1e2e6;
  }
`
