import { injectIntl, intlShape } from 'react-intl'

import Card from '../Card'
import { Expire, Status } from '../../nodes'

const LifetimeCard = ({ planName, intl: { formatMessage } }) => {
  return (
    <Card
      planName={planName}
      Status={
        <Status status='active'>
          {formatMessage({ id: 'accountBilling.billing.status.active' })}
        </Status>
      }
      Expire={<Expire>{formatMessage({ id: 'accountBilling.billing.neverExpires' })}</Expire>}
    />
  )
}

LifetimeCard.propTypes = {
  intl: intlShape.isRequired,
}

LifetimeCard.defaultProps = {}

export default injectIntl(LifetimeCard)
