import { injectIntl, intlShape } from 'react-intl'
import moment from 'moment'

// import { Button } from 'packages/components/buttons'

import Card from '../Card'
import { Expire, Status, UpgradeButton } from '../../nodes'

const TrialActiveCard = ({
  onSelectPlan,
  planName,
  // onDowngradeToFree,
  renewsOn,
  intl: { formatMessage },
}) => {
  const expireDate = moment(renewsOn)

  const expireText = expireDate.fromNow()

  return (
    <Card
      planName={planName}
      renewsOn={renewsOn}
      Status={
        <Status status='active'>
          {formatMessage({ id: 'accountBilling.billing.status.active' })}
        </Status>
      }
      Expire={
        <Expire>
          {formatMessage({ id: 'accountBilling.billing.status.text.expires' })} {expireText}
        </Expire>
      }
      RightBlock={
        <>
          <UpgradeButton onClick={onSelectPlan}>
            {formatMessage({ id: 'accountBilling.billing.upgrade' })}
          </UpgradeButton>

          {/* <Button view='primaryText' onClick={onDowngradeToFree}>
            {formatMessage({ id: 'accountBilling.billing.downgrade' })}
          </Button> */}
        </>
      }
    />
  )
}

TrialActiveCard.propTypes = {
  intl: intlShape.isRequired,
}

TrialActiveCard.defaultProps = {}

export default injectIntl(TrialActiveCard)
