import PropTypes from 'prop-types'
import React from 'react'
import cs from 'classnames'
import { Semantic } from 'packages/components'
import { Close } from 'packages/elements/icon/close'
import style from './style.less'

const Actions = ({ children, className }) => (
  <Semantic.Modal.Actions className={className}>{children}</Semantic.Modal.Actions>
)

const Body = ({ children, className }) => (
  <div className={cs(className, style.modal__body)}>{children}</div>
)

const Content = ({ children, className }) => (
  <Semantic.Modal.Content className={cs(className, style.modal__content)}>
    {children}
  </Semantic.Modal.Content>
)

const Description = ({ children, className }) => (
  <Semantic.Modal.Description className={className}>{children}</Semantic.Modal.Description>
)

const Footer = ({ children, className }) => (
  <div className={cs(className, style.modal__footer)}>{children}</div>
)

const Header = ({ children, className }) => (
  <div className={cs(className, style.modal__header)}>{children}</div>
)

export const Modal = ({ children, className, dimmed, icon, onClose, ...props }) => {
  return (
    <Semantic.Modal
      {...props}
      className={cs(className, style.ui, style.modal, dimmed && style['modal--dimmed'])}
      onClose={onClose}
    >
      {icon && (
        <div
          className={cs(style['modal__icon-close'], dimmed && style['modal__icon-close--dimmed'])}
          onClick={onClose}
        >
          <Close />
        </div>
      )}
      {children}
    </Semantic.Modal>
  )
}

const Title = ({ children, className }) => {
  return <div className={cs(className, style.modal__title)}>{children}</div>
}

Modal.Actions = Actions
Modal.Body = Body
Modal.Content = Content
Modal.Description = Description
Modal.Footer = Footer
Modal.Header = Header
Modal.Title = Title

Actions.defaultProps = {
  className: undefined,
  children: undefined,
}
Actions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Body.defaultProps = {
  className: undefined,
  children: undefined,
}
Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Content.defaultProps = {
  className: undefined,
  children: undefined,
}
Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Description.defaultProps = {
  className: undefined,
  children: undefined,
}
Description.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Footer.defaultProps = {
  className: undefined,
  children: undefined,
}
Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  className: undefined,
  children: undefined,
}
Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Modal.defaultProps = {
  className: undefined,
  dimmed: undefined,
  icon: undefined,
  onClose: undefined,
}
Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  dimmed: PropTypes.bool,
  icon: PropTypes.bool,
  onClose: PropTypes.func,
}

Title.defaultProps = {
  className: undefined,
  children: undefined,
}
Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
