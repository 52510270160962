import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Container = styled.div`
  height: 100%;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  ${media.max.phone`
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    `}
`

export const Icon = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Count = styled.span`
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #343840;
`
export const Text = styled.span`
  font-size: 13px;
  line-height: 140%;
  text-transform: lowercase;
  color: #666a73;
`

export const Info = styled.div`
  padding-top: 8px;
  padding-left: 1.3em;
  align-self: center;

  ${media.max.phone`
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    `}
`

export const PagesContainer = styled.div`
  ${Icon} {
    background-color: #58c367;
  }
`

export const VisitorsContainer = styled.div`
  ${Icon} {
    background-color: #6727ab;
  }
`

export const VisitorsNowContainer = styled.div`
  ${Icon} {
    background-color: #42aee2;
  }
`

export const AverageTimeContainer = styled.div`
  ${Icon} {
    background-color: #2049ab;
  }
`
