import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { renderRoutes } from 'react-router-config'

export default class Home extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
  }

  static defaultProps = {}

  static contextTypes = {
    intl: PropTypes.object.isRequired,
  }

  render() {
    const { route } = this.props

    return <Fragment>{renderRoutes(route.routes)}</Fragment>
  }
}
