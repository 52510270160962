import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import { withRouter } from 'react-router'
import * as Yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import {
  open as openDiscardChangesModal,
  close as closeDiscardChangesModal,
} from 'packages/redux/modules/modalConfirmDelete/actions'
import { connect } from 'react-redux'
import { FEATURE_PROPERTY_KEYS, MESSAGE_TYPE, URLS } from 'packages/enum'
import { lockModal } from 'packages/redux/modules/modalProgress/actions'
import websiteAlbumUpdate from '@graphql/gql/website/websiteAlbumUpdate.gql'
import websitePagesGet from '@graphql/gql/website/websitePagesGet.gql'
import websitePagesGetByType from '@graphql/gql/website/websitePagesGetByType.gql'
import websiteAlbumGet from '@graphql/gql/website/websiteAlbumGet.gql'
import websiteAlbumSeoImageUpdateMutation from '@graphql/gql/website/websiteAlbumSeoImageUpdate.gql'
import AlbumUpdate from '@configurator/components/modals/albumUpdate'
import { push } from 'connected-react-router'
import { SLUG_VALIDATION_PATTERN, SPACES_REGEXP } from 'packages/helpers/Helper'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('album.field.error.name'),
  pageIds: Yup.array().required('album.field.error.pageIds'),
  albumUrl: Yup.string()
    .transform((value) => {
      return value ? value.replace(SPACES_REGEXP, '-') : value
    })
    .matches(SLUG_VALIDATION_PATTERN, 'newDesign.album.customSlug.error')
    .nullable(),
})

export const albumUpdateWrapper = (Component) =>
  compose(
    withRouter,
    connect(
      (state) => ({
        domain: state.getIn(['preview', 'meta', 'domain']),
        premiumDomain: state.getIn(['preview', 'meta', 'premiumDomain']),
        features: state.getIn(['preview', 'meta', 'features']).toJS(),
        seoDescription: state.getIn(['preview', 'meta', 'seoDescription']),
      }),
      (dispatch) => ({
        openDiscardChangesModal: ({ subMessageId, yesMessageId, hideHeader, onClickYes, styles }) =>
          dispatch(
            openDiscardChangesModal({
              subMessageId,
              yesMessageId,
              hideHeader,
              onClickYes,
              styles,
            })
          ),
        closeDiscardChangesModal: () => dispatch(closeDiscardChangesModal()),
        lockModalProgress: () => dispatch(lockModal(true)),
        unLockModalProgress: () => dispatch(lockModal(false)),
        redirectToConfig: () => dispatch(push(URLS.website_config)),
        redirectTo: (path) => dispatch(push(path)),
      })
    ),
    graphql(websitePagesGetByType, {
      options({ pageType }) {
        return {
          variables: {
            pageType,
          },
          ssr: false,
          fetchPolicy: 'network-only',
        }
      },
      props({ data: { loading, websitePagesGetByType } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(websitePagesGetByType)) {
          return
        }
        const { data } = websitePagesGetByType

        return { pagesList: data }
      },
    }),
    graphql(websiteAlbumGet, {
      options({ albumId }) {
        return {
          variables: {
            albumId,
          },
          ssr: false,
          fetchPolicy: 'network-only',
        }
      },
      props({ data: { loading, websiteAlbumGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(websiteAlbumGet) || !websiteAlbumGet.data) {
          return { initErrors: websiteAlbumGet.errors }
        }
        const { data } = websiteAlbumGet

        return {
          initialValues: {
            ...data,
            hide: !data.visible,
            hideHeader: !data.showHeader,
          },
          cover: data.cover,
          title: data.title,
          seoPhotoUrl: data.seoPhotoUrl,
        }
      },
    }),
    graphql(websiteAlbumUpdate, {
      props({ mutate }) {
        return {
          async updateAlbum({
            title,
            description,
            albumId,
            pageIds,
            visible,
            showHeader,
            seoTitle,
            seoDescription,
            password,
            albumUrl,
            seoPhotoUrl,
          }) {
            try {
              return await mutate({
                variables: {
                  title,
                  description,
                  albumId,
                  pageIds,
                  visible,
                  showHeader,
                  seoTitle,
                  seoDescription,
                  seoPhotoUrl,
                  password,
                  albumUrl: albumUrl.replace(SPACES_REGEXP, '-'),
                },
                refetchQueries: [
                  {
                    query: websitePagesGet,
                  },
                ],
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(websiteAlbumSeoImageUpdateMutation, {
      props({ mutate }) {
        return {
          async websiteAlbumSeoImageUpdate({ albumId, file }) {
            try {
              return await mutate({
                variables: {
                  albumId,
                  file,
                },
              })
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),

    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        return {
          pageIds: [],
          title: '',
          albumUrl: '',
          ...initialValues,
        }
      },
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
      handleSubmit: async (
        {
          title,
          description,
          pageIds,
          visible,
          hideHeader,
          seoTitle,
          seoDescription,
          deleteSeoPhotoUrl,
          password,
          seoImage,
          albumUrl,
        },
        {
          setSubmitting,
          setStatus,
          props: {
            updateAlbum,
            onClose,
            albumId,
            websiteAlbumSeoImageUpdate,
            features,
            lockModalProgress,
            unLockModalProgress,
            initialValues,
            match,
            pageUrl,
            redirectTo,
          },
        }
      ) => {
        let defaultError = 'page.update.error.header'
        try {
          if (seoImage) {
            const featureId = features.find(
              (f) => f.featureName === FEATURE_PROPERTY_KEYS.openGraph
            )?.id
            if (featureId) {
              lockModalProgress()
              await websiteAlbumSeoImageUpdate({
                albumId,
                file: seoImage,
              })
              unLockModalProgress()
            }
          }
          const res = await updateAlbum({
            title,
            description,
            albumId,
            pageIds,
            visible,
            showHeader: !hideHeader,
            seoTitle,
            seoDescription,
            seoPhotoUrl: deleteSeoPhotoUrl ? '' : undefined,
            password,
            albumUrl,
          })
          setSubmitting(false)
          let {
            data: { websiteAlbumUpdate: { success } = {} },
          } = res
          if (success) {
            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_PAGE,
              }),
              '*'
            )
            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_META,
              }),
              '*'
            )

            if ((initialValues.albumUrl || {}).albumUrl !== albumUrl) {
              const initialPageUrl = decodeURIComponent(match.params?.pageId).split('/')?.pop()
              if (albumUrl && initialPageUrl === initialValues.albumUrl) {
                const paths = decodeURIComponent(pageUrl).split('/')
                paths.splice(paths.length - 1, 1, albumUrl.replace(SPACES_REGEXP, '-'))
                redirectTo(encodeURIComponent(paths.join('/')))
              }
            }
          }
          return onClose()
        } catch (err) {
          setSubmitting(false)
          setStatus({
            error: __PRODUCTION__ ? defaultError : err.toString(),
          })
        }
      },
    })
  )(Component)

export default albumUpdateWrapper(AlbumUpdate)
