import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Semantic } from 'packages/components'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { FormInput } from 'packages/components/inputs'
import { Button } from 'packages/components/buttons'
import Message from 'packages/components/message/Message'
import { Content, Container, Header, Footer, ForgotPasswordContainer } from './nodes'

const UpdatePasswordForm = (
  { handleSubmit, errors, status, isSubmitting, loading, onCancel, onClickForgot },
  { intl: { formatMessage } }
) => {
  const statusSuccess = useMemo(() => {
    return status && status.success
  }, [status])

  const error = Object.keys(errors).length || (status && !!status.error)
  return (
    <Container>
      <Header>{formatMessage({ id: 'changePassword.header' })}</Header>
      <Content>
        <Semantic.Form
          error={error}
          loading={isSubmitting || loading}
          onSubmit={(e, ...args) => {
            e.stopPropagation()
            handleSubmit(e, ...args)
          }}
        >
          <Message
            error={error}
            messages={errors}
            content={
              (status && status.error) ||
              (statusSuccess && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'changePassword.status.success' }),
                  }}
                />
              ))
            }
          />
          <FormInput
            name='oldPassword'
            label={formatMessage({ id: 'changePassword.field.oldPassword' })}
            type='password'
            inlineNode={
              <ForgotPasswordContainer onClick={onClickForgot}>
                {formatMessage({ id: 'changePassword.button.forgotPassword' })}
              </ForgotPasswordContainer>
            }
            required
          />
          <FormInput
            name='newPassword'
            label={formatMessage({ id: 'changePassword.field.newPassword' })}
            type='password'
            required
          />
          <FormInput
            name='confirmNewPassword'
            label={formatMessage({ id: 'changePassword.field.confirmNewPassword' })}
            type='password'
            required
          />
          <Footer>
            <Button
              type='submit'
              disabled={isSubmitting}
              content={formatMessage({ id: 'changePassword.button.submit' })}
            />
            <Button
              type='button'
              view='primaryGray'
              disabled={isSubmitting}
              content={formatMessage({ id: 'changePassword.button.cancel' })}
              onClick={onCancel}
            />
          </Footer>
        </Semantic.Form>
      </Content>
    </Container>
  )
}

UpdatePasswordForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onClickForgot: PropTypes.func.isRequired,
  ...formProptypes,
}

UpdatePasswordForm.defaultProps = {}

UpdatePasswordForm.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default UpdatePasswordForm
