import PropTypes from 'prop-types'

export const loaderVariants = {
  default: 'default',
  circle: 'circle',
  ellipsis: 'ellipsis',
  facebook: 'facebook',
  grid: 'grid',
  heart: 'heart',
  hourglass: 'hourglass',
  ring: 'ring',
  ringDotted: 'ringDotted',
  ringDual: 'ringDual',
  ripple: 'ripple',
  roller: 'roller',
  spinner: 'spinner',
  audio: 'audio',
  ballTriangle: 'ballTriangle',
  bars: 'bars',
  circles: 'circles',
  hearts: 'hearts',
  oval: 'oval',
  puff: 'puff',
  rings: 'rings',
  spinningCircles: 'spinningCircles',
  tailSpin: 'tailSpin',
  threeDots: 'threeDots',
}

export const loaderPropTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export const loaderDefaultProps = {
  color: '#343840',
  width: 65,
  height: 65,
}
