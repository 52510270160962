import convert from 'color-convert'

export const rgbaStringToHslObj = (rgbaString) => {
  if (!rgbaString) {
    return rgbaString
  }
  const rgbaArr = rgbaString.match(/\d+/g) /* [r, g, b, a] */
  const rgbArr = rgbaArr.splice(0, 3) /* [r, g, b] */
  const hslArr = convert.rgb.hsl(rgbArr) /* [h, s, l] */
  const hslObj = {
    h: hslArr[0],
    s: hslArr[1] / 100,
    l: hslArr[2] / 100,
    a: +rgbaArr[0],
  } /* { h, s, l, a } */
  return hslObj
}

export const hslObjToRgbaString = (hslObj) => {
  const hslArr = [hslObj.h, hslObj.s * 100, hslObj.l * 100]
  const rgbArr = convert.hsl.rgb(hslArr)
  const rgbaString = `rgba(${rgbArr[0]}, ${rgbArr[1]}, ${rgbArr[2]}, ${hslObj.a})`
  return rgbaString
}

export const hsvObjToHslObj = (hsvObj) => {
  const hsvArr = [hsvObj.h, hsvObj.s * 100, hsvObj.v * 100]
  const hslArr = convert.hsv.hsl(hsvArr)
  const hslObj = { h: hslArr[0], s: hslArr[1] / 100, l: hslArr[2] / 100, a: +hsvObj.a }
  return hslObj
}

export const hslObjToHsvObj = (hslObj) => {
  const hslArr = [hslObj.h, hslObj.s * 100, hslObj.l * 100]
  const hsvArr = convert.hsl.hsv(hslArr)
  const hsvObj = { h: hsvArr[0], s: hsvArr[1] / 100, v: hsvArr[2] / 100, a: +hslObj.a }
  return hsvObj
}

export const hslObjToHex = (hslObj, enablePrefix = false) => {
  const hslArr = [hslObj.h, hslObj.s * 100, hslObj.l * 100]
  const hex = convert.hsl.hex(hslArr)
  return enablePrefix ? `#${hex}` : hex
}

export const hexToHslObj = (hex, alpha = 1) => {
  const hslArr = convert.hex.hsl(hex.replace('#', ''))
  const hslObj = { h: hslArr[0], s: hslArr[1] / 100, l: hslArr[2] / 100, a: alpha }
  return hslObj /* { h, s, l, a } */
}

export const makeHslString = (color) =>
  `hsl(${Math.round(color.h)}, ${Math.round(color.s * 100)}%, ${Math.round(color.l * 100)}%)`
