import ChangeTemplateModal from '@configurator/components/modals/changeTemplateModal'
import {
  Container,
  FeatureCodeButton,
  ResetButton,
  StyleItem,
  StylesTemplatesColorToggle,
  StylesTitle,
} from '@configurator/components/pages/pageStyles/components'
import ApplyFeatureCodeContainer from '@configurator/containers/ApplyFeatureCodeContainer'
import { useSubscription } from '@configurator/providers/subscription'
import websiteShareFeaturesGetQuery from '@graphql/gql/website/websiteShareFeaturesGet.gql'
import { Formik } from 'formik'
import fromPairs from 'lodash/fromPairs'
import get from 'lodash/get'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import throttle from 'lodash/throttle'
import { Semantic } from 'packages/components'
import { PermissionModal } from 'packages/components/permissionsOverlay/permissionModal'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { TabPropTypes } from 'packages/components/tabs'
import {
  FEATURE_PROPERTY_KEYS,
  MESSAGE_TYPE,
  STYLE_FEATURE_PROPERTIES,
  STYLE_TYPES,
  URLS,
} from 'packages/enum'
import { getLoaderShortProperty, getPropertyMediaUrl } from 'packages/helpers/Helper'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-apollo'
import { injectIntl, intlShape } from 'react-intl'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import CodeModal from '../codeModal'
import { LoaderContainer } from './nodes'
import StyleForm from './styleForm'

const stylesList = [
  {
    type: STYLE_TYPES.logo,
    title: 'styles.title.logo',
    padding: true,
  },
  {
    type: STYLE_TYPES.header,
    title: 'styles.title.header',
    padding: true,
  },
  {
    type: STYLE_TYPES.burgerMenu,
    title: 'styles.title.burgerMenu',
  },
  {
    type: STYLE_TYPES.burgerColor,
    title: 'styles.title.burger',
    padding: true,
  },
  {
    type: STYLE_TYPES.navigation,
    title: 'styles.title.navigation',
    padding: true,
  },
  {
    type: STYLE_TYPES.pageBackground,
    title: 'styles.title.backgroundColor',
  },
  {
    type: STYLE_TYPES.photos,
    title: 'styles.title.photoPage',
  },

  {
    type: STYLE_TYPES.albumList,
    title: 'styles.title.albumListPage.title',
  },
  {
    type: STYLE_TYPES.album,
    title: 'styles.title.albumPage',
  },
  {
    type: STYLE_TYPES.contacts,
    title: 'styles.title.contacts',
  },
  {
    type: STYLE_TYPES.text,
    title: 'styles.title.textPage',
    formTitle: 'styles.title.textPage.text',
    padding: true,
  },

  {
    type: STYLE_TYPES.hoverColor,
    title: 'styles.title.hover',
    padding: true,
  },

  {
    type: STYLE_TYPES.slideshow,
    title: 'tabs.tab.slideshow',
    padding: true,
  },

  {
    type: STYLE_TYPES.imageDetail,
    title: 'styles.title.imageDetail',
    padding: true,
  },

  {
    type: STYLE_TYPES.loaderAnimation,
    title: 'styles.title.loaderAnimation',
    padding: true,
  },
  {
    type: STYLE_TYPES.socialLinks,
    title: 'styles.title.socialLinks',
  },
  {
    type: STYLE_TYPES.footer,
    title: 'styles.title.footer',
  },
]

const stylesListMobile = [
  {
    type: STYLE_TYPES.logoMobile,
    title: 'styles.title.logo',
  },
  {
    type: STYLE_TYPES.headerMobile,
    title: 'newDesign.page.create.field.mainNavigation',
  },
  {
    type: STYLE_TYPES.burgerMenuMobile,
    title: 'styles.title.burgerMenu',
  },
  {
    type: STYLE_TYPES.albumListMobile,
    title: 'styles.title.albumListPage.title',
  },
  {
    type: STYLE_TYPES.albumMobile,
    title: 'styles.title.albumPage',
  },
  {
    type: STYLE_TYPES.photosMobile,
    title: 'styles.title.photoPage',
  },
  {
    type: STYLE_TYPES.imageDetailMobile,
    title: 'styles.title.imageDetail',
  },
  {
    type: STYLE_TYPES.footerMobile,
    title: 'styles.title.footer',
  },
]

const boolProperties = [
  STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_YEAR,
  STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_DOMAIN_TITLE,
]

const PageStyle = ({
  isMobile,
  featureData = {},
  loaderData = [],
  featureUpdate,
  featureUpdateWithPhoto,
  openErrorModal,
  closeErrorModal,
  // history,
  refetchPagePreview,
  updateFeatureProperties,
  featureResetTemplate,
  websiteShareFeaturesCreate,
  websiteShareFeaturesDelete,
  websiteShareFeaturesApply,
  featureResetFeaturesProp,
  websiteId,
  domain,
  loading,
  loaderStart,
  loaderStop,
  templatesColor,
  changeTemplatesColor,
  updateTemplate,
  updateSlideshow,
  templateParams,
  scrollableHeader,
  socialLinks,
  websiteMetaUpdate,
  logoValues,
  tab: { setBackArrowState, clearBackArrowState, onChangeTab },
  intl: { formatMessage },
}) => {
  const [selectedStyle, setSelectedStyle] = useState(null)
  const [newTemplatesColor, setNewTemplatesColor] = useState(templatesColor)
  const [isChangeTemplateDialogOpen, setIsChangeTemplateDialogOpen] = useState(false)
  const [isFeaturesListExceeded, setIsIsFeaturesListExceeded] = useState(false)
  const [applyCodeModalOpen, setApplyCodeModalOpen] = useState(false)
  const [sharedCode, setSharedCode] = useState(null)
  const dispatch = useDispatch()
  const { permissions } = useSubscription()
  const { data: { websiteShareFeaturesGet } = {} } = useQuery(websiteShareFeaturesGetQuery)
  const [isScrollableHeader, setIsScrollableHeader] = useState(false)

  const styles = useMemo(
    () => ({
      ...fromPairs(
        get(featureData, 'properties', []).map(({ key, value }) => {
          if (boolProperties.includes(key)) {
            value = isString(value) ? value === 'true' : !!value
          }

          return [key, value]
        })
      ),
      [STYLE_FEATURE_PROPERTIES.SCROLLABLE_HEADER]: scrollableHeader?.enabled,
      ...(selectedStyle?.type === STYLE_TYPES.socialLinks && {
        [STYLE_FEATURE_PROPERTIES.SOCIAL_LINKS]: socialLinks,
      }),
    }),
    [featureData, scrollableHeader?.enabled, selectedStyle?.type, socialLinks]
  )

  useEffect(() => {
    if (scrollableHeader) {
      setIsScrollableHeader(scrollableHeader.enabled)
    }
  }, [scrollableHeader])

  const loaderStyles = loaderData
    .map((loaderField) =>
      fromPairs(
        get(loaderField, 'properties', [])
          .filter((item) => {
            if (loaderField.featureName === STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM) {
              return item.key === 'customMediaUrl' && item.value !== null
            }
            return item
          })
          .map(({ value }) => [loaderField.featureName, value])
      )
    )
    .reduce((acc, item) => ({ ...acc, ...item }), {})

  const handleChangeTemplatesColor = (value) => {
    if (value === templatesColor) return
    setNewTemplatesColor(value)
    setIsChangeTemplateDialogOpen(true)
  }

  const handleTemplateUpdateConfirm = async () => {
    const { success } = await updateTemplate({
      ...templateParams,
      inverted: newTemplatesColor === 'black',
    })
    success && changeTemplatesColor(newTemplatesColor)
    setIsChangeTemplateDialogOpen(false)
  }

  const handleTemplateUpdateCancel = () => {
    setNewTemplatesColor(templatesColor)
    setIsChangeTemplateDialogOpen(false)
  }

  const handleSubmitForm = async ({ FOOTER_OVER_SLIDESHOW, ...values }, { setSubmitting }) => {
    let defaultError = 'fonts.update.error.text'
    loaderStart()
    let res
    try {
      if (selectedStyle.type === STYLE_TYPES.loaderAnimation) {
        const result = await Promise.all(
          loaderData.map(async ({ featureName, id }) => {
            const fieldName = getLoaderShortProperty(featureName)
            if (featureName === STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM) {
              if (values[STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM] instanceof File) {
                const res = await featureUpdateWithPhoto({
                  featureId: id,
                  file: values[STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM],
                  propertyName: fieldName,
                })
                delete values[STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM]
                delete values[getPropertyMediaUrl(STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM)]
                return res
              } else {
                return await featureUpdate({
                  featureId: id,
                  properties: {
                    [getPropertyMediaUrl(fieldName)]: values[featureName] ?? null,
                  },
                })
              }
            } else {
              return await featureUpdate({
                featureId: id,
                properties: {
                  [fieldName]: values[featureName] ?? null,
                },
              })
            }
          })
        )
        const responseArray = result
          .filter((response) => response !== undefined)
          .map((response) => {
            const [featureUpdate] = Object.keys(response.data)
            return response.data[featureUpdate]
          })
        const hasErrors = responseArray.some((response) => response.errors)
        const allSuccessed = responseArray.every((response) => response.success)
        if (hasErrors) {
          res = { data: { featureUpdate: { errors: null, success: false } } }
        } else if (allSuccessed) {
          res = { data: { featureUpdate: { errors: null, success: true } } }
        }
      } else {
        if (selectedStyle.type === STYLE_TYPES.header && !!scrollableHeader) {
          await featureUpdate({
            featureId: scrollableHeader.id,
            properties: {},
            enabled: isScrollableHeader,
          })
        }

        res = await featureUpdate({
          featureId: get(featureData, 'id'),
          properties: omit(values, STYLE_FEATURE_PROPERTIES.SOCIAL_LINKS),
        })

        if (selectedStyle.type === STYLE_TYPES.socialLinks) {
          const socialLinks = get(values, STYLE_FEATURE_PROPERTIES.SOCIAL_LINKS).map(
            (value, index) => ({
              name: value.name,
              url: value.url?.replace(/https?:\/\//g, ''),
              iconId: `${value.name}_default`,
              orderIndex: index,
            })
          )

          const {
            data: {
              websiteMetaUpdate: { errors, success },
            },
          } = await websiteMetaUpdate({ socialLinks, websiteId })

          if (!success) {
            res = { data: { featureUpdate: { errors, success: false } } }
          }
        }
      }

      let {
        data: { featureUpdate: { errors, success } = {} },
      } = res
      if (!success) {
        setSubmitting(false)
        loaderStop()
        return openErrorModal({
          headerMessageId: 'error.header',
          subMessageId: errors?._error || defaultError,
          hideCancelButton: true,
          yesMessageId: 'upload.error.ok',
          styles: { width: '450px' },
        })
      } else {
        window.frames['preview-frame']?.postMessage(
          JSON.stringify({
            name: 'update_features',
          }),
          '*'
        )
        refetchPagePreview().then(loaderStop)
      }
    } catch (err) {
      setSubmitting(false)
      loaderStop()
      return openErrorModal({
        headerMessageId: 'error.header',
        subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
        hideCancelButton: true,
        yesMessageId: 'upload.error.ok',
        styles: { width: '450px' },
      })
    }
  }

  const handleResetStyles = () => {
    let defaultError = 'fonts.update.error.text'
    loaderStart()
    closeErrorModal()
    featureResetTemplate({
      websiteId,
    })
      .then((res) => {
        let {
          data: { featureResetTemplate: { errors, success } = {} },
        } = res
        if (!success) {
          loaderStop()
          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId: errors._error || defaultError,
            hideCancelButton: true,
            yesMessageId: 'upload.error.ok',
            styles: { width: '450px' },
          })
        } else {
          refetchPagePreview().then(loaderStop)
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: 'update_features',
            }),
            '*'
          )
        }
      })
      .catch((err) => {
        openErrorModal({
          headerMessageId: 'error.header',
          subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
          hideCancelButton: true,
          yesMessageId: 'upload.error.ok',
          styles: { width: '450px' },
        })
        loaderStop()
      })
  }

  const handleSaveCustomStyles = () => {
    let defaultError = 'fonts.update.error.text'
    loaderStart()
    closeErrorModal()
    websiteShareFeaturesCreate({
      websiteId,
    })
      .then((res) => {
        let {
          data: { websiteShareFeaturesCreate: { success, data } = {} },
        } = res
        if (!success) {
          loaderStop()
          setIsIsFeaturesListExceeded(true)
        } else {
          setSharedCode(data.code)
          loaderStop()
        }
      })
      .catch((err) => {
        openErrorModal({
          headerMessageId: 'error.header',
          subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
          hideCancelButton: true,
          yesMessageId: 'upload.error.ok',
          styles: { width: '450px' },
        })
        loaderStop()
      })
  }

  const handleResetFeatureProperties = ({ featurePropertyNames }) => {
    let defaultError = 'fonts.update.error.text'
    loaderStart()
    closeErrorModal()
    featureResetFeaturesProp({
      featurePropertyNames,
      featureId: get(featureData, 'id'),
      domain,
    })
      .then((res) => {
        let {
          data: { featureResetFeaturesProp: { errors, success } = {} },
        } = res
        if (!success) {
          loaderStop()
          return openErrorModal({
            headerMessageId: 'error.header',
            subMessageId: errors._error || defaultError,
            hideCancelButton: true,
            yesMessageId: 'upload.error.ok',
            styles: { width: '450px' },
          })
        } else {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: 'update_features',
            }),
            '*'
          )
          refetchPagePreview().then(loaderStop)
        }
      })
      .catch((err) => {
        openErrorModal({
          headerMessageId: 'error.header',
          subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
          hideCancelButton: true,
          yesMessageId: 'upload.error.ok',
          styles: { width: '450px' },
        })
        loaderStop()
      })
  }

  const handleUpdateFeatureProp = useMemo(
    () =>
      throttle(({ key, values }) => {
        const { FOOTER_OVER_SLIDESHOW, SCROLLABLE_HEADER, ...styleValues } = values

        if (SCROLLABLE_HEADER !== null || SCROLLABLE_HEADER !== undefined) {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
              key: FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER,
              value: isScrollableHeader,
            }),
            '*'
          )
        }

        window.frames['preview-frame']?.postMessage(
          JSON.stringify({
            name: 'update_feature',
            key,
            values: styleValues,
          }),
          '*'
        )
        updateFeatureProperties({
          key: FEATURE_PROPERTY_KEYS.style,
          values: styleValues,
        })
      }, 32),
    [updateFeatureProperties, isScrollableHeader]
  )

  const handleToggleScrollableHeader = (value) => {
    if (typeof value === 'boolean') {
      setIsScrollableHeader(value)
    } else {
      setIsScrollableHeader(!isScrollableHeader)
    }
  }

  const handleDiscard = useCallback(() => {
    if (!!scrollableHeader && selectedStyle.type === STYLE_TYPES.navigation) {
      setIsScrollableHeader(scrollableHeader.enabled)
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.SET_FEATURE_ENABLED,
          key: FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER,
          value: scrollableHeader.enabled,
        }),
        '*'
      )
    }
  }, [selectedStyle?.type, scrollableHeader])

  const isV2Template = templateParams?.template?.includes('v2')

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Semantic.Loader active size='large' />
        </LoaderContainer>
      )}
      {!selectedStyle && (
        <>
          {!isMobile && (
            <>
              {!isV2Template && (
                <StylesTemplatesColorToggle
                  value={templatesColor}
                  onChange={handleChangeTemplatesColor}
                />
              )}
              <StylesTitle>{formatMessage({ id: 'styles.title.visiblepro' })}</StylesTitle>
            </>
          )}
          {(isMobile ? stylesListMobile : stylesList)
            ?.filter((el) => {
              if (!isV2Template) {
                if (
                  el?.type === STYLE_TYPES.burgerMenu ||
                  el?.type === STYLE_TYPES.albumList ||
                  el?.type === STYLE_TYPES.imageDetail ||
                  el?.type === STYLE_TYPES.slideshow
                ) {
                  return false
                }
              } else if (isV2Template && el?.type === STYLE_TYPES.burgerColor) {
                return false
              }

              return true
            })
            .map(({ padding, type, title, ...rest }) => (
              <StyleItem
                key={type}
                style={{
                  marginBottom: padding ? '10px' : '0',
                  paddingBottom: padding ? '10px' : '0',
                  borderBottom: padding ? '1px solid #e1e2e6' : 'none',
                }}
                onClick={() => {
                  if (selectedStyle && selectedStyle.type === type) {
                    setSelectedStyle(null)
                    clearBackArrowState()
                  }
                  if (type === 'logo' || type === 'logoMobile') {
                    setSelectedStyle({ type, title: 'styles.logo.title', ...rest })
                  } else {
                    setSelectedStyle({ type, title, ...rest })
                  }
                }}
              >
                {formatMessage({ id: title })}
              </StyleItem>
            ))}
          <ResetButton
            view='secondaryGray'
            content={formatMessage({
              id: 'styles.button.resetAllSettingsToDefault',
            })}
            color='black'
            onClick={() => {
              return openErrorModal({
                onClickYes: handleResetStyles,
                headerMessageId: 'styles.reset.header',
                subMessageId: 'styles.reset.subHeader',
                yesMessageId: 'modalYesNo.yes',
              })
            }}
          />
          <PermissionsOverlay isAllowed={+permissions.FEATURES_SHARES_COUNT > 0}>
            <FeatureCodeButton
              data-intercom-target={'SaveCustomSettings'}
              view='primary'
              content={formatMessage({
                id: 'feature.share.save',
              })}
              color='black'
              onClick={() => {
                return openErrorModal({
                  onClickYes: handleSaveCustomStyles,
                  headerMessageId: 'feature.share.generate.code',
                  subMessageId: 'feature.share.generate.code.desc',
                  yesMessageId: 'feature.share.generate.code.proceed',
                })
              }}
            />
          </PermissionsOverlay>

          <PermissionsOverlay isAllowed={+permissions.FEATURES_SHARES_COUNT > 0}>
            <FeatureCodeButton
              view='secondaryGray'
              content={formatMessage({ id: 'feature.share.load' })}
              color='black'
              onClick={() => {
                setApplyCodeModalOpen(true)
              }}
            />
          </PermissionsOverlay>
          {/*<PermissionsOverlay isAllowed={+permissions.FEATURES_SHARES_COUNT > 0}>
            <FeatureCodeButton
              view='secondaryGray'
              content={formatMessage({ id: 'feature.share.show' })}
              color='black'
              onClick={() => {
                setIsShareStylesModalOpen(true)
              }}
            />
          </PermissionsOverlay>*/}
        </>
      )}

      {selectedStyle && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          validate={() => ({})}
          initialValues={selectedStyle.type === STYLE_TYPES.loaderAnimation ? loaderStyles : styles}
          onSubmit={handleSubmitForm}
          render={(props) => {
            return (
              <StyleForm
                logoValues={logoValues}
                isV2Template={isV2Template}
                isMobile={isMobile}
                templateName={templateParams?.template}
                handleDiscard={handleDiscard}
                slideshowEnabled={templateParams?.slideshowEnabled}
                toggleScollableHeader={handleToggleScrollableHeader}
                scrollableHeader={
                  !!scrollableHeader ? { ...scrollableHeader, enabled: isScrollableHeader } : null
                }
                selectedStyle={selectedStyle}
                setSelectedStyle={setSelectedStyle}
                initialValues={
                  selectedStyle.type === STYLE_TYPES.loaderAnimation ? loaderStyles : styles
                }
                updateFeatureState={handleUpdateFeatureProp}
                resetFeaturesProp={({ featurePropertyNames }) =>
                  openErrorModal({
                    onClickYes: () => handleResetFeatureProperties({ featurePropertyNames }),
                    headerMessageId: 'styles.reset.header',
                    subMessageId: 'styles.reset.subHeader',
                    yesMessageId: 'modalYesNo.yes',
                  })
                }
                openErrorModal={openErrorModal}
                closeErrorModal={closeErrorModal}
                clearBackArrowState={clearBackArrowState}
                setBackArrowState={setBackArrowState}
                updateTemplate={updateTemplate}
                updateSlideshow={updateSlideshow}
                onChangeTab={onChangeTab}
                {...props}
              />
            )
          }}
        />
      )}
      {isChangeTemplateDialogOpen && (
        <ChangeTemplateModal
          open
          onClickYes={handleTemplateUpdateConfirm}
          onClose={handleTemplateUpdateCancel}
        />
      )}
      {sharedCode && (
        <CodeModal
          code={sharedCode}
          open
          onClickYes={() => setSharedCode(null)}
          onClose={() => setSharedCode(null)}
        />
      )}
      {applyCodeModalOpen && (
        <ApplyFeatureCodeContainer
          code={sharedCode}
          open
          openErrorModal={openErrorModal}
          codes={websiteShareFeaturesGet?.data || []}
          onDelete={async (code) => {
            loaderStart()
            await websiteShareFeaturesDelete({
              code,
            })
            loaderStop()
          }}
          applyCode={async (code) => {
            let defaultError = 'fonts.update.error.text'
            loaderStart()
            closeErrorModal()
            websiteShareFeaturesApply({
              code,
              websiteId,
            })
              .then((res) => {
                let {
                  data: { websiteShareFeaturesApply: { errors, success } = {} },
                } = res
                if (!success) {
                  loaderStop()
                  return openErrorModal({
                    headerMessageId: 'error.header',
                    subMessageId: errors._error || defaultError,
                    hideCancelButton: true,
                    yesMessageId: 'upload.error.ok',
                    styles: { width: '450px' },
                  })
                } else {
                  window.location = '/website/config'
                }
              })
              .catch((err) => {
                openErrorModal({
                  headerMessageId: 'error.header',
                  subMessageId: __PRODUCTION__ ? defaultError : err.toString(),
                  hideCancelButton: true,
                  yesMessageId: 'upload.error.ok',
                  styles: { width: '450px' },
                })
                loaderStop()
              })
          }}
          onClickYes={() => setApplyCodeModalOpen(false)}
          onClose={() => setApplyCodeModalOpen(false)}
        />
      )}
      {isFeaturesListExceeded && (
        <PermissionModal
          headerMessageId={'feature.share.maximum'}
          subMessageId={'feature.share.maximum.upgrade'}
          open={() => {}}
          onClose={() => setIsIsFeaturesListExceeded(false)}
          onButtonClick={() => {
            dispatch(push(URLS.website_billing))
            setIsIsFeaturesListExceeded(false)
          }}
        />
      )}
    </Container>
  )
}

PageStyle.propTypes = {
  isMobile: PropTypes.bool,
  intl: intlShape.isRequired,
  websiteId: PropTypes.number,
  domain: PropTypes.string,
  history: PropTypes.object,
  featureData: PropTypes.object,
  featureUpdate: PropTypes.func.isRequired,
  featureUpdateWithPhoto: PropTypes.func.isRequired,
  openErrorModal: PropTypes.func.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
  updateFeatureProperties: PropTypes.func.isRequired,
  featureResetTemplate: PropTypes.func.isRequired,
  featureResetFeaturesProp: PropTypes.func.isRequired,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  refetchPagePreview: PropTypes.func,
  loading: PropTypes.bool,
  scrollableHeader: PropTypes.object,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      iconId: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  ...TabPropTypes,
}

PageStyle.defaultProps = {
  loading: false,
  refetchPagePreview: () => null,
}
PageStyle.contextTypes = {
  intl: PropTypes.object.isRequired,
  refetchPagePreview: () => null,
}
export default injectIntl(PageStyle)
