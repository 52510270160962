import { CenteredDropModal, UploadWrapper } from './nodes'
import Dropzone from 'react-dropzone'
import { injectIntl } from 'react-intl'

import { FileUploadIcon } from 'packages/components/icons'

const dropModalStyle = {
  height: '540px',
  width: '670px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '20px',
  paddingBottom: '20px',
}

const DropModal = ({
  intl: { formatMessage },
  isOpen,
  onClose,
  onDrop,
  onReject,
  maxSize,
  accept,
  openErrorModal,
  textInfoId,
}) => {
  const handleDropFile = (files) => {
    if (files && files.length) {
      const file = files[0]

      const preview = {
        index: 0,
        file,
        name: file.name,
        preview: window.URL.createObjectURL(file),
      }

      onDrop?.(preview, file)
    }
  }

  const onDropRejected = (files, accept) => {
    if (files.find((el) => el.size > maxSize)) {
      onReject?.()

      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: maxSize / 1024 / 1024 },
      })
    }

    const acceptArr = accept.split(', ')

    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
      })
    }
  }

  return (
    <CenteredDropModal
      open={isOpen}
      onClose={() => onClose(false)}
      styles={dropModalStyle}
      centered
    >
      <UploadWrapper>
        <Dropzone
          accept={accept}
          onDrop={handleDropFile}
          multiple={false}
          maxSize={maxSize}
          onDropRejected={(files) => onDropRejected(files, accept)}
        >
          {({ getInputProps, getRootProps }) => (
            <div {...getRootProps()} className='input'>
              <input {...getInputProps()} />

              <FileUploadIcon color='lightGray' />

              <span className='dropzone-header'>
                {formatMessage({
                  id: 'upload.video.text.title',
                })}
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: 'upload.video.text.browse',
                    }),
                  }}
                />
              </span>
              <span
                className='dropzone-footer'
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: textInfoId,
                  }),
                }}
              />
            </div>
          )}
        </Dropzone>
      </UploadWrapper>
    </CenteredDropModal>
  )
}

export default injectIntl(DropModal)
