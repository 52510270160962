import styled from 'styled-components'
import { media } from 'packages/components/media'

export const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
  }

  ${media.max.phone`
        flex-wrap: wrap;

        > b {
            width: 100%;
            text-align: left;
            margin-top: 10px;
        }
    `}
`

export const Title = styled.span`
  margin: 0 16px 0 10px;
  user-select: none;

  ${media.max.phone`
        width: 50%;
        text-align: left;
    `}
`
