import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { filterUsersList } from './actions'

const defaultState = fromJS({ filter: '' })

export default handleActions(
  { [filterUsersList]: (state, { payload }) => state.merge({ filter: payload }) },
  defaultState
)
