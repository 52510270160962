import PropTypes from 'prop-types'
import { iconColorPropTypes, MobileIcon } from '../../icons'
import { StyledSVG } from '../svgStyles'

export const MobileButton = StyledSVG(MobileIcon)

MobileButton.propTypes = {
  ...iconColorPropTypes,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

MobileButton.defaultProps = {
  color: 'darkGray',
  active: false,
  className: '',
  onClick: () => {},
}
