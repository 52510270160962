import { useEffect, useState } from 'react'
import { mobileBreakpoint } from '../enum'
import { useMediaQuery } from 'packages/helpers/useMediaQuery'

export const useWindowSize = () => {
  const orientationMobile = useMediaQuery('(orientation: portrait) and (pointer: coarse)')

  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      clientWidth: window.document.body.clientWidth,
      clientHeight: window.document.body.clientHeight,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  // Дадим отрендрится всему
  useEffect(() => {
    setTimeout(setWindowSize(getSize()), 50)
  }, [])

  useEffect(() => {
    setWindowSize(getSize())
  }, [orientationMobile])

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState()

  const handleResize = () => setIsMobile(window.innerWidth <= mobileBreakpoint.thin)

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}
