import React from 'react'
import PropTypes from 'prop-types'

export const Close = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
    <path
      fill='none'
      stroke='#454545'
      strokeLinecap='round'
      strokeMiterlimit='10'
      d='M51.5 51.5L.5.5M.5 51.5l51-51'
    />
  </svg>
)

Close.propTypes = {
  className: PropTypes.string.isRequired,
}
