import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Modal } from 'packages/components/modal'
import { Semantic } from 'packages/components'
import Message from 'packages/components/message/Message'
import { Button } from 'packages/components/buttons'
import { Content, Header, FormRow, ButtonRow, Subheader, Code } from './nodes'

import { FormInput } from 'packages/components/inputs'
import CopyToClipboardButton from '../codeModal/copyToClipboardButton/CopyToClipboardButton'
import { TrashIcon } from 'packages/components/icons/basic'
import { useSubscription } from '@configurator/providers/subscription'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'

const ApplyCodeModal = (
  {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    setFieldValue,
    loading,
    open,
    onClose,
    onDelete,
    codes,
  },
  { intl: { formatMessage } }
) => {
  const { permissions } = useSubscription()

  const [deleteModalOpen, setDeleteModalOpen] = useState()

  const error = Object.keys(errors).length || (status && !!status.error)
  return (
    <Modal
      open={open}
      closeOnDocumentClick
      onClose={onClose}
      size='large'
      closeIcon
      dimmer={false}
      styles={{
        width: '780px',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '60px',
        paddingBottom: '60px',
      }}
    >
      <Content>
        <Header>{formatMessage({ id: 'feature.share.apply' })}</Header>
        <Semantic.Form
          noValidate
          error={error}
          loading={isSubmitting || loading}
          onSubmit={handleSubmit}
        >
          <Message error={error} errors={errors} content={status && status.error} />
          <FormRow>
            <FormInput
              name='code'
              label='feature.share.code'
              placeholder={formatMessage({ id: 'feature.share.code.enter' })}
              type='text'
              // width="596px"
            />
          </FormRow>
          <ButtonRow>
            <Button
              type='submit'
              fluid
              disabled={isSubmitting}
              content={formatMessage({ id: 'feature.share.apply.button' })}
            />
          </ButtonRow>
        </Semantic.Form>
      </Content>
      <br />
      <br />
      <Content>
        <Header>{formatMessage({ id: 'feature.share.your.codes' })}</Header>
        <Subheader>
          {formatMessage({ id: 'feature.share.stores' })} {permissions.FEATURES_SHARES_COUNT}
        </Subheader>
        {codes.map((itm) => (
          <ButtonRow key={itm.code}>
            <div>
              <CopyToClipboardButton
                textToCopy={itm.code}
                successMessage={formatMessage({ id: 'feature.share.copied' })}
              >
                <Code>{itm.code}</Code>
              </CopyToClipboardButton>
            </div>
            <TrashIcon
              onClick={() => {
                setDeleteModalOpen(itm.code)
              }}
            />
            <Button
              type='submit'
              className='button'
              content={formatMessage({ id: 'feature.share.apply.button' })}
              onClick={() => {
                setFieldValue('code', itm.code)
                handleSubmit()
              }}
            />
          </ButtonRow>
        ))}
      </Content>
      {deleteModalOpen && (
        <ModalConfirmDelete
          open
          onClickYes={() => {
            onDelete(deleteModalOpen)
            setDeleteModalOpen(false)
          }}
          onClickCancel={() => setDeleteModalOpen(false)}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </Modal>
  )
}

ApplyCodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

ApplyCodeModal.defaultProps = {
  open: false,
}

ApplyCodeModal.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default ApplyCodeModal
