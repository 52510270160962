import styled from 'styled-components'
import { media } from 'packages/components/media'
import { Semantic } from 'packages/components'
import { Popup } from 'packages/components/semantic'

const HEADER_HEIGHT = 80
const LIFT_SIDE_MIN_WIDTH = 400

export const Header = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  > a {
    &:first-child {
      padding: 20px;
      margin: 0 0 0 -2px;
    }
  }

  ${media.tablet`
       flex-grow: initial;
       width: ${LIFT_SIDE_MIN_WIDTH}px;
       min-width: ${LIFT_SIDE_MIN_WIDTH}px;
       > a {
            &:first-child {
                margin: 0 40px 0 10px;
            }
        }
    `}
`

export const ReturnArrowContainer = styled.div`
  font-size: 13px;
  line-height: 140%;
  padding: 3px;
  cursor: pointer;
  color: #343840;
  white-space: nowrap;

  > svg {
    margin-right: 10px;
  }
`

export const RightBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
`

export const DevicesBlock = styled.div`
  display: flex;
  align-items: center;
  grid-column: 2 / 3;

  > a {
    display: flex;
  }

  ${media.tablet`
        column-gap: 4px;
    `}
`

export const StyledPopup = styled(Popup)`
  background-color: #34363b !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 16px !important;
  color: #fff !important;
  margin: 12px 0 0 !important;
  max-width: 200px !important;

  &:before {
    top: -12px !important;
    margin-left: -12px !important;
    width: auto !important;
    height: auto !important;
    box-shadow: none !important;
    transform: none !important;
    border-left: 12px solid transparent !important;
    border-right: 12px solid transparent !important;
    border-bottom: 12px solid #34363b !important;
    background: none !important;
  }
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const PopupTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
`

export const PopupText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #ededed;
`

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 3 / 4;
  column-gap: 8px;

  ${media.tablet`
        margin-left: 24px;
    `}

  > * {
    margin: 0;
  }

  > .ui.dropdown {
    display: flex;
    align-items: center;
  }

  .menu.transition.visible {
    border-radius: 6px !important;
  }
`

export const LocalSwitcherBlock = styled.div`
  display: flex !important;
  justify-content: space-between !important;

  > .ui.dropdown {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    width: calc(100% - 10px);
    justify-content: flex-end;
    padding-right: 17px;
    color: #666a73;

    > .icon {
      color: #c1c3c7;
      transform: rotate(90deg);
    }

    &.active.visible {
      > .icon {
        transform: rotate(-90deg);
      }
    }
  }

  .visible.menu.transition {
    width: 135px !important;
    left: -148px !important;
    top: -25px !important;
    box-shadow: 0 4px 24px rgb(0 0 0 / 10%) !important;
  }
`

export const UserDropdownContainer = styled(Semantic.Dropdown)`
  &.active {
    > svg {
      transform: rotate(180deg);
    }
  }

  .menu.transition.visible {
    width: 288px;
    border: none;
    box-shadow: 0 24px 60px rgb(0 0 0 / 15%);
    padding: 10px 0;
    z-index: 101;

    &:after {
      content: none;
    }

    .header {
      margin: 10px 0 20px 0;
      padding: 0 20px;
    }

    > div {
      .item {
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0 10px !important;
        padding: 0 10px !important;
        border-radius: 2px;
      }

      > a {
        cursor: pointer;
        display: block;
        border: none;
        height: auto;
        text-align: left;
        line-height: 1em;
        color: rgba(0, 0, 0, 0.87);
        font-size: 1rem;
        text-transform: none;
        font-weight: normal;
        box-shadow: none;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.95);
        }
      }
    }

    .item {
      height: 40px;
      display: flex;
      align-items: center;
      margin: 0 10px !important;
      padding: 0 10px !important;
      border-radius: 2px;
    }

    .divider {
      margin: 12px 20px;
    }
  }
`

export const Initials = styled.div`
  white-space: nowrap;

  ${media.phone`
        text-overflow: ellipsis;
        max-width: 85px;
        white-space: nowrap;
        overflow: hidden;
    `}

  ${media.tablet`
         max-width: 250px;
         white-space: wrap;
         white-space: nowrap;
   `}
`

export const WhatsNewBlock = styled.div`
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;

  ${media.max.tablet`
        display: none !important;
    `}

  ${media.laptopLarge`
        margin-right: 42px;
    `}
`

export const InfoBannerLink = styled.a`
  display: block;
  margin: 0 8px 8px;
`

export const InfoBannerIconWrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 8px;
  height: 16px;
`

export const InfoBannerText = styled.div`
  font-size: 13px;
  max-width: 232px;
  white-space: break-spaces;
`
