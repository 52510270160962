import React, { useEffect } from 'react'
import { URLS } from 'packages/enum'
import Loader from '@configurator/components/loader/Loader.js'

import { Wrapper, Container } from '@landing/components/stripeRedirect/nodes'

const StripeRedirect = () => {
  useEffect(() => {
    window.location = URLS.website_billing
  }, [])
  return (
    <Wrapper>
      <Container>
        <Loader />
      </Container>
    </Wrapper>
  )
}

StripeRedirect.propTypes = {}

StripeRedirect.defaultProps = {}

StripeRedirect.contextTypes = {}

export default StripeRedirect
