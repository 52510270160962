import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import { Semantic } from 'packages/components'
import { SettingsContent, SettingsItemButton, Dropdown, IconWrapper } from './nodes'
import { iconColors } from '../icons'

const DropdownMenu = ({
  trigger,
  items,
  size = 's',
  isLastItemInRow,
  dropdownRef,
  closeOnBlur,
  openOnFocus,
  menuRef,
  open,
  disabled,
  intl: { formatMessage },
}) => {
  const isIconExist = items.filter((item) => !!item.icon).length > 0

  return (
    <Dropdown
      ref={dropdownRef}
      openOnFocus={openOnFocus}
      closeOnBlur={closeOnBlur}
      trigger={trigger}
      $isLastItemInRow={isLastItemInRow}
      direction='left'
      disabled={disabled}
      upward={false}
    >
      <Semantic.Dropdown.Menu open={open}>
        <SettingsContent size={size} ref={menuRef}>
          {items.map(
            (
              {
                text,
                onClick = () => null,
                icon,
                component,
                color = 'dark',
                header,
                overwrite,
                dataIntercomTarget,
              },
              i
            ) => {
              const isLastButton = i + 1 === items.length
              const isBorderBottomButton = i + 1 === 2
              const isAfterBorderBottomButton = i + 1 === 3

              if (overwrite && component) {
                const Component = component
                return (
                  <Component
                    dataIntercomTarget={dataIntercomTarget}
                    key={text}
                    onClick={onClick}
                    isLastButton={isLastButton}
                    isBorderBottomButton={isBorderBottomButton}
                    isAfterBorderBottomButton={isAfterBorderBottomButton}
                    text={text}
                  />
                )
              }

              return (
                <SettingsItemButton
                  dataIntercomTarget={dataIntercomTarget}
                  key={text}
                  header={header}
                  color={iconColors[color]}
                  onClick={onClick}
                  isIconExist={isIconExist}
                  isLastButton={isLastButton}
                  isBorderBottomButton={isBorderBottomButton}
                  isAfterBorderBottomButton={isAfterBorderBottomButton}
                >
                  {isIconExist && <IconWrapper>{icon}</IconWrapper>}
                  {formatMessage({ id: text })}
                  {component && component}
                </SettingsItemButton>
              )
            }
          )}
        </SettingsContent>
      </Semantic.Dropdown.Menu>
    </Dropdown>
  )
}

DropdownMenu.propTypes = {
  intl: intlShape.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      dataIntercomTarget: PropTypes.string,
      label: PropTypes.bool,
      text: PropTypes.string.isRequired,
      icon: PropTypes.node,
      onClick: PropTypes.func,
      header: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
      disabled: PropTypes.bool,
      overwrite: PropTypes.bool,
      color: PropTypes.oneOf(Object.keys(iconColors)),
    })
  ).isRequired,
  trigger: PropTypes.element.isRequired,
  size: PropTypes.string,
  isLastItemInRow: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  closeOnBlur: PropTypes.bool,
  menuRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  dropdownRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  open: PropTypes.bool,
  disabled: PropTypes.bool,
}

DropdownMenu.defaultProps = {}

export default injectIntl(DropdownMenu)
