import React from 'react'
import { iconColorPropTypes, iconColors } from '../const'
import PropTypes from 'prop-types'

export const SlideshowIcon = ({ color, newVariant }) =>
  !newVariant ? (
    <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 16.0009H18C19.0691 16.0329 19.963 15.1949 20 14.1259V1.87592C19.9636 0.806725 19.0693 -0.0316533 18 0.000917777H2C0.930683 -0.0316533 0.0364132 0.806725 0 1.87592V14.1249C0.0364164 15.1943 0.930516 16.0329 2 16.0009ZM2 13.9899V2.00092L18 2.01192V14.0009L2 13.9899ZM8 3.50098V12.501L14 8.00098L8 3.50098Z'
        fill={iconColors[color]}
      />
    </svg>
  ) : (
    <svg
      width='20'
      height='17'
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          d='M1248 4954 c-78 -42 -76 -168 3 -210 42 -21 2576 -21 2618 0 58 30
                     78 104 46 166 -33 64 52 60 -1357 60 -1115 -1 -1287 -3 -1310 -16z'
        />
        <path
          d='M671 4254 c-45 -37 -58 -91 -35 -139 9 -20 31 -46 48 -56 30 -18 88
                     -19 1876 -19 1788 0 1846 1 1876 19 17 10 39 36 48 56 23 48 10 102 -35 139
                     l-31 26 -1858 0 -1858 0 -31 -26z'
        />
        <path
          d='M514 3649 c-243 -41 -449 -236 -499 -474 -22 -104 -22 -2425 0 -2530
                     45 -216 201 -385 425 -461 53 -18 128 -19 2120 -19 1992 0 2067 1 2120 19 224
                     76 380 245 425 461 22 105 22 2426 0 2530 -43 205 -204 382 -410 453 l-80 27
                     -2025 1 c-1114 1 -2048 -2 -2076 -7z m4102 -254 c106 -31 191 -112 236 -223
                     l23 -57 3 -1160 c3 -1213 1 -1258 -40 -1345 -50 -103 -157 -181 -274 -200 -80
                     -13 -3928 -13 -4008 0 -163 26 -280 148 -306 315 -7 48 -10 436 -8 1230 l3
                     1160 23 57 c44 109 120 183 225 219 49 17 154 18 2063 19 1745 0 2017 -2 2060-15z'
        />
        <path
          d='M2114 2520 c-11 -4 -33 -22 -47 -40 l-27 -32 0 -553 0 -553 26 -31
                     c35 -42 89 -58 134 -38 61 25 894 517 920 543 21 20 25 34 25 79 0 45 -4 59
                     -25 79 -22 22 -799 484 -902 537 -40 20 -70 23 -104 9z m417 -473 c134 -79
                     243 -148 242 -153 -2 -7 -232 -146 -440 -265 l-53 -30 0 295 c0 163 2 296 4
                     296 2 0 113 -64 247 -143z'
        />
      </g>
    </svg>
  )

SlideshowIcon.propTypes = {
  ...iconColorPropTypes,
  newVariant: PropTypes.bool,
}

SlideshowIcon.defaultProps = {
  color: 'dark',
  newVariant: false,
}
