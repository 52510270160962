import React from 'react'
import PropTypes from 'prop-types'
import { Semantic } from 'packages/components'
import { Modal } from 'packages/components/modal'
import { Container, Header, Content, Actions } from './nodes'

export const ErrorModal = ({ open, onErrorChange, content }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  return (
    <Modal
      size='tiny'
      active={open && isOpen}
      dimmer={false}
      onClickOutside={() => setIsOpen(false)}
    >
      <Container>
        <Header>Error</Header>
        <Content>{content}</Content>
        <Actions>
          <Semantic.Button
            content='Ok'
            onClick={() => {
              setIsOpen(false)
              onErrorChange(false)
            }}
            positive
          />
        </Actions>
      </Container>
    </Modal>
  )
}

ErrorModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.string,
  onErrorChange: PropTypes.func,
}

ErrorModal.defaultProps = {
  open: false,
  content: '',
  onErrorChange: () => null,
}
