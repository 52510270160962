import React from 'react'
import PropTypes from 'prop-types'
import MostViewed from './MostViewed'

const MostViewedAlbum = ({ item, loading, list }) => (
  <MostViewed title='mostViewed.album.title' item={item} loading={loading} list={list} />
)

MostViewedAlbum.propTypes = {
  item: PropTypes.string,
  loading: PropTypes.bool,
  list: PropTypes.array,
}

MostViewedAlbum.defaultProps = {
  loading: false,
  list: [],
}

export default MostViewedAlbum
