import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'

class AnalyticsService {
  constructor() {
    mixpanel.init(__MIXPANEL_KEY__, { debug: true })
  }

  identify = ({ email, aboStatus, planType }) => {
    __MIXPANEL_KEY__ && mixpanel.identify(email)
    __MIXPANEL_KEY__ &&
      mixpanel.people.set({
        Plan: aboStatus,
        PlanType: planType,
        Email: email,
      })

    posthog.identify(
      'email', // Required. Replace 'distinct_id' with your user's unique identifier
      { email, aboStatus, planType }
    )
  }

  trackFreeSignUp = () => {
    __MIXPANEL_KEY__ && mixpanel.track('Free SignUp Click')
    posthog.capture('Free SignUp Click')
  }

  trackProSignUp = () => {
    __MIXPANEL_KEY__ && mixpanel.track('PRO SignUp Click')
    posthog.capture('PRO SignUp Click')
  }

  trackLogin = () => {
    __MIXPANEL_KEY__ && mixpanel.track('Login Click')
    posthog.capture('Login Click')
  }

  pageLoad = () => {
    __MIXPANEL_KEY__ && mixpanel.track('Page Load')
    posthog.capture('Page Load')
  }

  trackIntegration = (integrationName) => {
    __MIXPANEL_KEY__ && mixpanel.track(`Integration Details ${integrationName} Click`)
    posthog.capture(`Integration Details ${integrationName} Click`)
  }

  switchLang = (lang) => {
    __MIXPANEL_KEY__ && mixpanel.track(`Switch Lang To ${lang} Click`)
    posthog.capture(`Switch Lang To ${lang} Click`)
  }

  downgradeToFree = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Downgrade To Free Click`)
    posthog.capture(`Downgrade To Free Click`)
  }

  deleteAccount = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Delete Account Click`)
    posthog.capture(`Delete Account Click`)
  }

  billingThirdStep = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Proceed In Stripe Click`)
    posthog.capture(`Proceed In Stripe Click`)
  }

  startTrial = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Start Trial Click`)
    posthog.capture(`Start Trial Click`)
  }

  trialUpgrade = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Trial Upgrade Click`)
    posthog.capture(`Trial Upgrade Click`)
  }

  photoUpload = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Photo Upload`)
    posthog.capture(`Photo Upload`)
  }

  videoUpload = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Video Upload`)
    posthog.capture(`Video Upload`)
  }

  pageCreate = (pageType) => {
    __MIXPANEL_KEY__ && mixpanel.track(`Page Create [${pageType}]`)
    posthog.capture(`Page Create`)
  }

  changeTemplate = () => {
    __MIXPANEL_KEY__ && mixpanel.track(`Change Template`)
    posthog.capture(`Change Template`)
  }
}

export const ANALYTICS = new AnalyticsService()
