import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal } from 'packages/components/modal'
import { Button } from 'packages/components/buttons'
import { Content, Header, LoaderContainer, ButtonRow } from './nodes'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { URLS } from 'packages/enum'
import { PermissionModal } from 'packages/components/permissionsOverlay/permissionModal'
import CodeModal from '@configurator/components/pages/codeModal'
import { Semantic } from 'packages/components'

const ChangeTemplateModal = (
  { open, onClose, onClickYes, websiteId, websiteShareFeaturesCreate },
  { intl: { formatMessage } }
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFeaturesListExceeded, setIsIsFeaturesListExceeded] = useState(false)
  const [sharedCode, setSharedCode] = useState(null)
  const dispatch = useDispatch()

  return (
    <>
      <Modal
        open={open}
        closeOnDocumentClick
        onClose={onClose}
        size='mini'
        closeIcon
        styles={{
          width: '380px',
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
      >
        <Content>
          <Header>{formatMessage({ id: 'template.change.header' })}</Header>
          <span>{formatMessage({ id: 'template.change.note' })}</span>
          <span>{formatMessage({ id: 'template.change.question' })}</span>
          {isLoading && (
            <LoaderContainer>
              <Semantic.Loader active size='large' />
            </LoaderContainer>
          )}
          <ButtonRow>
            <Button
              content={formatMessage({ id: 'template.change.continue' })}
              onClick={onClickYes}
            />
            <Button
              view='primaryGray'
              content={formatMessage({ id: 'template.change.save' })}
              onClick={() => {
                setIsLoading(true)
                websiteShareFeaturesCreate({
                  websiteId,
                })
                  .then((res) => {
                    let {
                      data: { websiteShareFeaturesCreate: { success, data } = {} },
                    } = res
                    if (!success) {
                      setIsLoading(false)
                      setIsIsFeaturesListExceeded(true)
                    } else {
                      setIsLoading(false)
                      setSharedCode(data.code)
                    }
                  })
                  .catch(() => {
                    setIsLoading(false)
                  })
              }}
            />
          </ButtonRow>
        </Content>
      </Modal>
      {sharedCode && (
        <CodeModal
          onProceed={onClickYes}
          code={sharedCode}
          open
          onClickYes={() => setSharedCode(null)}
          onClose={() => setSharedCode(null)}
        />
      )}
      {isFeaturesListExceeded && (
        <PermissionModal
          headerMessageId={'feature.share.maximum'}
          subMessageId={'feature.share.maximum.upgrade'}
          open={() => {}}
          onClose={() => setIsIsFeaturesListExceeded(false)}
          onButtonClick={() => {
            dispatch(push(URLS.website_billing))
            setIsIsFeaturesListExceeded(false)
          }}
        />
      )}
    </>
  )
}

ChangeTemplateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

ChangeTemplateModal.defaultProps = {
  open: false,
}

ChangeTemplateModal.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default ChangeTemplateModal
