/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const TabletVibratesIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='8'
      y='6'
      width='8'
      height='12'
      stroke={iconColors[color]}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.5 8C5.5 7.72386 5.27614 7.5 5 7.5C4.72386 7.5 4.5 7.72386 4.5 8L5.5 8ZM4.5 16L4.5 16.5L5.5 16.5L5.5 16L4.5 16ZM4.5 8L4.5 16L5.5 16L5.5 8L4.5 8Z'
      stroke={iconColors[color]}
    />
    <path
      d='M19.5 8C19.5 7.72386 19.2761 7.5 19 7.5C18.7239 7.5 18.5 7.72386 18.5 8L19.5 8ZM18.5 16L18.5 16.5L19.5 16.5L19.5 16L18.5 16ZM18.5 8L18.5 16L19.5 16L19.5 8L18.5 8Z'
      stroke={iconColors[color]}
    />
    <path
      d='M2.5 9C2.5 8.72386 2.27614 8.5 2 8.5C1.72386 8.5 1.5 8.72386 1.5 9L2.5 9ZM1.5 15L1.5 15.5L2.5 15.5L2.5 15L1.5 15ZM1.5 9L1.5 15L2.5 15L2.5 9L1.5 9Z'
      stroke={iconColors[color]}
    />
    <path
      d='M22.5 9C22.5 8.72386 22.2761 8.5 22 8.5C21.7239 8.5 21.5 8.72386 21.5 9L22.5 9ZM21.5 15L21.5 15.5L22.5 15.5L22.5 15L21.5 15ZM21.5 9L21.5 15L22.5 15L22.5 9L21.5 9Z'
      stroke={iconColors[color]}
    />
  </svg>
)

TabletVibratesIcon.propTypes = {
  ...iconColorPropTypes,
}

TabletVibratesIcon.defaultProps = {
  color: 'dark',
}
