/* eslint-disable */
import React from 'react'
import { iconColors, iconColorPropTypes } from '../const'

export const DoubleRefreshIcon = ({ color }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.9623 12.6302C17.7663 14.4722 16.7233 16.1982 14.9973 17.1953C12.1298 18.8518 8.46317 17.8688 6.80761 14.9996L6.63128 14.694M6.03736 11.3698C6.2333 9.52782 7.27633 7.80176 9.00227 6.8047C11.8698 5.14816 15.5364 6.13122 17.192 9.00043L17.3683 9.30603M6 16.2811L6.51634 14.353L8.44333 14.8696M15.5567 9.13041L17.4837 9.64706L18 7.71892'
      stroke={iconColors[color]}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

DoubleRefreshIcon.propTypes = {
  ...iconColorPropTypes,
}

DoubleRefreshIcon.defaultProps = {
  color: 'dark',
}
