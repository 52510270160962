import BoxModelImg from 'src/packages/theme-ab/resources/assets/configurator/boxmodel.png'

const FroalaEditor = require('froala-editor')

const getIndentList = (value) => {
  let indentList = (value || '').match(/\d+/g) || []
  if (indentList.length === 1) {
    return Array(4).fill(indentList[0])
  }
  if (indentList.length === 2) {
    return [indentList[0], indentList[1], indentList[0], indentList[1]]
  }
  return indentList
}

const normalizeIndent = (value) => `${value || '0'}px`

const convertIndent = ({ top, right, bottom, left }) => {
  return `${normalizeIndent(top)} ${normalizeIndent(right)} ${normalizeIndent(
    bottom
  )} ${normalizeIndent(left)}`
}

// Define popup template.
FroalaEditor.POPUP_TEMPLATES['imageIndentPlugin.popup'] = '[_BUTTONS_][_CUSTOM_LAYER_]'

// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
  popupButtons: ['imageIndentBack', '|'],
})

// The custom popup is defined inside a plugin (new or existing).
FroalaEditor.PLUGINS.imageIndentPlugin = function (editor) {
  // Create custom popup.
  const initPopup = () => {
    // Popup buttons.
    let popup_buttons = ''

    // Create the list of buttons.
    if (editor.opts.popupButtons.length > 1) {
      popup_buttons += '<div class="fr-buttons">'
      popup_buttons += editor.button.buildList(editor.opts.popupButtons)
      popup_buttons += '</div>'
    }

    // Load popup template.
    let template = {
      buttons: popup_buttons,
      custom_layer: `
          <div class="custom-layer">
              <div class="fr-layer fr-active" id="fr-image-layer-1" data-mouseenter-event-set="true">
                  <div class="fr-input-line">
                      <input id="image-top-padding" name="image-top-padding" type="text" tabindex="1">
                      <label for="image-top-padding">Top padding in px</label>
                  </div>
                  <div class="fr-input-line">
                      <input id="image-top-margin" name="image-top-margin" type="text" tabindex="2">
                      <label for="image-top-margin">Top margin in px</label>
                  </div>
                  <div class="middle-wrapper">
                      <div>
                           <div class="fr-input-line">
                               <input id="image-left-padding" name="image-left-padding" type="text" tabindex="7">
                               <label for="image-left-padding">Left padding in px</label>
                           </div>
                          <div class="fr-input-line">
                              <input id="image-left-margin" name="image-left-margin" type="text" tabindex="8">
                              <label for="image-left-margin">Left margin in px</label>
                          </div>
                      </div>
                      <img src="${BoxModelImg}" alt=""/>
                      <div>
                          <div class="fr-input-line">
                              <input id="image-right-padding" name="image-right-padding" type="text" tabindex="3">
                              <label for="image-right-padding">Right padding in px</label>
                          </div>
                          <div class="fr-input-line">
                              <input id="image-right-margin" name="image-right-margin" type="text" tabindex="4">
                              <label for="image-right-margin">Right margin in px</label>
                          </div>
                      </div>
                  </div>
                  <div class="fr-input-line">
                      <input id="image-bottom-padding" name="image-bottom-padding" type="text" tabindex="5">
                      <label for="image-bottom-padding">Bottom padding in px</label>
                  </div>
                  <div class="fr-input-line">
                      <input id="image-bottom-margin" name="image-bottom-margin" type="text" tabindex="6">
                      <label for="image-bottom-margin">Bottom margin in px</label>
                  </div>
                  <div class="fr-action-buttons">
                      <button type="button" class="fr-command fr-submit" data-cmd="updateImageIndent" role="button">Update</button>
                  </div>
              </div>
          </div>
      `,
    }

    // Create popup.
    let $popup = editor.popups.create('imageIndentPlugin.popup', template)

    return $popup
  }

  // Show the popup
  const showPopup = () => {
    // Get the popup object defined above.
    let $popup = editor.popups.get('imageIndentPlugin.popup')

    // If popup doesn't exist then create it.
    // To improve performance it is best to create the popup when it is first needed
    // and not when the editor is initialized.
    if (!$popup) {
      $popup = initPopup()
    }

    // Set the editor toolbar as the popup's container.
    editor.popups.setContainer('imageIndentPlugin.popup', editor.$tb)

    // Get the button's object in order to place the popup relative to it.
    const $img = editor.image.get()

    // Find Values
    const imagePadding = getIndentList($img.css('padding'))
    const imageMargin = getIndentList($img.css('margin'))

    // Add Values to the input boxes + not-empty class if value exists
    $popup
      .find('input[id="image-top-padding"]')
      .val(imagePadding[0] || '')
      .addClass(imagePadding[0] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-top-margin"]')
      .val(imageMargin[0] || '')
      .addClass(imageMargin[0] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-right-padding"]')
      .val(imagePadding[1] || '')
      .addClass(imagePadding[1] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-right-margin"]')
      .val(imageMargin[1] || '')
      .addClass(imageMargin[1] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-bottom-padding"]')
      .val(imagePadding[2] || '')
      .addClass(imagePadding[2] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-bottom-margin"]')
      .val(imageMargin[2] || '')
      .addClass(imageMargin[2] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-left-padding"]')
      .val(imagePadding[3] || '')
      .addClass(imagePadding[3] ? 'fr-not-empty' : '')
    $popup
      .find('input[id="image-left-margin"]')
      .val(imageMargin[3] || '')
      .addClass(imageMargin[3] ? 'fr-not-empty' : '')

    const getPosLeft = () => {
      if ($img.offset().left + $img.outerWidth() + $popup.outerWidth() > window.innerWidth) {
        return $popup.addClass('fr-popup_offsreenX')
      }
      $popup.removeClass('fr-popup_offsreenX')
      return $img.offset().left + $img.outerWidth() / 2
    }

    // Set the popup's position.
    let left = getPosLeft()

    let top =
      $popup.outerHeight() + $img.offset().top + $img.outerHeight() > window.innerHeight
        ? $img.offset().top - $popup.outerHeight()
        : $img.offset().top + (editor.opts.toolbarBottom ? 10 : $img.outerHeight() - 10)

    // Show the custom popup.
    // The button's outerHeight is required in case the popup needs to be displayed above it.
    editor.popups.show('imageIndentPlugin.popup', left, top > 0 ? top : 1, $img.outerHeight())
  }

  // Back to image menu.
  const hidePopup = () => {
    editor.image.back()
  }

  // Update values
  const updateImageIndent = () => {
    const $popup = editor.popups.get('imageIndentPlugin.popup')

    // Find image in editor
    const $image = editor.image.get()

    // Get values
    const padding = convertIndent({
      top: $popup.find('input[id="image-top-padding"]').val(),
      right: $popup.find('input[id="image-right-padding"]').val(),
      bottom: $popup.find('input[id="image-bottom-padding"]').val(),
      left: $popup.find('input[id="image-left-padding"]').val(),
    })
    const margin = convertIndent({
      top: $popup.find('input[id="image-top-margin"]').val(),
      right: $popup.find('input[id="image-right-margin"]').val(),
      bottom: $popup.find('input[id="image-bottom-margin"]').val(),
      left: $popup.find('input[id="image-left-margin"]').val(),
    })

    $image.css('padding', padding)
    $image.css('margin', margin)

    editor.image.back()
  }

  // Methods visible outside the plugin.
  return {
    _init: initPopup,
    showPopup,
    hidePopup,
    updateImageIndent,
  }
}

// Define an icon and command for the button that opens the custom popup.
FroalaEditor.DefineIcon('imageIndent', { NAME: 'fullscreen', SVG_KEY: 'fullscreen' })
FroalaEditor.RegisterCommand('imageIndent', {
  title: 'Image Indent',
  icon: 'imageIndent',
  undo: false,
  focus: false,
  plugin: 'imageIndentPlugin',
  callback: function () {
    this.imageIndentPlugin.showPopup()
  },
})

// Define custom popup back button icon and command.
FroalaEditor.DefineIcon('imageIndentBack', { NAME: 'times', SVG_KEY: 'back' })
FroalaEditor.RegisterCommand('imageIndentBack', {
  title: 'Close',
  undo: false,
  focus: false,
  callback: function () {
    this.imageIndentPlugin.hidePopup()
  },
})

FroalaEditor.RegisterCommand('updateImageIndent', {
  title: 'Update Image Indent',
  undo: true,
  focus: true,
  callback: function () {
    this.imageIndentPlugin.updateImageIndent()
  },
})
