import React from 'react'
import PropTypes from 'prop-types'
import { iconColors, iconColorPropTypes } from '../const'

export const HideIcon = ({ color, showBg, size }) => {
  if (size === 'small') {
    return (
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.94132 0.000976562L13.2539 11.3135L13.255 11.3124L13.2562 11.3135L12.3134 12.2563L10.0755 10.0184C9.23755 10.4093 8.22182 10.6665 6.99967 10.6665C1.93834 10.6665 0.41722 6.25514 0.402632 6.21047L0.333008 5.99981L0.403295 5.78914C0.412092 5.76092 1.02237 3.99009 2.74666 2.6896L0.999676 0.942617L1.94132 0.000976562ZM9.05747 9.00041L7.85933 7.80227C7.59807 7.92871 7.30626 7.99981 6.99967 7.99981C5.90501 7.99981 4.99967 7.09447 4.99967 5.99981C4.99967 5.69323 5.07077 5.40141 5.19722 5.14016L3.69056 3.6335C2.51233 4.44376 1.94326 5.53965 1.74405 5.99981C2.07692 6.76914 3.4515 9.33314 6.99967 9.33314C7.78901 9.33314 8.47079 9.20624 9.05747 9.00041Z'
          fill={iconColors[color]}
        />
        <path
          d='M5.33315 1.5072C5.8404 1.39615 6.39475 1.33314 6.99967 1.33314C12.061 1.33314 13.5821 5.74447 13.5967 5.78914L13.6663 5.99981L13.5961 6.21047C13.5892 6.23249 13.2162 7.31523 12.2337 8.40776L11.3059 7.47998C11.8302 6.88621 12.1236 6.30405 12.2553 5.99981C11.9224 5.23047 10.5478 2.66647 6.99967 2.66647C6.83136 2.66647 6.66793 2.67224 6.50928 2.68332L5.33315 1.5072Z'
          fill={iconColors[color]}
        />
      </svg>
    )
  }
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {showBg && <circle className='iconBg' cx='16' cy='16' r='16' fill='white' />}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.41246 7.00195L25.3813 23.9708L25.3831 23.969L25.3848 23.9708L23.9706 25.385L20.6137 22.0281C19.3568 22.6144 17.8332 23.0002 16 23.0002C8.408 23.0002 6.12632 16.3832 6.10444 16.3162L6 16.0002L6.10543 15.6842C6.11863 15.6419 7.03404 12.9856 9.62047 11.0349L7 8.41441L8.41246 7.00195ZM19.0867 20.5011L17.2895 18.7039C16.8976 18.8936 16.4599 19.0002 16 19.0002C14.358 19.0002 13 17.6422 13 16.0002C13 15.5403 13.1066 15.1026 13.2963 14.7107L11.0363 12.4507C9.26898 13.6661 8.41537 15.31 8.11657 16.0002C8.61587 17.1542 10.6777 21.0002 16 21.0002C17.184 21.0002 18.2067 20.8098 19.0867 20.5011Z'
        fill={iconColors[color]}
      />
      <path
        d='M13.5002 9.26128C14.2611 9.09472 15.0926 9.0002 16 9.0002C23.592 9.0002 25.8737 15.6172 25.8956 15.6842L26 16.0002L25.8946 16.3162C25.8843 16.3492 25.3248 17.9733 23.8511 19.6121L22.4594 18.2205C23.2458 17.3298 23.6859 16.4566 23.8834 16.0002C23.3841 14.8462 21.3223 11.0002 16 11.0002C15.7475 11.0002 15.5024 11.0089 15.2644 11.0255L13.5002 9.26128Z'
        fill={iconColors[color]}
      />
    </svg>
  )
}

HideIcon.propTypes = {
  ...iconColorPropTypes,
  showBg: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
}

HideIcon.defaultProps = {
  color: 'dark',
  showBg: false,
  size: 'medium',
}
