import { compose } from 'redux'
import { graphql } from 'react-apollo'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import get from 'lodash/get'
import { COOKIE, PERIOD } from 'packages/enum'
import login from '@graphql/gql/common/login.gql'
import createAccount from '@graphql/gql/common/createAccount.gql'
import CreateAccount from '@landing/components/createAccount/CreateAccountForm'
import { Cookies } from 'react-cookie'
import { URLS } from 'packages/enum'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('landing.account.field.error.name'),
  email: Yup.string().email().required('landing.account.field.error.email'),
  password: Yup.string().required('landing.account.field.error.password'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'landing.account.field.match.password')
    .required('landing.account.field.confirm.password'),
  ...(__RECAPTCHA_KEY__ && {
    recaptcha: Yup.string().required('landing.account.create.recaptcha'),
  }),
})

export default compose(
  graphql(login, {
    props({ mutate }) {
      return {
        async loginMutation({ login, password }) {
          try {
            return await mutate({
              variables: {
                login: login.trim(),
                password,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  graphql(createAccount, {
    props({ mutate }) {
      return {
        async createAccountMutation({
          name,
          email,
          password,
          referral,
          recaptcha,
          freePlan,
          fbp,
          fbc,
        }) {
          try {
            return await mutate({
              variables: {
                name,
                email: email.trim(),
                password,
                referral,
                recaptcha,
                freePlan,
                fbp,
                fbc,
              },
            })
          } catch (err) {
            return {
              success: false,
            }
          }
        },
      }
    },
  }),
  withFormik({
    mapPropsToValues: ({
      initialValues = {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        recaptcha: '',
      },
    }) => {
      return {
        ...initialValues,
      }
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    handleSubmit: async (
      { name, email, password, recaptcha, freePlan },
      { setSubmitting, setStatus, props: { loginMutation, createAccountMutation } }
    ) => {
      let defaultError = 'error.desc'
      try {
        let fbpArr = document.cookie
          .split(';')
          .filter((c) => c.includes('_fbp='))
          .map((c) => c.split('_fbp=')[1])
        let fbcArr = document.cookie
          .split(';')
          .filter((c) => c.includes('_fbc='))
          .map((c) => c.split('_fbc=')[1])
        let fbp = (fbpArr.length && fbpArr[0]) || null
        let fbc = (fbcArr.length && fbcArr[0]) || null

        if (!fbc && window.location.search.includes('fbclid=')) {
          fbc = 'fb.1.' + +new Date() + '.' + window.location.search.split('fbclid=')[1]
        }

        const res = await createAccountMutation({
          name,
          email,
          password,
          referral: null,
          recaptcha,
          freePlan,
          fbp,
          fbc,
        })

        let {
          data: { createAccount: { errors, success } = {} },
        } = res
        if (success) {
          // CompleteRegistration google
          // gtag('event', 'conversion', { 'send_to': 'AW-662000735/MF5OCNqnqNoBEN-o1bsC' });
          gtag('event', 'conversion', { send_to: 'AW-11118125403/5Te9COWV55EYENvCxLUp' })

          const loginRes = await loginMutation({
            login: email,
            password,
          })
          let {
            data: { login: { success, data } = {} },
          } = loginRes

          if (success && get(data, 'token')) {
            new Cookies().set(COOKIE.token, get(data, 'token'), {
              expires: PERIOD.neverExpires,
              path: '/',
            })
            setSubmitting(false)
            return (window.location = URLS.website_config)
          }
          setSubmitting(false)
        } else {
          setSubmitting(false)
        }
        setStatus({ error: errors._error || defaultError })
      } catch (err) {
        setSubmitting(false)
        setStatus({
          error: __PRODUCTION__ ? defaultError : err.toString(),
        })
      }
    },
  })
)(CreateAccount)
